import { RouteNames } from "@rmp/enterprise/router/routes";
import { useBreadcrumb } from "@rmp/core/stores/composables/breadcrumb/useBreadcrumb";
import routeToPermissionsMap from "@rmp/enterprise/router/routeToPermissionsMap";
import { Dictionary } from "vue-router/types/router";

export function usePierDetailsBreadcrumb(text: string, params?: Dictionary<string>) {
	return useBreadcrumb({
		route: {
			name: RouteNames.PIER_DETAILS, params
		},
		text,
		routeToPermissionsMap
	});
}
