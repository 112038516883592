import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { useServicesBreadcrumb } from "@rmp/enterprise/stores/services/composables/useServicesBreadcrumb";
import { ApiServiceDocumentKindEnum } from "@rmp/core/api/types/service/document/ApiServiceDocumentKindEnum";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import router from "@rmp/enterprise/router";
import { RouteNames } from "@rmp/enterprise/router/routes";
import { ServiceDocumentMapper } from "@rmp/core/types/services/serviceDocument";
import { ServiceController } from "@rmp/enterprise/api/service";
import { SnoInstallationServiceWorkflowStateType } from "@rmp/core/types/services/snoInstallation/SnoInstallationServiceWorkflowStateType";
import { SnoInstallationServiceController } from "@rmp/enterprise/api/service/snoInstallation";
import {
	SnoInstallationServiceApplication,
	SnoInstallationServiceApplicationMapper
} from "@rmp/core/types/services/snoInstallation/snoInstallationServiceApplication";
import {
	useSnoInstallationServiceBreadcrumb
} from "@rmp/enterprise/stores/serviceDetails/snoInstallation/composables/useSnoInstallationServiceBreadcrumb";
import {
	useUploadSnoInstallationServiceDocumentsBreadcrumb
} from "@rmp/enterprise/stores/uploadServiceDocuments/snoInstallation/composables/useUploadSnoInstallationServiceDocumentsBreadcrumb";
import SnoInstallationServiceApplicationWorkflowService
	from "@rmp/core/services/serviceApplication/snoInstallationServiceApplicationWorkflowService";
import { ApiSnoInstallationServiceTransitionCodeEnum } from "@rmp/core/api/types/service/snoIntallation/ApiSnoInstallationServiceTransitionCodeEnum";
import {
	BaseUploadServiceDocumentsState,
	useBaseUploadServiceDocumentsStore
} from "@rmp/enterprise/stores/composables/baseUploadServiceDocuments";
import { formatServiceTitle } from "@rmp/core/utils/formatting";

const abortService = new AbortService();
const page = usePageStore(abortService);
const baseUploadServiceDocumentsStore = useBaseUploadServiceDocumentsStore({ abortService });

const snoInstallationTransitionService = new SnoInstallationServiceApplicationWorkflowService();

const snoInstallationServiceController = new SnoInstallationServiceController(abortService);
const serviceController = new ServiceController(abortService);

export interface UploadSnoInstallationServiceDocumentsState extends PageState, BaseUploadServiceDocumentsState {
	service: SnoInstallationServiceApplication;
}

const getDefaultState = (): UploadSnoInstallationServiceDocumentsState => {
	return {
		...page.getDefaultPageState(),
		...baseUploadServiceDocumentsStore.getDefaultState(),
		service: {} as SnoInstallationServiceApplication
	};
};

export const useUploadSnoInstallationServiceDocumentsStore = defineStore({
	id: "upload-sno-installation-service-documents",
	state: (): UploadSnoInstallationServiceDocumentsState => getDefaultState(),
	getters: {
		...page.getters,
		...baseUploadServiceDocumentsStore.getters,
		breadcrumbs(state: UploadSnoInstallationServiceDocumentsState) {
			return [
				useServicesBreadcrumb(),
				useSnoInstallationServiceBreadcrumb(state.title, { id: state.service.id }),
				useUploadSnoInstallationServiceDocumentsBreadcrumb()
			];
		},
		targetState(state: UploadSnoInstallationServiceDocumentsState) {
			if(!state.service.workflowState) return;
			
			switch (state.service.workflowState.alias) {
				case SnoInstallationServiceWorkflowStateType.SENT:
					return SnoInstallationServiceWorkflowStateType.APPROVED_BY_NGO_SERVICE_EMPLOYEE;
				case SnoInstallationServiceWorkflowStateType.APPROVED_BY_FLEET_OPERATIONS_SERVICE_EMPLOYEE:
					return SnoInstallationServiceWorkflowStateType.INITIALLY_SIGNED_BY_ENTERPRISE;
				case SnoInstallationServiceWorkflowStateType.PROVIDED:
					return SnoInstallationServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED;
				case SnoInstallationServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED:
					return SnoInstallationServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE;
				default:
					throw new Error();
			}
		},
		signAvailable(state: UploadSnoInstallationServiceDocumentsState) {
			if(!state.service.workflowState) return;
			
			switch (state.service.workflowState.alias) {
				case SnoInstallationServiceWorkflowStateType.SENT:
				case SnoInstallationServiceWorkflowStateType.PROVIDED:
					return false;
				case SnoInstallationServiceWorkflowStateType.APPROVED_BY_FLEET_OPERATIONS_SERVICE_EMPLOYEE:
				case SnoInstallationServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED:
					return true;
				default:
					throw new Error();
			}
		},
		isOnlySignMode(state: UploadSnoInstallationServiceDocumentsState) {
			return [
				SnoInstallationServiceWorkflowStateType.APPROVED_BY_FLEET_OPERATIONS_SERVICE_EMPLOYEE,
				SnoInstallationServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED
			].includes(state.service.workflowState?.alias);
		},
		isComplete(state: UploadSnoInstallationServiceDocumentsState) {
			return state.newDocuments.every(x => x.meta);
		}
	},
	actions: {
		...page.actions,
		...baseUploadServiceDocumentsStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			await this.fetch(id);
			this.initializeDocuments();
			
			await this.initializeBaseUploadServiceDocumentsStore(this.service.type);
		},
		initializeDocuments() {
			switch (this.service.workflowState.alias) {
				case SnoInstallationServiceWorkflowStateType.SENT:
					[ApiServiceDocumentKindEnum.AGREEMENT].forEach(kind => this.newDocuments.push(this.createDocument(
						kind)));
					break;
				case SnoInstallationServiceWorkflowStateType.APPROVED_BY_FLEET_OPERATIONS_SERVICE_EMPLOYEE:
					break;
				case SnoInstallationServiceWorkflowStateType.PROVIDED:
					[
						ApiServiceDocumentKindEnum.SERVICE_ACCEPTANCE_CERTIFICATE, ApiServiceDocumentKindEnum.INVOICE
					].forEach(kind => this.newDocuments.push(this.createDocument(kind)));
					break;
				case SnoInstallationServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED:
					break;
				default:
					throw new Error();
			}
		},
		async fetch(serviceId: string) {
			try {
				let { serviceApplication } = await snoInstallationServiceController.getService(serviceId);
				
				this.service = SnoInstallationServiceApplicationMapper.map(serviceApplication);
				this.title = formatServiceTitle(this.service.type, this.service.applicationNumber);
				
				if(this.isOnlySignMode) {
					const { documents } = await serviceController.getDocuments(serviceId);
					
					this.documents = documents.map(x => ServiceDocumentMapper.map(x, this.title));
				}
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		},
		async approveSignature() {
			this.approving = true;
			
			try {
				await snoInstallationServiceController.updateStatusByTransition(this.id!,
					snoInstallationTransitionService.getTransition(this.service.workflowState.alias,
						this.targetState!) as ApiSnoInstallationServiceTransitionCodeEnum);
				
				this.service.workflowState.alias = this.targetState!;
				
				alertService.addSuccess(AlertKeys.DOCUMENTS_SENT);
				
				await router.push({ name: RouteNames.SNO_INSTALLATION_SERVICE, params: { id: this.id } });
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.approving = false;
			}
		}
	}
});
