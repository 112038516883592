import { defineStore } from "pinia";
import AbortService from "@rmp/core/services/abortService";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { useTariffStepStore } from "@rmp/enterprise/stores/assignment/tariff/steps/tariff";
import { ApiServiceTariffsEnum } from "@rmp/core/api/types/service/tariffs/ApiServiceTariffsEnum";
import ServiceApplicationApiFacade from "@rmp/enterprise/api/service/facade";
import { TariffAssignmentStepTypeEnum } from "@rmp/enterprise/stores/assignment/tariff/steps/shared/TariffAssignmentStepTypeEnum";
import { TariffAssignmentStepState } from "@rmp/enterprise/stores/assignment/tariff/steps/shared/tariffAssignmentStepState";
import { useTariffAssignmentStore } from "@rmp/enterprise/stores/assignment/tariff";
import { ServiceTypeEnum } from "@rmp/core/types/services/ServiceTypeEnum";
import { ApiServiceApplicationCrewboat } from "@rmp/core/api/types/service/crewboat/apiServiceApplicationCrewboat";
import { ApiServiceApplicationPier } from "@rmp/core/api/types/service/pier/apiServiceApplicationPier";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import router from "@rmp/enterprise/router";
import { RouteNames } from "@rmp/enterprise/router/routes";
import { BoatController } from "@rmp/enterprise/api/boat";
import { ApiCounterpartyBoat, ApiCounterpartyBoatPersisted } from "@rmp/core/api/types/counterpartyBoat/apiCounterpartyBoat";
import { formatISODate, formatWorkingTime, parseWorkingTime } from "@rmp/core/utils/dates";
import { ApiBaseServiceTransitionCodeEnum } from "@rmp/core/api/types/service/base/ApiInformationServiceTransitionCodeEnum";
import { ApiServiceApplicationTugboat } from "@rmp/core/api/types/service/tugboat/apiServiceApplicationTugboat";
import { ApiTariffTotalAmount } from "@rmp/core/api/types/service/apiTariffTotalAmount";
import { ServiceWorkflowStateType } from "@rmp/core/types/services/ServiceWorkflowStateType";
import { TugboatServiceController } from "@rmp/enterprise/api/service/tugboat";
import { CrewboatServiceController } from "@rmp/enterprise/api/service/crewboat";
import { PierServiceController } from "@rmp/enterprise/api/service/pier";
import { getHours, getMinutes, set } from "date-fns";
import {
	BaseUploadServiceDocumentsState,
	useBaseUploadServiceDocumentsStore
} from "@rmp/enterprise/stores/composables/baseUploadServiceDocuments";
import { ApiServiceDocumentKindEnum } from "@rmp/core/api/types/service/document/ApiServiceDocumentKindEnum";
import { TugboatServiceApplication } from "@rmp/core/types/services/tugboat/tugboatServiceApplication";
import { CrewboatServiceApplication } from "@rmp/core/types/services/crewboat/crewboatServiceApplication";
import { PierServiceApplication } from "@rmp/core/types/services/pier/pierServiceApplication";
import { ServiceApplicationPier } from "@rmp/core/types/services/pier/serviceApplicationPier";
import { ServiceApplicationPropertyBase } from "@rmp/core/types/services/base/serviceApplicationPropertyBase";
import { parseFloatNumber } from "@rmp/core/utils/number";
import { i18n } from "@rmp/core/plugins";
import {
	usePortDestinationsForEndOptionsStore,
	usePortDestinationsForStartOptionsStore
} from "@rmp/enterprise/stores/options/portDestinations";

const abortService = new AbortService();
const page = usePageStore(abortService);
const uploadServiceDocumentsStore = useBaseUploadServiceDocumentsStore({ abortService });

const serviceApplicationApiFacade = new ServiceApplicationApiFacade(abortService);
const tugboatServiceController = new TugboatServiceController(abortService);
const crewboatServiceController = new CrewboatServiceController(abortService);
const pierServiceController = new PierServiceController(abortService);
const boatController = new BoatController(abortService);

export interface RouteStepState extends TariffAssignmentStepState, PageState, BaseUploadServiceDocumentsState {
	formValid: boolean;
	startDestinationId: string;
	endDestinationId: string;
	startDate: Date | null;
	endDate: Date | null;
	startTime: Date | null;
	endTime: Date | null;
	isSaving: boolean;
	counterpartyBoat: ApiCounterpartyBoat;
}

const getDefaultState = (): RouteStepState => {
	return {
		...page.getDefaultPageState(),
		...uploadServiceDocumentsStore.getDefaultState(),
		formValid: true,
		startDestinationId: "",
		endDestinationId: "",
		startDate: null,
		endDate: null,
		startTime: null,
		endTime: null,
		stepType: TariffAssignmentStepTypeEnum.ROUTE,
		isSaving: false,
		counterpartyBoat: {} as ApiCounterpartyBoat,
		newDocuments: []
	};
};

export const useRouteStepStore = defineStore({
	id: "tariff-assignment-route-step",
	state: (): RouteStepState => getDefaultState(),
	getters: {
		...page.getters,
		...uploadServiceDocumentsStore.getters,
		completed(state) {
			return state.formValid;
		},
		breadcrumbs() {
			const { breadcrumbs } = useTariffAssignmentStore();
			return breadcrumbs;
		}
	},
	actions: {
		...page.actions,
		...uploadServiceDocumentsStore.actions,
		async beforeInitialized() {
			const { serviceType, documentsUploadEnabled, title } = useTariffAssignmentStore();
			serviceApplicationApiFacade.configure(serviceType);
			
			await Promise.all([
				usePortDestinationsForStartOptionsStore().initialize(),
				usePortDestinationsForEndOptionsStore().initialize()
			]);
			
			await this.presetTariffValue();
			await this.fetch();
			
			if(documentsUploadEnabled) {
				const kind = serviceType === ServiceTypeEnum.TUGBOAT_SERVICE ?
					ApiServiceDocumentKindEnum.WORK_ORDER :
					ApiServiceDocumentKindEnum.ORDER_ASSIGNMENT;
				
				this.newDocuments.push({
					kind,
					meta: undefined,
					isSigning: false,
					isSigned: false,
					fileName: `${title} - ${i18n.t(`service.document.${kind}`)}`
				});				
			}
		},
		async fetch() {
			try {
				const { propertyTariffs } = useTariffStepStore();
				const { service } = useTariffAssignmentStore();
				
				const volumeTariffs = propertyTariffs.map(x => x.tariffs.filter(y => x.selectedTariffIds.includes(y.id)))
													 .flat()
													 .filter(x => x.type === ApiServiceTariffsEnum.PER_CUBIC_METER);
				
				const tasks: Promise<any>[] = [];
				
				if(volumeTariffs.length)
					tasks.push(boatController.getBoat(service.counterpartyBoatId));
				
				volumeTariffs.forEach(x => tasks.push(this.setTariffValue(x.id, x.value)));
				
				const [counterpartyBoat]: { counterpartyBoatPersisted: ApiCounterpartyBoatPersisted }[] = await Promise.all(tasks);
				
				this.counterpartyBoat = counterpartyBoat?.counterpartyBoatPersisted.counterpartyBoat;
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		},
		async setTariffValue(id: string, value: string) {
			const { propertyTariffs } = useTariffStepStore();
			const { service } = useTariffAssignmentStore();
			
			let tariff = propertyTariffs.map(x => x.tariffs)
										.flat()
										.find(x => x.id === id);
			if(!tariff) return;
			
			const { propertyId } = propertyTariffs.find(x => x.tariffs.some(y => y.id === id))!;
			tariff.value = value;
			try {
				tariff.loading = true;
				
				const { amount, vatIncluded } = await serviceApplicationApiFacade.calculateTariffCost(service.divisionId,
					propertyId,
					id,
					{
						counterpartyBoatId: service.counterpartyBoatId,
						quantity: parseFloatNumber(value)
					});
				
				tariff.amount = amount;
				tariff.vatIncluded = vatIncluded;
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				tariff.loading = false;
			}
		},
		async save() {
			this.isSaving = true;
			
			try {
				const { id, serviceType, service, documentsUploadEnabled } = useTariffAssignmentStore();
				const { propertyTariffs } = useTariffStepStore();
				const till = formatISODate(set(this.endDate!, { hours: getHours(this.endTime!), minutes: getMinutes(this.endTime!) }));
				const from = formatISODate(set(this.startDate!,
					{ hours: getHours(this.startTime!), minutes: getMinutes(this.startTime!) }));
				
				switch (serviceType) {
					case ServiceTypeEnum.TUGBOAT_SERVICE:
					{
						const tugboats: ApiServiceApplicationTugboat[] = [];
						propertyTariffs.forEach(propertyTariff => {
							const tariffs = propertyTariff.tariffs.map(x => ({
								tugBoatId: propertyTariff.propertyId,
								workingTime: x.value ? formatWorkingTime(parseFloatNumber(x.value)) : "",
								tariffId: x.id,
								tugServiceApplicationId: "",
								totalAmount: {} as ApiTariffTotalAmount
							})).filter(x => propertyTariff.selectedTariffIds.includes(x.tariffId));
							
							tugboats.push(...tariffs);
						});
						
						await serviceApplicationApiFacade.assignTariffs(id!, tugboats);
						await tugboatServiceController.updateProvisionData(id!, {
							till,
							from,
							startDestinationId: this.startDestinationId,
							endDestinationId: this.endDestinationId
						});
						break;
					}
					
					case ServiceTypeEnum.CREWBOAT_SERVICE:
					{
						const crewboats: ApiServiceApplicationCrewboat[] = [];
						propertyTariffs.forEach(propertyTariff => {
							const tariffs = propertyTariff.tariffs.map(x => ({
								crewBoatId: propertyTariff.propertyId,
								workingTime: x.value ? formatWorkingTime(parseFloatNumber(x.value)) : "",
								tariffId: x.id,
								crewboatServiceApplicationId: "",
								totalAmount: {} as ApiTariffTotalAmount
							})).filter(x => propertyTariff.selectedTariffIds.includes(x.tariffId));
							
							crewboats.push(...tariffs);
						});
						
						await serviceApplicationApiFacade.assignTariffs(id!, crewboats);
						await crewboatServiceController.updateProvisionData(id!, {
							till,
							from,
							startDestinationId: this.startDestinationId,
							endDestinationId: this.endDestinationId
						});
						break;
					}
					
					case ServiceTypeEnum.PIER_SERVICE:
					{
						const piers: ApiServiceApplicationPier[] = [];
						propertyTariffs.forEach(propertyTariff => {
							const tariffs = propertyTariff.tariffs.map(x => ({
								pierId: propertyTariff.propertyId,
								workingTime: x.value ? formatWorkingTime(parseFloatNumber(x.value)): "",
								tariffId: x.id,
								pierServiceApplicationId: "",
								totalAmount: {} as ApiTariffTotalAmount
							})).filter(x => propertyTariff.selectedTariffIds.includes(x.tariffId));
							
							piers.push(...tariffs);
						});
						
						await serviceApplicationApiFacade.assignTariffs(id!, piers);
						await pierServiceController.updateProvisionData(id!, { till, from });
						break;
					}
					default:
						throw new Error();
				}
				
				if(documentsUploadEnabled && this.newDocuments.some(x => x.meta))
					await serviceApplicationApiFacade.saveDocuments(service.id,
						this.newDocuments.filter(x => x.meta).map(x => ({
							documentKind: x.kind,
							title: x.meta!.name,
							tempFileId: x.meta!.id
						})));
				
				if(service.workflowState.alias === ServiceWorkflowStateType.PROVIDING) {
					await serviceApplicationApiFacade.updateStatusByTransition(id!, ApiBaseServiceTransitionCodeEnum.FINISH);
					
					alertService.addSuccess(AlertKeys.TARIFFS_ASSIGNED);
				} else if(service.workflowState.alias === ServiceWorkflowStateType.PROVIDED) {
					alertService.addSuccess(AlertKeys.TARIFFS_CHANGED);
				}
				
				switch (serviceType) {
					case ServiceTypeEnum.TUGBOAT_SERVICE:
						return await router.push({ name: RouteNames.TUGBOAT_SERVICE, params: router.currentRoute.params });
					case ServiceTypeEnum.CREWBOAT_SERVICE:
						return await router.push({ name: RouteNames.CREWBOAT_SERVICE, params: router.currentRoute.params });
					case ServiceTypeEnum.PIER_SERVICE:
						return await router.push({ name: RouteNames.PIER_SERVICE, params: router.currentRoute.params });
					default:
						throw new Error();
				}
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.isSaving = false;
			}
		},
		async presetTariffValue() {
			const { service } = useTariffAssignmentStore();
			
			if(service.workflowState.alias !== ServiceWorkflowStateType.PROVIDED)
				return;
			
			try {
				const { propertyTariffs } = useTariffStepStore();
				
				const { startDestinationId, endDestinationId, from, till } = service;
				
				this.startDestinationId = startDestinationId;
				this.endDestinationId = endDestinationId;
				
				if(from) {
					this.startDate = from;
					this.startTime = from;
				}

				if(till) {
					this.endDate = till;
					this.endTime = till;
				}
				
				const filteredSelectedTariffs = propertyTariffs.map(x => x.tariffs.filter(y => x.selectedTariffIds.includes(y.id)))
															   .flat();
				const hourTariffs = filteredSelectedTariffs.filter(x => x.type === ApiServiceTariffsEnum.PER_HOUR);
				const dayTariffs = filteredSelectedTariffs.filter(x => x.type === ApiServiceTariffsEnum.PER_DAY);
				
				
				const tasks: Promise<any>[] = [];
				
				// Предустановка часовых тарифов
				if(hourTariffs.length) {
					hourTariffs.forEach(x => {
						let assignedProperty: ServiceApplicationPropertyBase[] = [];

						switch (service.type) {
							case ServiceTypeEnum.TUGBOAT_SERVICE:
								const tugboatService = service as TugboatServiceApplication;
								assignedProperty = tugboatService.assignedTugBoats;
								break;
							case ServiceTypeEnum.CREWBOAT_SERVICE:
								const crewboatService = service as CrewboatServiceApplication;
								assignedProperty = crewboatService.assignedCrewBoats;
								break;
						}
						
						const time = assignedProperty.find(y => y.tariffId ===
							x.id);
						const hour: string = time?.workingTime ? parseWorkingTime(time?.workingTime).toString() : "";
						
						if(hour) tasks.push(this.setTariffValue(x.id, hour));
					});
				}
				
				// Предустановка дневных тарифов
				if(dayTariffs.length) {
					dayTariffs.forEach(x => {
						const pierService = service as PierServiceApplication;
						const assignedPierService: ServiceApplicationPier[] = pierService.assignedPiers;
						const time = assignedPierService.find(y => y.tariffId === x.id);
						const hour: string = time?.workingTime ? parseWorkingTime(time?.workingTime).toString() : "";
						
						if(hour) tasks.push(this.setTariffValue(x.id, hour));
					});
				}
				
				await Promise.all(tasks);
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		}
	}
});
