import { defineStore } from "pinia";
import AbortService from "@rmp/core/services/abortService";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import router from "@rmp/enterprise/router";
import { RouteNames } from "@rmp/enterprise/router/routes";
import {
	InformationTariffAssignmentStepState
} from "@rmp/enterprise/stores/assignment/informationTariff/steps/shared/informationTariffAssignmentStepState";
import {
	InformationTariffAssignmentStepTypeEnum
} from "@rmp/enterprise/stores/assignment/informationTariff/steps/shared/InformationTariffAssignmentStepTypeEnum";
import { useInformationTariffAssignmentStore } from "@rmp/enterprise/stores/assignment/informationTariff";
import { useInformationTariffStepStore } from "@rmp/enterprise/stores/assignment/informationTariff/steps/tariff";
import { InformationServiceController } from "@rmp/enterprise/api/service/information";
import { ApiCreateInformationRequestedTariff } from "@rmp/core/api/types/service/information/apiCreateInformationRequestedTariff";
import { ApiInformationServiceTransitionCodeEnum } from "@rmp/core/api/types/service/information/ApiInformationServiceTransitionCodeEnum";
import { InformationServiceWorkflowStateType } from "@rmp/core/types/services/information/InformationServiceWorkflowStateType";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";

const abortService = new AbortService();
const page = usePageStore(abortService);

const informationServiceController = new InformationServiceController(abortService);

export interface InformationTimeStepState extends InformationTariffAssignmentStepState, PageState {
	formValid: boolean;
	isSaving: boolean;
}

const getDefaultState = (): InformationTimeStepState => {
	return {
		...page.getDefaultPageState(),
		formValid: true,
		stepType: InformationTariffAssignmentStepTypeEnum.TIME,
		isSaving: false
	};
};

export const useInformationTimeStepStore = defineStore({
	id: "information-time-assignment-time-step",
	state: (): InformationTimeStepState => getDefaultState(),
	getters: {
		...page.getters,
		completed(state) {
			return state.formValid;
		},
		breadcrumbs() {
			const { breadcrumbs } = useInformationTariffAssignmentStore();
			return breadcrumbs;
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized() {
			await this.fetch();
		},
		async fetch() {
			const { service } = useInformationTariffAssignmentStore();
			
			if(service.workflowState.alias !== InformationServiceWorkflowStateType.PROVIDED)
				return;
			
			try {
				const { tariffs } = useInformationTariffStepStore();
				
				let tariff = tariffs.map(x => x.tariffs).flat().find(x => x.id === service.tariff?.tariffId!);
				
				const { amount, vatIncluded } = await informationServiceController.calculateInformationTariffCost(service.divisionId,
					service.tariff?.tariffId!,
					{ quantity: service.tariff?.units! });
				
				tariff!.value = (service.tariff?.units!).toString();
				tariff!.amount = amount;
				tariff!.vatIncluded = vatIncluded;
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		},
		async setTariffValue(id: string, value: string) {
			const { tariffs } = useInformationTariffStepStore();
			const { service } = useInformationTariffAssignmentStore();
			
			let tariff = tariffs.map(x => x.tariffs).flat().find(x => x.id === id);
			if(!tariff) return;
			
			tariff.value = value;
			try {
				tariff.loading = true;
				
				const { amount, vatIncluded } = await informationServiceController.calculateInformationTariffCost(service.divisionId,
					id,
					{ quantity: +value });
				
				tariff.amount = amount;
				tariff.vatIncluded = vatIncluded;
			} catch (e) {
				console.error(e);
			} finally {
				tariff.loading = false;
			}
		},
		async save() {
			this.isSaving = true;
			
			try {
				const { id, service } = useInformationTariffAssignmentStore();
				const { tariffs } = useInformationTariffStepStore();
				
				let requestedTariff: ApiCreateInformationRequestedTariff = {} as ApiCreateInformationRequestedTariff;
				
				tariffs.forEach(x => {
					const selected = x.tariffs.find(y => y.id === x.selectedTariffId);
					
					if(!selected) return;
					
					requestedTariff = {
						quantity: +selected.value,
						tariffId: selected.id
					} as ApiCreateInformationRequestedTariff;
				});
				
				await informationServiceController.assignInformationTariffs(id!, requestedTariff);
				
				
				if(service.workflowState.alias === InformationServiceWorkflowStateType.PROVIDING) {
					await informationServiceController.updateStatusByTransition(id!, ApiInformationServiceTransitionCodeEnum.FINISH);
					
					alertService.addSuccess(AlertKeys.TARIFFS_ASSIGNED);
				} else if(service.workflowState.alias === InformationServiceWorkflowStateType.PROVIDED) {
					alertService.addSuccess(AlertKeys.TARIFFS_CHANGED);
				}
				
				return await router.push({ name: RouteNames.INFORMATION_SERVICE, params: router.currentRoute.params });
			} catch (e) {
				console.error(e);
			} finally {
				this.isSaving = false;
			}
		}
	}
});
