import { ApiPier, ApiPierPersisted } from "@rmp/core/api/types/enterpriseProperty/apiPier";
import { PersistedBase, PersistedBaseMapper } from "@rmp/core/types/core/persisted";

export interface Pier {
	name: string;
	depth: string;
	length: string;
	status: string;
	isArchived: boolean;
}

export interface PierPersisted extends PersistedBase {
	pier: Pier;
}

export class PierMapper {
	static map(source: ApiPier): Pier {
		return {
			...source
		};
	}
	
	static mapPersisted(source: ApiPierPersisted): PierPersisted {
		return {
			...PersistedBaseMapper.map(source),
			pier: this.map(source.pier)
		};
	}
}
