import ApiFileMeta from "@rmp/core/api/types/storage/apiFileMeta";
import { ApiCounterpartyDocumentType } from "@rmp/core/api/types/documents/ApiCounterpartyDocumentType";
import { ApiCounterpartyDocumentPersisted } from "@rmp/core/api/types/documents/apiCounterpartyDocument";
import { parseISO } from "date-fns";
import { isNil } from "lodash";

export interface CounterpartyDocument {
	id: string;
	createdAt: Date;
	name: string;
	documentType: ApiCounterpartyDocumentType;
	documentTypeText: string;
	isArchived: boolean;
	fileId: string;
	fileMeta: ApiFileMeta;
	counterpartyId: string;
	creationDate: Date;
	expirationDate: Date | null;
}

export class CounterpartyDocumentMapper {
	static map(source: ApiCounterpartyDocumentPersisted): CounterpartyDocument {
		return {
			...source,
			...source.document,
			createdAt: parseISO(source.createdAt),
			expirationDate: isNil(source.document.expirationDate) ? null :  parseISO(source.document.expirationDate),
			creationDate: parseISO(source.document.creationDate),
			fileMeta: {} as ApiFileMeta
		};
	}
}
