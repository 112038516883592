import { RouteNames } from "@rmp/enterprise/router/routes";
import { Permissions } from "@rmp/core/constants/permissions";

const routeToPermissionsMap = new Map<string, Permissions[]>([
	[RouteNames.ROOT, []],
	[RouteNames.CALLBACK, []],
	[RouteNames.LOGOUT, []],
	[RouteNames.SILENT_RENEW, []],
	[RouteNames.ERROR_PAGE_NOT_FOUND, []],
	[RouteNames.ERROR_ACCESS_DENIED, []],
	[RouteNames.ERROR_INTERNAL_SERVER_ERROR, []],
	
	[RouteNames.CONFIRM_PHONE, []],
	[RouteNames.CONFIRM_EMAIL, []],
	[RouteNames.RESET_PASSWORD, []],
	
	[RouteNames.TUGBOATS, [Permissions.GLOBAL_OFFICE_PROPERTY_READ_LIST, Permissions.OWN_OFFICE_PROPERTY_READ_LIST]],
	[RouteNames.TUGBOAT, [Permissions.GLOBAL_OFFICE_PROPERTY_READ, Permissions.OWN_OFFICE_PROPERTY_READ]],
	[RouteNames.TUGBOAT_CREATE, [Permissions.GLOBAL_OFFICE_PROPERTY_CREATE, Permissions.OWN_OFFICE_PROPERTY_CREATE]],
	[RouteNames.TUGBOAT_DETAILS, [Permissions.GLOBAL_OFFICE_PROPERTY_READ, Permissions.OWN_OFFICE_PROPERTY_READ]],
	[RouteNames.TUGBOAT_DETAILS_GENERAL, [Permissions.GLOBAL_OFFICE_PROPERTY_READ, Permissions.OWN_OFFICE_PROPERTY_READ]],
	[RouteNames.TUGBOAT_DETAILS_TARIFFS, [Permissions.GLOBAL_OFFICE_PROPERTY_READ, Permissions.OWN_OFFICE_PROPERTY_READ]],
	
	[RouteNames.CREWBOATS, [Permissions.GLOBAL_OFFICE_PROPERTY_READ_LIST, Permissions.OWN_OFFICE_PROPERTY_READ_LIST]],
	[RouteNames.CREWBOAT, [Permissions.GLOBAL_OFFICE_PROPERTY_READ, Permissions.OWN_OFFICE_PROPERTY_READ]],
	[RouteNames.CREWBOAT_CREATE, [Permissions.GLOBAL_OFFICE_PROPERTY_CREATE, Permissions.OWN_OFFICE_PROPERTY_CREATE]],
	[RouteNames.CREWBOAT_DETAILS, [Permissions.GLOBAL_OFFICE_PROPERTY_READ, Permissions.OWN_OFFICE_PROPERTY_READ]],
	[RouteNames.CREWBOAT_DETAILS_GENERAL, [Permissions.GLOBAL_OFFICE_PROPERTY_READ, Permissions.OWN_OFFICE_PROPERTY_READ]],
	[RouteNames.CREWBOAT_DETAILS_TARIFFS, [Permissions.GLOBAL_OFFICE_PROPERTY_READ, Permissions.OWN_OFFICE_PROPERTY_READ]],
	
	[RouteNames.FLOATING_CRANES, [Permissions.GLOBAL_OFFICE_PROPERTY_READ_LIST, Permissions.OWN_OFFICE_PROPERTY_READ_LIST]],
	[RouteNames.FLOATING_CRANE, [Permissions.GLOBAL_OFFICE_PROPERTY_READ, Permissions.OWN_OFFICE_PROPERTY_READ]],
	[RouteNames.FLOATING_CRANE_CREATE, [Permissions.GLOBAL_OFFICE_PROPERTY_CREATE, Permissions.OWN_OFFICE_PROPERTY_CREATE]],
	[RouteNames.FLOATING_CRANE_DETAILS, [Permissions.GLOBAL_OFFICE_PROPERTY_READ, Permissions.OWN_OFFICE_PROPERTY_READ]],
	
	[RouteNames.PIERS, [Permissions.GLOBAL_OFFICE_PROPERTY_READ_LIST, Permissions.OWN_OFFICE_PROPERTY_READ_LIST]],
	[RouteNames.PIER, [Permissions.GLOBAL_OFFICE_PROPERTY_READ, Permissions.OWN_OFFICE_PROPERTY_READ]],
	[RouteNames.PIER_CREATE, [Permissions.GLOBAL_OFFICE_PROPERTY_CREATE, Permissions.OWN_OFFICE_PROPERTY_CREATE]],
	[RouteNames.PIER_DETAILS, [Permissions.GLOBAL_OFFICE_PROPERTY_READ, Permissions.OWN_OFFICE_PROPERTY_READ]],
	
	[RouteNames.DIVISIONS, []],
	[RouteNames.BRANCHES, []],
	[RouteNames.BRANCH, []],
	[RouteNames.BRANCH_GENERAL, []],
	[RouteNames.BRANCH_DEPARTMENTS, []],
	[RouteNames.DEPARTMENT, []],
	[RouteNames.DEPARTMENT_GENERAL, []],
	[RouteNames.DEPARTMENT_PORTS, []],
	[RouteNames.PORT, []],
	[RouteNames.PORT_GENERAL, []],
	[RouteNames.PORT_DESTINATION, [Permissions.OWN_DESTINATION_UPDATE]],
	[RouteNames.PORT_DESTINATION_DETAILS, []],
	[RouteNames.PORT_DESTINATIONS, []],
	[RouteNames.PORT_DESTINATION_GENERAL, []],
	[RouteNames.PORT_DESTINATION_CREATE, [Permissions.OWN_DESTINATION_UPDATE]],
	
	[RouteNames.SERVICES, []],
	
	[RouteNames.TARIFFS, []],
	
	[RouteNames.COUNTERPARTIES, [Permissions.GLOBAL_COUNTERPARTY_READ_LIST]],
	[RouteNames.COUNTERPARTY_DETAILS, [Permissions.GLOBAL_COUNTERPARTY_READ]],
	[RouteNames.COUNTERPARTY_DETAILS_GENERAL, [Permissions.GLOBAL_COUNTERPARTY_READ]],
	[RouteNames.COUNTERPARTY_DETAILS_BANK_ACCOUNTS, [Permissions.GLOBAL_COUNTERPARTY_READ]],
	[RouteNames.COUNTERPARTY_DETAILS_BANK_ACCOUNT, [Permissions.GLOBAL_COUNTERPARTY_READ]],
	[RouteNames.COUNTERPARTY_DETAILS_EMPLOYEES, [Permissions.GLOBAL_COUNTERPARTY_READ]],
	[RouteNames.COUNTERPARTY_DETAILS_EMPLOYEE, [Permissions.GLOBAL_COUNTERPARTY_READ]],
	[RouteNames.COUNTERPARTY_DETAILS_DOCUMENTS, [Permissions.GLOBAL_COUNTERPARTY_READ]],
	
	
	[RouteNames.PIER_SERVICE, []],
	[RouteNames.PIER_SERVICE_GENERAL, []],
	[RouteNames.PIER_SERVICE_DOCUMENTS, []],
	[RouteNames.PIER_SERVICE_HISTORY, []],
	[RouteNames.PIER_SERVICE_COMMENTS, []],
	[RouteNames.PIER_SERVICE_PIER_ASSIGNMENT, []],
	[RouteNames.PIER_SERVICE_UPLOAD_DOCUMENTS, []],
	[RouteNames.PIER_SERVICE_TARIFF_ASSIGNMENT, []],
	[RouteNames.PIER_SERVICE_FEEDBACK, []],
	
	[RouteNames.TUGBOAT_SERVICE, []],
	[RouteNames.TUGBOAT_SERVICE_GENERAL, []],
	[RouteNames.TUGBOAT_SERVICE_DOCUMENTS, []],
	[RouteNames.TUGBOAT_SERVICE_HISTORY, []],
	[RouteNames.TUGBOAT_SERVICE_COMMENTS, []],
	[RouteNames.TUGBOAT_SERVICE_TUGBOAT_ASSIGNMENT, []],
	[RouteNames.TUGBOAT_SERVICE_UPLOAD_DOCUMENTS, []],
	[RouteNames.TUGBOAT_SERVICE_TARIFF_ASSIGNMENT, []],
	[RouteNames.TUGBOAT_SERVICE_FEEDBACK, []],
	
	[RouteNames.CREWBOAT_SERVICE, []],
	[RouteNames.CREWBOAT_SERVICE_GENERAL, []],
	[RouteNames.CREWBOAT_SERVICE_DOCUMENTS, []],
	[RouteNames.CREWBOAT_SERVICE_HISTORY, []],
	[RouteNames.CREWBOAT_SERVICE_COMMENTS, []],
	[RouteNames.CREWBOAT_SERVICE_CREWBOAT_ASSIGNMENT, []],
	[RouteNames.CREWBOAT_SERVICE_UPLOAD_DOCUMENTS, []],
	[RouteNames.CREWBOAT_SERVICE_TARIFF_ASSIGNMENT, []],
	[RouteNames.CREWBOAT_SERVICE_FEEDBACK, []],
	
	[RouteNames.DREDGING_SERVICE, []],
	[RouteNames.DREDGING_SERVICE_GENERAL, []],
	[RouteNames.DREDGING_SERVICE_DOCUMENTS, []],
	[RouteNames.DREDGING_SERVICE_HISTORY, []],
	[RouteNames.DREDGING_SERVICE_COMMENTS, []],
	[RouteNames.DREDGING_SERVICE_UPLOAD_DOCUMENTS, []],
	[RouteNames.DREDGING_SERVICE_FEEDBACK, []],
	
	[RouteNames.SNO_INSTALLATION_SERVICE, []],
	[RouteNames.SNO_INSTALLATION_SERVICE_GENERAL, []],
	[RouteNames.SNO_INSTALLATION_SERVICE_DOCUMENTS, []],
	[RouteNames.SNO_INSTALLATION_SERVICE_HISTORY, []],
	[RouteNames.SNO_INSTALLATION_SERVICE_COMMENTS, []],
	[RouteNames.SNO_INSTALLATION_SERVICE_UPLOAD_DOCUMENTS, []],
	[RouteNames.SNO_INSTALLATION_SERVICE_FEEDBACK, []],
	
	[RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE, []],
	[RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_GENERAL, []],
	[RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_DOCUMENTS, []],
	[RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_HISTORY, []],
	[RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_COMMENTS, []],
	[RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_UPLOAD_DOCUMENTS, []],
	[RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_FEEDBACK, []],
	
	[RouteNames.INFORMATION_SERVICE, []],
	[RouteNames.INFORMATION_SERVICE_GENERAL, []],
	[RouteNames.INFORMATION_SERVICE_DOCUMENTS, []],
	[RouteNames.INFORMATION_SERVICE_HISTORY, []],
	[RouteNames.INFORMATION_SERVICE_COMMENTS, []],
	[RouteNames.INFORMATION_SERVICE_UPLOAD_DOCUMENTS, []],
	[RouteNames.INFORMATION_SERVICE_TARIFF_ASSIGNMENT, []],
	[RouteNames.INFORMATION_SERVICE_FEEDBACK, []],
	
	[RouteNames.FLOATING_CRANE_SERVICE, []],
	[RouteNames.FLOATING_CRANE_SERVICE_GENERAL, []],
	[RouteNames.FLOATING_CRANE_SERVICE_DOCUMENTS, []],
	[RouteNames.FLOATING_CRANE_SERVICE_HISTORY, []],
	[RouteNames.FLOATING_CRANE_SERVICE_COMMENTS, []],
	[RouteNames.FLOATING_CRANE_SERVICE_UPLOAD_DOCUMENTS, []],
	[RouteNames.FLOATING_CRANE_SERVICE_TARIFF_ASSIGNMENT, []],
	[RouteNames.FLOATING_CRANE_SERVICE_FLOATING_CRANE_ASSIGNMENT, []],
	[RouteNames.FLOATING_CRANE_SERVICE_FEEDBACK, []],
	
	[RouteNames.BOAT_APPLICATIONS, [Permissions.GLOBAL_COUNTERPARTY_PROPERTY_READ_LIST]],
	[RouteNames.BOAT_APPLICATION_DETAILS, [Permissions.GLOBAL_COUNTERPARTY_PROPERTY_READ]],
	[RouteNames.BOAT_APPLICATION_DETAILS_GENERAL, [Permissions.GLOBAL_COUNTERPARTY_PROPERTY_READ]],
	[RouteNames.BOAT_APPLICATION_DETAILS_DOCUMENTS, [Permissions.GLOBAL_COUNTERPARTY_PROPERTY_READ]],
	[RouteNames.BOAT_APPLICATION_DETAILS_HISTORY, [Permissions.GLOBAL_COUNTERPARTY_PROPERTY_READ]],
	[RouteNames.BOAT_APPLICATION_DETAILS_COMMENTS, [Permissions.GLOBAL_COUNTERPARTY_PROPERTY_READ]],
	
	[RouteNames.METRICS, [Permissions.GLOBAL_OFFICE_PROPERTY_READ_LIST, Permissions.OWN_OFFICE_PROPERTY_READ_LIST]],
	[RouteNames.METRICS_BY_TIME, [Permissions.GLOBAL_OFFICE_PROPERTY_READ_LIST, Permissions.OWN_OFFICE_PROPERTY_READ_LIST]],
	[RouteNames.METRICS_BY_COST, [Permissions.GLOBAL_OFFICE_PROPERTY_READ_LIST, Permissions.OWN_OFFICE_PROPERTY_READ_LIST]],
	[RouteNames.METRICS_BY_PROPERTY, [Permissions.GLOBAL_OFFICE_PROPERTY_READ_LIST, Permissions.OWN_OFFICE_PROPERTY_READ_LIST]],
	[RouteNames.METRICS_BY_PROPERTY_DETAILED, [Permissions.GLOBAL_OFFICE_PROPERTY_READ, Permissions.OWN_OFFICE_PROPERTY_READ]],
	[RouteNames.METRICS_BY_ACCOUNTS, [Permissions.GLOBAL_OFFICE_PROPERTY_READ_LIST, Permissions.OWN_OFFICE_PROPERTY_READ_LIST]],
	
	[RouteNames.AGREEMENTS, []],
	
	[RouteNames.AGREEMENT_DETAILS, []],
	[RouteNames.AGREEMENT_DETAILS_GENERAL, []],
	[RouteNames.AGREEMENT_DETAILS_DOCUMENTS, []],
	[RouteNames.AGREEMENT_DETAILS_HISTORY, []],
	[RouteNames.AGREEMENT_DETAILS_SERVICES, []],
	[RouteNames.AGREEMENT_DETAILS_COMMENTS, []],
	[RouteNames.AGREEMENT_UPLOAD_DOCUMENTS, []],
	[RouteNames.AGREEMENT_SIGN_DOCUMENTS, []]
]);

export default routeToPermissionsMap;
