import { RouteNames } from "@rmp/enterprise/router/routes";
import { useBreadcrumb } from "@rmp/core/stores/composables/breadcrumb/useBreadcrumb";
import { i18n } from "@rmp/enterprise/plugins";
import routeToPermissionsMap from "@rmp/enterprise/router/routeToPermissionsMap";

export function useBoatApplicationDetailsDocumentsBreadcrumb() {
	return useBreadcrumb({
		route: {
			name: RouteNames.BOAT_APPLICATION_DETAILS_DOCUMENTS
		},
		text: i18n.t(`navigation.breadcrumbs.${RouteNames.BOAT_APPLICATION_DETAILS_DOCUMENTS}`).toString(),
		routeToPermissionsMap
	});
}
