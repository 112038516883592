import AbortService from "@rmp/core/services/abortService";
import { ApiDepartmentPersisted } from "@rmp/core/api/types/divisions/department/apiDepartment";
import { urls } from "@rmp/enterprise/api/config/urls";
import BaseEnterpriseController from "@rmp/enterprise/api/base/baseEnterpriseController";
import ApiGetDepartmentParameters from "@rmp/core/api/types/divisions/department/apiGetDepartmentParameters";
import { ApiDivisionKindPersisted } from "@rmp/core/api/types/divisions/department/apiDivisionKindPersisted";

export class DepartmentController extends BaseEnterpriseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getDepartments = async (branchId: string): Promise<{ totalCount: number; items: ApiDepartmentPersisted[] }> => {
		let items = await this.get<object[]>(urls.enterprise.division.branchBasin.$id.department, { id: branchId });
		
		return {
			totalCount: items.length,
			items: items as ApiDepartmentPersisted[]
		};
	};
	
	getDepartment = async (departmentId: string): Promise<ApiDepartmentPersisted> => {
		return await this.get(urls.enterprise.division.department.$id, { id: departmentId });
	};
	
	getBranchDepartments = async (branchId: string, parameters: ApiGetDepartmentParameters): Promise<{
		totalCount: number;
		items: ApiDepartmentPersisted[]
	}> => {
		let items = await this.get<object[]>(urls.enterprise.division.branchBasin.$id.department, { id: branchId, ...parameters });
		
		return {
			totalCount: items.length,
			items: items as ApiDepartmentPersisted[]
		};
	};
	
	getOwnDepartment = async (): Promise<ApiDepartmentPersisted> => {
		return await this.get<ApiDepartmentPersisted>(urls.own.enterprise.division.department);
	};
	
	getOwnDivisionKind = async (): Promise<ApiDivisionKindPersisted> => {
		return await this.get<ApiDivisionKindPersisted>(urls.own.enterprise.division.kind);
	};
}
