import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { EnterprisePropertyController } from "@rmp/enterprise/api/enterpriseProperty";
import { Tugboat } from "@rmp/core/types/enterpriseProperty/tugboat";
import { useTugboatDetailsStore } from "@rmp/enterprise/stores/property/tugboatDetails";
import { cloneDeep } from "lodash";
import { BasePropertyGeneralState, useBasePropertyGeneralStore } from "@rmp/enterprise/stores/composables/basePropertyGeneral";

const abortService = new AbortService();

const enterprisePropertyController = new EnterprisePropertyController(abortService);

const page = usePageStore(abortService);
const baseBasePropertyGeneralStore = useBasePropertyGeneralStore({ abortService });

export interface TugboatDetailsGeneralState extends PageState, BasePropertyGeneralState {
	details: Tugboat;
}

const getDefaultState = (): TugboatDetailsGeneralState => {
	return {
		...page.getDefaultPageState(),
		...baseBasePropertyGeneralStore.getDefaultState(),
		details: {} as Tugboat
	};
};

export const useTugboatDetailsGeneralStore = defineStore({
	id: "tugboat-details-general",
	state: (): TugboatDetailsGeneralState => getDefaultState(),
	getters: {
		...page.getters,
		...baseBasePropertyGeneralStore.getters,
		breadcrumbs() {
			const { breadcrumbs } = useTugboatDetailsStore();
			return breadcrumbs;
		}
	},
	actions: {
		...page.actions,
		...baseBasePropertyGeneralStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			let { details } = useTugboatDetailsStore();
			
			this.details = cloneDeep(details);
			
			const [{ divisionId }] = await Promise.all([
				enterprisePropertyController.getTugboatDivision(id),
				this.fetchBranches()
			]);
			
			await this.initializeBasePropertyGeneralStore(divisionId);
		}
	}
});
