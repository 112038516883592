import AbortService from "@rmp/core/services/abortService";
import BaseController from "@rmp/core/api/shared/baseController";
import { ApiSegment, createUrlsTree, Node, resolveUrl } from "@rmp/core/utils/api";
import TreeModel from "tree-model";
import { urls } from "@rmp/enterprise/api/config/urls";
const baseUrl = process.env.VUE_APP_ENTERPRISE_API_URL!;

export default class BaseEnterpriseController extends BaseController {
	urlsTree: TreeModel.Node<Node>;
	
	constructor(abortService: AbortService) {
		super(abortService);
		
		this.urlsTree = createUrlsTree(urls);
	}
	
	async get<T>(segment: ApiSegment, params: object = {}, options: object = {}) {
		const url = resolveUrl(baseUrl, this.urlsTree, segment, params);
		
		return await this.client.get<T>(url, options);
	}
	
	async post<T>(segment: ApiSegment, params: object = {}, payload: object = {}) {
		const url = resolveUrl(baseUrl, this.urlsTree, segment, params);
		
		return await this.client.post<T>(url, payload);
	}
	
	async put<T>(segment: ApiSegment, params: object = {}, payload: any = {}, options: object = {}) {
		const url = resolveUrl(baseUrl, this.urlsTree, segment, params);
		
		return await this.client.put<T>(url, payload, options);
	}
	
	async delete(segment: ApiSegment, params: object = {}): Promise<void> {
		const url = resolveUrl(baseUrl, this.urlsTree, segment, params);
		
		return await this.client.delete(url);
	}
}
