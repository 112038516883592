import AbortService from "@rmp/core/services/abortService";
import BaseEnterpriseController from "@rmp/enterprise/api/base/baseEnterpriseController";
import { urls } from "@rmp/enterprise/api/config/urls";
import { ApiCreateServiceDocument } from "@rmp/core/api/types/service/document/apiCreateServiceDocument";
import { ApiInformationServiceApplication } from "@rmp/core/api/types/service/information/apiInformationServiceApplication";
import { ApiInformationServiceTransitionCodeEnum } from "@rmp/core/api/types/service/information/ApiInformationServiceTransitionCodeEnum";
import { ApiCreateInformationRequestedTariff } from "@rmp/core/api/types/service/information/apiCreateInformationRequestedTariff";
import { ServiceControllerBase } from "@rmp/enterprise/api/service/serviceControllerBase";
import NotImplementedException from "@rmp/core/exceptions/notImplementedException";
import { ApiTariff } from "@rmp/core/api/types/service/tariffs/apiTariff";
import {
	ApiGetInformationTariffTotalAmountRequest
} from "@rmp/core/api/types/service/information/apiGetInformationTariffTotalAmountRequest";
import { ApiTariffTotalAmount } from "@rmp/core/api/types/service/apiTariffTotalAmount";

export class InformationServiceController extends BaseEnterpriseController implements ServiceControllerBase {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getService = async (serviceId: string) => {
		return await this.get<{ informationServiceApplication: ApiInformationServiceApplication }>(urls.service.information.$serviceId, { serviceId });
	};
	
	assignInformationTariffs = async (serviceId: string, payload: ApiCreateInformationRequestedTariff) => {
		await this.put(urls.service.information.$serviceId.tariff, { serviceId }, payload);
	};
	
	updateStatusByTransition = async (serviceId: string, code: ApiInformationServiceTransitionCodeEnum, reason?: string) => {
		return await this.post<void>(urls.service.information.$serviceId.$code,
			{ serviceId, code },
			{ rejectionTransition: { reason } });
	};
	
	saveDocuments = async (serviceId: string, documents: ApiCreateServiceDocument[]) => {
		return await this.put<void>(urls.service.information.$serviceId.document, { serviceId }, documents);
	};
	
	getInformationTariffs = async (departmentId: string) => {
		return await this.get<ApiTariff[]>(urls.service.information.department.$departmentId.tariff, { departmentId });
	};
	
	calculateInformationTariffCost = async (departmentId: string, tariffId: string, payload: ApiGetInformationTariffTotalAmountRequest) => {
		const [data] = await this.post<ApiTariffTotalAmount[]>(urls.service.information.department.$departmentId.tariff.$tariffId.calculate,
			{ departmentId, tariffId }, payload);
		return data;
	};
	
	assignTariffs = async () => {
		throw new NotImplementedException("assignTariffs");
	};
	
	getTariffs = async () => {
		throw new NotImplementedException("getTariffs");
	};
	
	calculateTariffCost = async () => {
		throw new NotImplementedException("calculateTariffCost");
	};
}
