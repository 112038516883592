import { IsString, IsInt, Min, IsIn, Max, Length, IsEnum } from "class-validator";
import { SortingOrderTypeEnum } from "@rmp/core/types/SortingOrderTypeEnum";
import RegisterRouteQuery from "@rmp/core/stores/composables/register/types/registerRouteQuery";
import { DepartmentPortsFilterStatusEnum } from "@rmp/enterprise/stores/divisions/department/ports/types/DepartmentPortsFilterStatusEnum";

export const AVAILABLE_SORTS = ["fullName", "shortName", "kpp", "status", "isArchived"];

export default class DepartmentPortsRouteQuery implements RegisterRouteQuery {
	@IsString()
	@IsIn(AVAILABLE_SORTS)
	sort: string;
	
	@IsString()
	@IsIn([SortingOrderTypeEnum.DESCENDING, SortingOrderTypeEnum.ASCENDING])
	sortDirection: string = SortingOrderTypeEnum.DESCENDING;
	
	@IsString()
	@Length(0, 100)
	query: string = "";
	
	@IsString()
	@IsEnum(DepartmentPortsFilterStatusEnum)
	status: DepartmentPortsFilterStatusEnum;
	
	@IsInt()
	@Min(0)
	@Max(1000)
	page: number = 1;
	
	constructor(sort: string, sortDirection: string, query: string, status: DepartmentPortsFilterStatusEnum) {
		this.sort = sort;
		this.sortDirection = sortDirection;
		this.query = query;
		this.status = status;
	}
}
