import AbortService from "@rmp/core/services/abortService";
import { urls } from "@rmp/enterprise/api/config/urls";
import BaseEnterpriseController from "@rmp/enterprise/api/base/baseEnterpriseController";
import { ApiPortDestinationPersisted } from "@rmp/core/api/types/divisions/port/destination/apiPortDestinationPersisted";
import ApiGetPortDestinationsParameters from "@rmp/core/api/types/divisions/port/destination/apiGetPortDestinationsParameters";
import { ApiCreatePortDestination } from "@rmp/core/api/types/divisions/port/destination/apiCreatePortDestination";

export class PortDestinationController extends BaseEnterpriseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getPortDestinations = async (portId: string, parameters: ApiGetPortDestinationsParameters): Promise<{
		totalCount: number;
		items: ApiPortDestinationPersisted[]
	}> => {
		const items = await this.get<ApiPortDestinationPersisted[]>(urls.enterprise.division.port.$portId.destination,
			{ portId, ...parameters });
		
		return {
			totalCount: items.length,
			items
		};
	};
	
	getPortDestination = async (portId: string, destinationId: string) => {
		return await this.get<ApiPortDestinationPersisted>(urls.enterprise.division.port.$portId.destination.$destinationId,
			{ portId, destinationId });
	};
	
	createPortDestination = async (portId: string, payload: ApiCreatePortDestination) => {
		return await this.post<ApiPortDestinationPersisted>(urls.enterprise.division.port.$portId.destination,
			{ portId }, payload);
	};
	
	updatePortDestination = async (portId: string, destinationId: string, payload: ApiCreatePortDestination) => {
		return await this.put<ApiPortDestinationPersisted>(urls.enterprise.division.port.$portId.destination.$destinationId,
			{ portId, destinationId }, payload);
	};
	
	archive = async (portId: string, destinationId: string) => {
		return await this.post(urls.enterprise.division.port.$portId.destination.$destinationId.archive, { portId, destinationId });
	};
	
	unarchive = async (portId: string, destinationId: string) => {
		return await this.post(urls.enterprise.division.port.$portId.destination.$destinationId.unarchive, { portId, destinationId });
	};
}
