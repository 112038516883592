import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { useServicesBreadcrumb } from "@rmp/enterprise/stores/services/composables/useServicesBreadcrumb";
import { useCrewboatServiceBreadcrumb } from "@rmp/enterprise/stores/serviceDetails/crewboat/composables/useCrewboatServiceBreadcrumb";
import { useUploadCrewboatServiceDocumentsBreadcrumb } from "@rmp/enterprise/stores/uploadServiceDocuments/crewboat/composables/useUploadCrewboatServiceDocumentsBreadcrumb";
import { CrewboatServiceController } from "@rmp/enterprise/api/service/crewboat";
import { CrewboatServiceApplication, CrewboatServiceApplicationMapper } from "@rmp/core/types/services/crewboat/crewboatServiceApplication";
import {
	BaseUploadServiceDocumentsState,
	useBaseUploadServiceDocumentsStore
} from "@rmp/enterprise/stores/composables/baseUploadServiceDocuments";
import { formatServiceTitle } from "@rmp/core/utils/formatting";
import CrewboatServiceApplicationWorkflowService from "@rmp/core/services/serviceApplication/crewboatServiceApplicationWorkflowService";
import { ServiceController } from "@rmp/enterprise/api/service";
import { ServiceWorkflowStateType } from "@rmp/core/types/services/ServiceWorkflowStateType";
import { ServiceDocumentMapper } from "@rmp/core/types/services/serviceDocument";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import router from "@rmp/enterprise/router";
import { RouteNames } from "@rmp/enterprise/router/routes";
import { ApiPropertyServiceTransitionCodeEnum } from "@rmp/core/api/types/service/ApiPropertyServiceTransitionCodeEnum";
import { ApiServiceDocumentKindEnum } from "@rmp/core/api/types/service/document/ApiServiceDocumentKindEnum";

const abortService = new AbortService();
const page = usePageStore(abortService);
const baseUploadServiceDocumentsStore = useBaseUploadServiceDocumentsStore({ abortService });

const crewboatTransitionService = new CrewboatServiceApplicationWorkflowService();

const crewboatServiceController = new CrewboatServiceController(abortService);
const serviceController = new ServiceController(abortService);

export interface UploadCrewboatServiceDocumentsState extends PageState, BaseUploadServiceDocumentsState {
	service: CrewboatServiceApplication;
}

const getDefaultState = (): UploadCrewboatServiceDocumentsState => {
	return {
		...page.getDefaultPageState(),
		...baseUploadServiceDocumentsStore.getDefaultState(),
		service: {} as CrewboatServiceApplication,
		newDocuments: []
	};
};

export const useUploadCrewboatServiceDocumentsStore = defineStore({
	id: "upload-crewboat-service-documents",
	state: (): UploadCrewboatServiceDocumentsState => getDefaultState(),
	getters: {
		...page.getters,
		...baseUploadServiceDocumentsStore.getters,
		breadcrumbs(state: UploadCrewboatServiceDocumentsState) {
			return [
				useServicesBreadcrumb(),
				useCrewboatServiceBreadcrumb(state.title, { id: state.service.id }),
				useUploadCrewboatServiceDocumentsBreadcrumb()
			];
		},
		targetState(state: UploadCrewboatServiceDocumentsState) {
			if(!state.service.workflowState) return;
			
			switch (state.service.workflowState.alias) {
				case ServiceWorkflowStateType.APPROVED_BY_FINANSIST:
					return ServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED;
				case ServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED:
					return ServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE;
				default:
					throw new Error();
			}
		},
		signAvailable(state: UploadCrewboatServiceDocumentsState) {
			if(!state.service.workflowState) return;
			
			switch (state.service.workflowState.alias) {
				case ServiceWorkflowStateType.APPROVED_BY_FINANSIST:
					return false;
				case ServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED:
					return true;
				default:
					throw new Error();
			}
		},
		isOnlySignMode(state: UploadCrewboatServiceDocumentsState) {
			return state.service.workflowState?.alias === ServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED;
		},
		isComplete(state: UploadCrewboatServiceDocumentsState) {
			return state.newDocuments.every(x => x.meta);
		}
	},
	actions: {
		...page.actions,
		...baseUploadServiceDocumentsStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			
			await this.fetch(id);
			
			this.initializeDocuments();
			
			await this.initializeBaseUploadServiceDocumentsStore(this.service.type);
		},
		initializeDocuments() {
			switch (this.service.workflowState.alias) {
				case ServiceWorkflowStateType.APPROVED_BY_FINANSIST:
					[
						ApiServiceDocumentKindEnum.SERVICE_ACCEPTANCE_CERTIFICATE, ApiServiceDocumentKindEnum.INVOICE
					].forEach(kind => this.newDocuments.push(this.createDocument(kind)));
					break;
				case ServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED:
					break;
				default:
					throw new Error();
			}
		},
		async fetch(serviceId: string) {
			try {
				let { serviceApplication } = await crewboatServiceController.getService(serviceId);
				
				this.service = CrewboatServiceApplicationMapper.map(serviceApplication);
				this.title = formatServiceTitle(this.service.type, this.service.applicationNumber);
				
				if(this.isOnlySignMode) {
					const { documents } = await serviceController.getDocuments(serviceId);
					
					this.documents = documents.map(x => ServiceDocumentMapper.map(x, this.title));
				}
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		},
		async approveSignature() {
			this.approving = true;
			
			try {
				await crewboatServiceController.updateStatusByTransition(this.id!,
					crewboatTransitionService.getTransition(this.service.workflowState.alias,
						this.targetState!) as ApiPropertyServiceTransitionCodeEnum);
				
				this.service.workflowState.alias = this.targetState!;
				
				alertService.addSuccess(AlertKeys.DOCUMENTS_SENT);
				
				await router.push({ name: RouteNames.CREWBOAT_SERVICE, params: { id: this.id } });
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.approving = false;
			}
		}
	}
});
