import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { DocumentsState, useDocumentsStore } from "@rmp/enterprise/stores/composables/baseDocuments";
import { useDredgingServiceStore } from "@rmp/enterprise/stores/serviceDetails/dredging";
import { useDredgingServiceDocumentsBreadcrumb } from "@rmp/enterprise/stores/serviceDetails/dredging/documents/composables/useDredgingServiceDocumentsBreadcrumb";
import { ServiceDocument } from "@rmp/core/types/services/serviceDocument";

const abortService = new AbortService();
const page = usePageStore(abortService);

const documentsStore = useDocumentsStore<ServiceDocument>({});

export interface DredgingServiceDocumentsState extends PageState, DocumentsState<ServiceDocument> {
}

const getDefaultState = (): DredgingServiceDocumentsState => {
	return {
		...page.getDefaultPageState(),
		...documentsStore.getDefaultState()
	};
};

export const useDredgingServiceDocumentsStore = defineStore({
	id: "dredging-service-documents",
	state: (): DredgingServiceDocumentsState => getDefaultState(),
	getters: {
		...page.getters,
		...documentsStore.getters,
		breadcrumbs() {
			const { breadcrumbs } = useDredgingServiceStore();
			return [...breadcrumbs, useDredgingServiceDocumentsBreadcrumb()];
		}
	},
	actions: {
		...page.actions,
		...documentsStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			const { documents } = useDredgingServiceStore();
			
			this.id = id;
			this.documents = documents;
		}
	}
});
