import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { DocumentsState, useDocumentsStore } from "@rmp/enterprise/stores/composables/baseDocuments";
import { AgreementDocument } from "@rmp/core/types/agreement/agreementDocument";
import { useAgreementDetailsStore } from "@rmp/enterprise/stores/agreementDetails";
import {
	useAgreementDetailsDocumentsBreadcrumb
} from "@rmp/enterprise/stores/agreementDetails/documents/composables/useAgreementDetailsDocumentsBreadcrumb";

const abortService = new AbortService();
const page = usePageStore(abortService);

const documentsStore = useDocumentsStore<AgreementDocument>({});

export interface AgreementDetailsDocumentsState extends PageState, DocumentsState<AgreementDocument> {
}

const getDefaultState = (): AgreementDetailsDocumentsState => {
	return {
		...page.getDefaultPageState(),
		...documentsStore.getDefaultState()
	};
};

export const useAgreementDetailsDocumentsStore = defineStore({
	id: "agreement-details-documents",
	state: (): AgreementDetailsDocumentsState => getDefaultState(),
	getters: {
		...page.getters,
		...documentsStore.getters,
		breadcrumbs() {
			const { breadcrumbs } = useAgreementDetailsStore();
			return [...breadcrumbs, useAgreementDetailsDocumentsBreadcrumb()];
		}
	},
	actions: {
		...page.actions,
		...documentsStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			const { documents } = useAgreementDetailsStore();
			
			this.id = id;
			this.documents = documents;
		}
	}
});
