import { ApiGetServicesParameters } from "@rmp/core/api/types/service/apiGetServicesParameters";
import { ServicesState } from "@rmp/enterprise/stores/services/index";
import { ServicesItem } from "@rmp/enterprise/stores/services/types/servicesItem";
import { convertToTimestamp } from "@rmp/core/utils/dates";
import ServiceMapperProfile from "@rmp/core/api/mappers/service";
import { ApiServicesItem } from "@rmp/core/api/types/service/apiServicesItem";
import { ServicesFilterStatusEnum } from "@rmp/core/types/enums/ServicesFilterStatusEnum";

export default class ServicesMapperProfile {
	constructor() {
	}
	
	mapToApiGetServicesParameters(source: ServicesState): ApiGetServicesParameters {
		return {
			take: source.paging.pageSize,
			skip: source.paging.pageSize * (source.paging.page - 1),
			query: source.search.query,
			sortField: source.sorting.type,
			sortingDirection: source.sorting.order,
			statuses: source.filter.statuses,
			serviceTypes: source.filter.serviceTypes,
			isArchived: ServicesFilterStatusEnum.ALL === source.filter.status ? null : source.filter.status === ServicesFilterStatusEnum.ARCHIVED
		};
	}
	
	mapToServicesItem(source: ApiServicesItem): ServicesItem {
		return {
			id: source.id,
			number: source.applicationNumber,
			createdAt: convertToTimestamp(source.filed) as number,
			type: ServiceMapperProfile.mapServiceType(source.type),
			counterparty: source.counterparty.title,
			agreement: source.agreement?.title ?? "",
			department: source.division.title,
			port: source.port?.title,
			workflowState: source.workflowState
		};
	}
}
