import { defineStore } from "pinia";
import AbortService from "@rmp/core/services/abortService";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import ApiFileMeta from "@rmp/core/api/types/storage/apiFileMeta";
import {
	UploadAgreementDocumentsStepState
} from "@rmp/enterprise/stores/uploadAgreementDocuments/steps/shared/uploadAgreementDocumentsStepState";
import {
	UploadAgreementDocumentsStepTypeEnum
} from "@rmp/enterprise/stores/uploadAgreementDocuments/steps/shared/UploadAgreementDocumentsStepTypeEnum";
import { AgreementController } from "@rmp/enterprise/api/agreement";
import { StorageController } from "@rmp/core/api/storage";
import { i18n } from "@rmp/core/plugins";
import { useUploadAgreementDocumentsStore } from "@rmp/enterprise/stores/uploadAgreementDocuments";
import { useAppStore } from "@rmp/core/stores/appStore";
import ServiceMapperProfile from "@rmp/core/api/mappers/service";
import { saveAs } from "file-saver";
import { FileMetaHelper } from "@rmp/core/types/storage/fileMeta";
import { AgreementDocument } from "@rmp/enterprise/stores/uploadAgreementDocuments/steps/document/types/agreementDocument";
import { AgreementDocumentKindEnum } from "@rmp/core/types/agreement/agreementDocumentKindEnum";

const abortService = new AbortService();

const page = usePageStore(abortService);

const storageController = new StorageController(abortService);
const agreementController = new AgreementController(abortService);

export interface DocumentStepState extends UploadAgreementDocumentsStepState, PageState {
	templateDownloading: boolean;
	documents: AgreementDocument[];
}

const getDefaultState = (): DocumentStepState => {
	return {
		...page.getDefaultPageState(),
		stepType: UploadAgreementDocumentsStepTypeEnum.DOCUMENTS,
		templateDownloading: false,
		documents: []
	};
};

export const useDocumentStepStore = defineStore({
	id: "upload-agreement-documents-document-step",
	state: (): DocumentStepState => getDefaultState(),
	getters: {
		...page.getters,
		completed(state) {
			return state.documents.every(x => x.meta);
		},
		breadcrumbs() {
			const { breadcrumbs } = useUploadAgreementDocumentsStore();
			return breadcrumbs;
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized() {
			const { title } = useUploadAgreementDocumentsStore();
			
			this.documents = [
				{
					kind: AgreementDocumentKindEnum.AGREEMENT,
					meta: undefined,
					isSigning: false,
					isSigned: false,
					fileName: title
				},
				{
					kind: AgreementDocumentKindEnum.AGREEMENT_NEGOTIATION_LIST,
					meta: undefined,
					isSigning: false,
					isSigned: false,
					fileName: title
				}
			];
		},
		addFile(meta: ApiFileMeta, kind: AgreementDocumentKindEnum) {
			let serviceDocument = this.documents.find(x => x.kind === kind)!;
			serviceDocument.meta = FileMetaHelper.map(meta);
		},
		deleteFile(kind: AgreementDocumentKindEnum) {
			let serviceDocument = this.documents.find(x => x.kind === kind)!;
			serviceDocument.meta = undefined;
		},
		async downloadTemplate() {
			this.templateDownloading = true;
			
			try {
				const { agreement, title } = useUploadAgreementDocumentsStore();
				
				const file = await agreementController.getTemplate(agreement.id);
				
				saveAs(file, title);
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.templateDownloading = false;
			}
		}
	}
});
