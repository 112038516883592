import { render, staticRenderFns } from "./RmpSpecialDetails.vue?vue&type=template&id=a98a5096&scoped=true&"
import script from "./RmpSpecialDetails.vue?vue&type=script&setup=true&lang=ts&generic=T%20extends%20Record%3Cstring%2C%20unknown%3E&"
export * from "./RmpSpecialDetails.vue?vue&type=script&setup=true&lang=ts&generic=T%20extends%20Record%3Cstring%2C%20unknown%3E&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a98a5096",
  null
  
)

export default component.exports