import AbortService from "@rmp/core/services/abortService";
import { urls } from "@rmp/enterprise/api/config/urls";
import BaseEnterpriseController from "@rmp/enterprise/api/base/baseEnterpriseController";

export class AxaptaController extends BaseEnterpriseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getCalculationObjectCode = async (id: string) => {
		return await this.get<{ id: string, calculationObjectCode: string }>(urls.axapta.property.$id, { id });
	};
	
	updateCalculationObjectCode = async (id: string, calculationObjectCode: string) => {
		return await this.put<{ id: string, calculationObjectCode: string }>(urls.axapta.property.$id, { id }, { calculationObjectCode });
	};
}
