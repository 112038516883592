import { render, staticRenderFns } from "./RmpCrewboatService.vue?vue&type=template&id=4e8d004d&scoped=true&"
import script from "./RmpCrewboatService.vue?vue&type=script&setup=true&lang=ts&"
export * from "./RmpCrewboatService.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e8d004d",
  null
  
)

export default component.exports