import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { SortingOrderTypeEnum } from "@rmp/core/types/SortingOrderTypeEnum";
import { RegisterState, useRegisterStore } from "@rmp/core/stores/composables/register/useRegisterStore";
import { RouteNames } from "@rmp/enterprise/router/routes";
import router from "@rmp/enterprise/router";
import MetricsByAccountsMapperProfile from "@rmp/enterprise/stores/metrics/byAccounts/mapper";
import MetricsByAccountsRouteQuery from "@rmp/enterprise/stores/metrics/byAccounts/types/metricsByAccountsRouteQuery";
import MetricsByAccountsFilter from "@rmp/enterprise/stores/metrics/byAccounts/types/metricsByAccountsFilter";
import MetricsByAccountsRouteQueryService from "@rmp/enterprise/stores/metrics/byAccounts/services/metricsByAccountsRouteQueryService";
import { MetricsController } from "@rmp/enterprise/api/metrics";
import { MetricsPeriodType } from "@rmp/core/types/metrics/metricsPeriodType";
import { getMetricsPeriods } from "@rmp/enterprise/stores/metrics/helpers/getMetricsPeriods";
import { MetricsPeriod } from "@rmp/enterprise/stores/metrics/types/metricsPeriod";
import { MetricsByCounterpartyItem, MetricsByCounterpartyItemMapper } from "@rmp/core/types/metrics/metricsByCounterpartyItem";
import { saveAs } from "file-saver";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";

const abortService = new AbortService();
const metricsController = new MetricsController(abortService);

const mapper = new MetricsByAccountsMapperProfile();

export interface MetricsByAccountsState extends PageState, RegisterState<MetricsByCounterpartyItem, MetricsByAccountsFilter>
{
	filter: MetricsByAccountsFilter;
	periods: MetricsPeriod[];
	reportDownloading: boolean;
}

const defaultRouteQuery = new MetricsByAccountsRouteQuery(1,
	"createdAt",
	SortingOrderTypeEnum.DESCENDING,
	"",
	MetricsPeriodType.CURRENT_MONTH,
	"",
	"");

const routeQueryService = new MetricsByAccountsRouteQueryService(defaultRouteQuery);

const page = usePageStore(abortService);

const register = useRegisterStore<MetricsByCounterpartyItem, MetricsByAccountsFilter>({
	routeName: RouteNames.METRICS_BY_ACCOUNTS,
	routeQueryService,
	routeQuery: defaultRouteQuery,
	getRouter: () => router
});

const getDefaultState = (): MetricsByAccountsState => {
	return {
		...page.getDefaultPageState(),
		...register.getDefaultRegisterState(),
		filter: new MetricsByAccountsFilter(),
		periods: [],
		reportDownloading: false
	};
};

export const useMetricsByAccountsStore = defineStore({
	id: "metrics-by-accounts",
	state: (): MetricsByAccountsState => getDefaultState(),
	getters: {
		...page.getters,
		...register.getters,
		filteredItems(state: MetricsByAccountsState): MetricsByCounterpartyItem[] {
			return state.listing.items;
		}
	},
	actions: {
		...page.actions,
		...register.actions,
		async beforeInitialized() {
			this.periods = getMetricsPeriods();
			await this.initializeRegister();
		},
		patchStateWithRouteQuery(query: MetricsByAccountsRouteQuery) {
			this.patchRegisterStateWithRouteQuery(query);
			this.$patch((state) => {
				state.filter.period = query.period;
				state.filter.periodStart = query.periodStart;
				state.filter.periodEnd = query.periodEnd;
			});
		},
		async fetchRegisterItems() {
			const item = await metricsController.getMetricsByCounterparty(mapper.mapToApiGetMetricsByAccountsParameters(this));
			
			return { items: [MetricsByCounterpartyItemMapper.map(item)], totalCount: 1 };
		},
		async downloadReport() {
			this.reportDownloading = true;
			
			try {
				const file = await metricsController.downloadReportByCounterparty(mapper.mapToApiGetMetricsByAccountsParameters(this));
				
				saveAs(file, "Статистика по контрагентам");
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.reportDownloading = false;
			}
		}
	}
});
