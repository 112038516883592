import { render, staticRenderFns } from "./RmpCrewboatDetails.vue?vue&type=template&id=750d1474&scoped=true&"
import script from "./RmpCrewboatDetails.vue?vue&type=script&setup=true&lang=ts&"
export * from "./RmpCrewboatDetails.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "750d1474",
  null
  
)

export default component.exports