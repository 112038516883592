import AbortService from "@rmp/core/services/abortService";
import { urls } from "@rmp/enterprise/api/config/urls";
import BaseEnterpriseController from "@rmp/enterprise/api/base/baseEnterpriseController";
import { ApiGetMetricsByTimeParameters } from "@rmp/core/api/types/metrics/apiGetMetricsByTimeParameters";
import { ApiGetMetricsByMoneyParameters } from "@rmp/core/api/types/metrics/apiGetMetricsByMoneyParameters";
import { ApiGetMetricsByPropertyParameters } from "@rmp/core/api/types/metrics/apiGetMetricsByPropertyParameters";
import { ApiMetricsByPropertyItem } from "@rmp/core/api/types/metrics/apiMetricsByPropertyItem";
import { ApiMetricsByMoneyItem } from "@rmp/core/api/types/metrics/apiMetricsByMoneyItem";
import { ApiMetricsByTimeItem } from "@rmp/core/api/types/metrics/apiMetricsByTimeItem";
import { ApiGetMetricsByCounterpartyParameters } from "@rmp/core/api/types/metrics/apiGetMetricsByCounterpartyParameters";
import { ApiMetricsByCounterpartyItem } from "@rmp/core/api/types/metrics/apiMetricsByCounterpartyItem";
import { ApiMetricsByPropertyDetailedItem } from "@rmp/core/api/types/metrics/apiMetricsByPropertyDetailedItem";
import { ApiGetMetricsByPropertyDetailedParameters } from "@rmp/core/api/types/metrics/apiGetMetricsByPropertyDetailedParameters";

export class MetricsController extends BaseEnterpriseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getMetricsByTime = async (parameters: ApiGetMetricsByTimeParameters) => {
		return await this.get<{ items: ApiMetricsByTimeItem[] }>(urls.metrics.statsTime, parameters);
	};
	
	downloadReportByTime = async (parameters: ApiGetMetricsByTimeParameters) => {
		return await this.get<Blob>(urls.metrics.statsTime.report, parameters, { responseType: "blob" });
	};
	
	getMetricsByMoney = async (parameters: ApiGetMetricsByMoneyParameters) => {
		return await this.get<{ items: ApiMetricsByMoneyItem[] }>(urls.metrics.statsMoney, parameters);
	};
	
	downloadReportByMoney = async (parameters: ApiGetMetricsByMoneyParameters) => {
		return await this.get<Blob>(urls.metrics.statsMoney.report, parameters, { responseType: "blob" });
	};
	
	getMetricsByProperty = async (parameters: ApiGetMetricsByPropertyParameters) => {
		return await this.get<{ items: ApiMetricsByPropertyItem[] }>(urls.metrics.statsProperty, parameters);
	};
	
	downloadReportByProperty = async (parameters: ApiGetMetricsByPropertyParameters) => {
		return await this.get<Blob>(urls.metrics.statsProperty.report, parameters, { responseType: "blob" });
	};
	
	getMetricsByPropertyDetailed = async (parameters: ApiGetMetricsByPropertyDetailedParameters) => {
		return await this.get<{ items: ApiMetricsByPropertyDetailedItem[] }>(urls.metrics.propertyStats, parameters);
	};
	
	downloadReportPropertyDetailed = async (parameters: ApiGetMetricsByPropertyDetailedParameters) => {
		return await this.get<Blob>(urls.metrics.propertyStats.report, parameters, { responseType: "blob" });
	};
	
	getMetricsByCounterparty = async (parameters: ApiGetMetricsByCounterpartyParameters) => {
		return await this.get<ApiMetricsByCounterpartyItem>(urls.metrics.counterpartyStats, parameters);
	};
	
	downloadReportByCounterparty = async (parameters: ApiGetMetricsByCounterpartyParameters) => {
		return await this.get<Blob>(urls.metrics.counterpartyStats.report, parameters, { responseType: "blob" });
	};
	
}
