import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { RouteNames } from "@rmp/enterprise/router/routes";
import router from "@rmp/enterprise/router";
import { RegisterState, useRegisterStore } from "@rmp/core/stores/composables/register/useRegisterStore";
import { CounterpartyController } from "@rmp/enterprise/api/counterparty";
import {
	useCounterpartyDetailsBankAccountsBreadcrumb
} from "@rmp/enterprise/stores/counterpartyDetails/bankAccounts/composables/useCounterpartyDetailsBankAccountsBreadcrumb";
import CounterpartyDetailsBankAccountsRouteQuery
	from "@rmp/enterprise/stores/counterpartyDetails/bankAccounts/types/counterpartyDetailsBankAccountsRouteQuery";
import { SortingOrderTypeEnum } from "@rmp/core/types/SortingOrderTypeEnum";
import CounterpartyDetailsBankAccountsRouteQueryService
	from "@rmp/enterprise/stores/counterpartyDetails/bankAccounts/services/counterpartyDetailsBankAccountsRouteQueryService";
import { useCounterpartyDetailsStore } from "@rmp/enterprise/stores/counterpartyDetails";
import { ApiCounterpartyBankAccountPersisted } from "@rmp/core/api/types/counterpartyBankAccount/apiCounterpartyBankAccount";

const abortService = new AbortService();
const counterpartyController = new CounterpartyController(abortService);

export interface CounterpartyDetailsBankAccountsState extends PageState, RegisterState<ApiCounterpartyBankAccountPersisted>
{
	id: string;
}

const defaultRouteQuery = new CounterpartyDetailsBankAccountsRouteQuery(
	1,
	"bankAccountNumber",
	SortingOrderTypeEnum.ASCENDING,
	""
);
const routeQueryService = new CounterpartyDetailsBankAccountsRouteQueryService(defaultRouteQuery);

const page = usePageStore(abortService);

const register = useRegisterStore<ApiCounterpartyBankAccountPersisted>({
	routeName: RouteNames.COUNTERPARTY_DETAILS_BANK_ACCOUNTS,
	routeQueryService,
	routeQuery: defaultRouteQuery,
	getRouter: () => router
});

const getDefaultState = (): CounterpartyDetailsBankAccountsState => {
	return {
		...page.getDefaultPageState(),
		...register.getDefaultRegisterState(),
		id: ""
	};
};

export const useCounterpartyDetailsBankAccountsStore = defineStore({
	id: "counterparty-details-bank-accounts",
	state: (): CounterpartyDetailsBankAccountsState => getDefaultState(),
	getters: {
		...page.getters,
		...register.getters,
		breadcrumbs(state: CounterpartyDetailsBankAccountsState) {
			const { breadcrumbs } = useCounterpartyDetailsStore();
			
			return [
				...breadcrumbs,
				useCounterpartyDetailsBankAccountsBreadcrumb({ id: state.id })
			];
		},
		formattedItems(state: CounterpartyDetailsBankAccountsState) {
			return state.listing.items.map(x => {
				return {
					id: x.id,
					bankAccountNumber: x.bankAccount.bankAccountNumber,
					bankIdentificationCode: x.bankAccount.bankIdentificationCode,
					bankName: x.bankAccount.bankName,
					correspondentAccount: x.bankAccount.correspondentAccount
				};
			});
		},
		filteredItems() {
			return this.formattedItems;
		}
	},
	actions: {
		...page.actions,
		...register.actions,
		async beforeInitialized() {
			this.id = router.currentRoute.params.id;
			
			await this.initializeRegister();			
		},
		async fetchRegisterItems() {
			let items = await counterpartyController.getCounterpartyAccounts(this.id);
			return { items };
		}
	}
});
