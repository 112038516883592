import { RouteNames } from "@rmp/enterprise/router/routes";
import { useBreadcrumb } from "@rmp/core/stores/composables/breadcrumb/useBreadcrumb";
import { i18n } from "@rmp/enterprise/plugins";
import routeToPermissionsMap from "@rmp/enterprise/router/routeToPermissionsMap";

export function useFloatingCraneServiceDocumentsBreadcrumb() {
	return useBreadcrumb({
		route: {
			name: RouteNames.FLOATING_CRANE_SERVICE_DOCUMENTS
		},
		text: i18n.t(`navigation.breadcrumbs.${RouteNames.FLOATING_CRANE_SERVICE_DOCUMENTS}`).toString(),
		routeToPermissionsMap
	});
}
