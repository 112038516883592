import TreeModel from "tree-model";
import Route from "@rmp/core/router/route";
import { AuthenticationRouteNames, BaseRouteNames } from "@rmp/core/router/routeNames";

enum EnterpriseRouteNames {
	TUGBOATS = "TUGBOATS",
	TUGBOAT = "TUGBOAT",
	TUGBOAT_CREATE = "TUGBOAT_CREATE",
	TUGBOAT_DETAILS = "TUGBOAT_DETAILS",
	TUGBOAT_DETAILS_GENERAL = "TUGBOAT_DETAILS_GENERAL",
	TUGBOAT_DETAILS_TARIFFS = "TUGBOAT_DETAILS_TARIFFS",
	
	CREWBOATS = "CREWBOATS",
	CREWBOAT_CREATE = "CREWBOAT_CREATE",
	CREWBOAT = "CREWBOAT",
	CREWBOAT_DETAILS = "CREWBOAT_DETAILS",
	CREWBOAT_DETAILS_GENERAL = "CREWBOAT_DETAILS_GENERAL",
	CREWBOAT_DETAILS_TARIFFS = "CREWBOAT_DETAILS_TARIFFS",
	
	FLOATING_CRANES = "FLOATING_CRANES",
	FLOATING_CRANE = "FLOATING_CRANE",
	FLOATING_CRANE_CREATE = "FLOATING_CRANE_CREATE",
	FLOATING_CRANE_DETAILS = "FLOATING_CRANE_DETAILS",
	
	PIERS = "PIERS",
	PIER = "PIER",
	PIER_CREATE = "PIER_CREATE",
	PIER_DETAILS = "PIER_DETAILS",
	
	DIVISIONS = "DIVISIONS",
	BRANCHES = "BRANCHES",
	BRANCH = "BRANCH",
	BRANCH_GENERAL = "BRANCH_GENERAL",
	BRANCH_DEPARTMENTS = "BRANCH_DEPARTMENTS",
	DEPARTMENT = "DEPARTMENT",
	DEPARTMENT_GENERAL = "DEPARTMENT_GENERAL",
	DEPARTMENT_PORTS = "DEPARTMENT_PORTS",
	PORT = "PORT",
	PORT_GENERAL = "PORT_GENERAL",
	PORT_DESTINATION = "PORT_DESTINATION",
	PORT_DESTINATION_DETAILS = "PORT_DESTINATION_DETAILS",
	PORT_DESTINATION_GENERAL = "PORT_DESTINATION_GENERAL",
	PORT_DESTINATIONS = "PORT_DESTINATIONS",
	PORT_DESTINATION_CREATE = "PORT_DESTINATION_CREATE",
	
	SERVICES = "SERVICES",
	
	TARIFFS = "TARIFFS",
	
	COUNTERPARTIES = "COUNTERPARTIES",
	
	COUNTERPARTY_DETAILS = "COUNTERPARTY_DETAILS",
	COUNTERPARTY_DETAILS_GENERAL = "COUNTERPARTY_DETAILS_GENERAL",
	COUNTERPARTY_DETAILS_BANK_ACCOUNTS = "COUNTERPARTY_DETAILS_BANK_ACCOUNTS",
	COUNTERPARTY_DETAILS_BANK_ACCOUNT = "COUNTERPARTY_DETAILS_BANK_ACCOUNT",
	COUNTERPARTY_DETAILS_EMPLOYEES = "COUNTERPARTY_DETAILS_EMPLOYEES",
	COUNTERPARTY_DETAILS_EMPLOYEE = "COUNTERPARTY_DETAILS_EMPLOYEE",
	COUNTERPARTY_DETAILS_DOCUMENTS = "COUNTERPARTY_DETAILS_DOCUMENTS",
	
	
	PIER_SERVICE = "PIER_SERVICE",
	PIER_SERVICE_GENERAL = "PIER_SERVICE_GENERAL",
	PIER_SERVICE_DOCUMENTS = "PIER_SERVICE_DOCUMENTS",
	PIER_SERVICE_HISTORY = "PIER_SERVICE_HISTORY",
	PIER_SERVICE_COMMENTS = "PIER_SERVICE_COMMENTS",
	PIER_SERVICE_PIER_ASSIGNMENT = "PIER_SERVICE_PIER_ASSIGNMENT",
	PIER_SERVICE_UPLOAD_DOCUMENTS = "PIER_SERVICE_UPLOAD_DOCUMENTS",
	PIER_SERVICE_TARIFF_ASSIGNMENT = "PIER_SERVICE_TARIFF_ASSIGNMENT",
	PIER_SERVICE_FEEDBACK = "PIER_SERVICE_FEEDBACK",
	
	TUGBOAT_SERVICE = "TUGBOAT_SERVICE",
	TUGBOAT_SERVICE_GENERAL = "TUGBOAT_SERVICE_GENERAL",
	TUGBOAT_SERVICE_DOCUMENTS = "TUGBOAT_SERVICE_DOCUMENTS",
	TUGBOAT_SERVICE_HISTORY = "TUGBOAT_SERVICE_HISTORY",
	TUGBOAT_SERVICE_COMMENTS = "TUGBOAT_SERVICE_COMMENTS",
	TUGBOAT_SERVICE_TUGBOAT_ASSIGNMENT = "TUGBOAT_SERVICE_TUGBOAT_ASSIGNMENT",
	TUGBOAT_SERVICE_UPLOAD_DOCUMENTS = "TUGBOAT_SERVICE_UPLOAD_DOCUMENTS",
	TUGBOAT_SERVICE_TARIFF_ASSIGNMENT = "TUGBOAT_SERVICE_TARIFF_ASSIGNMENT",
	TUGBOAT_SERVICE_FEEDBACK = "TUGBOAT_SERVICE_FEEDBACK",
	
	CREWBOAT_SERVICE = "CREWBOAT_SERVICE",
	CREWBOAT_SERVICE_GENERAL = "CREWBOAT_SERVICE_GENERAL",
	CREWBOAT_SERVICE_DOCUMENTS = "CREWBOAT_SERVICE_DOCUMENTS",
	CREWBOAT_SERVICE_HISTORY = "CREWBOAT_SERVICE_HISTORY",
	CREWBOAT_SERVICE_COMMENTS = "CREWBOAT_SERVICE_COMMENTS",
	CREWBOAT_SERVICE_CREWBOAT_ASSIGNMENT = "CREWBOAT_SERVICE_CREWBOAT_ASSIGNMENT",
	CREWBOAT_SERVICE_UPLOAD_DOCUMENTS = "CREWBOAT_SERVICE_UPLOAD_DOCUMENTS",
	CREWBOAT_SERVICE_TARIFF_ASSIGNMENT = "CREWBOAT_SERVICE_TARIFF_ASSIGNMENT",
	CREWBOAT_SERVICE_FEEDBACK = "CREWBOAT_SERVICE_FEEDBACK",
	
	DREDGING_SERVICE = "DREDGING_SERVICE",
	DREDGING_SERVICE_GENERAL = "DREDGING_SERVICE_GENERAL",
	DREDGING_SERVICE_DOCUMENTS = "DREDGING_SERVICE_DOCUMENTS",
	DREDGING_SERVICE_HISTORY = "DREDGING_SERVICE_HISTORY",
	DREDGING_SERVICE_COMMENTS = "DREDGING_SERVICE_COMMENTS",
	DREDGING_SERVICE_UPLOAD_DOCUMENTS = "DREDGING_SERVICE_UPLOAD_DOCUMENTS",
	DREDGING_SERVICE_FEEDBACK = "DREDGING_SERVICE_FEEDBACK",
	
	SNO_INSTALLATION_SERVICE = "SNO_INSTALLATION_SERVICE",
	SNO_INSTALLATION_SERVICE_GENERAL = "SNO_INSTALLATION_SERVICE_GENERAL",
	SNO_INSTALLATION_SERVICE_DOCUMENTS = "SNO_INSTALLATION_SERVICE_DOCUMENTS",
	SNO_INSTALLATION_SERVICE_HISTORY = "SNO_INSTALLATION_SERVICE_HISTORY",
	SNO_INSTALLATION_SERVICE_COMMENTS = "SNO_INSTALLATION_SERVICE_COMMENTS",
	SNO_INSTALLATION_SERVICE_UPLOAD_DOCUMENTS = "SNO_INSTALLATION_SERVICE_UPLOAD_DOCUMENTS",
	SNO_INSTALLATION_SERVICE_FEEDBACK = "SNO_INSTALLATION_SERVICE_FEEDBACK",
	
	GEODETIC_HYDROGRAPHIC_SERVICE = "GEODETIC_HYDROGRAPHIC_SERVICE",
	GEODETIC_HYDROGRAPHIC_SERVICE_GENERAL = "GEODETIC_HYDROGRAPHIC_SERVICE_GENERAL",
	GEODETIC_HYDROGRAPHIC_SERVICE_DOCUMENTS = "GEODETIC_HYDROGRAPHIC_SERVICE_DOCUMENTS",
	GEODETIC_HYDROGRAPHIC_SERVICE_HISTORY = "GEODETIC_HYDROGRAPHIC_SERVICE_HISTORY",
	GEODETIC_HYDROGRAPHIC_SERVICE_COMMENTS = "GEODETIC_HYDROGRAPHIC_SERVICE_COMMENTS",
	GEODETIC_HYDROGRAPHIC_SERVICE_UPLOAD_DOCUMENTS = "GEODETIC_HYDROGRAPHIC_SERVICE_UPLOAD_DOCUMENTS",
	GEODETIC_HYDROGRAPHIC_SERVICE_FEEDBACK = "GEODETIC_HYDROGRAPHIC_SERVICE_FEEDBACK",
	
	INFORMATION_SERVICE = "INFORMATION_SERVICE",
	INFORMATION_SERVICE_GENERAL = "INFORMATION_SERVICE_GENERAL",
	INFORMATION_SERVICE_DOCUMENTS = "INFORMATION_SERVICE_DOCUMENTS",
	INFORMATION_SERVICE_HISTORY = "INFORMATION_SERVICE_HISTORY",
	INFORMATION_SERVICE_COMMENTS = "INFORMATION_SERVICE_COMMENTS",
	INFORMATION_SERVICE_UPLOAD_DOCUMENTS = "INFORMATION_SERVICE_UPLOAD_DOCUMENTS",
	INFORMATION_SERVICE_TARIFF_ASSIGNMENT = "INFORMATION_SERVICE_TARIFF_ASSIGNMENT",
	INFORMATION_SERVICE_FEEDBACK = "INFORMATION_SERVICE_FEEDBACK",
	
	FLOATING_CRANE_SERVICE = "FLOATING_CRANE_SERVICE",
	FLOATING_CRANE_SERVICE_GENERAL = "FLOATING_CRANE_SERVICE_GENERAL",
	FLOATING_CRANE_SERVICE_DOCUMENTS = "FLOATING_CRANE_SERVICE_DOCUMENTS",
	FLOATING_CRANE_SERVICE_HISTORY = "FLOATING_CRANE_SERVICE_HISTORY",
	FLOATING_CRANE_SERVICE_COMMENTS = "FLOATING_CRANE_SERVICE_COMMENTS",
	FLOATING_CRANE_SERVICE_UPLOAD_DOCUMENTS = "FLOATING_CRANE_SERVICE_UPLOAD_DOCUMENTS",
	FLOATING_CRANE_SERVICE_TARIFF_ASSIGNMENT = "FLOATING_CRANE_SERVICE_TARIFF_ASSIGNMENT",
	FLOATING_CRANE_SERVICE_FLOATING_CRANE_ASSIGNMENT = "FLOATING_CRANE_SERVICE_FLOATING_CRANE_ASSIGNMENT",
	FLOATING_CRANE_SERVICE_FEEDBACK = "FLOATING_CRANE_SERVICE_FEEDBACK",
	
	BOAT_APPLICATIONS = "BOAT_APPLICATIONS",
	BOAT_APPLICATION_DETAILS = "BOAT_APPLICATION_DETAILS",
	BOAT_APPLICATION_DETAILS_GENERAL = "BOAT_APPLICATION_DETAILS_GENERAL",
	BOAT_APPLICATION_DETAILS_DOCUMENTS = "BOAT_APPLICATION_DETAILS_DOCUMENTS",
	BOAT_APPLICATION_DETAILS_HISTORY = "BOAT_APPLICATION_DETAILS_HISTORY",
	BOAT_APPLICATION_DETAILS_COMMENTS = "BOAT_APPLICATION_DETAILS_COMMENTS",
	
	METRICS = "METRICS",
	METRICS_BY_TIME = "METRICS_BY_TIME",
	METRICS_BY_COST = "METRICS_BY_COST",
	METRICS_BY_PROPERTY = "METRICS_BY_PROPERTY",
	METRICS_BY_PROPERTY_DETAILED = "METRICS_BY_PROPERTY_DETAILED",
	METRICS_BY_ACCOUNTS = "METRICS_BY_ACCOUNTS",
	
	AGREEMENTS = "AGREEMENTS",
	
	AGREEMENT_DETAILS = "AGREEMENT_DETAILS",
	AGREEMENT_DETAILS_GENERAL = "AGREEMENT_DETAILS_GENERAL",
	AGREEMENT_DETAILS_DOCUMENTS = "AGREEMENT_DETAILS_DOCUMENTS",
	AGREEMENT_DETAILS_HISTORY = "AGREEMENT_DETAILS_HISTORY",
	AGREEMENT_DETAILS_COMMENTS = "AGREEMENT_DETAILS_COMMENTS",
	AGREEMENT_DETAILS_SERVICES = "AGREEMENT_DETAILS_SERVICES",
	AGREEMENT_UPLOAD_DOCUMENTS = "AGREEMENT_UPLOAD_DOCUMENTS",
	AGREEMENT_SIGN_DOCUMENTS = "AGREEMENT_SIGN_DOCUMENTS",
	
	CONFIRM_PHONE = "CONFIRM_PHONE",
	CONFIRM_EMAIL = "CONFIRM_EMAIL",
	RESET_PASSWORD = "RESET_PASSWORD"
}

export const RouteNames = {
	...EnterpriseRouteNames,
	...BaseRouteNames,
	...AuthenticationRouteNames
};

export type RouteNames = typeof RouteNames[keyof typeof RouteNames];

const tree = new TreeModel();

export const findRoute = (routeName: string, route = routesTreeRoot) => {
	return route.first(x => x.model.name === routeName);
};

export const findAllByAncestor = (ancestorRouteName: string) => {
	let searchNode: any = findRoute(ancestorRouteName);
	
	return routesTreeRoot.all(x => x.getPath().includes(searchNode));
};

export const routesTreeRoot = tree.parse<Route>(
	new Route(RouteNames.ROOT, [
		new Route(RouteNames.CALLBACK),
		new Route(RouteNames.SILENT_RENEW),
		
		new Route(RouteNames.TUGBOATS, [
			new Route(RouteNames.TUGBOAT, []),
			new Route(RouteNames.TUGBOAT_CREATE, []),
			new Route(RouteNames.TUGBOAT_DETAILS, [
				new Route(RouteNames.TUGBOAT_DETAILS_GENERAL, []),
				new Route(RouteNames.TUGBOAT_DETAILS_TARIFFS, [])
			])
		]),
		
		new Route(RouteNames.CREWBOATS, [
			new Route(RouteNames.CREWBOAT, []),
			new Route(RouteNames.CREWBOAT_CREATE, []),
			new Route(RouteNames.CREWBOAT_DETAILS, [
				new Route(RouteNames.CREWBOAT_DETAILS_GENERAL, []),
				new Route(RouteNames.CREWBOAT_DETAILS_TARIFFS, [])
			])
		]),
		
		new Route(RouteNames.FLOATING_CRANES, [
			new Route(RouteNames.FLOATING_CRANE, []),
			new Route(RouteNames.FLOATING_CRANE_CREATE, []),
			new Route(RouteNames.FLOATING_CRANE_DETAILS, [])
		]),
		
		new Route(RouteNames.PIERS, [
			new Route(RouteNames.PIER, []),
			new Route(RouteNames.PIER_CREATE, []),
			new Route(RouteNames.PIER_DETAILS, [])
		]),
		
		new Route(RouteNames.DIVISIONS, [
			new Route(RouteNames.BRANCHES, [
				new Route(RouteNames.BRANCH, [
					new Route(RouteNames.BRANCH_GENERAL),
					new Route(RouteNames.BRANCH_DEPARTMENTS, [
						new Route(RouteNames.DEPARTMENT, [
							new Route(RouteNames.DEPARTMENT_GENERAL),
							new Route(RouteNames.DEPARTMENT_PORTS),
							new Route(RouteNames.PORT, [
								new Route(RouteNames.PORT_GENERAL),
								new Route(RouteNames.PORT_DESTINATIONS, [
									new Route(RouteNames.PORT_DESTINATION_DETAILS, [
										new Route(RouteNames.PORT_DESTINATION_GENERAL),
										new Route(RouteNames.PORT_DESTINATION),
										new Route(RouteNames.PORT_DESTINATION_CREATE)
									])
								])
							])
						])
					])
				])
			])
		]),
		
		new Route(RouteNames.TARIFFS, [
			new Route(RouteNames.TARIFFS, [])
		]),
		
		new Route(RouteNames.SERVICES, [
			new Route(RouteNames.PIER_SERVICE, [
				new Route(RouteNames.PIER_SERVICE_GENERAL, []),
				new Route(RouteNames.PIER_SERVICE_DOCUMENTS, []),
				new Route(RouteNames.PIER_SERVICE_HISTORY, []),
				new Route(RouteNames.PIER_SERVICE_COMMENTS, []),
				new Route(RouteNames.PIER_SERVICE_PIER_ASSIGNMENT, []),
				new Route(RouteNames.PIER_SERVICE_UPLOAD_DOCUMENTS, []),
				new Route(RouteNames.PIER_SERVICE_TARIFF_ASSIGNMENT, []),
				new Route(RouteNames.PIER_SERVICE_FEEDBACK, [])
			]),
			new Route(RouteNames.TUGBOAT_SERVICE, [
				new Route(RouteNames.TUGBOAT_SERVICE_GENERAL, []),
				new Route(RouteNames.TUGBOAT_SERVICE_DOCUMENTS, []),
				new Route(RouteNames.TUGBOAT_SERVICE_HISTORY, []),
				new Route(RouteNames.TUGBOAT_SERVICE_COMMENTS, []),
				new Route(RouteNames.TUGBOAT_SERVICE_TUGBOAT_ASSIGNMENT, []),
				new Route(RouteNames.TUGBOAT_SERVICE_UPLOAD_DOCUMENTS, []),
				new Route(RouteNames.TUGBOAT_SERVICE_TARIFF_ASSIGNMENT, []),
				new Route(RouteNames.TUGBOAT_SERVICE_FEEDBACK, [])
			]),
			new Route(RouteNames.CREWBOAT_SERVICE, [
				new Route(RouteNames.CREWBOAT_SERVICE_GENERAL, []),
				new Route(RouteNames.CREWBOAT_SERVICE_DOCUMENTS, []),
				new Route(RouteNames.CREWBOAT_SERVICE_HISTORY, []),
				new Route(RouteNames.CREWBOAT_SERVICE_COMMENTS, []),
				new Route(RouteNames.CREWBOAT_SERVICE_CREWBOAT_ASSIGNMENT, []),
				new Route(RouteNames.CREWBOAT_SERVICE_UPLOAD_DOCUMENTS, []),
				new Route(RouteNames.CREWBOAT_SERVICE_TARIFF_ASSIGNMENT, []),
				new Route(RouteNames.CREWBOAT_SERVICE_FEEDBACK, [])
			]),
			new Route(RouteNames.DREDGING_SERVICE, [
				new Route(RouteNames.DREDGING_SERVICE_GENERAL, []),
				new Route(RouteNames.DREDGING_SERVICE_DOCUMENTS, []),
				new Route(RouteNames.DREDGING_SERVICE_HISTORY, []),
				new Route(RouteNames.DREDGING_SERVICE_COMMENTS, []),
				new Route(RouteNames.DREDGING_SERVICE_UPLOAD_DOCUMENTS, []),
				new Route(RouteNames.DREDGING_SERVICE_FEEDBACK, [])
			]),
			new Route(RouteNames.SNO_INSTALLATION_SERVICE, [
				new Route(RouteNames.SNO_INSTALLATION_SERVICE_GENERAL, []),
				new Route(RouteNames.SNO_INSTALLATION_SERVICE_DOCUMENTS, []),
				new Route(RouteNames.SNO_INSTALLATION_SERVICE_HISTORY, []),
				new Route(RouteNames.SNO_INSTALLATION_SERVICE_COMMENTS, []),
				new Route(RouteNames.SNO_INSTALLATION_SERVICE_UPLOAD_DOCUMENTS, []),
				new Route(RouteNames.SNO_INSTALLATION_SERVICE_FEEDBACK, [])
			]),
			new Route(RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE, [
				new Route(RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_GENERAL, []),
				new Route(RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_DOCUMENTS, []),
				new Route(RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_HISTORY, []),
				new Route(RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_COMMENTS, []),
				new Route(RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_UPLOAD_DOCUMENTS, []),
				new Route(RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_FEEDBACK, [])
			]),
			new Route(RouteNames.INFORMATION_SERVICE, [
				new Route(RouteNames.INFORMATION_SERVICE_GENERAL, []),
				new Route(RouteNames.INFORMATION_SERVICE_DOCUMENTS, []),
				new Route(RouteNames.INFORMATION_SERVICE_HISTORY, []),
				new Route(RouteNames.INFORMATION_SERVICE_COMMENTS, []),
				new Route(RouteNames.INFORMATION_SERVICE_UPLOAD_DOCUMENTS, []),
				new Route(RouteNames.INFORMATION_SERVICE_TARIFF_ASSIGNMENT, []),
				new Route(RouteNames.INFORMATION_SERVICE_FEEDBACK, [])
			]),
			new Route(RouteNames.FLOATING_CRANE_SERVICE, [
				new Route(RouteNames.FLOATING_CRANE_SERVICE_GENERAL, []),
				new Route(RouteNames.FLOATING_CRANE_SERVICE_DOCUMENTS, []),
				new Route(RouteNames.FLOATING_CRANE_SERVICE_HISTORY, []),
				new Route(RouteNames.FLOATING_CRANE_SERVICE_COMMENTS, []),
				new Route(RouteNames.FLOATING_CRANE_SERVICE_UPLOAD_DOCUMENTS, []),
				new Route(RouteNames.FLOATING_CRANE_SERVICE_TARIFF_ASSIGNMENT, []),
				new Route(RouteNames.FLOATING_CRANE_SERVICE_FLOATING_CRANE_ASSIGNMENT, []),
				new Route(RouteNames.FLOATING_CRANE_SERVICE_FEEDBACK, [])
			])
		]),
		
		new Route(RouteNames.COUNTERPARTIES, [
			new Route(RouteNames.COUNTERPARTY_DETAILS, [
				new Route(RouteNames.COUNTERPARTY_DETAILS_GENERAL),
				new Route(RouteNames.COUNTERPARTY_DETAILS_BANK_ACCOUNTS, [
					new Route(RouteNames.COUNTERPARTY_DETAILS_BANK_ACCOUNT)
				]),
				new Route(RouteNames.COUNTERPARTY_DETAILS_EMPLOYEES, [
					new Route(RouteNames.COUNTERPARTY_DETAILS_EMPLOYEE)
					]),
				new Route(RouteNames.COUNTERPARTY_DETAILS_DOCUMENTS)
			])
		]),
		
		
		
		new Route(RouteNames.BOAT_APPLICATIONS, [
			new Route(RouteNames.BOAT_APPLICATION_DETAILS, [
				new Route(RouteNames.BOAT_APPLICATION_DETAILS_GENERAL),
				new Route(RouteNames.BOAT_APPLICATION_DETAILS_DOCUMENTS),
				new Route(RouteNames.BOAT_APPLICATION_DETAILS_HISTORY),
				new Route(RouteNames.BOAT_APPLICATION_DETAILS_COMMENTS)
			])
		]),
		
		new Route(RouteNames.METRICS, [
			new Route(RouteNames.METRICS_BY_TIME),
			new Route(RouteNames.METRICS_BY_COST),
			new Route(RouteNames.METRICS_BY_PROPERTY, [
				new Route(RouteNames.METRICS_BY_PROPERTY_DETAILED)
			]),
			new Route(RouteNames.METRICS_BY_ACCOUNTS)
		]),
		
		new Route(RouteNames.AGREEMENTS, [
			new Route(RouteNames.AGREEMENT_DETAILS, [
				new Route(RouteNames.AGREEMENT_DETAILS_GENERAL),
				new Route(RouteNames.AGREEMENT_DETAILS_DOCUMENTS),
				new Route(RouteNames.AGREEMENT_DETAILS_HISTORY),
				new Route(RouteNames.AGREEMENT_DETAILS_COMMENTS),
				new Route(RouteNames.AGREEMENT_UPLOAD_DOCUMENTS),
				new Route(RouteNames.AGREEMENT_SIGN_DOCUMENTS)
			])
		]),
		
		new Route(RouteNames.CONFIRM_EMAIL),
		new Route(RouteNames.CONFIRM_PHONE),
		new Route(RouteNames.RESET_PASSWORD),
		
		new Route(RouteNames.ERROR_PAGE_NOT_FOUND),
		new Route(RouteNames.ERROR_ACCESS_DENIED),
		new Route(RouteNames.ERROR_INTERNAL_SERVER_ERROR)
	]));
