import { defineStore } from "pinia";
import AbortService from "@rmp/core/services/abortService";
import { PortDestinationController } from "@rmp/enterprise/api/divisions/port/destination";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { Options, OptionsHelper } from "@rmp/core/types/options/options";
import { NIL as NIL_UUID } from "uuid";
import { i18n } from "@rmp/core/plugins";
import { useTariffAssignmentStore } from "@rmp/enterprise/stores/assignment/tariff";

const abortService = new AbortService();
const portDestinationController = new PortDestinationController(abortService);

export interface PortDestinationsOptionsState {
	initialized: boolean;
	options: Options;
	query?: string | null;
	cancel?: () => void;
}

const getDefaultState = (): PortDestinationsOptionsState => ({
	initialized: false,
	options: OptionsHelper.getEmpty()
});

const createPortDestinationsOptionsStore = (id: string) => defineStore({
	id,
	state: () => getDefaultState(),
	actions: {
		async fetch() {
			this.options.loading = true;
			
			try {
				const { service } = useTariffAssignmentStore();
				
				const { items } = await portDestinationController.getPortDestinations(service.portId, {
					query: this.query,
					isArchived: false,
					take: 100
				});
				
				this.options.items = items.map(portDestination => {
					return {
						title: portDestination.portDestination.shortName,
						value: portDestination.id
					};
				});
				
				this.addGuidEmptyItem();
				
				this.initialized = true;
			} catch (ex) {
				console.error(ex);
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.options.loading = false;
			}
		},
		async initialize(query?: string | null) {
			abortService.initialize();
			
			this.query = query;
			
			await this.fetch();
		},
		addGuidEmptyItem() {
			this.options.items.push({
				title: i18n.tc("common.itemNotInList.title"),
				value: NIL_UUID
			});
		}
	}
});

export const usePortDestinationsForStartOptionsStore = createPortDestinationsOptionsStore(
	"port-destinations-for-start-location-options");
export const usePortDestinationsForEndOptionsStore = createPortDestinationsOptionsStore("port-destinations-for-end-location-options");
