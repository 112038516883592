import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { useServicesBreadcrumb } from "@rmp/enterprise/stores/services/composables/useServicesBreadcrumb";
import { BaseServiceState, useBaseServiceStore } from "@rmp/enterprise/stores/composables/baseService";
import { FloatingCraneServiceController } from "@rmp/enterprise/api/service/floatingCrane";
import {
	FloatingCraneServiceApplication,
	FloatingCraneServiceApplicationMapper
} from "@rmp/core/types/services/floatingCrane/floatingCraneServiceApplication";
import {
	useFloatingCraneServiceBreadcrumb
} from "@rmp/enterprise/stores/serviceDetails/floatingCrane/composables/useFloatingCraneServiceBreadcrumb";
import { formatServiceTitle } from "@rmp/core/utils/formatting";

const abortService = new AbortService();
const page = usePageStore(abortService);
const baseServiceStore = useBaseServiceStore({ abortService });

const floatingCraneServiceController = new FloatingCraneServiceController(abortService);

export interface FloatingCraneServiceState extends PageState, BaseServiceState {
	service: FloatingCraneServiceApplication;
}

const getDefaultState = (): FloatingCraneServiceState => {
	return {
		...page.getDefaultPageState(),
		...baseServiceStore.getDefaultState(),
		service: {} as FloatingCraneServiceApplication
	};
};

export const useFloatingCraneServiceStore = defineStore({
	id: "floating-crane-service",
	state: (): FloatingCraneServiceState => getDefaultState(),
	getters: {
		...page.getters,
		...baseServiceStore.getters,
		breadcrumbs(state: FloatingCraneServiceState) {
			return [
				useServicesBreadcrumb(),
				useFloatingCraneServiceBreadcrumb(state.title)
			];
		}
	},
	actions: {
		...page.actions,
		...baseServiceStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			
			await Promise.all([
				this.fetch(),
				this.fetchDocuments()
			]);
			
			await this.initializeBaseServiceStore(this.service.type);
		},
		async fetch() {
			try {
				const { serviceApplication } = await floatingCraneServiceController.getService(this.id!);
				
				this.service = FloatingCraneServiceApplicationMapper.map(serviceApplication);
				this.title = formatServiceTitle(this.service.type, this.service.applicationNumber);
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		}
	}
});
