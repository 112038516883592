import { DivisionStatusEnum } from "@rmp/core/api/types/divisions/DivisionStatusEnum";
import { DepartmentsItem } from "@rmp/enterprise/stores/divisions/branch/departments/types/departmentsItem";
import { ApiDepartmentPersisted } from "@rmp/core/api/types/divisions/department/apiDepartment";
import { DepartmentsState } from "@rmp/enterprise/stores/divisions/branch/departments/index";
import ApiGetDepartmentParameters from "@rmp/core/api/types/divisions/department/apiGetDepartmentParameters";
import {
	BranchDepartmentsFilterStatusEnum
} from "@rmp/enterprise/stores/divisions/branch/departments/types/BranchDepartmentsFilterStatusEnum";

export default class DepartmentsMapperProfile {
	constructor() {
	}
	
	mapToApiGetDepartmentParameters(source: DepartmentsState): ApiGetDepartmentParameters {
		return {
			take: source.paging.pageSize,
			skip: source.paging.pageSize * (source.paging.page - 1),
			query: source.search.query,
			sortField: source.sorting.type,
			sortingDirection: source.sorting.order,
			isArchived: BranchDepartmentsFilterStatusEnum.ALL === source.filter.status ?
				null :
				source.filter.status === BranchDepartmentsFilterStatusEnum.ARCHIVED
		};
	}
	
	mapFromApi(source: ApiDepartmentPersisted): DepartmentsItem {
		return {
			...source.division,
			id: source.id,
			status: DivisionStatusEnum.ACTIVE
		};
	}
}
