import { ApiCounterpartyEmployeeBase } from "@rmp/core/api/types/counterparty/apiCounterpartyEmployee";

export interface CounterpartyEmployeeGeneral {
	inn: string;
	firstName: string;
	middleName: string;
	lastName: string;
	snils: string;
	isActive: boolean;
	email: string;
	phone: string;
	position: string;
}


export class CounterpartyEmployeeGeneralMapper {
	static map(source: ApiCounterpartyEmployeeBase): CounterpartyEmployeeGeneral {
		return {
			...source
		};
	}
}
