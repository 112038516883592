import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { useAppStore } from "@rmp/core/stores/appStore";
import { cloneDeep } from "lodash";
import { ApiDepartment } from "@rmp/core/api/types/divisions/department/apiDepartment";
import { ApiPort } from "@rmp/core/api/types/divisions/port/apiPort";
import { ServiceDocument, ServiceDocumentMapper } from "@rmp/core/types/services/serviceDocument";
import { ServiceController } from "@rmp/enterprise/api/service";
import { DepartmentController } from "@rmp/enterprise/api/divisions/department";
import { PortController } from "@rmp/enterprise/api/divisions/port";
import { FloatingCraneServiceApplication } from "@rmp/core/types/services/floatingCrane/floatingCraneServiceApplication";
import { useFloatingCraneServiceStore } from "@rmp/enterprise/stores/serviceDetails/floatingCrane";
import { ApiFloatingCrane, ApiFloatingCranePersisted } from "@rmp/core/api/types/enterpriseProperty/apiFloatingCrane";
import { ApiServiceDocument } from "@rmp/core/api/types/service/document/apiServiceDocument";
import { EnterprisePropertyController } from "@rmp/enterprise/api/enterpriseProperty";

const abortService = new AbortService();
const page = usePageStore(abortService);

const departmentController = new DepartmentController(abortService);
const portController = new PortController(abortService);
const serviceController = new ServiceController(abortService);
const enterprisePropertyController = new EnterprisePropertyController(abortService);

export interface ServiceDetailsGeneralState extends PageState {
	id: string | null;
	service: FloatingCraneServiceApplication;
	port: ApiPort;
	department: ApiDepartment;
	documents: ServiceDocument[];
	floatingCranes: ApiFloatingCrane[];
}

const getDefaultState = (): ServiceDetailsGeneralState => {
	return {
		...page.getDefaultPageState(),
		id: null,
		service: {} as FloatingCraneServiceApplication,
		department: {} as ApiDepartment,
		port: {} as ApiPort,
		documents: [],
		floatingCranes: []
	};
};

export const useFloatingCraneServiceGeneralStore = defineStore({
	id: "floating-crane-service-details-general",
	state: (): ServiceDetailsGeneralState => getDefaultState(),
	getters: {
		...page.getters,
		breadcrumbs() {
			const { breadcrumbs } = useFloatingCraneServiceStore();
			return breadcrumbs;
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			await this.fetch();
		},
		async fetch() {
			try {
				let { service, title } = useFloatingCraneServiceStore();
				
				const tasks: Promise<any>[] = [
					departmentController.getDepartment(service.divisionId),
					portController.getPort(service.divisionId, service.portId),
					serviceController.getDocuments(service.id)
				];
				
				const floatingCraneTasks: Promise<ApiFloatingCranePersisted>[] = [];
				
				service.assignedFloatingcranes.forEach(x => {
					floatingCraneTasks.push(enterprisePropertyController.getFloatingCrane(x.floatingcraneId));
				});
				
				const [{ division: department }, { division: port }, { documents }] = await Promise.all(
					[...tasks, floatingCraneTasks]);
				
				const floatingCranes = await Promise.all(floatingCraneTasks);			
								
				this.department = department;
				this.port = port;
				this.documents = documents.map((x: ApiServiceDocument) => ServiceDocumentMapper.map(x, title));
				this.service = cloneDeep(service);
				this.floatingCranes = floatingCranes.map(x => x.floatingCrane);
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		}
	}
});
