import { RouteNames } from "@rmp/enterprise/router/routes";
import { useBreadcrumb } from "@rmp/core/stores/composables/breadcrumb/useBreadcrumb";
import { i18n } from "@rmp/enterprise/plugins";
import routeToPermissionsMap from "@rmp/enterprise/router/routeToPermissionsMap";
import { Dictionary } from "vue-router/types/router";

export function usePortsBreadcrumb(params?: Dictionary<string>) {
	return useBreadcrumb({
		route: {
			name: RouteNames.DEPARTMENT_PORTS, params
		},
		text: i18n.t(`navigation.breadcrumbs.${RouteNames.DEPARTMENT_PORTS}`).toString(),
		routeToPermissionsMap
	});
}
