import { difference } from "@rmp/core/utils/difference";
import { Dictionary } from "vue-router/types/router";
import { plainToInstance } from "class-transformer";
import { validateOrReject } from "class-validator";
import RouteQueryService from "@rmp/core/services/route/routeQueryService";
import { parseArrayParameter } from "@rmp/core/utils/query";
import TariffsRouteQuery from "@rmp/enterprise/stores/tariffs/types/tariffsRouteQuery";
import { TariffsState } from "@rmp/enterprise/stores/tariffs";
import { ArchiveStatusType } from "@rmp/core/types/ArchiveStatusType";

export default class TariffsRouteQueryService implements RouteQueryService {
	defaultRouteQuery: TariffsRouteQuery;
	
	constructor(defaultRouteQuery: TariffsRouteQuery) {
		this.defaultRouteQuery = defaultRouteQuery;
	}
	
	private mapStateToQuery(state: TariffsState) {
		return new TariffsRouteQuery(
			state.paging.page,
			state.sorting.type,
			state.sorting.order,
			state.search.query,
			state.filter.serviceTypes,
			state.filter.status,
			state.propertyId
		);
	}
	
	public resolveRouteQueryDictionary(state: TariffsState) {
		let query = this.mapStateToQuery(state);
		
		return difference(this.defaultRouteQuery, query);
	}
	
	public async hasRouteChanges(state: TariffsState, route: Dictionary<any>): Promise<{ [key: string]: any }> {
		let stateQuery = this.mapStateToQuery(state);
		let routeQuery = await this.resolveRouteQuery(route);
		
		return difference(routeQuery, stateQuery);
	}
	
	public async resolveRouteQuery(query: Dictionary<any>) {
		try {
			let result = plainToInstance(TariffsRouteQuery, {
				...this.defaultRouteQuery,
				...query,
				serviceTypes: parseArrayParameter(query.serviceTypes),
				status: query.status || ArchiveStatusType.ALL
			}, { enableImplicitConversion: true });
			
			await validateOrReject(result);
			
			return result;
		} catch (e) {
			console.error(e);
			return this.defaultRouteQuery;
		}
	}
}
