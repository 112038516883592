import { ApiTariff } from "@rmp/core/api/types/service/tariffs/apiTariff";
import { TariffAssignmentTariff } from "@rmp/enterprise/stores/assignment/tariff/steps/tariff/types/tariffAssignmentTariff";
import { i18n } from "@rmp/enterprise/plugins";

export default class TariffAssignmentTariffMapper {
	mapTariff(source: ApiTariff): TariffAssignmentTariff {
		return {
			id: source.id,
			name: source.title,
			price: source.unitPrice,
			measure: source.unit,
			vat: source.vat,
			type: source.type,
			value: "",
			amount: 0,
			vatIncluded: 0,
			loading: false
		};
	}
}
