import { urls } from "@rmp/enterprise/api/config/urls";
import AbortService from "@rmp/core/services/abortService";
import ApiGetBoatApplicationsParameters from "@rmp/core/api/types/counterparty/boatApplication/apiGetBoatApplicationsParameters";
import ApiBoatHistoryEntryPersisted from "@rmp/core/api/types/counterparty/boatApplication/apiBoatHistoryEntryPersisted";
import { ApiChatMessage } from "@rmp/core/api/types/chat/apiChatMessage";
import BaseEnterpriseController from "@rmp/enterprise/api/base/baseEnterpriseController";
import { ApiCounterpartyBoatApplicationPersisted } from "@rmp/core/api/types/counterpartyBoatApplication/apiCounterpartyBoatApplication";

export class BoatApplicationController extends BaseEnterpriseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getBoatApplications = async (parameters: ApiGetBoatApplicationsParameters) => {
		return await this.get<{ counterpartyBoatApplications: ApiCounterpartyBoatApplicationPersisted[], totalCount: number }>(urls.boatApplication,
			parameters);
	};
	
	getHistory = async (boatApplicationId: string) => {
		return await this.get<{ history: ApiBoatHistoryEntryPersisted[] }>(urls.boatApplication.$boatApplicationId.history,
			{ boatApplicationId });
	};
	
	getBoatApplication = async (boatApplicationId: string) => {
		return await this.get<ApiCounterpartyBoatApplicationPersisted>(urls.boatApplication.$boatApplicationId, { boatApplicationId });
	};
	
	getChatMessages = async (boatApplicationId: string) => {
		return await this.get<{ messages: ApiChatMessage[] }>(urls.boatApplication.$boatApplicationId.chat.message, { boatApplicationId });
	};
	
	createChatMessage = async (boatApplicationId: string, message: string): Promise<ApiChatMessage> => {
		return await this.post(urls.boatApplication.$boatApplicationId.chat.message, { boatApplicationId }, { message });
	};
	
	approve = async (boatApplicationId: string) => {
		return await this.post<ApiCounterpartyBoatApplicationPersisted>(urls.boatApplication.$boatApplicationId.approve,
			{ boatApplicationId });
	};
	
	decline = async (boatApplicationId: string, reason: string) => {
		return await this.post<ApiCounterpartyBoatApplicationPersisted>(urls.boatApplication.$boatApplicationId.decline,
			{ boatApplicationId }, { reason });
	};
}
