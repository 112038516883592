import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useCrewboatServiceStore } from "@rmp/enterprise/stores/serviceDetails/crewboat";
import { useCrewboatServiceDocumentsBreadcrumb } from "@rmp/enterprise/stores/serviceDetails/crewboat/documents/composables/useCrewboatServiceDocumentsBreadcrumb";
import { ServiceDocument } from "@rmp/core/types/services/serviceDocument";
import { DocumentsState, useDocumentsStore } from "@rmp/enterprise/stores/composables/baseDocuments";

const abortService = new AbortService();
const page = usePageStore(abortService);

const documentsStore = useDocumentsStore<ServiceDocument>({});

export interface CrewboatServiceDocumentsState extends PageState, DocumentsState<ServiceDocument> {
}

const getDefaultState = (): CrewboatServiceDocumentsState => {
	return {
		...page.getDefaultPageState(),
		...documentsStore.getDefaultState()
	};
};

export const useCrewboatServiceDocumentsStore = defineStore({
	id: "crewboat-service-documents",
	state: (): CrewboatServiceDocumentsState => getDefaultState(),
	getters: {
		...page.getters,
		...documentsStore.getters,
		breadcrumbs() {
			const { breadcrumbs } = useCrewboatServiceStore();
			return [...breadcrumbs, useCrewboatServiceDocumentsBreadcrumb()];
		}
	},
	actions: {
		...page.actions,
		...documentsStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			const { documents } = useCrewboatServiceStore();
			
			this.id = id;
			this.documents = documents;
		}
	}
});
