import AbortService from "@rmp/core/services/abortService";
import { urls } from "@rmp/enterprise/api/config/urls";
import { ApiPortPersisted } from "@rmp/core/api/types/divisions/port/apiPort";
import BaseEnterpriseController from "@rmp/enterprise/api/base/baseEnterpriseController";
import ApiGetDepartmentPortsParameters from "@rmp/core/api/types/divisions/port/apiGetDepartmentPortsParameters";

export class PortController extends BaseEnterpriseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getPorts = async (departmentId: string): Promise<{ totalCount: number; items: ApiPortPersisted[] }> => {
		let items = await this.get<object[]>(urls.enterprise.division.department.$id.port, { id: departmentId });
		
		return {
			totalCount: items.length,
			items: items as ApiPortPersisted[]
		};
	};
	
	getPort = async (departmentId: string, portId: string) => {
		return await this.get<ApiPortPersisted>(urls.enterprise.division.department.$departmentId.port.$portId,
			{ departmentId, portId });
	};
	
	getDepartmentPorts = async (departmentId: string, parameters: ApiGetDepartmentPortsParameters): Promise<{
		totalCount: number;
		items: ApiPortPersisted[]
	}> => {
		let items = await this.get<object[]>(urls.enterprise.division.department.$id.port, { id: departmentId, ...parameters });
		
		return {
			totalCount: items.length,
			items: items as ApiPortPersisted[]
		};
	};
}
