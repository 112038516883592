import { ArchiveStatusType } from "@rmp/core/types/ArchiveStatusType";

export default class FloatingCranesFilter {
	status: ArchiveStatusType;
	
	constructor(
		status: ArchiveStatusType = ArchiveStatusType.ALL
	)
	{
		this.status = status;
	}
}
