import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { EnterprisePropertyController } from "@rmp/enterprise/api/enterpriseProperty";
import router from "@rmp/enterprise/router";
import { Pier, PierMapper } from "@rmp/core/types/enterpriseProperty/pier";
import HttpNotFoundException from "@rmp/core/exceptions/httpNotFoundException";
import AccessForbiddenException from "@rmp/core/exceptions/accessForbiddenException";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import { usePierDetailsBreadcrumb } from "@rmp/enterprise/stores/property/pierDetails/composables/usePierDetailsBreadcrumb";
import { usePiersBreadcrumb } from "@rmp/enterprise/stores/piers/composables/usePiersBreadcrumb";
import { BasePropertyGeneralState, useBasePropertyGeneralStore } from "@rmp/enterprise/stores/composables/basePropertyGeneral";
import { ArchiveStatusState, useArchiveStatusStore } from "@rmp/core/stores/composables/archiveStatus";

const abortService = new AbortService();

const enterprisePropertyController = new EnterprisePropertyController(abortService);

const page = usePageStore(abortService);
const baseBasePropertyGeneralStore = useBasePropertyGeneralStore({ abortService });
const archiveStatusStore = useArchiveStatusStore();

export interface PierDetailsState extends PageState, BasePropertyGeneralState, ArchiveStatusState {
	details: Pier;
}

const getDefaultState = (): PierDetailsState => {
	return {
		...page.getDefaultPageState(),
		...baseBasePropertyGeneralStore.getDefaultState(),
		...archiveStatusStore.getDefaultState(),
		details: {} as Pier
	};
};

export const usePierDetailsPropertyStore = defineStore({
	id: "pier-details",
	state: (): PierDetailsState => getDefaultState(),
	getters: {
		...page.getters,
		...baseBasePropertyGeneralStore.getters,
		...archiveStatusStore.getters,
		breadcrumbs(state: PierDetailsState) {
			return [
				usePiersBreadcrumb(),
				usePierDetailsBreadcrumb(state.details.name)
			];
		}
	},
	actions: {
		...page.actions,
		...baseBasePropertyGeneralStore.actions,
		...archiveStatusStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			const [{ divisionId }] = await Promise.all([
				enterprisePropertyController.getPierDivision(id),
				this.fetchBranches(),
				this.fetch(id)
			]);
			
			await this.initializeBasePropertyGeneralStore(divisionId);
		},
		async fetch(id: string) {
			try {
				const { pier } = await enterprisePropertyController.getPier(id);
				
				this.details = PierMapper.map(pier);
			} catch (ex) {
				const { setPageModeNotFound, setPageModeAccessForbidden } = useAppStore();
				
				switch (ex.constructor) {
					case HttpNotFoundException:
						setPageModeNotFound();
						break;
					case AccessForbiddenException:
						setPageModeAccessForbidden();
						break;
					default:
						AlertHelper.handleGeneralRequestErrors(ex);
						break;
				}
			}
		},
		async updateArchiveStatus() {
			if(!this.details.isArchived) {
				const { pier } = await enterprisePropertyController.archivePier(router.currentRoute.params.id);
				
				this.details = PierMapper.map(pier);
			} else {
				const { pier } = await enterprisePropertyController.unarchivePier(router.currentRoute.params.id);
				
				this.details = PierMapper.map(pier);
			}
			
			alertService.addSuccess(AlertKeys.STATUS_SUCCESS_UPDATED);
		}
	}
});
