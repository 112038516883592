import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { SortingOrderTypeEnum } from "@rmp/core/types/SortingOrderTypeEnum";
import { RegisterState, useRegisterStore } from "@rmp/core/stores/composables/register/useRegisterStore";
import { EnterprisePropertyController } from "@rmp/enterprise/api/enterpriseProperty";
import { ArchiveStatusType } from "@rmp/core/types/ArchiveStatusType";
import { RouteNames } from "@rmp/enterprise/router/routes";
import router from "@rmp/enterprise/router";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import CrewBoatsRouteQuery from "@rmp/enterprise/stores/crewboats/types/crewBoatsRouteQuery";
import { CrewBoatsItem } from "@rmp/enterprise/stores/crewboats/types/crewBoatsItem";
import CrewBoatsRouteQueryService from "@rmp/enterprise/stores/crewboats/services/crewBoatsRouteQueryService";
import CrewBoatsFilter from "@rmp/enterprise/stores/crewboats/types/crewBoatsFilter";
import CrewBoatsMapperProfile from "@rmp/enterprise/stores/crewboats/mapper";
import { ArchiveStatusState, useArchiveStatusStore } from "@rmp/core/stores/composables/archiveStatus";
import NotDefinedException from "@rmp/core/exceptions/notDefinedException";

const abortService = new AbortService();
const enterprisePropertyController = new EnterprisePropertyController(abortService);

const mapper = new CrewBoatsMapperProfile();

export interface CrewBoatsState extends PageState, RegisterState<CrewBoatsItem, CrewBoatsFilter>, ArchiveStatusState
{
	filter: CrewBoatsFilter;
}

const defaultRouteQuery = new CrewBoatsRouteQuery(1,
	"name",
	SortingOrderTypeEnum.ASCENDING,
	"",
	ArchiveStatusType.ALL);

const routeQueryService = new CrewBoatsRouteQueryService(defaultRouteQuery);

const page = usePageStore(abortService);
const archiveStatusStore = useArchiveStatusStore();

const register = useRegisterStore<CrewBoatsItem, CrewBoatsFilter>({
	routeName: RouteNames.CREWBOATS,
	routeQueryService,
	routeQuery: defaultRouteQuery,
	getRouter: () => router
});

const getDefaultState = (): CrewBoatsState => {
	return {
		...page.getDefaultPageState(),
		...register.getDefaultRegisterState(),
		...archiveStatusStore.getDefaultState(),
		filter: new CrewBoatsFilter()
	};
};

export const useCrewBoatsStore = defineStore({
	id: "crewboats",
	state: (): CrewBoatsState => getDefaultState(),
	getters: {
		...page.getters,
		...register.getters,
		...archiveStatusStore.getters,
		filteredItems(state: CrewBoatsState): CrewBoatsItem[] {
			return state.listing.items;
		}
	},
	actions: {
		...page.actions,
		...register.actions,
		...archiveStatusStore.actions,
		async beforeInitialized() {
			await this.initializeRegister();
		},
		patchStateWithRouteQuery(query: CrewBoatsRouteQuery) {
			this.patchRegisterStateWithRouteQuery(query);
			this.$patch((state) => {
				state.filter.status = query.status;
			});
		},
		async fetchRegisterItems() {
			const { crewboats, totalCount } = await enterprisePropertyController.getCrewboats(mapper.mapToApiGetCrewBoatsParameters(this));
			return { items: crewboats.map(x => mapper.mapToCrewBoatsItem(x)), totalCount };
		},
		async updateArchiveStatus(id: string) {
			if(!id)
				throw new NotDefinedException("id");
			
			const isArchived = this.listing.items.find(x => x.id === id)!.isArchived;
			
			if(isArchived)
				await enterprisePropertyController.unarchiveCrewboat(id);
			else
				await enterprisePropertyController.archiveCrewboat(id);
			
			await this.updateListingItems();
			
			alertService.addSuccess(AlertKeys.STATUS_SUCCESS_UPDATED);
		}
	}
});
