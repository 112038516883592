import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { RegisterState, useRegisterStore } from "@rmp/core/stores/composables/register/useRegisterStore";
import { RouteNames } from "@rmp/enterprise/router/routes";
import router from "@rmp/enterprise/router";
import MetricsByPropertyMapperProfile from "@rmp/enterprise/stores/metrics/byProperty/mapper";
import MetricsByPropertyFilter from "@rmp/enterprise/stores/metrics/byProperty/types/metricsByPropertyFilter";
import { MetricsController } from "@rmp/enterprise/api/metrics";
import { MetricsPeriod } from "@rmp/enterprise/stores/metrics/types/metricsPeriod";
import { MetricsPeriodType } from "@rmp/core/types/metrics/metricsPeriodType";
import { getMetricsPeriods } from "@rmp/enterprise/stores/metrics/helpers/getMetricsPeriods";
import { MetricsByPropertyItem, MetricsByPropertyItemMapper } from "@rmp/core/types/metrics/metricsByPropertyItem";
import { saveAs } from "file-saver";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { ApiPropertyType } from "@rmp/core/api/types/enterpriseProperty/apiPropertyType";
import { z } from "zod";
import { AVAILABLE_SORTS } from "@rmp/enterprise/stores/counterpartyDetails/documents/constants";
import { useRouteQueryService } from "@rmp/core/composables/useRouteQueryService";

const abortService = new AbortService();
const metricsController = new MetricsController(abortService);

const mapper = new MetricsByPropertyMapperProfile();

export interface MetricsByPropertyState extends PageState, RegisterState<MetricsByPropertyItem, MetricsByPropertyFilter>
{
	filter: MetricsByPropertyFilter;
	periods: MetricsPeriod[];
	reportDownloading: boolean;
}


const schema = z.object({
	sort: z.nativeEnum(AVAILABLE_SORTS).default(AVAILABLE_SORTS.CREATED_AT),
	period: z.nativeEnum(MetricsPeriodType).default(MetricsPeriodType.CURRENT_MONTH),
	periodStart: z.string().default(""),
	periodEnd: z.string().default(""),
	propertyType: z.nativeEnum(ApiPropertyType).default(ApiPropertyType.FloatingCrane)
});

const { defaultRouteQuery, ...routeQueryService } = useRouteQueryService(schema.shape);


const page = usePageStore(abortService);

const register = useRegisterStore<MetricsByPropertyItem, MetricsByPropertyFilter>({
	routeName: RouteNames.METRICS_BY_PROPERTY,
	routeQueryService,
	routeQuery: defaultRouteQuery,
	getRouter: () => router
});

const getDefaultState = (): MetricsByPropertyState => {
	return {
		...page.getDefaultPageState(),
		...register.getDefaultRegisterState(),
		filter: new MetricsByPropertyFilter(),
		periods: [],
		reportDownloading: false
	};
};

export const useMetricsByPropertyStore = defineStore({
	id: "metrics-by-property",
	state: (): MetricsByPropertyState => getDefaultState(),
	getters: {
		...page.getters,
		...register.getters,
		filteredItems(state: MetricsByPropertyState): MetricsByPropertyItem[] {
			return state.listing.items;
		}
	},
	actions: {
		...page.actions,
		...register.actions,
		async beforeInitialized() {
			this.periods = getMetricsPeriods();
			
			await this.initializeRegister();
		},
		// MetricsByPropertyRouteQuery
		patchStateWithRouteQuery(query: typeof defaultRouteQuery) {
			this.patchRegisterStateWithRouteQuery(query);
			this.$patch((state) => {
				state.filter.period = query.period;
				state.filter.periodStart = query.periodStart;
				state.filter.periodEnd = query.periodEnd;
				state.filter.propertyType = query.propertyType;
			});
		},
		async fetchRegisterItems() {
			const { items } = await metricsController.getMetricsByProperty(mapper.mapToApiGetMetricsByPropertyParameters(this));
			
			return { items: items.map(x => MetricsByPropertyItemMapper.map(x)), totalCount: items.length };
		},
		async downloadReport() {
			this.reportDownloading = true;
			
			try {
				const file = await metricsController.downloadReportByProperty(mapper.mapToApiGetMetricsByPropertyParameters(this));
				
				saveAs(file, "Статистика по объектам имущества");
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.reportDownloading = false;
			}
		}
	}
});
