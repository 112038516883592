import { ServiceTypeEnum } from "@rmp/core/types/services/ServiceTypeEnum";
import { RouteNames } from "@rmp/enterprise/router/routes";

const serviceTypeToRouteMap = new Map([
	[ServiceTypeEnum.DREDGING_WORKS_SERVICE, RouteNames.DREDGING_SERVICE],
	[ServiceTypeEnum.GEODETIC_HYDROGRAPHIC_SERVICE, RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE],
	[ServiceTypeEnum.SNO_INSTALLATION_SERVICE, RouteNames.SNO_INSTALLATION_SERVICE],
	[ServiceTypeEnum.FLOATING_CRANE_SERVICE, RouteNames.FLOATING_CRANE_SERVICE],
	[ServiceTypeEnum.PIER_SERVICE, RouteNames.PIER_SERVICE],
	[ServiceTypeEnum.CREWBOAT_SERVICE, RouteNames.CREWBOAT_SERVICE],
	[ServiceTypeEnum.TUGBOAT_SERVICE, RouteNames.TUGBOAT_SERVICE],
	[ServiceTypeEnum.INFORMATION_SERVICE, RouteNames.INFORMATION_SERVICE]
]);

export const getServiceRoute = (serviceType: ServiceTypeEnum) => {
	return serviceTypeToRouteMap.get(serviceType);
}
