import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { useServicesBreadcrumb } from "@rmp/enterprise/stores/services/composables/useServicesBreadcrumb";
import { DredgingServiceController } from "@rmp/enterprise/api/service/dredging";
import { DredgingServiceApplication, DredgingServiceApplicationMapper } from "@rmp/core/types/services/dredging/dredgingServiceApplication";
import { useDredgingServiceBreadcrumb } from "@rmp/enterprise/stores/serviceDetails/dredging/composables/useDredgingServiceBreadcrumb";
import { DredgingServiceWorkflowStateType } from "@rmp/core/types/services/dredging/DredgingServiceWorkflowStateType";
import {
	useUploadDredgingServiceDocumentsBreadcrumb
} from "@rmp/enterprise/stores/uploadServiceDocuments/dredging/composables/useUploadDredgingServiceDocumentsBreadcrumb";
import { ApiServiceDocumentKindEnum } from "@rmp/core/api/types/service/document/ApiServiceDocumentKindEnum";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import router from "@rmp/enterprise/router";
import { RouteNames } from "@rmp/enterprise/router/routes";
import { ServiceDocumentMapper } from "@rmp/core/types/services/serviceDocument";
import { ServiceController } from "@rmp/enterprise/api/service";
import DredgingServiceApplicationWorkflowService from "@rmp/core/services/serviceApplication/dredgingServiceApplicationWorkflowService";
import { ApiDredgingServiceTransitionCodeEnum } from "@rmp/core/api/types/service/dredging/ApiDredgingServiceTransitionCodeEnum";
import {
	BaseUploadServiceDocumentsState,
	useBaseUploadServiceDocumentsStore
} from "@rmp/enterprise/stores/composables/baseUploadServiceDocuments";
import { formatServiceTitle } from "@rmp/core/utils/formatting";

const abortService = new AbortService();
const page = usePageStore(abortService);
const baseUploadServiceDocumentsStore = useBaseUploadServiceDocumentsStore({ abortService });

const dredgingTransitionService = new DredgingServiceApplicationWorkflowService();

const dredgingServiceController = new DredgingServiceController(abortService);
const serviceController = new ServiceController(abortService);

export interface UploadDredgingServiceDocumentsState extends PageState, BaseUploadServiceDocumentsState {
	service: DredgingServiceApplication;
}

const getDefaultState = (): UploadDredgingServiceDocumentsState => {
	return {
		...page.getDefaultPageState(),
		...baseUploadServiceDocumentsStore.getDefaultState(),
		service: {} as DredgingServiceApplication
	};
};

export const useUploadDredgingServiceDocumentsStore = defineStore({
	id: "upload-dredging-service-documents",
	state: (): UploadDredgingServiceDocumentsState => getDefaultState(),
	getters: {
		...page.getters,
		...baseUploadServiceDocumentsStore.getters,
		breadcrumbs(state: UploadDredgingServiceDocumentsState) {
			return [
				useServicesBreadcrumb(),
				useDredgingServiceBreadcrumb(state.title, { id: state.service.id }),
				useUploadDredgingServiceDocumentsBreadcrumb()
			];
		},
		targetState(state: UploadDredgingServiceDocumentsState) {
			if(!state.service.workflowState) return;
			
			switch (state.service.workflowState.alias) {
				case DredgingServiceWorkflowStateType.SENT:
					return DredgingServiceWorkflowStateType.APPROVED_BY_FLEET_OPERATIONS_SERVICE_EMPLOYEE;
				case DredgingServiceWorkflowStateType.APPROVED_BY_FLEET_OPERATIONS_HEAD_OFFICE_EMPLOYEE:
					return DredgingServiceWorkflowStateType.INITIALLY_SIGNED_BY_ENTERPRISE;
				case DredgingServiceWorkflowStateType.PROVIDED:
					return DredgingServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED;
				case DredgingServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED:
					return DredgingServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE;
				default:
					throw new Error();
			}
		},
		signAvailable(state: UploadDredgingServiceDocumentsState) {
			if(!state.service.workflowState) return;
			
			switch (state.service.workflowState.alias) {
				case DredgingServiceWorkflowStateType.SENT:
				case DredgingServiceWorkflowStateType.PROVIDED:
					return false;
				case DredgingServiceWorkflowStateType.APPROVED_BY_FLEET_OPERATIONS_HEAD_OFFICE_EMPLOYEE:
				case DredgingServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED:
					return true;
				default:
					throw new Error();
			}
		},
		isOnlySignMode(state: UploadDredgingServiceDocumentsState) {
			return [
				DredgingServiceWorkflowStateType.APPROVED_BY_FLEET_OPERATIONS_HEAD_OFFICE_EMPLOYEE,
				DredgingServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED
			].includes(state.service.workflowState?.alias);
		},
		isComplete(state: UploadDredgingServiceDocumentsState) {
			return state.newDocuments.every(x => x.meta);
		}
	},
	actions: {
		...page.actions,
		...baseUploadServiceDocumentsStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			await this.fetch(id);
			
			this.initializeDocuments();
			
			await this.initializeBaseUploadServiceDocumentsStore(this.service.type);
		},
		initializeDocuments() {
			switch (this.service.workflowState.alias) {
				case DredgingServiceWorkflowStateType.SENT:
					[ApiServiceDocumentKindEnum.AGREEMENT].forEach(kind => this.newDocuments.push(this.createDocument(
						kind)));
					break;
				case DredgingServiceWorkflowStateType.APPROVED_BY_FLEET_OPERATIONS_HEAD_OFFICE_EMPLOYEE:
					break;
				case DredgingServiceWorkflowStateType.PROVIDED:
					[
						ApiServiceDocumentKindEnum.SERVICE_ACCEPTANCE_CERTIFICATE, ApiServiceDocumentKindEnum.INVOICE
					].forEach(kind => this.newDocuments.push(this.createDocument(kind)));
					break;
				case DredgingServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED:
					break;
				default:
					throw new Error();
			}
		},
		async fetch(serviceId: string) {
			try {
				let { serviceApplication } = await dredgingServiceController.getService(serviceId);
				
				this.service = DredgingServiceApplicationMapper.map(serviceApplication);
				this.title = formatServiceTitle(this.service.type, this.service.applicationNumber);
				
				if(this.isOnlySignMode) {
					const { documents } = await serviceController.getDocuments(serviceId);
					
					this.documents = documents.map(x => ServiceDocumentMapper.map(x, this.title));
				}
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		},
		async approveSignature() {
			this.approving = true;

			try {
				await dredgingServiceController.updateStatusByTransition(this.id!,
					dredgingTransitionService.getTransition(this.service.workflowState.alias,
						this.targetState!) as ApiDredgingServiceTransitionCodeEnum);

				this.service.workflowState.alias = this.targetState!;

				alertService.addSuccess(AlertKeys.DOCUMENTS_SENT);

				await router.push({ name: RouteNames.DREDGING_SERVICE, params: { id: this.id } });
			} catch (ex) {
				console.error(ex);

				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);

				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.approving = false;
			}
		}
	}
});
