import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { ServiceController } from "@rmp/enterprise/api/service";
import { FeedbackState, useFeedbackStore } from "@rmp/core/stores/composables/feedback";
import {
	useFloatingCraneServiceFeedbackBreadcrumb
} from "@rmp/enterprise/stores/serviceDetails/floatingCrane/feedback/composables/useFloatingCraneServiceFeedbackBreadcrumb";
import { useFloatingCraneServiceStore } from "@rmp/enterprise/stores/serviceDetails/floatingCrane";

const abortService = new AbortService();
const page = usePageStore(abortService);

const serviceController = new ServiceController(abortService);
const feedbackStore = useFeedbackStore({ controller: serviceController });

export interface FloatingCraneServiceFeedbackState extends PageState, FeedbackState {
}

const getDefaultState = (): FloatingCraneServiceFeedbackState => {
	return {
		...page.getDefaultPageState(),
		...feedbackStore.getDefaultState()
	};
};

export const useFloatingCraneServiceFeedbackStore = defineStore({
	id: "floating-crane-service-feedback",
	state: (): FloatingCraneServiceFeedbackState => getDefaultState(),
	getters: {
		...page.getters,
		...feedbackStore.getters,
		breadcrumbs() {
			const { breadcrumbs } = useFloatingCraneServiceStore();
			return [...breadcrumbs, useFloatingCraneServiceFeedbackBreadcrumb()];
		}
	},
	actions: {
		...page.actions,
		...feedbackStore.actions,
		async beforeInitialized(this: any, { id }: { id: string }) {
			this.id = id;
			await this.initializeFeedbackStore();
		}
	}
});
