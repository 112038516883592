import { defineStore } from "pinia";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { DepartmentController } from "@rmp/enterprise/api/divisions/department";
import { ApiDepartmentPersisted } from "@rmp/core/api/types/divisions/department/apiDepartment";
import { DivisionKindEnum } from "@rmp/core/api/types/divisions/DivisionKindEnum";

const abortService = new AbortService();
const departmentController = new DepartmentController(abortService);

export interface OwnEmployeeState {
	initialized: boolean;
	department: ApiDepartmentPersisted;
	kind: DivisionKindEnum | null;
}

const getDefaultState = (): OwnEmployeeState => {
	return {
		initialized: false,
		department: {} as ApiDepartmentPersisted,
		kind: null
	};
};

export const useOwnEmployeeStore = defineStore({
	id: "own-employee",
	state: (): OwnEmployeeState => getDefaultState(),
	getters: {
		belongsToDepartment(state: OwnEmployeeState) {
			return state.kind === DivisionKindEnum.DEPARTMENT
		}
	},
	actions: {
		async fetch() {
			try {
				const { kind } = await departmentController.getOwnDivisionKind();
				this.kind = kind;
				
				if(this.kind !== DivisionKindEnum.HEAD) {
					this.department = await departmentController.getOwnDepartment();
				}
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		},
		async initialize() {
			abortService.initialize();
			
			await this.fetch();
			
			this.initialized = true;
		}
	}
});
