import AbortService from "@rmp/core/services/abortService";
import BaseEnterpriseController from "@rmp/enterprise/api/base/baseEnterpriseController";
import { ApiTugboatServiceApplication } from "@rmp/core/api/types/service/tugboat/apiTugboatServiceApplication";
import { urls } from "@rmp/enterprise/api/config/urls";
import { ApiTugboatPersisted } from "@rmp/core/api/types/enterpriseProperty/apiTugboat";
import { ApiCreateServiceDocument } from "@rmp/core/api/types/service/document/apiCreateServiceDocument";
import { ApiTariff } from "@rmp/core/api/types/service/tariffs/apiTariff";
import { ApiGetTariffTotalAmountRequest } from "@rmp/core/api/types/service/apiGetTariffTotalAmountRequest";
import { ApiTariffTotalAmount } from "@rmp/core/api/types/service/apiTariffTotalAmount";
import { ApiRequestedTugboat } from "@rmp/core/api/types/service/tugboat/apiRequestedTugboat";
import { ServiceWorkflowStateType } from "@rmp/core/types/services/ServiceWorkflowStateType";
import { ApiPropertyServiceTransitionCodeEnum } from "@rmp/core/api/types/service/ApiPropertyServiceTransitionCodeEnum";
import { ApiBoatProvisionData } from "@rmp/core/api/types/service/apiBoatProvisionData";
import { ApiPierServiceApplication } from "@rmp/core/api/types/service/pier/apiPierServiceApplication";

export class TugboatServiceController extends BaseEnterpriseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getService = async (tugboatServiceId: string) => {
		return await this.get<{
			serviceApplication: ApiTugboatServiceApplication
		}>(urls.service.tugboat.$tugboatServiceId,
			{ tugboatServiceId });
	};
	
	assignTugboat = async (tugboatServiceId: string, payload: string[]) => {
		return await this.put(urls.service.tugboat.$tugboatServiceId.tugboats, { tugboatServiceId }, payload);
	};
	
	getServiceTugboats = async (departmentId: string) => {
		return await this.get<ApiTugboatPersisted[]>(urls.service.tugboat.department.$departmentId.tugboat, { departmentId });
	};
	
	getTariffs = async (departmentId: string, tugboatId: string) => {
		return await this.get<ApiTariff[]>(urls.service.tugboat.department.$departmentId.tugboat.$tugboat.tariff,
			{ departmentId, tugboatId });
	};
	
	saveDocuments = async (tugboatServiceId: string, documents: ApiCreateServiceDocument[]) => {
		return await this.put<void>(urls.service.tugboat.$tugboatServiceId.document, { tugboatServiceId }, documents);
	};
	
	calculateTariffCost = async (departmentId: string,
		tugboatId: string,
		tariffId: string,
		payload: ApiGetTariffTotalAmountRequest) => {
		const [data] = await this.post<ApiTariffTotalAmount[]>(urls.service.tugboat.department.$departmentId.tugboat.$tugboat.tariff.$tariffId.calculate,
			{ departmentId, tugboatId, tariffId }, payload);
		return data;
	};
	
	assignTariffs = async (tugboatServiceId: string, payload: ApiRequestedTugboat[]) => {
		await this.put(urls.service.tugboat.$tugboatServiceId.tariff, { tugboatServiceId }, payload);
	};
	
	updateStatusByTransition = async (tugboatServiceId: string, code: ApiPropertyServiceTransitionCodeEnum, reason?: string) => {
		return await this.post<void>(urls.service.tugboat.$tugboatServiceId.$code,
			{ tugboatServiceId, code },
			{ rejectionTransition: { reason } });
	};
	
	updateProvisionData = async (tugboatServiceId: string, payload: ApiBoatProvisionData) => {
		return await this.put<{ serviceApplication: ApiPierServiceApplication }>(urls.service.tugboat.$tugboatServiceId.provisionData,
			{ tugboatServiceId },
			payload);
	};
}
