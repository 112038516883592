import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { ChatState, useChatStore } from "@rmp/core/stores/composables/chat";
import { AgreementController } from "@rmp/enterprise/api/agreement";
import { useAgreementDetailsChatBreadcrumb } from "@rmp/enterprise/stores/agreementDetails/chat/composables/useAgreementDetailsChatBreadcrumb";
import { useAgreementDetailsStore } from "@rmp/enterprise/stores/agreementDetails";

const abortService = new AbortService();
const page = usePageStore(abortService);

const agreementController = new AgreementController(abortService);
const chatStore = useChatStore({ controller: agreementController });

export interface AgreementDetailsChatState extends PageState, ChatState {
	id: string | null;
}

const getDefaultState = (): AgreementDetailsChatState => {
	return {
		...page.getDefaultPageState(),
		...chatStore.getDefaultState(),
		id: null
	};
};

export const useAgreementDetailsChatStore = defineStore({
	id: "agreement-details-chat",
	state: (): AgreementDetailsChatState => getDefaultState(),
	getters: {
		...page.getters,
		...chatStore.getters,
		breadcrumbs() {
			// @ts-ignore
			const { breadcrumbs } = useAgreementDetailsStore();
			return [...breadcrumbs, useAgreementDetailsChatBreadcrumb()];
		}
	},
	actions: {
		...page.actions,
		...chatStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			await this.initializeChatStore(id);
		}
	}
});
