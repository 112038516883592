import { RouteNames } from "@rmp/enterprise/router/routes";
import { useBreadcrumb } from "@rmp/core/stores/composables/breadcrumb/useBreadcrumb";
import { i18n } from "@rmp/enterprise/plugins";
import routeToPermissionsMap from "@rmp/enterprise/router/routeToPermissionsMap";

export function useInformationServiceFeedbackBreadcrumb() {
	return useBreadcrumb({
		route: {
			name: RouteNames.INFORMATION_SERVICE_FEEDBACK
		},
		text: i18n.t(`navigation.breadcrumbs.${RouteNames.INFORMATION_SERVICE_FEEDBACK}`).toString(),
		routeToPermissionsMap
	});
}
