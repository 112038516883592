import ApiGetTugBoatsParameters from "@rmp/core/api/types/enterpriseProperty/apiGetTugBoatsParameters";
import { TugBoatsState } from "@rmp/enterprise/stores/tugboats/index";
import { ApiTugboatPersisted, ApiTugboatsItem } from "@rmp/core/api/types/enterpriseProperty/apiTugboat";
import { TugBoatsItem } from "@rmp/enterprise/stores/tugboats/types/tugBoatsItem";
import { convertToTimestamp } from "@rmp/core/utils/dates";
import { ArchiveStatusType } from "@rmp/core/types/ArchiveStatusType";

export default class TugBoatsMapperProfile {
	constructor() {
	}
	
	mapToApiGetTugBoatsParameters(source: TugBoatsState): ApiGetTugBoatsParameters {
		return {
			take: source.paging.pageSize,
			skip: source.paging.pageSize * (source.paging.page - 1),
			query: source.search.query,
			sortField: source.sorting.type,
			sortingDirection: source.sorting.order,
			isArchived: ArchiveStatusType.ALL === source.filter.status ? null : source.filter.status === ArchiveStatusType.ARCHIVED
		};
	}
	
	mapToTugBoatsItem(source: ApiTugboatsItem): TugBoatsItem {
		return {
			...source.tugboatPersisted.tugboat,
			id: source.tugboatPersisted.id,
			createdAt: convertToTimestamp(source.tugboatPersisted.createdAt) as number,
			department: source.divisionPersisted.division.shortName
		};
	}
}
