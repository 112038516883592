import { defineStore } from "pinia";
import uniq from "lodash/uniq";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { useServicesBreadcrumb } from "@rmp/enterprise/stores/services/composables/useServicesBreadcrumb";
import { useTugboatServiceBreadcrumb } from "@rmp/enterprise/stores/serviceDetails/tugboat/composables/useTugboatServiceBreadcrumb";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import { i18n } from "@rmp/core/plugins";
import ServiceApplicationTugboatMapper, { ServiceApplicationTugboat } from "@rmp/core/types/services/serviceApplicationTugboat";
import { useTugboatAssignmentBreadcrumb } from "@rmp/enterprise/stores/assignment/tugboat/composables/useTugboatAssignmentBreadcrumb";
import { SnapshotKeysEnum, SnapshotState, useSnapshotStore } from "@rmp/core/stores/composables/snapshot";
import { TugboatServiceController } from "@rmp/enterprise/api/service/tugboat";
import { TugboatServiceApplication, TugboatServiceApplicationMapper } from "@rmp/core/types/services/tugboat/tugboatServiceApplication";
import router from "@rmp/enterprise/router";
import { RouteNames } from "@rmp/enterprise/router/routes";
import { formatServiceTitle } from "@rmp/core/utils/formatting";

const abortService = new AbortService();
const page = usePageStore(abortService);
const snapshotStore = useSnapshotStore([
	{
		key: SnapshotKeysEnum.LAST_SAVED, fields: ["selectedTugboatIds"]
	}
]);

const tugboatServiceController = new TugboatServiceController(abortService);

export interface TugboatAssignmentState extends PageState, SnapshotState {
	selectedTugboatIds: string[];
	tugboats: ServiceApplicationTugboat[];
	service: TugboatServiceApplication;
	id: string;
	isSaving: boolean;
}

const getDefaultState = (): TugboatAssignmentState => {
	return {
		...page.getDefaultPageState(),
		...snapshotStore.getDefaultState(),
		selectedTugboatIds: [],
		tugboats: [],
		service: {} as TugboatServiceApplication,
		id: "",
		isSaving: false
	};
};

export const useTugboatAssignmentStore = defineStore({
	id: "tugboat-assignment",
	state: (): TugboatAssignmentState => getDefaultState(),
	getters: {
		...page.getters,
		...snapshotStore.getters,
		breadcrumbs(state: TugboatAssignmentState) {
			return [
				useServicesBreadcrumb(),
				useTugboatServiceBreadcrumb(formatServiceTitle(state.service.type, state.service.applicationNumber), { id: state.id }),
				useTugboatAssignmentBreadcrumb()
			];
		}
	},
	actions: {
		...page.actions,
		...snapshotStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			
			await this.fetch();
			
			if(this.service.requestedTugBoats.length) {
				this.setStateSnapshot();
				
				let requestedTugboatsIds = this.service.requestedTugBoats.map(x => x.tugboatId);
				
				this.selectedTugboatIds = uniq(requestedTugboatsIds);
			} else {
				this.selectedTugboatIds = this.service.assignedTugBoats.map(x => x.tugboatId);
				
				this.setStateSnapshot();
			}
		},
		async fetch() {
			try {
				let { serviceApplication } = await tugboatServiceController.getService(this.id!);
				
				let tugBoats = await tugboatServiceController.getServiceTugboats(serviceApplication.divisionId);
				
				this.service = TugboatServiceApplicationMapper.map(serviceApplication);
				this.tugboats = tugBoats.map(x => ServiceApplicationTugboatMapper.map(x));
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		},
		async assign() {
			this.isSaving = true;
			
			try {
				await tugboatServiceController.assignTugboat(this.id!, this.selectedTugboatIds);
				
				alertService.addSuccess(AlertKeys.TUGBOATS_ASSIGNED);
				
				await router.push({ name: RouteNames.TUGBOAT_SERVICE, params: router.currentRoute.params });
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.isSaving = false;
			}
		}
	}
});
