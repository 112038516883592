import { ApiPersistedBase } from "@rmp/core/api/types/base/apiPersistedBase";
import { convertToTimestamp } from "@rmp/core/utils/dates";

export interface PersistedBase {
	createdAt: number;
	updatedAt: number;
	id: string;
}

export class PersistedBaseMapper {
	static map(source: ApiPersistedBase): PersistedBase {
		return {
			id: source.id,
			createdAt: convertToTimestamp(source.createdAt) as number,
			updatedAt: convertToTimestamp(source.createdAt) as number
		};
	}
}
