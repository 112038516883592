import { IsString, IsInt, Min, IsIn, Max, Length, IsEnum } from "class-validator";
import { SortingOrderTypeEnum } from "@rmp/core/types/SortingOrderTypeEnum";
import { BranchesFilterStatusEnum } from "@rmp/enterprise/stores/divisions/branches/types/BranchesFilterStatusEnum";
import RegisterRouteQuery from "@rmp/core/stores/composables/register/types/registerRouteQuery";

export const AVAILABLE_SORTS = ["fullName", "shortName", "type", "isArchived"];

export default class BranchesRouteQuery implements RegisterRouteQuery {
	@IsString()
	@IsIn(AVAILABLE_SORTS)
	sort: string;
	
	@IsString()
	@IsIn([SortingOrderTypeEnum.DESCENDING, SortingOrderTypeEnum.ASCENDING])
	sortDirection: string;
	
	@IsString()
	@Length(0, 100)
	query: string;
	
	@IsString()
	@IsEnum(BranchesFilterStatusEnum)
	status: BranchesFilterStatusEnum;
	
	@IsInt()
	@Min(0)
	@Max(1000)
	page: number = 1;
	
	constructor(sort: string, sortDirection: string, query: string, status: BranchesFilterStatusEnum) {
		this.sort = sort;
		this.sortDirection = sortDirection;
		this.query = query;
		this.status = status;
	}
}
