import {
	endOfMonth,
	endOfQuarter,
	endOfYear,
	startOfMonth,
	startOfQuarter,
	startOfYear,
	subMonths,
	subQuarters,
	subYears,
	endOfDay
} from "date-fns";
import { MetricsPeriodType } from "@rmp/core/types/metrics/metricsPeriodType";
import { MetricsPeriod } from "@rmp/enterprise/stores/metrics/types/metricsPeriod";
import { i18n } from "@rmp/enterprise/plugins";

export const getMetricsPeriods = (): MetricsPeriod[] => {
	const pastMonthDate = subMonths(new Date(), 1);
	const pastQuarterDate = subQuarters(new Date(), 1);
	const pastYearDate = subYears(new Date(), 1);
	
	return [
		{
			text: i18n.tc(`metrics.periods.${MetricsPeriodType.CURRENT_MONTH}`),
			value: MetricsPeriodType.CURRENT_MONTH,
			start: startOfMonth(new Date()).toISOString(),
			end: endOfDay(new Date()).toISOString()
		},
		{
			text: i18n.tc(`metrics.periods.${MetricsPeriodType.PAST_MONTH}`),
			value: MetricsPeriodType.PAST_MONTH,
			start: startOfMonth(pastMonthDate).toISOString(),
			end: endOfMonth(pastMonthDate).toISOString()
		},
		{
			text: i18n.tc(`metrics.periods.${MetricsPeriodType.CURRENT_QUARTER}`),
			value: MetricsPeriodType.CURRENT_QUARTER,
			start: startOfQuarter(new Date()).toISOString(),
			end: endOfDay(new Date()).toISOString()
		},
		{
			text: i18n.tc(`metrics.periods.${MetricsPeriodType.PAST_QUARTER}`),
			value: MetricsPeriodType.PAST_QUARTER,
			start: startOfQuarter(pastQuarterDate).toISOString(),
			end: endOfQuarter(pastQuarterDate).toISOString()
		},
		{
			text: i18n.tc(`metrics.periods.${MetricsPeriodType.CURRENT_YEAR}`),
			value: MetricsPeriodType.CURRENT_YEAR,
			start: startOfYear(new Date()).toISOString(),
			end: endOfDay(new Date()).toISOString()
		},
		{
			text: i18n.tc(`metrics.periods.${MetricsPeriodType.PAST_YEAR}`),
			value: MetricsPeriodType.PAST_YEAR,
			start: startOfYear(pastYearDate).toISOString(),
			end: endOfYear(pastYearDate).toISOString()
		},
		{
			text: i18n.tc(`metrics.periods.${MetricsPeriodType.PERIOD}`),
			value: MetricsPeriodType.PERIOD,
			start: "",
			end: ""
		}
	];
};
