import AbortService from "@rmp/core/services/abortService";
import { AgreementController } from "@rmp/enterprise/api/agreement";
import ServicesMapperProfile from "@rmp/enterprise/stores/services/mapper";
import { SortingOrderTypeEnum } from "@rmp/core/types/SortingOrderTypeEnum";
import { ServicesFilterStatusEnum } from "@rmp/core/types/enums/ServicesFilterStatusEnum";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import { RegisterState, useRegisterStore } from "@rmp/core/stores/composables/register/useRegisterStore";
import { ServicesItem } from "@rmp/enterprise/stores/services/types/servicesItem";
import { RouteNames } from "@rmp/enterprise/router/routes";
import router from "@rmp/enterprise/router";
import { defineStore } from "pinia";
import { useAgreementDetailsStore } from "@rmp/enterprise/stores/agreementDetails";
import { useAgreementDetailsServicesBreadcrumb } from "@rmp/enterprise/stores/agreementDetails/services/composables/useAgreementDetailsServicesBreadcrumb";
import AgreementServicesRouteQueryService
	from "@rmp/enterprise/stores/agreementDetails/services/services/agreementServicesRouteQueryService";
import AgreementServicesRouteQuery from "@rmp/enterprise/stores/agreementDetails/services/types/agreementServicesRouteQuery";
import { AgreementServicesFilter } from "@rmp/enterprise/stores/agreementDetails/services/types/agreementServicesFilter";

const abortService = new AbortService();
const agreementController = new AgreementController(abortService);

const mapper = new ServicesMapperProfile();

const defaultRouteQuery = new AgreementServicesRouteQuery(
	1,
	"number",
	SortingOrderTypeEnum.DESCENDING,
	"",
	[],
	[],
	ServicesFilterStatusEnum.ALL);

const routeQueryService = new AgreementServicesRouteQueryService(defaultRouteQuery);

const page = usePageStore(abortService);

export interface AgreementDetailsServicesState extends PageState, RegisterState<ServicesItem, AgreementServicesFilter>
{
	filter: AgreementServicesFilter;
	id: string;
}

const register = useRegisterStore<ServicesItem, AgreementServicesFilter>({
	routeName: RouteNames.AGREEMENT_DETAILS_SERVICES,
	routeQueryService,
	routeQuery: defaultRouteQuery,
	getRouter: () => router
});

const getDefaultState = (): AgreementDetailsServicesState => {
	return {
		...page.getDefaultPageState(),
		...register.getDefaultRegisterState(),
		id: "",
		filter: {
			statuses: [],
			serviceTypes: [],
			status: ServicesFilterStatusEnum.ALL
		}
	};
};

export const useAgreementDetailsServicesStore = defineStore({
	id: "agreement-details-services",
	state: (): AgreementDetailsServicesState => getDefaultState(),
	getters: {
		...register.getters,
		...page.getters,
		filteredItems(state: AgreementDetailsServicesState) {
			return state.listing.items;
		},
		breadcrumbs() {
			const { breadcrumbs } = useAgreementDetailsStore();
			return [...breadcrumbs, useAgreementDetailsServicesBreadcrumb()];
		}
	},
	actions: {
		...page.actions,
		...register.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			await this.initializeRegister();
		},
		patchStateWithRouteQuery(query: AgreementServicesRouteQuery) {
			this.patchRegisterStateWithRouteQuery(query);
			this.$patch((state) => {
				state.filter.status = query.status;
			});
		},
		async fetchRegisterItems() {
			let { items, totalCount } = await agreementController.getServices(this.id, mapper.mapToApiGetServicesParameters(this));
			
			return {
				items: items.map(x => mapper.mapToServicesItem(x)),
				totalCount
			};
		}
	}
});
