import { difference } from "@rmp/core/utils/difference";
import { Dictionary } from "vue-router/types/router";
import { plainToInstance } from "class-transformer";
import { validateOrReject } from "class-validator";
import RouteQueryService from "@rmp/core/services/route/routeQueryService";
import { parseArrayParameter } from "@rmp/core/utils/query";
import { ServicesFilterStatusEnum } from "@rmp/core/types/enums/ServicesFilterStatusEnum";
import AgreementServicesRouteQuery from "@rmp/enterprise/stores/agreementDetails/services/types/agreementServicesRouteQuery";
import { AgreementDetailsServicesState } from "@rmp/enterprise/stores/agreementDetails/services";

export default class AgreementServicesRouteQueryService implements RouteQueryService {
	defaultRouteQuery: AgreementServicesRouteQuery;
	
	constructor(defaultRouteQuery: AgreementServicesRouteQuery) {
		this.defaultRouteQuery = defaultRouteQuery;
	}
	
	private mapStateToQuery(state: AgreementDetailsServicesState) {
		return new AgreementServicesRouteQuery(
			state.paging.page,
			state.sorting.type,
			state.sorting.order,
			state.search.query,
			state.filter.statuses,
			state.filter.serviceTypes,
			state.filter.status
		);
	}
	
	public resolveRouteQueryDictionary(state: AgreementDetailsServicesState) {
		let query = this.mapStateToQuery(state);
		
		return difference(this.defaultRouteQuery, query);
	}
	
	public async hasRouteChanges(state: AgreementDetailsServicesState, route: Dictionary<any>): Promise<{ [key: string]: any }> {
		let stateQuery = this.mapStateToQuery(state);
		let routeQuery = await this.resolveRouteQuery(route);
		
		return difference(routeQuery, stateQuery);
	}
	
	public async resolveRouteQuery(query: Dictionary<any>) {
		try {
			let result = plainToInstance(AgreementServicesRouteQuery, {
				...this.defaultRouteQuery,
				...query,
				statuses: parseArrayParameter(query.statuses),
				serviceTypes: parseArrayParameter(query.serviceTypes),
				status: query.status || ServicesFilterStatusEnum.ALL
			}, { enableImplicitConversion: true });
			
			await validateOrReject(result);
			
			return result;
		} catch (e) {
			console.error(e);
			return this.defaultRouteQuery;
		}
	}
}
