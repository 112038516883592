import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { EnterprisePropertyController } from "@rmp/enterprise/api/enterpriseProperty";
import { Crewboat } from "@rmp/core/types/enterpriseProperty/crewboat";
import { useCrewboatDetailsStore } from "@rmp/enterprise/stores/property/crewboatDetails";
import { cloneDeep } from "lodash";
import { BasePropertyGeneralState, useBasePropertyGeneralStore } from "@rmp/enterprise/stores/composables/basePropertyGeneral";

const abortService = new AbortService();

const enterprisePropertyController = new EnterprisePropertyController(abortService);

const page = usePageStore(abortService);
const baseBasePropertyGeneralStore = useBasePropertyGeneralStore({ abortService });

export interface CrewboatDetailsGeneralState extends PageState, BasePropertyGeneralState {
	details: Crewboat;
}

const getDefaultState = (): CrewboatDetailsGeneralState => {
	return {
		...page.getDefaultPageState(),
		...baseBasePropertyGeneralStore.getDefaultState(),
		details: {} as Crewboat
	};
};

export const useCrewboatDetailsGeneralStore = defineStore({
	id: "crewboat-details-general",
	state: (): CrewboatDetailsGeneralState => getDefaultState(),
	getters: {
		...page.getters,
		...baseBasePropertyGeneralStore.getters,
		breadcrumbs() {
			const { breadcrumbs } = useCrewboatDetailsStore();
			return breadcrumbs;
		}
	},
	actions: {
		...page.actions,
		...baseBasePropertyGeneralStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			let { details } = useCrewboatDetailsStore();
			
			this.details = cloneDeep(details);
			
			const [{ divisionId }] = await Promise.all([
				enterprisePropertyController.getCrewboatDivision(id),
				this.fetchBranches()
			]);
			
			await this.initializeBasePropertyGeneralStore(divisionId);
		}
	}
});
