import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { ServiceController } from "@rmp/enterprise/api/service";
import { FeedbackState, useFeedbackStore } from "@rmp/core/stores/composables/feedback";
import { useGeodeticHydrographicServiceStore } from "@rmp/enterprise/stores/serviceDetails/geodeticHydrographic";
import { useGeodeticHydrographicServiceFeedbackBreadcrumb } from "@rmp/enterprise/stores/serviceDetails/geodeticHydrographic/feedback/composables/useGeodeticHydrographicServiceFeedbackBreadcrumb";

const abortService = new AbortService();
const page = usePageStore(abortService);

const serviceController = new ServiceController(abortService);
const feedbackStore = useFeedbackStore({ controller: serviceController });

export interface GeodeticHydrographicServiceFeedbackState extends PageState, FeedbackState {
}

const getDefaultState = (): GeodeticHydrographicServiceFeedbackState => {
	return {
		...page.getDefaultPageState(),
		...feedbackStore.getDefaultState()
	};
};

export const useGeodeticHydrographicServiceFeedbackStore = defineStore({
	id: "geodetic-hydrographic-service-feedback",
	state: (): GeodeticHydrographicServiceFeedbackState => getDefaultState(),
	getters: {
		...page.getters,
		...feedbackStore.getters,
		breadcrumbs() {
			const { breadcrumbs } = useGeodeticHydrographicServiceStore();
			return [...breadcrumbs, useGeodeticHydrographicServiceFeedbackBreadcrumb()];
		}
	},
	actions: {
		...page.actions,
		...feedbackStore.actions,
		async beforeInitialized(this: any, { id }: { id: string }) {
			this.id = id;
			await this.initializeFeedbackStore();
		}
	}
});
