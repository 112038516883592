import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { EnterprisePropertyController } from "@rmp/enterprise/api/enterpriseProperty";
import router from "@rmp/enterprise/router";
import { Tugboat, TugboatMapper } from "@rmp/core/types/enterpriseProperty/tugboat";
import HttpNotFoundException from "@rmp/core/exceptions/httpNotFoundException";
import AccessForbiddenException from "@rmp/core/exceptions/accessForbiddenException";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import { useTugboatDetailsBreadcrumb } from "@rmp/enterprise/stores/property/tugboatDetails/composables/useTugboatDetailsBreadcrumb";
import { useTugboatsBreadcrumb } from "@rmp/enterprise/stores/tugboats/composables/useTugboatsBreadcrumb";
import { ArchiveStatusState, useArchiveStatusStore } from "@rmp/core/stores/composables/archiveStatus";

const abortService = new AbortService();

const enterprisePropertyController = new EnterprisePropertyController(abortService);

const page = usePageStore(abortService);
const archiveStatusStore = useArchiveStatusStore();

export interface TugboatDetailsState extends PageState, ArchiveStatusState {
	details: Tugboat;
}

const getDefaultState = (): TugboatDetailsState => {
	return {
		...page.getDefaultPageState(),
		...archiveStatusStore.getDefaultState(),
		details: {} as Tugboat
	};
};

export const useTugboatDetailsStore = defineStore({
	id: "tugboat-details",
	state: (): TugboatDetailsState => getDefaultState(),
	getters: {
		...page.getters,
		...archiveStatusStore.getters,
		breadcrumbs(state: TugboatDetailsState) {
			return [
				useTugboatsBreadcrumb(),
				useTugboatDetailsBreadcrumb(state.details.name)
			];
		}
	},
	actions: {
		...page.actions,
		...archiveStatusStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			await this.fetch(id);
		},
		async fetch(id: string) {
			try {
				const tugboat = await enterprisePropertyController.getTugboat(id);
				
				this.details = TugboatMapper.map(tugboat);
			} catch (ex) {
				const { setPageModeNotFound, setPageModeAccessForbidden } = useAppStore();
				
				switch (ex.constructor) {
					case HttpNotFoundException:
						setPageModeNotFound();
						break;
					case AccessForbiddenException:
						setPageModeAccessForbidden();
						break;
					default:
						AlertHelper.handleGeneralRequestErrors(ex);
						break;
				}
			}
		},
		async updateArchiveStatus() {
			if(!this.details.isArchived) {
				const tugboat = await enterprisePropertyController.archiveTugboat(router.currentRoute.params.id);
				
				this.details = TugboatMapper.map(tugboat);
			} else {
				const tugboat = await enterprisePropertyController.unarchiveTugboat(router.currentRoute.params.id);
				
				this.details = TugboatMapper.map(tugboat);
			}
			
			alertService.addSuccess(AlertKeys.STATUS_SUCCESS_UPDATED);
		}
	}
});
