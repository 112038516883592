import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { SortingOrderTypeEnum } from "@rmp/core/types/SortingOrderTypeEnum";
import { RouteNames } from "@rmp/enterprise/router/routes";
import router from "@rmp/enterprise/router";
import CounterpartiesMapperProfile from "@rmp/enterprise/stores/counterparties/mapper";
import ApiCounterpartyPersistedBase from "@rmp/core/api/types/counterparty/apiCounterpartyPersistedBase";
import CounterpartiesFilter from "@rmp/enterprise/stores/counterparties/types/counterpartiesFilter";
import CounterpartiesRouteQuery from "@rmp/enterprise/stores/counterparties/types/counterpartiesRouteQuery";
import CounterpartiesRouteQueryService from "@rmp/enterprise/stores/counterparties/services/counterpartiesRouteQueryService";
import { get } from "local-storage";
import { LAST_VISITED_COUNTERPARTIES } from "@rmp/core/constants/localStorage";
import SearchSuggestion from "@rmp/core/services/searchSuggestion/searchSuggestion";
import { convertIsoToNumber, formatDate } from "@rmp/core/utils/dates";
import { dateTimeFormat } from "@rmp/core/utils/formats";
import { i18n } from "@rmp/core/plugins";
import ApiLegalEntityCounterparty from "@rmp/core/api/types/counterparty/apiLegalEntityCounterparty";
import ApiLegalPersonCounterparty from "@rmp/core/api/types/counterparty/apiLegalPersonCounterparty";
import ApiForeignOrganizationCounterparty from "@rmp/core/api/types/counterparty/apiForeignOrganizationCounterparty";
import ApiPhysicalPersonCounterparty from "@rmp/core/api/types/counterparty/apiPhysicalPersonCounterparty";
import { formatFullName } from "@rmp/core/utils/formatting";
import { CounterpartiesItem } from "@rmp/enterprise/stores/counterparties/types/counterpartiesItem";
import { RegisterState, useRegisterStore } from "@rmp/core/stores/composables/register/useRegisterStore";
import { CounterpartyController } from "@rmp/enterprise/api/counterparty";

const abortService = new AbortService();
const counterpartyController = new CounterpartyController(abortService);

const mapper = new CounterpartiesMapperProfile();

export interface CounterpartiesState extends PageState, RegisterState<ApiCounterpartyPersistedBase, CounterpartiesFilter>
{
	filter: CounterpartiesFilter;
	searchSuggestions: SearchSuggestion[];
}

const defaultRouteQuery = new CounterpartiesRouteQuery(1,
	"createdAt",
	SortingOrderTypeEnum.DESCENDING,
	"",
	[]);

const routeQueryService = new CounterpartiesRouteQueryService(defaultRouteQuery);

const page = usePageStore(abortService);

const register = useRegisterStore<ApiCounterpartyPersistedBase, CounterpartiesFilter>({
	routeName: RouteNames.COUNTERPARTIES,
	routeQueryService,
	routeQuery: defaultRouteQuery,
	getRouter: () => router,
	itemsKey: "counterparties"
});

const getDefaultState = (): CounterpartiesState => {
	return {
		...page.getDefaultPageState(),
		...register.getDefaultRegisterState(),
		filter: new CounterpartiesFilter(),
		searchSuggestions: []
	};
};

export const useCounterpartiesStore = defineStore({
	id: "counterparties",
	state: (): CounterpartiesState => getDefaultState(),
	getters: {
		...register.getters,
		...page.getters,
		formattedItems(state: CounterpartiesState): CounterpartiesItem[] {
			return state.listing.items.map(x => {
				let item = {
					id: x.id,
					createdAt: formatDate(convertIsoToNumber(x.createdAt), dateTimeFormat),
					inn: "",
					ogrn: "",
					title: "",
					counterpartyType: i18n.t(`aliases.counterpartyType.${x.counterparty.type}`)
				};
				
				if(x.counterparty instanceof ApiLegalEntityCounterparty) {
					item.inn = x.counterparty.inn;
					item.ogrn = x.counterparty.ogrn;
					item.title = x.counterparty.shortName;
				}
				if(x.counterparty instanceof ApiLegalPersonCounterparty) {
					item.inn = x.counterparty.inn;
					item.ogrn = x.counterparty.ogrnIp;
					item.title = x.counterparty.fullName;
				}
				if(x.counterparty instanceof ApiForeignOrganizationCounterparty) {
					item.title = x.counterparty.longName;
				}
				
				if(x.counterparty instanceof ApiPhysicalPersonCounterparty) {
					item.inn = x.counterparty.inn;
					item.title = formatFullName({ ...x.counterparty });
				}
				
				return item as CounterpartiesItem;
			});
		},
		filteredItems(): CounterpartiesItem[] {
			return this.formattedItems;
		}
	},
	actions: {
		...page.actions,
		...register.actions,
		async beforeInitialized() {
			await this.initializeRegister();
			
			this.searchSuggestions = get(LAST_VISITED_COUNTERPARTIES) || [];
		},
		patchStateWithRouteQuery(query: CounterpartiesRouteQuery) {
			this.patchRegisterStateWithRouteQuery(query);
			this.$patch((state) => {
				state.filter.counterpartyTypes = query.filterType;
			});
		},
		async fetchRegisterItems() {
			return await counterpartyController.getCounterparties(mapper.mapToApiGetCounterpartiesParameters(this));
		}
	}
});
