import AbortService from "@rmp/core/services/abortService";
import BaseEnterpriseController from "@rmp/enterprise/api/base/baseEnterpriseController";
import { urls } from "@rmp/enterprise/api/config/urls";
import { ApiCrewboatServiceApplication } from "@rmp/core/api/types/service/crewboat/apiCrewboatServiceApplication";
import { ApiTariff } from "@rmp/core/api/types/service/tariffs/apiTariff";
import { ApiGetTariffTotalAmountRequest } from "@rmp/core/api/types/service/apiGetTariffTotalAmountRequest";
import { ApiTariffTotalAmount } from "@rmp/core/api/types/service/apiTariffTotalAmount";
import { ApiServiceApplicationCrewboat } from "@rmp/core/api/types/service/crewboat/apiServiceApplicationCrewboat";
import { ApiCreateServiceDocument } from "@rmp/core/api/types/service/document/apiCreateServiceDocument";
import { ApiCrewboatPersisted } from "@rmp/core/api/types/enterpriseProperty/apiCrewboat";
import { ApiPropertyServiceTransitionCodeEnum } from "@rmp/core/api/types/service/ApiPropertyServiceTransitionCodeEnum";
import { ApiPierServiceApplication } from "@rmp/core/api/types/service/pier/apiPierServiceApplication";
import { ApiBoatProvisionData } from "@rmp/core/api/types/service/apiBoatProvisionData";

export class CrewboatServiceController extends BaseEnterpriseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getService = async (crewboatServiceId: string) => {
		return await this.get<{ serviceApplication: ApiCrewboatServiceApplication }>(urls.service.crewboat.$crewboatServiceId,
			{ crewboatServiceId });
	};
	
	assignCrewboat = async (crewboatServiceId: string, payload: string[]) => {
		return await this.put(urls.service.crewboat.$crewboatServiceId.crewboat, { crewboatServiceId }, payload);
	};
	
	getServiceCrewboats = async (departmentId: string) => {
		return await this.get<ApiCrewboatPersisted[]>(urls.service.crewboat.department.$departmentId.crewboat, { departmentId });
	};
	
	getTariffs = async (departmentId: string, crewboatId: string) => {
		return await this.get<ApiTariff[]>(urls.service.crewboat.department.$departmentId.crewboat.$crewboatId.tariff,
			{ departmentId, crewboatId });
	};
	
	calculateTariffCost = async (departmentId: string,
		crewboatId: string,
		tariffId: string,
		payload: ApiGetTariffTotalAmountRequest) => {
		const [data] = await this.post<ApiTariffTotalAmount[]>(urls.service.crewboat.department.$departmentId.crewboat.$crewboatId.tariff.$tariffId.calculate,
			{ departmentId, crewboatId, tariffId }, payload);
		return data;
	};
	
	assignTariffs = async (crewboatServiceId: string, payload: ApiServiceApplicationCrewboat[]) => {
		await this.put(urls.service.crewboat.$crewboatServiceId.tariff, { crewboatServiceId }, payload);
	};
	
	saveDocuments = async (crewboatServiceId: string, documents: ApiCreateServiceDocument[]) => {
		return await this.put<void>(urls.service.crewboat.$crewboatServiceId.document, { crewboatServiceId }, documents);
	};
	
	updateStatusByTransition = async (crewboatServiceId: string, code: ApiPropertyServiceTransitionCodeEnum, reason?: string) => {
		return await this.post<void>(urls.service.crewboat.$crewboatServiceId.$code,
			{ crewboatServiceId, code },
			{ rejectionTransition: { reason } });
	};
	
	updateProvisionData = async (crewboatServiceId: string, payload: ApiBoatProvisionData) => {
		return await this.put<{ serviceApplication: ApiPierServiceApplication }>(urls.service.crewboat.$crewboatServiceId.provisionData,
			{ crewboatServiceId },
			payload);
	};
}
