import { ApiServiceDocumentKindEnum } from "@rmp/core/api/types/service/document/ApiServiceDocumentKindEnum";
import { ENTERPRISE_SIGNATURE_REQUIRED_SERVICE_DOCUMENT_TYPES } from "@rmp/core/constants/service";

export function useServiceDocuments() {
	const isSignatureRequiredForDocumentType = (type: ApiServiceDocumentKindEnum) => {		
		return ENTERPRISE_SIGNATURE_REQUIRED_SERVICE_DOCUMENT_TYPES.includes(type);
	};
	
	
	return { isSignatureRequiredForDocumentType };
}
