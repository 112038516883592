import { parseISO } from "date-fns";
import { ApiTugboatPersisted } from "@rmp/core/api/types/enterpriseProperty/apiTugboat";

export interface Tugboat {
	id: string;
	createdAt: Date;
	name: string;
	callSign: string;
	imo: string;
	mmsi: string;
	flag: string;
	type: string;
	grt: string;
	exclusionGRT?: string;
	nrt: string;
	deadWeight: string;
	length: string;
	width: string;
	height: string;
	power: string;
	portOfRegistry: string;
	numberOfRegistry: string;
	yearOfBuilding?: string;
	status: string;
	isArchived: boolean;
	kind: string;
	calculatedGRT?: string;
	precipitationMaxValue?: string;
}

export class TugboatMapper {
	static map(source: ApiTugboatPersisted): Tugboat {
		return {
			...source,
			...source.tugboat,
			createdAt: parseISO(source.createdAt),
			grt: source.tugboat?.grt.toString(),
			exclusionGRT: source.tugboat?.exclusionGRT?.toString(),
			nrt: source.tugboat.nrt.toString(),
			deadWeight: source.tugboat.deadWeight.toString(),
			length: source.tugboat.length.toString(),
			width: source.tugboat.width.toString(),
			height: source.tugboat.height.toString(),
			power: source.tugboat.power.toString(),
			yearOfBuilding: source.tugboat?.yearOfBuilding?.toString(),
			calculatedGRT: source.tugboat?.calculatedGRT?.toString(),
			precipitationMaxValue: source.tugboat?.precipitationMaxValue?.toString()
		};
	}
}
