import { parseISO } from "date-fns";
import { ApiFloatingCranePersisted } from "@rmp/core/api/types/enterpriseProperty/apiFloatingCrane";

export interface FloatingCrane {
	id: string;
	createdAt: Date;
	name: string;
	callSign: string;
	imo: string;
	mmsi: string;
	flag: string;
	type: string;
	grt: string;
	exclusionGRT?: string;
	nrt: string;
	deadWeight: string;
	length: string;
	width: string;
	height: string;
	power: string;
	portOfRegistry: string;
	numberOfRegistry: string;
	yearOfBuilding?: string;
	status: string;
	isArchived: boolean;
	kind: string;
	calculatedGRT?: string;
	precipitationMaxValue?: string;
}

export class FloatingCraneMapper {
	static map(source: ApiFloatingCranePersisted): FloatingCrane {
		return {
			...source,
			...source.floatingCrane,
			createdAt: parseISO(source.createdAt),
			grt: source.floatingCrane?.grt.toString(),
			exclusionGRT: source.floatingCrane?.exclusionGRT?.toString(),
			nrt: source.floatingCrane.nrt.toString(),
			deadWeight: source.floatingCrane.deadWeight.toString(),
			length: source.floatingCrane.length.toString(),
			width: source.floatingCrane.width.toString(),
			height: source.floatingCrane.height.toString(),
			power: source.floatingCrane.power.toString(),
			yearOfBuilding: source.floatingCrane?.yearOfBuilding?.toString(),
			calculatedGRT: source.floatingCrane?.calculatedGRT?.toString(),
			precipitationMaxValue: source.floatingCrane?.precipitationMaxValue?.toString()
		};
	}
}
