import { IsArray, IsIn } from "class-validator";
import { AVAILABLE_FILTER_TYPE } from "@rmp/enterprise/stores/counterparties/constants";

export default class CounterpartiesFilter {
	@IsArray()
	@IsIn(AVAILABLE_FILTER_TYPE, {
		each: true
	})
	counterpartyTypes: string[];
	
	constructor(
		counterpartyTypes: string[] = []
	)
	{
		this.counterpartyTypes = counterpartyTypes;
	}
}
