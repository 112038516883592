import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import router from "@rmp/enterprise/router";
import { SnapshotKeysEnum, SnapshotState, useSnapshotStore } from "@rmp/core/stores/composables/snapshot";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import { RouteNames } from "@rmp/enterprise/router/routes";
import { FormState, useFormStore } from "@rmp/core/stores/composables/form/useFormStore";
import { i18n } from "@rmp/core/plugins";
import { PortDestinationController } from "@rmp/enterprise/api/divisions/port/destination";
import { usePortDestinationBreadcrumb } from "@rmp/enterprise/stores/divisions/portDestination/composables/usePortDestinationBreadcrumb";
import { usePortDestinationsBreadcrumb } from "@rmp/enterprise/stores/divisions/port/destinations/composables/usePortDestinationsBreadcrumb";
import ApiBranch from "@rmp/core/api/types/divisions/branch/apiBranch";
import { ApiDepartment } from "@rmp/core/api/types/divisions/department/apiDepartment";
import { ApiPort } from "@rmp/core/api/types/divisions/port/apiPort";
import { useBranchesBreadcrumb } from "@rmp/enterprise/stores/divisions/branches/composables/useBranchesBreadcrumb";
import { useBranchBreadcrumb } from "@rmp/enterprise/stores/divisions/branch/composables/useBranchesBreadcrumb";
import { useDepartmentsBreadcrumb } from "@rmp/enterprise/stores/divisions/branch/departments/composables/useDepartmentsBreadcrumb";
import { useDepartmentBreadcrumb } from "@rmp/enterprise/stores/divisions/department/composables/useDepartmentBreadcrumb";
import { usePortsBreadcrumb } from "@rmp/enterprise/stores/divisions/department/ports/composables/usePortsBreadcrumb";
import { usePortBreadcrumb } from "@rmp/enterprise/stores/divisions/port/composables/usePortBreadcrumb";
import { DepartmentController } from "@rmp/enterprise/api/divisions/department";
import { PortController } from "@rmp/enterprise/api/divisions/port";
import { BranchController } from "@rmp/enterprise/api/divisions/branch";
import { HarborDuesController } from "@rmp/enterprise/api/harborDues";
import { useOwnEmployeeStore } from "@rmp/enterprise/stores/ownEmployee";

const abortService = new AbortService();

const departmentController = new DepartmentController(abortService);
const portController = new PortController(abortService);
const branchController = new BranchController(abortService);
const portDestinationController = new PortDestinationController(abortService);
const harborDuesController = new HarborDuesController(abortService);

const page = usePageStore(abortService);
const form = useFormStore();

const snapshotStore = useSnapshotStore([
	{
		key: SnapshotKeysEnum.LAST_SAVED, fields: ["portDestination", "harborDuesIdentifier"]
	}
]);

export interface ApiCreatePortDestination {
	fullName: string;
	shortName: string;
	englishName: string;
}

export interface PortDestinationState extends PageState, SnapshotState, FormState {
	branchId: string;
	departmentId: string;
	portId: string;
	id: string;
	branch: ApiBranch;
	department: ApiDepartment,
	port: ApiPort;
	portDestination: ApiCreatePortDestination;
	portDestinationName: string;
	harborDuesIdentifier: number | undefined
}

const getDefaultState = (): PortDestinationState => {
	return {
		...page.getDefaultPageState(),
		...snapshotStore.getDefaultState(),
		...form.getDefaultState(),
		branchId: "",
		departmentId: "",
		portId: "",
		id: "",
		branch: {} as ApiBranch,
		department: {} as ApiDepartment,
		port: {} as ApiPort,
		portDestination: {} as ApiCreatePortDestination,
		portDestinationName: "",
		harborDuesIdentifier: undefined
	};
};

export const usePortDestinationStore = defineStore({
	id: "port-destination",
	state: (): PortDestinationState => getDefaultState(),
	getters: {
		...page.getters,
		...snapshotStore.getters,
		...form.getters,
		breadcrumbs(state: PortDestinationState) {
			const routeName = state.id ? RouteNames.PORT_DESTINATION : RouteNames.PORT_DESTINATION_CREATE;
			const text = state.id ? state.portDestination.fullName : String(i18n.t("titles.portDestinationCreate"));
			
			let result = [];
			
			const { belongsToDepartment } = useOwnEmployeeStore();
			
			if(!belongsToDepartment) {
				result.push(useBranchesBreadcrumb(),
					useBranchBreadcrumb(state.branch.shortName, { id: state.branchId }),
					useDepartmentsBreadcrumb({ id: state.branchId }));
			}
			
			result.push(useDepartmentBreadcrumb(state.department.shortName, { id: state.departmentId }),
				usePortsBreadcrumb({ id: state.portId }),
				usePortBreadcrumb(state.port.fullName, { id: state.portId }),
				usePortDestinationsBreadcrumb(),
				usePortDestinationBreadcrumb(routeName, text, { id: state.id }));
			
			return result;
		}
	},
	actions: {
		...page.actions,
		...snapshotStore.actions,
		...form.actions,
		async beforeInitialized() {
			this.setStateSnapshot();
			
			this.branchId = router.currentRoute.params.branchId;
			this.departmentId = router.currentRoute.params.departmentId;
			this.portId = router.currentRoute.params.portId;
			this.id = router.currentRoute.params.destinationId;
			
			const [department, branch, port] = await Promise.all([
				departmentController.getDepartment(this.departmentId),
				branchController.getBranch(this.branchId),
				portController.getPort(this.departmentId, this.portId)
			]);
			
			this.department = department.division;
			this.branch = branch.division;
			this.port = port.division;
			
			if(!this.id) return;
			
			await this.fetch();
			await this.getPortDestinationForHarborDues();
			
			this.setStateSnapshot();
		},
		async fetch() {
			try {
				const { portDestination } = await portDestinationController.getPortDestination(this.portId, this.id);
				
				this.portDestination = {
					fullName: portDestination.fullName,
					shortName: portDestination.shortName,
					englishName: portDestination.englishName
				};
				this.portDestinationName = portDestination.fullName;
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		},
		async save() {
			this.formSaving = true;
			
			const payload = this.portDestination;
			
			try {
				if(this.id) {
					await portDestinationController.updatePortDestination(this.portId, this.id, payload);
				} else {
					const { id } = await portDestinationController.createPortDestination(this.portId, payload);
					this.id = id;
				}
				
				await harborDuesController.updatePortDestination(this.id, {
					name: payload.shortName,
					id: this.harborDuesIdentifier as number
				});
				
				this.setStateSnapshot();
				
				alertService.addSuccess(this.id ? AlertKeys.SUCCESS_UPDATED_INFO : AlertKeys.SUCCESS_CREATED_INFO);
				
				await router.push({
					name: RouteNames.PORT_DESTINATION_DETAILS, params: {
						branchId: this.branchId,
						portId: this.portId,
						departmentId: this.departmentId,
						destinationId: this.id
					}
				});
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.formSaving = false;
			}
		},
		async getPortDestinationForHarborDues() {
			try {
				this.harborDuesIdentifier = (await harborDuesController.getPortDestination(this.id)).id;
			} catch (error) {
				console.error(error);
			}
		}
	}
});
