import { ServiceTypeEnum } from "@rmp/core/types/services/ServiceTypeEnum";
import AbortService from "@rmp/core/services/abortService";
import { TugboatServiceController } from "@rmp/enterprise/api/service/tugboat";
import { ServiceControllerBase } from "@rmp/enterprise/api/service/serviceControllerBase";
import { CrewboatServiceController } from "@rmp/enterprise/api/service/crewboat";
import { ApiGetTariffTotalAmountRequest } from "@rmp/core/api/types/service/apiGetTariffTotalAmountRequest";
import { PierServiceController } from "@rmp/enterprise/api/service/pier";
import { ServiceWorkflowStateType } from "@rmp/core/types/services/ServiceWorkflowStateType";
import { DredgingServiceController } from "@rmp/enterprise/api/service/dredging";
import { DredgingServiceWorkflowStateType } from "@rmp/core/types/services/dredging/DredgingServiceWorkflowStateType";
import {
	GeodeticHydrographicServiceWorkflowStateType
} from "@rmp/core/types/services/geodeticHydrographic/GeodeticHydrographicServiceWorkflowStateType";
import { GeodeticHydrographicServiceController } from "@rmp/enterprise/api/service/geodeticHydrographic";
import { SnoInstallationServiceWorkflowStateType } from "@rmp/core/types/services/snoInstallation/SnoInstallationServiceWorkflowStateType";
import { SnoInstallationServiceController } from "@rmp/enterprise/api/service/snoInstallation";
import { InformationServiceWorkflowStateType } from "@rmp/core/types/services/information/InformationServiceWorkflowStateType";
import { InformationServiceController } from "@rmp/enterprise/api/service/information";
import { FloatingCraneServiceController } from "@rmp/enterprise/api/service/floatingCrane";
import { FloatingCraneServiceWorkflowStateType } from "@rmp/core/types/services/floatingCrane/FloatingCraneServiceWorkflowStateType";
import { ApiCreateServiceDocument } from "@rmp/core/api/types/service/document/apiCreateServiceDocument";

export type BaseServiceWorkflowStateType =
	ServiceWorkflowStateType
	| InformationServiceWorkflowStateType
	| DredgingServiceWorkflowStateType
	| SnoInstallationServiceWorkflowStateType
	| GeodeticHydrographicServiceWorkflowStateType
	| FloatingCraneServiceWorkflowStateType;

export default class ServiceApplicationApiFacade {
	private controllers: Map<ServiceTypeEnum, ServiceControllerBase>;
	private serviceType: ServiceTypeEnum;
	
	constructor(public abortService: AbortService) {
		this.controllers = new Map<ServiceTypeEnum, ServiceControllerBase>([
			[ServiceTypeEnum.TUGBOAT_SERVICE, new TugboatServiceController(abortService) as ServiceControllerBase],
			[ServiceTypeEnum.CREWBOAT_SERVICE, new CrewboatServiceController(abortService) as ServiceControllerBase],
			[ServiceTypeEnum.PIER_SERVICE, new PierServiceController(abortService) as ServiceControllerBase],
			[ServiceTypeEnum.DREDGING_WORKS_SERVICE, new DredgingServiceController(abortService) as ServiceControllerBase],
			[ServiceTypeEnum.SNO_INSTALLATION_SERVICE, new SnoInstallationServiceController(abortService) as ServiceControllerBase],
			[ServiceTypeEnum.GEODETIC_HYDROGRAPHIC_SERVICE, new GeodeticHydrographicServiceController(abortService) as ServiceControllerBase],
			[ServiceTypeEnum.INFORMATION_SERVICE, new InformationServiceController(abortService) as ServiceControllerBase],
			[ServiceTypeEnum.FLOATING_CRANE_SERVICE, new FloatingCraneServiceController(abortService) as ServiceControllerBase]
		]);
	}
	
	private get controller() {
		return this.controllers.get(this.serviceType)!;
	}
	
	configure(serviceType: ServiceTypeEnum) {
		this.serviceType = serviceType;
	}
	
	async getTariffs(departmentId: string, propertyId: string) {
		return this.controller.getTariffs(departmentId, propertyId);
	}
	
	async calculateTariffCost(departmentId: string, propertyId: string, tariffId: string,
		payload: ApiGetTariffTotalAmountRequest)
	{
		return this.controller.calculateTariffCost(departmentId, propertyId, tariffId, payload);
	}
	
	async assignTariffs(serviceId: string, payload: any[]) {
		return this.controller.assignTariffs(serviceId, payload);
	}
	
	async updateStatusByTransition(propertyId: string, code: string, reason?: string) {
		return this.controller.updateStatusByTransition(propertyId, code, reason);
	}
	
	async saveDocuments(serviceId: string, documents: ApiCreateServiceDocument[]) {
		return this.controller.saveDocuments(serviceId, documents);
	}
}
