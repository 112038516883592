import { IsString, IsInt, Min, IsIn, Max, Length, IsEnum, IsArray } from "class-validator";
import { SortingOrderTypeEnum } from "@rmp/core/types/SortingOrderTypeEnum";
import { AVAILABLE_SORTS } from "@rmp/enterprise/stores/piers/constants";
import RegisterRouteQuery from "@rmp/core/stores/composables/register/types/registerRouteQuery";
import { MetricsPeriodType } from "@rmp/core/types/metrics/metricsPeriodType";

export default class MetricsByTimeRouteQuery implements RegisterRouteQuery {
	@IsInt()
	@Min(0)
	@Max(1000)
	page: number;
	
	@IsString()
	@IsIn(AVAILABLE_SORTS)
	sort: string;
	
	@IsString()
	@IsIn([SortingOrderTypeEnum.DESCENDING, SortingOrderTypeEnum.ASCENDING])
	sortDirection: string;
	
	@IsString()
	@Length(0, 100)
	query: string;
	
	@IsEnum(MetricsPeriodType)
	period: MetricsPeriodType;
	
	@IsString()
	periodStart: string;
	
	@IsString()
	periodEnd: string;
	
	@IsArray()
	divisionIds: string[];
	
	constructor(
		page: number,
		sort: string,
		sortDirection: string,
		query: string,
		period: MetricsPeriodType,
		periodStart: string,
		periodEnd: string,
		divisionIds: string[]
	)
	{
		this.page = page;
		this.sort = sort;
		this.sortDirection = sortDirection;
		this.query = query;
		this.period = period;
		this.periodStart = periodStart;
		this.periodEnd = periodEnd;
		this.divisionIds = divisionIds;
	}
}
