import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { SortingOrderTypeEnum } from "@rmp/core/types/SortingOrderTypeEnum";
import { RouteNames } from "@rmp/enterprise/router/routes";
import router from "@rmp/enterprise/router";
import { BranchController } from "@rmp/enterprise/api/divisions/branch";
import BranchesFilter from "@rmp/enterprise/stores/divisions/branches/types/branchesFilter";
import BranchesRouteQuery from "@rmp/enterprise/stores/divisions/branches/types/branchesRouteQuery";
import { BranchesFilterStatusEnum } from "@rmp/enterprise/stores/divisions/branches/types/BranchesFilterStatusEnum";
import BranchesRouteQueryService from "@rmp/enterprise/stores/divisions/branches/services/branchesRouteQueryService";
import { RegisterState, useRegisterStore } from "@rmp/core/stores/composables/register/useRegisterStore";
import { BranchesItem } from "@rmp/enterprise/stores/divisions/branches/types/branchesItem";
import BranchesMapperProfile from "@rmp/enterprise/stores/divisions/branches/mapper";

const abortService = new AbortService();
const controller = new BranchController(abortService);


const defaultRouteQuery = new BranchesRouteQuery(
	"shortName",
	SortingOrderTypeEnum.ASCENDING,
	"",
	BranchesFilterStatusEnum.ALL);

const routeQueryService = new BranchesRouteQueryService(defaultRouteQuery);

const page = usePageStore(abortService);

export interface BranchesState extends PageState, RegisterState<BranchesItem, BranchesFilter>
{
	filter: BranchesFilter;
}


const register = useRegisterStore<BranchesItem, BranchesFilter>({
	routeName: RouteNames.BRANCHES,
	routeQueryService,
	routeQuery: defaultRouteQuery,
	getRouter: () => router
});

const getDefaultState = (): BranchesState => {
	return {
		...page.getDefaultPageState(),
		...register.getDefaultRegisterState(),
		filter: {
			status: BranchesFilterStatusEnum.ALL
		}
	};
};

export const useBranchesStore = defineStore({
	id: "branches",
	state: (): BranchesState => getDefaultState(),
	getters: {
		...register.getters,
		...page.getters,
		filteredItems(state: BranchesState) {
			return state.listing.items;
		}
	},
	actions: {
		...page.actions,
		...register.actions,
		async beforeInitialized() {
			await this.initializeRegister();
		},
		patchStateWithRouteQuery(query: BranchesRouteQuery) {
			this.patchRegisterStateWithRouteQuery(query);
			this.$patch((state) => {
				state.filter.status = query.status;
			});
		},
		async fetchRegisterItems() {
			let { totalCount, items } = await controller.getBranches(BranchesMapperProfile.mapToApiGetBranchesParameters(this));
			
			return {
				totalCount,
				items: items.map(x => BranchesMapperProfile.mapToBranchesItem(x))
			};
		}
	}
});
