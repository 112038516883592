import AbortService from "@rmp/core/services/abortService";
import { urls } from "@rmp/enterprise/api/config/urls";
import BaseEnterpriseController from "@rmp/enterprise/api/base/baseEnterpriseController";

export class DivisionController extends BaseEnterpriseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	archive = async (id: string) => {
		return await this.post<void>(urls.enterprise.division.$id.archive, { id });
	};
	
	unarchive = async (id: string) => {
		return await this.post<void>(urls.enterprise.division.$id.unarchive, { id });
	};
}
