import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { usePierServiceStore } from "@rmp/enterprise/stores/serviceDetails/pier";
import { usePierServiceDocumentsBreadcrumb } from "@rmp/enterprise/stores/serviceDetails/pier/documents/composables/usePierServiceDocumentsBreadcrumb";
import { DocumentsState, useDocumentsStore } from "@rmp/enterprise/stores/composables/baseDocuments";
import { ServiceDocument } from "@rmp/core/types/services/serviceDocument";

const abortService = new AbortService();
const page = usePageStore(abortService);

const documentsStore = useDocumentsStore<ServiceDocument>({});

export interface PierServiceDocumentsState extends PageState, DocumentsState<ServiceDocument> {
}

const getDefaultState = (): PierServiceDocumentsState => {
	return {
		...page.getDefaultPageState(),
		...documentsStore.getDefaultState()
	};
};

export const usePierServiceDocumentsStore = defineStore({
	id: "pier-service-documents",
	state: (): PierServiceDocumentsState => getDefaultState(),
	getters: {
		...page.getters,
		...documentsStore.getters,
		breadcrumbs() {
			const { breadcrumbs } = usePierServiceStore();
			return [...breadcrumbs, usePierServiceDocumentsBreadcrumb()];
		}
	},
	actions: {
		...page.actions,
		...documentsStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			const { documents } = usePierServiceStore();
			
			this.id = id;
			this.documents = documents;
		}
	}
});
