import { Dictionary } from "vue-router/types/router";
import { useBreadcrumb } from "@rmp/core/stores/composables/breadcrumb/useBreadcrumb";
import { RouteNames } from "@rmp/enterprise/router/routes";
import { i18n } from "@rmp/enterprise/plugins";
import routeToPermissionsMap from "@rmp/enterprise/router/routeToPermissionsMap";

export function useCounterpartyBankAccountBreadcrumb(params: Dictionary<string>) {
	return useBreadcrumb({
		route: {
			name: RouteNames.COUNTERPARTY_DETAILS_BANK_ACCOUNT, params
		},
		text: i18n.t(`navigation.breadcrumbs.${RouteNames.COUNTERPARTY_DETAILS_BANK_ACCOUNT}`).toString(),
		routeToPermissionsMap
	});
}
