import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { useServicesBreadcrumb } from "@rmp/enterprise/stores/services/composables/useServicesBreadcrumb";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import { SnapshotKeysEnum, SnapshotState, useSnapshotStore } from "@rmp/core/stores/composables/snapshot";
import router from "@rmp/enterprise/router";
import { RouteNames } from "@rmp/enterprise/router/routes";
import { FloatingCraneServiceController } from "@rmp/enterprise/api/service/floatingCrane";
import { FloatingCrane, FloatingCraneMapper } from "@rmp/core/types/enterpriseProperty/floatingCrane";
import {
	FloatingCraneServiceApplication,
	FloatingCraneServiceApplicationMapper
} from "@rmp/core/types/services/floatingCrane/floatingCraneServiceApplication";
import {
	useFloatingCraneServiceBreadcrumb
} from "@rmp/enterprise/stores/serviceDetails/floatingCrane/composables/useFloatingCraneServiceBreadcrumb";
import {
	useFloatingCraneAssignmentBreadcrumb
} from "@rmp/enterprise/stores/assignment/floatingCrane/composables/useFloatingCraneAssignmentBreadcrumb";
import { formatServiceTitle } from "@rmp/core/utils/formatting";

const abortService = new AbortService();
const page = usePageStore(abortService);
const snapshotStore = useSnapshotStore([
	{
		key: SnapshotKeysEnum.LAST_SAVED, fields: ["selectedFloatingCraneIds"]
	}
]);

const floatingCraneServiceController = new FloatingCraneServiceController(abortService);

export interface FloatingCraneAssignmentState extends PageState, SnapshotState {
	selectedFloatingCraneIds: string[];
	floatingCranes: FloatingCrane[];
	service: FloatingCraneServiceApplication;
	id: string;
	isSaving: boolean;
}

const getDefaultState = (): FloatingCraneAssignmentState => {
	return {
		...page.getDefaultPageState(),
		...snapshotStore.getDefaultState(),
		selectedFloatingCraneIds: [],
		floatingCranes: [],
		service: {} as FloatingCraneServiceApplication,
		id: "",
		isSaving: false
	};
};

export const useFloatingCraneAssignmentStore = defineStore({
	id: "floating-crane-assignment",
	state: (): FloatingCraneAssignmentState => getDefaultState(),
	getters: {
		...page.getters,
		...snapshotStore.getters,
		breadcrumbs(state: FloatingCraneAssignmentState) {
			return [
				useServicesBreadcrumb(),
				useFloatingCraneServiceBreadcrumb(formatServiceTitle(state.service.type, state.service.applicationNumber), { id: state.id }),
				useFloatingCraneAssignmentBreadcrumb()
			];
		}
	},
	actions: {
		...page.actions,
		...snapshotStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			
			await this.fetch();
			
			if(this.service.requestedFloatingcranes.length) {
				this.setStateSnapshot();
				
				this.selectedFloatingCraneIds = this.service.requestedFloatingcranes.map(x => x.floatingcraneId);
			} else {
				this.selectedFloatingCraneIds = this.service.assignedFloatingcranes.map(x => x.floatingcraneId);
				
				this.setStateSnapshot();
			}
		},
		async fetch() {
			try {
				let { serviceApplication } = await floatingCraneServiceController.getService(this.id!);
				
				let floatingCranes = await floatingCraneServiceController.getServiceFloatingCranes(serviceApplication.divisionId);
				
				this.service = FloatingCraneServiceApplicationMapper.map(serviceApplication);
				this.floatingCranes = floatingCranes.map(x => FloatingCraneMapper.map(x));
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		},
		async assign() {
			this.isSaving = true;
			
			try {
				await floatingCraneServiceController.assignFloatingCrane(this.id!, this.selectedFloatingCraneIds);
				
				alertService.addSuccess(AlertKeys.FLOATING_CRANES_ASSIGNED);
				
				await router.push({ name: RouteNames.FLOATING_CRANE_SERVICE, params: router.currentRoute.params });
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.isSaving = false;
			}
		}
	}
});
