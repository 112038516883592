import { render, staticRenderFns } from "./RmpBranch.vue?vue&type=template&id=91707e86&scoped=true&"
import script from "./RmpBranch.vue?vue&type=script&setup=true&lang=ts&"
export * from "./RmpBranch.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "91707e86",
  null
  
)

export default component.exports