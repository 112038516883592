import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { DocumentsState, useDocumentsStore } from "@rmp/enterprise/stores/composables/baseDocuments";
import {
	useSnoInstallationServiceDocumentsBreadcrumb
} from "@rmp/enterprise/stores/serviceDetails/snoInstallation/documents/composables/useSnoInstallationServiceDocumentsBreadcrumb";
import { useSnoInstallationServiceStore } from "@rmp/enterprise/stores/serviceDetails/snoInstallation";
import { ServiceDocument } from "@rmp/core/types/services/serviceDocument";

const abortService = new AbortService();
const page = usePageStore(abortService);

const documentsStore = useDocumentsStore<ServiceDocument>({});

export interface SnoInstallationServiceDocumentsState extends PageState, DocumentsState<ServiceDocument> {
}

const getDefaultState = (): SnoInstallationServiceDocumentsState => {
	return {
		...page.getDefaultPageState(),
		...documentsStore.getDefaultState()
	};
};

export const useSnoInstallationServiceDocumentsStore = defineStore({
	id: "sno-isntalltion-service-documents",
	state: (): SnoInstallationServiceDocumentsState => getDefaultState(),
	getters: {
		...page.getters,
		...documentsStore.getters,
		breadcrumbs() {
			const { breadcrumbs } = useSnoInstallationServiceStore();
			return [...breadcrumbs, useSnoInstallationServiceDocumentsBreadcrumb()];
		}
	},
	actions: {
		...page.actions,
		...documentsStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			const { documents } = useSnoInstallationServiceStore();
			
			this.id = id;
			this.documents = documents;
		}
	}
});
