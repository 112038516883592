import { defineStore } from "pinia";
import AbortService from "@rmp/core/services/abortService";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import TariffAssignmentTariffMapper from "@rmp/enterprise/stores/assignment/tariff/steps/tariff/mapper";
import { TariffAssignmentStepState } from "@rmp/enterprise/stores/assignment/tariff/steps/shared/tariffAssignmentStepState";
import { TariffAssignmentPropertyTariff } from "@rmp/enterprise/stores/assignment/tariff/steps/tariff/types/tariffAssignmentPropertyTariff";
import { TariffAssignmentStepTypeEnum } from "@rmp/enterprise/stores/assignment/tariff/steps/shared/TariffAssignmentStepTypeEnum";
import { useTariffAssignmentStore } from "@rmp/enterprise/stores/assignment/tariff";
import ServiceApplicationApiFacade from "@rmp/enterprise/api/service/facade";
import { ApiVatEnum } from "@rmp/core/api/types/vat/ApiVatEnum";
import { ApiSortedServiceTariffsEnum } from "@rmp/core/api/types/service/tariffs/ApiServiceTariffsEnum";
import { TariffAssignmentTariff } from "@rmp/enterprise/stores/assignment/tariff/steps/tariff/types/tariffAssignmentTariff";
import { sortBy } from "lodash";

const abortService = new AbortService();
const page = usePageStore(abortService);
const mapper = new TariffAssignmentTariffMapper();

const serviceApplicationApiFacade = new ServiceApplicationApiFacade(abortService);

export interface TariffStepState extends TariffAssignmentStepState, PageState {
	formValid: boolean;
	propertyTariffs: TariffAssignmentPropertyTariff[];
}

const getDefaultState = (): TariffStepState => {
	return {
		...page.getDefaultPageState(),
		formValid: true,
		stepType: TariffAssignmentStepTypeEnum.TARIFF,
		propertyTariffs: []
	};
};

export const useTariffStepStore = defineStore({
	id: "tariff-assignment-tariff-step",
	state: (): TariffStepState => getDefaultState(),
	getters: {
		...page.getters,
		completed(state) {
			return state.propertyTariffs.every(x => x.selectedTariffIds.length);
		},
		breadcrumbs() {
			const { breadcrumbs } = useTariffAssignmentStore();
			return breadcrumbs;
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized() {
			const { serviceType } = useTariffAssignmentStore();
			serviceApplicationApiFacade.configure(serviceType);
			
			await this.fetch();
			this.setDefaultTariffs();
		},
		async fetch() {
			try {
				const { properties, service } = useTariffAssignmentStore();
				
				for (const property of properties) {
					let apiTariffs = await serviceApplicationApiFacade.getTariffs(service.divisionId, property.id);
					
					let tariffs = apiTariffs.map(x => mapper.mapTariff(x));
					
					if(!tariffs.length)
						return;
					
					let sortedTariffs = sortBy(tariffs, [
						x => x.name,
						x => x.vat === ApiVatEnum.NoVat,
						x => x.price
					]);
					
					this.propertyTariffs.push({
						propertyId: property.id,
						propertyName: property.name,
						selectedTariffIds: [],
						tariffs: sortedTariffs
					});
				}
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		},
		setDefaultTariffs() {
			const { selectedProperties } = useTariffAssignmentStore();

			this.propertyTariffs = this.propertyTariffs.map(x => {
				if(selectedProperties.some(y => y.propertyId === x.propertyId))
					return {
						...x,
						selectedTariffIds: x.tariffs.map(y => y.id).filter(id => selectedProperties.some(y => y.tariffId === id))
					};
				else
					return x;
			});
		}
	}
});
