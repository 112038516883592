import { ApiGetMetricsByCounterpartyParameters } from "@rmp/core/api/types/metrics/apiGetMetricsByCounterpartyParameters";
import { MetricsByAccountsState } from "@rmp/enterprise/stores/metrics/byAccounts/index";

export default class MetricsByAccountsMapperProfile {
	constructor() {
	}
	
	mapToApiGetMetricsByAccountsParameters(source: MetricsByAccountsState): ApiGetMetricsByCounterpartyParameters {
		return {
			from: source.filter.periodStart || source.periods.find(x => source.filter.period === x.value)?.start || "",
			to: source.filter.periodEnd || source.periods.find(x => source.filter.period === x.value)?.end || "",
		};
	}
}
