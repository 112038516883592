import AbortService from "@rmp/core/services/abortService";
import BaseEnterpriseController from "@rmp/enterprise/api/base/baseEnterpriseController";
import { urls } from "@rmp/enterprise/api/config/urls";
import { ApiPierServiceApplication } from "@rmp/core/api/types/service/pier/apiPierServiceApplication";
import { ApiPierPersisted } from "@rmp/core/api/types/enterpriseProperty/apiPier";
import { ApiTariff } from "@rmp/core/api/types/service/tariffs/apiTariff";
import { ApiGetTariffTotalAmountRequest } from "@rmp/core/api/types/service/apiGetTariffTotalAmountRequest";
import { ApiTariffTotalAmount } from "@rmp/core/api/types/service/apiTariffTotalAmount";
import { ApiServiceApplicationPier } from "@rmp/core/api/types/service/pier/apiServiceApplicationPier";
import { ServiceWorkflowStateType } from "@rmp/core/types/services/ServiceWorkflowStateType";
import { ApiCreateServiceDocument } from "@rmp/core/api/types/service/document/apiCreateServiceDocument";
import { ApiPropertyServiceTransitionCodeEnum } from "@rmp/core/api/types/service/ApiPropertyServiceTransitionCodeEnum";
import { ApiPierProvisionData } from "@rmp/core/api/types/service/pier/apiPierProvisionData";

export class PierServiceController extends BaseEnterpriseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getService = async (pierServiceId: string) => {
		return await this.get<{ serviceApplication: ApiPierServiceApplication }>(urls.service.pier.$pierServiceId, { pierServiceId });
	};
	
	assignPiers = async (pierServiceId: string, payload: string[]) => {
		return await this.put(urls.service.pier.$pierServiceId.pier, { pierServiceId }, payload);
	};
	
	getServicePiers = async (departmentId: string) => {
		return await this.get<ApiPierPersisted[]>(urls.service.pier.department.$departmentId.pier, { departmentId });
	};
	
	getTariffs = async (departmentId: string, pierId: string) => {
		return await this.get<ApiTariff[]>(urls.service.pier.department.$departmentId.pier.$pierId.tariff,
			{ departmentId, pierId });
	};
	
	calculateTariffCost = async (departmentId: string,
		pierId: string,
		tariffId: string,
		payload: ApiGetTariffTotalAmountRequest) => {
		const [data] = await this.post<ApiTariffTotalAmount[]>(urls.service.pier.department.$departmentId.pier.$pierId.tariff.$tariffId.calculate,
			{ departmentId, pierId, tariffId }, payload);
		return data;
	};
	
	assignTariffs = async (pierServiceId: string, payload: ApiServiceApplicationPier[]) => {
		await this.put(urls.service.pier.$pierServiceId.tariff, { pierServiceId }, payload);
	};
	
	saveDocuments = async (pierServiceId: string, documents: ApiCreateServiceDocument[]) => {
		return await this.put<void>(urls.service.pier.$pierServiceId.document, { pierServiceId }, documents);
	};
	
	updateStatusByTransition = async (pierServiceId: string, code: ApiPropertyServiceTransitionCodeEnum, reason?: string) => {
		return await this.post<void>(urls.service.pier.$pierServiceId.$code,
			{ pierServiceId, code },
			{ rejectionTransition: { reason } });
	};
	
	updateProvisionData = async (pierServiceId: string, payload: ApiPierProvisionData) => {
		return await this.put<{ serviceApplication: ApiPierServiceApplication }>(urls.service.pier.$pierServiceId.provisionData,
			{ pierServiceId },
			payload);
	};
}
