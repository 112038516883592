import { RouteNames } from "@rmp/enterprise/router/routes";
import { DivisionKindEnum } from "@rmp/core/api/types/divisions/DivisionKindEnum";
import { DivisionStatusEnum } from "@rmp/core/api/types/divisions/DivisionStatusEnum";
import { TariffAssignmentStepTypeEnum } from "@rmp/enterprise/stores/assignment/tariff/steps/shared/TariffAssignmentStepTypeEnum";
import {
	InformationTariffAssignmentStepTypeEnum
} from "@rmp/enterprise/stores/assignment/informationTariff/steps/shared/InformationTariffAssignmentStepTypeEnum";
import {
	UploadAgreementDocumentsStepTypeEnum
} from "@rmp/enterprise/stores/uploadAgreementDocuments/steps/shared/UploadAgreementDocumentsStepTypeEnum";
import { ServiceTypeEnum } from "@rmp/core/types/services/ServiceTypeEnum";
import { ServiceWorkflowStateType } from "@rmp/core/types/services/ServiceWorkflowStateType";

export default {
	titles: {
		counterparties: "Контрагенты",
		branches: "Филиалы",
		services: "Услуги",
		counterpartyEmployee: "Cотрудник"
	},
	content: {
		noBranches: "У вас нет филиалов"
	},
	subheaders: {
		feedback: "Отзыв"
	},
	alerts: {
		serviceAgreementLimitAlmostExhausted: "На балансе отсутствуют необходимые для оказания услуг средства."
	},
	navigation: {
		breadcrumbs: {
			[RouteNames.ROOT]: "Главная",
			[RouteNames.TUGBOATS]: "Буксиры",
			[RouteNames.TUGBOAT_CREATE]: "Новое судно",
			[RouteNames.TUGBOAT_DETAILS_TARIFFS]: "Тарифы",
			[RouteNames.CREWBOATS]: "Разъездные суда",
			[RouteNames.CREWBOAT_CREATE]: "Новое разъездное судно",
			[RouteNames.CREWBOAT_DETAILS_TARIFFS]: "Тарифы",
			[RouteNames.FLOATING_CRANES]: "Плавкраны",
			[RouteNames.FLOATING_CRANE_CREATE]: "Новый плавкран",
			[RouteNames.PIERS]: "Причалы",
			[RouteNames.PIER_CREATE]: "Новый причал",
			[RouteNames.BRANCHES]: "Филиалы",
			[RouteNames.BRANCH_DEPARTMENTS]: "Управления",
			[RouteNames.DEPARTMENT_PORTS]: "Порты",
			[RouteNames.SERVICES]: "Услуги",
			[RouteNames.COUNTERPARTIES]: "Контрагенты",
			[RouteNames.COUNTERPARTY_DETAILS_BANK_ACCOUNTS]: "Банковские реквизиты",
			[RouteNames.COUNTERPARTY_DETAILS_BANK_ACCOUNT]: "Просмотр счета",
			[RouteNames.COUNTERPARTY_DETAILS_EMPLOYEES]: "Сотрудники",
			[RouteNames.COUNTERPARTY_DETAILS_EMPLOYEE]: "Просмотр информации о сотруднике",
			[RouteNames.COUNTERPARTY_DETAILS_DOCUMENTS]: "Документы",
			[RouteNames.PIER_SERVICE_DOCUMENTS]: "Документы",
			[RouteNames.PIER_SERVICE_HISTORY]: "История",
			[RouteNames.PIER_SERVICE_COMMENTS]: "Комментарии",
			[RouteNames.PIER_SERVICE_PIER_ASSIGNMENT]: "Выбор причала",
			[RouteNames.PIER_SERVICE_TARIFF_ASSIGNMENT]: "Выбор тарифа",
			[RouteNames.PIER_SERVICE_UPLOAD_DOCUMENTS]: "Формирование отчетных документов",
			[RouteNames.PIER_SERVICE_FEEDBACK]: "Отзыв",
			[RouteNames.TUGBOAT_SERVICE_DOCUMENTS]: "Документы",
			[RouteNames.TUGBOAT_SERVICE_HISTORY]: "История",
			[RouteNames.TUGBOAT_SERVICE_COMMENTS]: "Комментарии",
			[RouteNames.TUGBOAT_SERVICE_TUGBOAT_ASSIGNMENT]: "Выбор буксира",
			[RouteNames.TUGBOAT_SERVICE_TARIFF_ASSIGNMENT]: "Выбор тарифа",
			[RouteNames.TUGBOAT_SERVICE_UPLOAD_DOCUMENTS]: "Формирование отчетных документов",
			[RouteNames.TUGBOAT_SERVICE_FEEDBACK]: "Отзыв",
			[RouteNames.CREWBOAT_SERVICE_DOCUMENTS]: "Документы",
			[RouteNames.CREWBOAT_SERVICE_HISTORY]: "История",
			[RouteNames.CREWBOAT_SERVICE_COMMENTS]: "Комментарии",
			[RouteNames.CREWBOAT_SERVICE_CREWBOAT_ASSIGNMENT]: "Выбор разъездного судна",
			[RouteNames.CREWBOAT_SERVICE_TARIFF_ASSIGNMENT]: "Выбор тарифа",
			[RouteNames.CREWBOAT_SERVICE_UPLOAD_DOCUMENTS]: "Формирование отчетных документов",
			[RouteNames.CREWBOAT_SERVICE_FEEDBACK]: "Отзыв",
			[RouteNames.DREDGING_SERVICE_DOCUMENTS]: "Документы",
			[RouteNames.DREDGING_SERVICE_HISTORY]: "История",
			[RouteNames.DREDGING_SERVICE_COMMENTS]: "Комментарии",
			[RouteNames.DREDGING_SERVICE_UPLOAD_DOCUMENTS]: "Формирование документов",
			[RouteNames.DREDGING_SERVICE_FEEDBACK]: "Отзыв",
			[RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_DOCUMENTS]: "Документы",
			[RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_HISTORY]: "История",
			[RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_COMMENTS]: "Комментарии",
			[RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_UPLOAD_DOCUMENTS]: "Формирование документов",
			[RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_FEEDBACK]: "Отзыв",
			[RouteNames.SNO_INSTALLATION_SERVICE_DOCUMENTS]: "Документы",
			[RouteNames.SNO_INSTALLATION_SERVICE_HISTORY]: "История",
			[RouteNames.SNO_INSTALLATION_SERVICE_COMMENTS]: "Комментарии",
			[RouteNames.SNO_INSTALLATION_SERVICE_UPLOAD_DOCUMENTS]: "Формирование документов",
			[RouteNames.SNO_INSTALLATION_SERVICE_FEEDBACK]: "Отзыв",
			[RouteNames.INFORMATION_SERVICE_DOCUMENTS]: "Документы",
			[RouteNames.INFORMATION_SERVICE_HISTORY]: "История",
			[RouteNames.INFORMATION_SERVICE_COMMENTS]: "Комментарии",
			[RouteNames.INFORMATION_SERVICE_TARIFF_ASSIGNMENT]: "Выбор тарифа",
			[RouteNames.INFORMATION_SERVICE_UPLOAD_DOCUMENTS]: "Формирование отчетных документов",
			[RouteNames.INFORMATION_SERVICE_FEEDBACK]: "Отзыв",
			[RouteNames.FLOATING_CRANE_SERVICE_DOCUMENTS]: "Документы",
			[RouteNames.FLOATING_CRANE_SERVICE_HISTORY]: "История",
			[RouteNames.FLOATING_CRANE_SERVICE_COMMENTS]: "Комментарии",
			[RouteNames.FLOATING_CRANE_SERVICE_TARIFF_ASSIGNMENT]: "Выбор тарифа",
			[RouteNames.FLOATING_CRANE_SERVICE_UPLOAD_DOCUMENTS]: "Формирование отчетных документов",
			[RouteNames.FLOATING_CRANE_SERVICE_FLOATING_CRANE_ASSIGNMENT]: "Выбор плавкрана",
			[RouteNames.FLOATING_CRANE_SERVICE_FEEDBACK]: "Отзыв",
			[RouteNames.BOAT_APPLICATIONS]: "Флот контрагентов",
			[RouteNames.BOAT_APPLICATION_DETAILS_DOCUMENTS]: "Документы",
			[RouteNames.BOAT_APPLICATION_DETAILS_HISTORY]: "История",
			[RouteNames.BOAT_APPLICATION_DETAILS_COMMENTS]: "Комментарии",
			[RouteNames.METRICS]: "Статистика",
			[RouteNames.AGREEMENTS]: "Мои договоры",
			[RouteNames.AGREEMENT_DETAILS_DOCUMENTS]: "Документы",
			[RouteNames.AGREEMENT_DETAILS_HISTORY]: "История",
			[RouteNames.AGREEMENT_DETAILS_SERVICES]: "Услуги",
			[RouteNames.AGREEMENT_DETAILS_COMMENTS]: "Комментарии",
			[RouteNames.AGREEMENT_UPLOAD_DOCUMENTS]: "Формирование договора",
			[RouteNames.AGREEMENT_SIGN_DOCUMENTS]: "Подпись договора",
			[RouteNames.PORT_DESTINATIONS]: "Места начала и окончания операций"
		}
	},
	aliases: {
		division: {
			[DivisionKindEnum.BRANCH_BASIN]: "Бассейновый филиал",
			[DivisionKindEnum.DEPARTMENT]: "Управление"
		},
		divisionStatus: {
			[DivisionStatusEnum.ACTIVE]: "Активен"
		},
		tariffAssignmentStep: {
			[TariffAssignmentStepTypeEnum.TARIFF]: "Тариф",
			[TariffAssignmentStepTypeEnum.ROUTE]: "Время и место"
		},
		informationTariffAssignmentStep: {
			[InformationTariffAssignmentStepTypeEnum.TARIFF]: "Тариф",
			[InformationTariffAssignmentStepTypeEnum.TIME]: "Время"
		},
		uploadAgreementDocumentsStep: {
			[UploadAgreementDocumentsStepTypeEnum.DOCUMENTS]: "Документы",
			[UploadAgreementDocumentsStepTypeEnum.DURATION]: "Срок действия",
			[UploadAgreementDocumentsStepTypeEnum.PAYMENT_METHOD]: "Способ оплаты"
		}
	},
	service: {
		propertyTariff: {
			title: {
				preferred: {
					[ServiceTypeEnum.TUGBOAT_SERVICE]: "Данные о буксирах, запрошенных контрагентом",
					[ServiceTypeEnum.CREWBOAT_SERVICE]: "Данные о разъездных судах, запрошенных контрагентом",
					[ServiceTypeEnum.PIER_SERVICE]: "Данные о причалах, запрошенных контрагентом",
					[ServiceTypeEnum.INFORMATION_SERVICE]: "Запрошенные данные о тарифе"
				}
			}
		}
	},
	dialogs: {
		service: {
			withdraw: {
				title: {
					[ServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE]: "Подтвердите отзыв электронной подписи документов",
					[ServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED]: "Подтвердите отзыв отчетных документов",
					[ServiceWorkflowStateType.PROVIDED]: "Подтвердите отзыв отчетных документов"
				},
				text: {
					[ServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE]: "В случае подтверждения данного действия, для продолжения потребуется повторное подписание документов. Вы уверены, что хотите продолжить?",
					[ServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED]: "В случае подтверждения данного действия, для восстановления информации потребуется повторная загрузка документов. Вы уверены, что хотите продолжить?",
					[ServiceWorkflowStateType.PROVIDED]: "В случае подтверждения данного действия, для восстановления информации потребуется повторная загрузка документов. Вы уверены, что хотите продолжить?"
				}
			}
		}
	}
};
