var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.initialized)?_c(_setup.RmpNestedContentLayout,{attrs:{"back-route":_setup.backRoute},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c(_setup.RmpHeadingTwo,[_vm._v(_vm._s(_setup.title))])]},proxy:true},{key:"content",fn:function(){return [_c('v-container',{staticClass:"pt-0",attrs:{"fluid":""}},[_c(_setup.RmpSpecialDetails,{attrs:{"type":"counterpartyEmployee","details":_setup.employee,"item-keys":[
				'lastName',
				'phone',
				'firstName',
				'inn',
				'middleName',
				'email',
				'position',
				'snils',
			]}}),_vm._l((_setup.formattedActiveTrusts),function(trust,i){return _c(_setup.RmpSpecialDetails,{key:i,attrs:{"type":"trust","details":trust,"item-keys":[
				'file',
				'certificateInfo',
				'expireDate',
				'acceptedDate',
				'actionerFullName'
			]},scopedSlots:_vm._u([{key:"file",fn:function({ value }){return [_c('div',{staticStyle:{"width":"fit-content"}},[_c(_setup.RmpFile,{staticClass:"mt-n1",attrs:{"id":value},scopedSlots:_vm._u([(!trust.isSigned)?{key:"append-outer",fn:function(){return [_c(_setup.RmpAlert,{staticClass:"mb-0",staticStyle:{"width":"fit-content"},attrs:{"type":"warning","text":_setup.tc('details.isSigned.digitallyMissed')}})]},proxy:true}:null],null,true)})],1)]}},{key:"certificateInfo",fn:function({ value }){return [_c('span',{staticClass:"text-body-2 secondary--text cursor-pointer",on:{"click":function($event){return _setup.handleOpenCertificateInfoDialog(value)}}},[_vm._v(" "+_vm._s(_setup.tc("aliases.documents.signatureGeneral"))+" ")])]}}],null,true)})}),(!_setup.formattedActiveTrusts.length)?_c(_setup.RmpCard,{attrs:{"title":_setup.tc('specialDetails.titles.trust'),"title-mb":"1","card-class":"mb-3"}},[_c(_setup.RmpAlert,{staticStyle:{"width":"100%"},attrs:{"custom-icon":"ico_warning-circle","px":"4","icon-color":_setup.colors.warning,"custom-background-color":"white"}},[_c(_setup.RmpTextBodyTwo,{staticClass:"ml-2"},[_vm._v(_vm._s(_setup.tc("alerts.info.noActiveTrust")))])],1)],1):_vm._e()],2),_c(_setup.RmpCertificateInfoDialog,{attrs:{"value":_setup.certificateInfoDialog,"certificate-info":_setup.openedCertificateInfo},on:{"dialog:close":function($event){_setup.certificateInfoDialog = false}}})]},proxy:true}],null,false,160645622)}):_c(_setup.RmpCounterpartyEmployeeLoader)
}
var staticRenderFns = []

export { render, staticRenderFns }