import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { CounterpartyController } from "@rmp/enterprise/api/counterparty";
import ApiLegalPersonCounterpartyPersisted from "@rmp/core/api/types/counterparty/apiLegalPersonCounterpartyPersisted";
import { useCounterpartyDetailsStore } from "@rmp/enterprise/stores/counterpartyDetails";
import { LegalPersonCounterparty, LegalPersonCounterpartyMapper } from "@rmp/core/types/counterparty/legalPerson/legalPersonCounterparty";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";

const abortService = new AbortService();
const counterpartyController = new CounterpartyController(abortService);

const page = usePageStore(abortService);

export interface LegalPersonCounterpartyState extends PageState {
	id: string;
	counterparty: LegalPersonCounterparty;
	counterpartyLoading: boolean;
}

const getDefaultState = (): LegalPersonCounterpartyState => {
	return {
		...page.getDefaultPageState(),
		id: "",
		counterparty: {} as LegalPersonCounterparty,
		counterpartyLoading: false
	};
};

export const useLegalPersonCounterpartyDetailsStore = defineStore({
	id: "legal-person-counterparty-details",
	state: (): LegalPersonCounterpartyState => getDefaultState(),
	getters: {
		...page.getters,
		breadcrumbs(state: LegalPersonCounterpartyState) {
			const { breadcrumbs } = useCounterpartyDetailsStore();
			return breadcrumbs;
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized() {
			const { id } = useCounterpartyDetailsStore();
			
			this.id = id;
			await this.fetch();
		},
		async fetch() {
			this.counterpartyLoading = true;
			
			try {
				let counterpartyPersisted = await counterpartyController.getCounterparty<ApiLegalPersonCounterpartyPersisted>(this.id,
					ApiLegalPersonCounterpartyPersisted);
				
				this.counterparty = LegalPersonCounterpartyMapper.mapFromApiLegalPerson(counterpartyPersisted.counterparty);
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.counterpartyLoading = false;
			}
		}
	}
});
