import { ApiDepartmentPersisted } from "@rmp/core/api/types/divisions/department/apiDepartment";

export interface Department {
	id: string;
	fullName: string;
	code: string;
	type: string;
	parentId: string;
	shortName: string;
	actualAddress: string;
	kpp: string;
	isArchived: boolean;
}

export class DepartmentMapper {
	static map(source: ApiDepartmentPersisted): Department {
		return {
			...source,
			...source.division
		}
	}
};
