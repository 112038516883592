import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { SortingOrderTypeEnum } from "@rmp/core/types/SortingOrderTypeEnum";
import { RegisterState, useRegisterStore } from "@rmp/core/stores/composables/register/useRegisterStore";
import { EnterprisePropertyController } from "@rmp/enterprise/api/enterpriseProperty";
import { ArchiveStatusType } from "@rmp/core/types/ArchiveStatusType";
import TugBoatsMapperProfile from "@rmp/enterprise/stores/tugboats/mapper";
import TugBoatsFilter from "@rmp/enterprise/stores/tugboats/types/tugBoatsFilter";
import TugBoatsRouteQuery from "@rmp/enterprise/stores/tugboats/types/tugBoatsRouteQuery";
import TugBoatsRouteQueryService from "@rmp/enterprise/stores/tugboats/services/tugBoatsRouteQueryService";
import { RouteNames } from "@rmp/enterprise/router/routes";
import router from "@rmp/enterprise/router";
import { TugBoatsItem } from "@rmp/enterprise/stores/tugboats/types/tugBoatsItem";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import { ArchiveStatusState, useArchiveStatusStore } from "@rmp/core/stores/composables/archiveStatus";
import NotDefinedException from "@rmp/core/exceptions/notDefinedException";

const abortService = new AbortService();
const enterprisePropertyController = new EnterprisePropertyController(abortService);

const mapper = new TugBoatsMapperProfile();

export interface TugBoatsState extends PageState, RegisterState<TugBoatsItem, TugBoatsFilter>, ArchiveStatusState
{
	filter: TugBoatsFilter;
}

const defaultRouteQuery = new TugBoatsRouteQuery(1,
	"name",
	SortingOrderTypeEnum.ASCENDING,
	"",
	ArchiveStatusType.ALL);

const routeQueryService = new TugBoatsRouteQueryService(defaultRouteQuery);

const page = usePageStore(abortService);
const archiveStatusStore = useArchiveStatusStore();

const register = useRegisterStore<TugBoatsItem, TugBoatsFilter>({
	routeName: RouteNames.TUGBOATS,
	routeQueryService,
	routeQuery: defaultRouteQuery,
	getRouter: () => router
});

const getDefaultState = (): TugBoatsState => {
	return {
		...page.getDefaultPageState(),
		...register.getDefaultRegisterState(),
		...archiveStatusStore.getDefaultState(),
		filter: new TugBoatsFilter()
	};
};

export const useTugBoatsStore = defineStore({
	id: "tugboats",
	state: (): TugBoatsState => getDefaultState(),
	getters: {
		...page.getters,
		...register.getters,
		...archiveStatusStore.getters,
		filteredItems(state: TugBoatsState): TugBoatsItem[] {
			return state.listing.items;
		}
	},
	actions: {
		...page.actions,
		...register.actions,
		...archiveStatusStore.actions,
		async beforeInitialized() {
			await this.initializeRegister();
		},
		patchStateWithRouteQuery(query: TugBoatsRouteQuery) {
			this.patchRegisterStateWithRouteQuery(query);
			this.$patch((state) => {
				state.filter.status = query.status;
			});
		},
		async fetchRegisterItems() {
			const { tugboats, totalCount } = await enterprisePropertyController.getTugboats(mapper.mapToApiGetTugBoatsParameters(this));
			return { items: tugboats.map(x => mapper.mapToTugBoatsItem(x)), totalCount };
		},
		async updateArchiveStatus(id: string) {
			if(!id)
				throw new NotDefinedException("id");
			
			const isArchived = this.listing.items.find(x => x.id === id)!.isArchived;
			
			if(isArchived)
				await enterprisePropertyController.unarchiveTugboat(id);
			else
				await enterprisePropertyController.archiveTugboat(id);
			
			await this.updateListingItems();
			
			alertService.addSuccess(AlertKeys.STATUS_SUCCESS_UPDATED);
		}
	}
});
