import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { EnterprisePropertyController } from "@rmp/enterprise/api/enterpriseProperty";
import { useCrewboatDetailsStore } from "@rmp/enterprise/stores/property/crewboatDetails";
import { ApiCrewboatTariffItem } from "@rmp/core/api/types/enterpriseProperty/apiCrewboatTariffs";
import { useCrewboatDetailsTariffsBreadcrumb } from "@rmp/enterprise/stores/property/crewboatDetails/tariffs/composables/useCrewboatDetailsTariffsBreadcrumb";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { useAppStore } from "@rmp/core/stores/appStore";

const abortService = new AbortService();

const enterprisePropertyController = new EnterprisePropertyController(abortService);

const page = usePageStore(abortService);

export interface CrewboatDetailsTariffsState extends PageState {
	totalCount: number,
	tariffs: ApiCrewboatTariffItem[],
}

const getDefaultState = (): CrewboatDetailsTariffsState => {
	return {
		...page.getDefaultPageState(),
		totalCount: 0,
		tariffs: [] as ApiCrewboatTariffItem[],
	};
};

export const useCrewboatDetailsTariffsStore = defineStore({
	id: "crewboat-details-tariffs",
	state: (): CrewboatDetailsTariffsState => getDefaultState(),
	getters: {
		...page.getters,
		breadcrumbs() {
			const { breadcrumbs } = useCrewboatDetailsStore();
			return [...breadcrumbs, useCrewboatDetailsTariffsBreadcrumb()];
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized({ id }: { id: string }) {
			try {
				const { tariffs } = await enterprisePropertyController.getCrewboatTariffs(id);
				
				if(tariffs) {
					this.tariffs = tariffs;
				}
			} catch (ex) {
				console.error(ex);
				AlertHelper.handleGeneralRequestErrors(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
			}
		}
	}
});
