import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { EnterprisePropertyController } from "@rmp/enterprise/api/enterpriseProperty";
import router from "@rmp/enterprise/router";
import { Crewboat, CrewboatMapper } from "@rmp/core/types/enterpriseProperty/crewboat";
import HttpNotFoundException from "@rmp/core/exceptions/httpNotFoundException";
import AccessForbiddenException from "@rmp/core/exceptions/accessForbiddenException";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import { useCrewboatDetailsBreadcrumb } from "@rmp/enterprise/stores/property/crewboatDetails/composables/useCrewboatDetailsBreadcrumb";
import { useCrewboatsBreadcrumb } from "@rmp/enterprise/stores/crewboats/composables/useCrewboatsBreadcrumb";
import { ArchiveStatusState, useArchiveStatusStore } from "@rmp/core/stores/composables/archiveStatus";

const abortService = new AbortService();

const enterprisePropertyController = new EnterprisePropertyController(abortService);

const page = usePageStore(abortService);
const archiveStatusStore = useArchiveStatusStore();

export interface CrewboatDetailsState extends PageState, ArchiveStatusState {
	details: Crewboat;
}

const getDefaultState = (): CrewboatDetailsState => {
	return {
		...page.getDefaultPageState(),
		...archiveStatusStore.getDefaultState(),
		details: {} as Crewboat,
	};
};

export const useCrewboatDetailsStore = defineStore({
	id: "crewboat-details",
	state: (): CrewboatDetailsState => getDefaultState(),
	getters: {
		...page.getters,
		...archiveStatusStore.getters,
		breadcrumbs(state: CrewboatDetailsState) {
			return [
				useCrewboatsBreadcrumb(),
				useCrewboatDetailsBreadcrumb(state.details.name),
			];
		}
	},
	actions: {
		...page.actions,
		...archiveStatusStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			await this.fetch(id);
		},
		async fetch(id: string) {
			try {
				const crewboat = await enterprisePropertyController.getCrewboat(id);
				
				this.details = CrewboatMapper.map(crewboat);
			} catch (ex) {
				const { setPageModeNotFound, setPageModeAccessForbidden } = useAppStore();
				
				switch (ex.constructor) {
					case HttpNotFoundException:
						setPageModeNotFound();
						break;
					case AccessForbiddenException:
						setPageModeAccessForbidden();
						break;
					default:
						AlertHelper.handleGeneralRequestErrors(ex);
						break;
				}
			}
		},
		async updateArchiveStatus() {
			if(!this.details.isArchived) {
				const crewboat = await enterprisePropertyController.archiveCrewboat(router.currentRoute.params.id);
				
				this.details = CrewboatMapper.map(crewboat);
			} else {
				const crewboat = await enterprisePropertyController.unarchiveCrewboat(router.currentRoute.params.id);
				
				this.details = CrewboatMapper.map(crewboat);
			}
			
			alertService.addSuccess(AlertKeys.STATUS_SUCCESS_UPDATED);
		}
	}
});
