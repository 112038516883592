import { MetricsByCostState } from "@rmp/enterprise/stores/metrics/byCost/index";
import { ApiGetMetricsByMoneyParameters } from "@rmp/core/api/types/metrics/apiGetMetricsByMoneyParameters";

export default class MetricsByCostMapperProfile {
	constructor() {
	}
	
	mapToApiGetMetricsByCostParameters(source: MetricsByCostState): ApiGetMetricsByMoneyParameters {
		return {
			from: source.filter.periodStart || source.periods.find(x => source.filter.period === x.value)?.start || "",
			to: source.filter.periodEnd || source.periods.find(x => source.filter.period === x.value)?.end || "",
			divisionIds: source.filter.divisionIds
		};
	}
}
