import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { useServicesBreadcrumb } from "@rmp/enterprise/stores/services/composables/useServicesBreadcrumb";
import { ApiServiceDocumentKindEnum } from "@rmp/core/api/types/service/document/ApiServiceDocumentKindEnum";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import router from "@rmp/enterprise/router";
import { RouteNames } from "@rmp/enterprise/router/routes";
import { ServiceDocumentMapper } from "@rmp/core/types/services/serviceDocument";
import { ServiceController } from "@rmp/enterprise/api/service";
import { GeodeticHydrographicServiceController } from "@rmp/enterprise/api/service/geodeticHydrographic";
import {
	GeodeticHydrographicServiceApplication,
	GeodeticHydrographicServiceApplicationMapper
} from "@rmp/core/types/services/geodeticHydrographic/geodeticHydrographicServiceApplication";
import { useGeodeticHydrographicServiceBreadcrumb } from "@rmp/enterprise/stores/serviceDetails/geodeticHydrographic/composables/useGeodeticHydrographicServiceBreadcrumb";
import { useUploadGeodeticHydrographicServiceDocumentsBreadcrumb } from "@rmp/enterprise/stores/uploadServiceDocuments/geodeticHydrographic/composables/useUploadGeodeticHydrographicServiceDocumentsBreadcrumb";
import {
	GeodeticHydrographicServiceWorkflowStateType
} from "@rmp/core/types/services/geodeticHydrographic/GeodeticHydrographicServiceWorkflowStateType";
import GeodeticHydrographicServiceApplicationWorkflowService
	from "@rmp/core/services/serviceApplication/geodeticHydrographicServiceApplicationWorkflowService";
import { ApiGeodeticHydrographicServiceTransitionCodeEnum } from "@rmp/core/api/types/service/geodeticHydrographic/ApiGeodeticHydrographicServiceTransitionCodeEnum";
import {
	BaseUploadServiceDocumentsState,
	useBaseUploadServiceDocumentsStore
} from "@rmp/enterprise/stores/composables/baseUploadServiceDocuments";
import { formatServiceTitle } from "@rmp/core/utils/formatting";

const abortService = new AbortService();
const page = usePageStore(abortService);
const baseUploadServiceDocumentsStore = useBaseUploadServiceDocumentsStore({ abortService });

const geodeticHydrographicTransitionService = new GeodeticHydrographicServiceApplicationWorkflowService();

const geodeticHydrographicServiceController = new GeodeticHydrographicServiceController(abortService);
const serviceController = new ServiceController(abortService);

export interface UploadGeodeticHydrographicServiceDocumentsState extends PageState, BaseUploadServiceDocumentsState {
	service: GeodeticHydrographicServiceApplication;
}

const getDefaultState = (): UploadGeodeticHydrographicServiceDocumentsState => {
	return {
		...page.getDefaultPageState(),
		...baseUploadServiceDocumentsStore.getDefaultState(),
		service: {} as GeodeticHydrographicServiceApplication
	};
};

export const useUploadGeodeticHydrographicServiceDocumentsStore = defineStore({
	id: "upload-geodetic-hydrographic-service-documents",
	state: (): UploadGeodeticHydrographicServiceDocumentsState => getDefaultState(),
	getters: {
		...page.getters,
		...baseUploadServiceDocumentsStore.getters,
		breadcrumbs(state: UploadGeodeticHydrographicServiceDocumentsState) {
			return [
				useServicesBreadcrumb(),
				useGeodeticHydrographicServiceBreadcrumb(state.title, { id: state.service.id }),
				useUploadGeodeticHydrographicServiceDocumentsBreadcrumb()
			];
		},
		targetState(state: UploadGeodeticHydrographicServiceDocumentsState) {
			if(!state.service.workflowState) return;
			
			switch (state.service.workflowState.alias) {
				case GeodeticHydrographicServiceWorkflowStateType.SENT:
					return GeodeticHydrographicServiceWorkflowStateType.APPROVED_BY_NGO_SERVICE_EMPLOYEE;
				case GeodeticHydrographicServiceWorkflowStateType.APPROVED_BY_FLEET_OPERATIONS_SERVICE_EMPLOYEE:
					return GeodeticHydrographicServiceWorkflowStateType.INITIALLY_SIGNED_BY_ENTERPRISE;
				case GeodeticHydrographicServiceWorkflowStateType.PROVIDED:
					return GeodeticHydrographicServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED;
				case GeodeticHydrographicServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED:
					return GeodeticHydrographicServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE;
				default:
					throw new Error();
			}
		},
		signAvailable(state: UploadGeodeticHydrographicServiceDocumentsState) {
			if(!state.service.workflowState) return;
			
			switch (state.service.workflowState.alias) {
				case GeodeticHydrographicServiceWorkflowStateType.SENT:
				case GeodeticHydrographicServiceWorkflowStateType.PROVIDED:
					return false;
				case GeodeticHydrographicServiceWorkflowStateType.APPROVED_BY_FLEET_OPERATIONS_SERVICE_EMPLOYEE:
				case GeodeticHydrographicServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED:
					return true;
				default:
					throw new Error();
			}
		},
		isOnlySignMode(state: UploadGeodeticHydrographicServiceDocumentsState) {
			return [
				GeodeticHydrographicServiceWorkflowStateType.APPROVED_BY_FLEET_OPERATIONS_SERVICE_EMPLOYEE,
				GeodeticHydrographicServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED
			].includes(state.service.workflowState?.alias);
		},
		isComplete(state: UploadGeodeticHydrographicServiceDocumentsState) {
			return state.newDocuments.every(x => x.meta);
		}
	},
	actions: {
		...page.actions,
		...baseUploadServiceDocumentsStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			await this.fetch(id);
			this.initializeDocuments();
			
			await this.initializeBaseUploadServiceDocumentsStore(this.service.type);
		},
		initializeDocuments() {
			switch (this.service.workflowState.alias) {
				case GeodeticHydrographicServiceWorkflowStateType.SENT:
					[ApiServiceDocumentKindEnum.AGREEMENT].forEach(kind => this.newDocuments.push(this.createDocument(kind)));
					break;
				case GeodeticHydrographicServiceWorkflowStateType.APPROVED_BY_FLEET_OPERATIONS_SERVICE_EMPLOYEE:
					break;
				case GeodeticHydrographicServiceWorkflowStateType.PROVIDED:
					[
						ApiServiceDocumentKindEnum.SERVICE_ACCEPTANCE_CERTIFICATE, ApiServiceDocumentKindEnum.INVOICE
					].forEach(kind => this.newDocuments.push(this.createDocument(kind)));
					break;
				case GeodeticHydrographicServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED:
					break;
				default:
					throw new Error();
			}
		},
		async fetch(serviceId: string) {
			try {
				let { serviceApplication } = await geodeticHydrographicServiceController.getService(serviceId);
				
				this.service = GeodeticHydrographicServiceApplicationMapper.map(serviceApplication);
				this.title = formatServiceTitle(this.service.type, this.service.applicationNumber);
				
				if(this.isOnlySignMode) {
					const { documents } = await serviceController.getDocuments(serviceId);
					
					this.documents = documents.map(x => ServiceDocumentMapper.map(x, this.title));
				}
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		},
		async approveSignature() {
			this.approving = true;
			
			try {
				await geodeticHydrographicServiceController.updateStatusByTransition(this.id!,
					geodeticHydrographicTransitionService.getTransition(this.service.workflowState.alias,
						this.targetState!) as ApiGeodeticHydrographicServiceTransitionCodeEnum);
				
				this.service.workflowState.alias = this.targetState!;
				
				alertService.addSuccess(AlertKeys.DOCUMENTS_SENT);
				
				await router.push({ name: RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE, params: { id: this.id } });
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.approving = false;
			}
		}
	}
});
