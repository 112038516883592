import { difference } from "@rmp/core/utils/difference";
import { z, ZodRawShape } from "zod";
import { RegisterState } from "@rmp/core/stores/composables/register/useRegisterStore";
import { SortingOrderTypeEnum } from "@rmp/core/types/SortingOrderTypeEnum";

type ParameterParsers = {
	parseFilterParameters?: (query: { [key: string]: any }) => Record<string, any>
}


//Данный сервис отвечает за реализацию синхронизации между стейтом и URL, тесно связан с useRegisterStore и usePageStore
//Вторым аргументом передается объект, имеющий функцию parseParameters, которая отвечает за то, как будут парсится дополнительные параметры из URL 
//Флоу работы синхронизации стейта и URL: 
// 1)В initializeRegister(RegisterStore) мы синхронизируем URL со стейтом
// 2)Синхронизируем стейт с URL
// 3)В дело вступают watchers, которые следят за последующими изменениями состояния URL и изменяют под него стейт 
export const useRouteQueryService = <Shape extends ZodRawShape>(shape: Shape, parameterParsers: ParameterParsers = {} as ParameterParsers) => {
	// Схема отвечает за валидацию стейта, имеет 3 дефолтных значения, которые всегда используются при запросах множества объектов	
	const schemaRouteQueryService = z.object({
		page: z.coerce.number().default(1),
		sortDirection: z.nativeEnum(SortingOrderTypeEnum).default(SortingOrderTypeEnum.DESCENDING),
		query: z.coerce.string().default(''),
	}).extend(shape)
	
	const defaultRouteQuery = schemaRouteQueryService.parse({})
	
	const mapStateToQuery = (state: RegisterState<any, any>) => {
		return schemaRouteQueryService.parse({
			page: state.paging.page,
			sort: state.sorting.type,
			sortDirection: state.sorting.order,
			query: state.search.query,
			...state.filter
		})
	}
	
	//Используется при синхронизации роута и стейта(Берем данные из стейта и делаем на их основе новый URL)
	const resolveRouteQueryDictionary = (state: RegisterState<any, any>) => {
		const query = mapStateToQuery(state);
		
		return difference(defaultRouteQuery, query);
	}
	
	// В результате работы данной функции мы получим разницу между схемой текущего стейта и объекта, который мы получим на основе парсинга данных из URL в объект  
	const hasRouteChanges = async (state: RegisterState<any, any>, route: Record<string, any>):  Promise<Record<string, any>> => {
		const stateQuery = mapStateToQuery(state);
		const routeQuery = await resolveRouteQuery(route);
		
		return difference(routeQuery, stateQuery);
	}
	
	// Парсим данные из стейта, с учетом дополнительных параметров
	const resolveRouteQuery = async (query: Record<string, any>)=> {
		const parseFilterParameters = parameterParsers.parseFilterParameters
		try {
			const result = {
				...defaultRouteQuery,
				...query,
				...parseFilterParameters?.(query)
			};
			
			await schemaRouteQueryService.parseAsync(result);
			
			return result;
		} catch (e) {
			console.error(e);
			return defaultRouteQuery;
		}
	}
	
	return {
		resolveRouteQueryDictionary,
		hasRouteChanges,
		resolveRouteQuery,
		defaultRouteQuery,
	}
}

