import { TrustStatusType } from "@rmp/core/types/counterpartyEmployee/trust/TrustStatusType";
import { convertIsoToNumber } from "@rmp/core/utils/dates";
import ApiCounterpartyEmployeeTrustPersisted from "@rmp/core/api/types/counterparty/trust/apiCounterpartyEmployeeTrustPersisted";
import { CertificateInfo } from "@rmp/core/types/certificateInfo";
import { TrustActionerKind } from "@rmp/core/types/counterpartyEmployee/trust/TrustActionerKind";

export interface CounterpartyEmployeeTrust {
	id: string;
	expireAt: number;
	tempFileId: string;
	fileId: string;
	isSigned: boolean;
	acceptedAt: number;
	acceptorOfficeEmployeeId: string;
	declinedAt: number;
	declinerOfficeEmployeeId: string;
	status: TrustStatusType;
	canceledAt: number;
	cancellerOfficeEmployeeId: string;
	declineReason: string;
	actionerFullName: string;
	certificateInfo?: CertificateInfo;
	trustActionerKind?: TrustActionerKind;
}

export class CounterpartyEmployeeTrustMapper {
	static map(source: ApiCounterpartyEmployeeTrustPersisted): CounterpartyEmployeeTrust {
		return {
			...source.trust,
			id: source.id,
			expireAt: convertIsoToNumber(source.trust.expireAt),
			tempFileId: "",
			actionerFullName: "",
			acceptedAt: convertIsoToNumber(source.trust.acceptedAt),
			declinedAt: convertIsoToNumber(source.trust.declinedAt),
			canceledAt: convertIsoToNumber(source.trust.canceledAt),
			certificateInfo: undefined,
			trustActionerKind: undefined
		}
	}
}
