import AbortService from "@rmp/core/services/abortService";
import { urls } from "@rmp/enterprise/api/config/urls";
import { ApiCounterpartyBoatPersisted } from "@rmp/core/api/types/counterpartyBoat/apiCounterpartyBoat";
import BaseEnterpriseController from "@rmp/enterprise/api/base/baseEnterpriseController";

export class BoatController extends BaseEnterpriseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getBoat = async (id: string) => {
		return await this.get<{ counterpartyBoatPersisted: ApiCounterpartyBoatPersisted }>(urls.boat.$id, { id });
	};
}
