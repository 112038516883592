import { defineStore } from "pinia";
import NotImplementedException from "@rmp/core/exceptions/notImplementedException";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import {
	UploadAgreementDocumentsStepTypeEnum
} from "@rmp/enterprise/stores/uploadAgreementDocuments/steps/shared/UploadAgreementDocumentsStepTypeEnum";
import { useDurationStepStore } from "@rmp/enterprise/stores/uploadAgreementDocuments/steps/duration";
import { useDocumentStepStore } from "@rmp/enterprise/stores/uploadAgreementDocuments/steps/document";
import { useAgreementsBreadcrumb } from "@rmp/enterprise/stores/agreements/composables/useAgreementsBreadcrumb";
import { useAgreementDetailsBreadcrumb } from "@rmp/enterprise/stores/agreementDetails/composables/useAgreementDetailsBreadcrumb";
import {
	useUploadAgreementDocumentsBreadcrumb
} from "@rmp/enterprise/stores/uploadAgreementDocuments/composables/useUploadAgreementDocumentsBreadcrumb";
import Breadcrumb from "@rmp/core/stores/composables/page/types/breadcrumb";
import { Agreement, AgreementMapper } from "@rmp/core/types/agreement/agreement";
import router from "@rmp/enterprise/router";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { AgreementController } from "@rmp/enterprise/api/agreement";
import { usePaymentMethodStepStore } from "@rmp/enterprise/stores/uploadAgreementDocuments/steps/paymentMethod";
import { formatAgreementTitle } from "@rmp/core/utils/formatting";

const abortService = new AbortService();
const page = usePageStore(abortService);

const agreementController = new AgreementController(abortService);

export interface UploadAgreementDocumentsState extends PageState {
	title: string;
	agreement: Agreement;
	currentStep: UploadAgreementDocumentsStepTypeEnum;
}

const getDefaultState = (): UploadAgreementDocumentsState => {
	return {
		...page.getDefaultPageState(),
		title: "",
		agreement: {} as Agreement,
		currentStep: UploadAgreementDocumentsStepTypeEnum.DURATION
	};
};

export const useUploadAgreementDocumentsStore = defineStore({
	id: "upload-agreement-documents",
	state: (): UploadAgreementDocumentsState => getDefaultState(),
	getters: {
		...page.getters,
		steps(): UploadAgreementDocumentsStepTypeEnum[] {
			return [
				UploadAgreementDocumentsStepTypeEnum.DURATION,
				UploadAgreementDocumentsStepTypeEnum.DOCUMENTS,
				UploadAgreementDocumentsStepTypeEnum.PAYMENT_METHOD
			];
		},
		nextStepAvailable(state): boolean {
			switch (state.currentStep) {
				case UploadAgreementDocumentsStepTypeEnum.DOCUMENTS:
				{
					const { completed } = useDocumentStepStore();
					return completed;
				}
				case UploadAgreementDocumentsStepTypeEnum.DURATION:
				{
					const { completed } = useDurationStepStore();
					return completed;
				}
				case UploadAgreementDocumentsStepTypeEnum.PAYMENT_METHOD:
				{
					const { completed } = usePaymentMethodStepStore();
					return completed;
				}
				default:
					throw new NotImplementedException("nextStepAvailable");
			}
		},
		prevStepAvailable(): boolean {
			return this.currentStepIndex !== 0;
		},
		currentStepIndex(): number {
			return this.steps.indexOf(this.currentStep);
		},
		breadcrumbs(state: UploadAgreementDocumentsState): Breadcrumb[] {
			return [
				useAgreementsBreadcrumb(),
				useAgreementDetailsBreadcrumb(state.title),
				useUploadAgreementDocumentsBreadcrumb()
			];
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized() {
			await this.fetch();
		},
		async fetch() {
			try {
				let agreement = await agreementController.getAgreement(router.currentRoute.params.id);
				
				this.agreement = AgreementMapper.map(agreement);
				this.title = formatAgreementTitle(this.agreement.serviceKind, this.agreement.agreementNumber);
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		},
		moveToNextStep() {
			this.currentStep = this.steps[this.currentStepIndex + 1];
		},
		moveToPrevStep() {
			this.currentStep = this.steps[this.currentStepIndex - 1];
		}
	}
});
