import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { Store } from "pinia";
import AbortService from "@rmp/core/services/abortService";
import { ApiBranchPersisted } from "@rmp/core/api/types/divisions/branch/apiBranch";
import { ApiDepartmentPersisted } from "@rmp/core/api/types/divisions/department/apiDepartment";
import { BranchController } from "@rmp/enterprise/api/divisions/branch";
import { DepartmentController } from "@rmp/enterprise/api/divisions/department";
import router from "@rmp/enterprise/router";
import { AxaptaController } from "@rmp/enterprise/api/axapta";
import HttpNotFoundException from "@rmp/core/exceptions/httpNotFoundException";

export interface BasePropertyGeneralState {
	id: string;
	branchId: string;
	branches: ApiBranchPersisted[];
	branchesLoading: boolean;
	branchDepartments: ApiDepartmentPersisted[];
	branchDepartmentsLoading: boolean;
	divisionId: string;
	calculationObjectCodeLoading: boolean;
	calculationObjectCode: string;
}

interface BasePropertyGeneralOptions {
	abortService: AbortService;
}

export const useBasePropertyGeneralStore = (options: BasePropertyGeneralOptions) => {
	type BasePropertyGeneralStore = Store<string, BasePropertyGeneralState, {}, {
		[key: string]: any,
		fetchBranchDepartments(branchId: string): void
	}>;
	
	const branchController = new BranchController(options.abortService);
	const departmentController = new DepartmentController(options.abortService);
	const axaptaController = new AxaptaController(options.abortService);
	
	const getDefaultState = (): BasePropertyGeneralState => {
		return {
			id: "",
			branchId: "",
			branches: [] as ApiBranchPersisted[],
			branchesLoading: false,
			branchDepartments: [] as ApiDepartmentPersisted[],
			branchDepartmentsLoading: false,
			divisionId: "",
			calculationObjectCodeLoading: false,
			calculationObjectCode: ""
		};
	};
	
	const getters = {};
	
	const actions = {
		async initializeBasePropertyGeneralStore(this: BasePropertyGeneralStore, divisionId: string) {
			this.id = router.currentRoute.params.id;
			
			const tasks: Promise<any>[] = [this.fetchCalculationObjectCode()];
			
			if(divisionId) {
				tasks.push(departmentController.getDepartment(divisionId));
				
				const [_, { division }] = await Promise.all(tasks);
				const branchId = division.parentId;
				
				if(branchId) {
					this.branchId = branchId;
					this.divisionId = divisionId;
					await this.fetchBranchDepartments(branchId);
				}
			} else {
				await Promise.all(tasks);
			}
		},
		async fetchBranches(this: BasePropertyGeneralState) {
			this.branchesLoading = true;
			
			try {
				const { items } = await branchController.getBranches();
				
				this.branches = items;
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.branchesLoading = false;
			}
		},
		async fetchBranchDepartments(this: BasePropertyGeneralState, branchId: string) {
			this.branchDepartmentsLoading = true;
			
			try {
				let { items } = await departmentController.getDepartments(branchId);
				
				this.branchDepartments = items;
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.branchDepartmentsLoading = false;
			}
		},
		async fetchCalculationObjectCode(this: BasePropertyGeneralState) {
			this.calculationObjectCodeLoading = true;

			try {
				let { calculationObjectCode } = await axaptaController.getCalculationObjectCode(this.id);

				this.calculationObjectCode = calculationObjectCode;
			} catch (ex) {
				if(ex.constructor === HttpNotFoundException)
					return;

				console.error(ex);

				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);

				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.calculationObjectCodeLoading = false;
			}
		}
	};
	
	return { getters, actions, getDefaultState };
};
