import { ServiceTypeEnum } from "@rmp/core/types/services/ServiceTypeEnum";
import { ApiMetricsByMoneyItem } from "@rmp/core/api/types/metrics/apiMetricsByMoneyItem";
import ServiceMapperProfile from "@rmp/core/api/mappers/service";

export interface MetricsByMoneyItem {
	serviceType: ServiceTypeEnum;
	minAmountTotal: number;
	maxAmountTotal: number;
	minAmountPure: number;
	maxAmountPure: number;
	avgAmountTotal: number;
	avgAmountPure: number;
	sumAmountTotal: number;
	sumAmountPure: number;
}

export class MetricsByMoneyItemMapper {
	static map(source: ApiMetricsByMoneyItem): MetricsByMoneyItem {
		return {
			...source,
			serviceType: ServiceMapperProfile.mapServiceType(source.serviceType)
		}
	}
}
