import { defineStore } from "pinia";
import NotImplementedException from "@rmp/core/exceptions/notImplementedException";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useServicesBreadcrumb } from "@rmp/enterprise/stores/services/composables/useServicesBreadcrumb";
import { i18n } from "@rmp/enterprise/plugins";
import { InformationServiceController } from "@rmp/enterprise/api/service/information";
import {
	InformationTariffAssignmentStepTypeEnum
} from "@rmp/enterprise/stores/assignment/informationTariff/steps/shared/InformationTariffAssignmentStepTypeEnum";
import {
	InformationServiceApplication,
	InformationServiceApplicationMapper
} from "@rmp/core/types/services/information/informationServiceApplication";
import {
	useInformationTariffAssignmentBreadcrumb
} from "@rmp/enterprise/stores/assignment/informationTariff/composables/useInformationTariffAssignmentBreadcrumb";
import { useInformationTariffStepStore } from "@rmp/enterprise/stores/assignment/informationTariff/steps/tariff";
import {
	useInformationServiceBreadcrumb
} from "@rmp/enterprise/stores/serviceDetails/information/composables/useInformationServiceBreadcrumb";
import { useInformationTimeStepStore } from "@rmp/enterprise/stores/assignment/informationTariff/steps/time";
import { formatServiceTitle } from "@rmp/core/utils/formatting";

const abortService = new AbortService();
const informationServiceController = new InformationServiceController(abortService);

const page = usePageStore(abortService);

export interface InformationTariffAssignmentState extends PageState {
	id: string;
	title: string;
	currentStep: InformationTariffAssignmentStepTypeEnum;
	service: InformationServiceApplication;
}

const getDefaultState = (): InformationTariffAssignmentState => {
	return {
		...page.getDefaultPageState(),
		id: "",
		title: "",
		currentStep: InformationTariffAssignmentStepTypeEnum.TARIFF,
		service: {} as InformationServiceApplication
	};
};

export const useInformationTariffAssignmentStore = defineStore({
	id: "information-tariff-assignment",
	state: (): InformationTariffAssignmentState => getDefaultState(),
	getters: {
		...page.getters,
		steps(): InformationTariffAssignmentStepTypeEnum[] {
			return [
				InformationTariffAssignmentStepTypeEnum.TARIFF,
				InformationTariffAssignmentStepTypeEnum.TIME
			];
		},
		nextStepAvailable(state): boolean {
			switch (state.currentStep) {
				case InformationTariffAssignmentStepTypeEnum.TARIFF:
				{
					const { completed } = useInformationTariffStepStore();
					return completed;
				}
				case InformationTariffAssignmentStepTypeEnum.TIME:
				{
					const { completed } = useInformationTimeStepStore();
					return completed;
				}
				default:
					throw new NotImplementedException("nextStepAvailable");
			}
		},
		prevStepAvailable(): boolean {
			return this.currentStepIndex !== 0;
		},
		currentStepIndex(): number {
			return this.steps.indexOf(this.currentStep);
		},
		breadcrumbs(state: InformationTariffAssignmentState) {			
			return [
				useServicesBreadcrumb(),
				useInformationServiceBreadcrumb(state.title, { id: state.id }),
				useInformationTariffAssignmentBreadcrumb()
			];
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			
			await this.fetch();
			
			this.title = formatServiceTitle(this.service.type, this.service.applicationNumber);
		},
		async fetch() {
			let { informationServiceApplication } = await informationServiceController.getService(this.id!);

			this.service = InformationServiceApplicationMapper.map(informationServiceApplication);
		},
		moveToNextStep() {
			this.currentStep = this.steps[this.currentStepIndex + 1];
		},
		moveToPrevStep() {
			this.currentStep = this.steps[this.currentStepIndex - 1];
		}
	}
});
