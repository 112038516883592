import { RouteNames } from "@rmp/enterprise/router/routes";
import { i18n } from "@rmp/enterprise/plugins";
import { useBreadcrumb } from "@rmp/core/stores/composables/breadcrumb/useBreadcrumb";
import routeToPermissionsMap from "@rmp/enterprise/router/routeToPermissionsMap";

export function useAgreementsBreadcrumb() {
	return useBreadcrumb({
		route: {
			name: RouteNames.AGREEMENTS
		},
		text: i18n.t(`navigation.breadcrumbs.${RouteNames.AGREEMENTS}`).toString(),
		routeToPermissionsMap
	});
}
