import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import router from "@rmp/enterprise/router";
import { i18n } from "@rmp/core/plugins";
import ApiLegalPersonCounterparty from "@rmp/core/api/types/counterparty/apiLegalPersonCounterparty";
import { CounterpartyBankAccount, CounterpartyBankAccountMapper } from "@rmp/core/types/counterparty/counterpartyBankAccount";
import ApiCounterpartyPersistedBase from "@rmp/core/api/types/counterparty/apiCounterpartyPersistedBase";
import ApiLegalEntityCounterparty from "@rmp/core/api/types/counterparty/apiLegalEntityCounterparty";
import { useCounterpartiesBreadcrumb } from "@rmp/enterprise/stores/counterparties/composables/useCounterpartiesBreadcrumb";
import { useCounterpartyDetailsBreadcrumb } from "@rmp/enterprise/stores/counterpartyDetails/composables/useCounterpartyDetailsBreadcrumb";
import {
	useCounterpartyBankAccountBreadcrumb
} from "@rmp/enterprise/stores/counterpartyBankAccount/composables/useCounterpartyBankAccountBreadcrumb";
import { useCounterpartyDetailsBankAccountsBreadcrumb } from "@rmp/enterprise/stores/counterpartyDetails/bankAccounts/composables/useCounterpartyDetailsBankAccountsBreadcrumb";
import { CounterpartyController } from "@rmp/enterprise/api/counterparty";

const abortService = new AbortService();
const counterpartyController = new CounterpartyController(abortService);

const page = usePageStore(abortService);

export interface CounterpartyBankAccountState extends PageState {
	id: string;
	account: CounterpartyBankAccount;
	counterpartyId: string;
	bankAccountId: string;
	counterpartyTitle: string;
	loading: boolean;
}

const getDefaultState = (): CounterpartyBankAccountState => {
	return {
		...page.getDefaultPageState(),
		id: "",
		account: {} as CounterpartyBankAccount,
		counterpartyId: "",
		bankAccountId: "",
		counterpartyTitle: "",
		loading: false
	};
};

export const useCounterpartyBankAccountStore = defineStore({
	id: "counterparty-bank-account",
	state: (): CounterpartyBankAccountState => getDefaultState(),
	getters: {
		...page.getters,
		breadcrumbs(state: CounterpartyBankAccountState) {
			return [
				useCounterpartiesBreadcrumb(),
				useCounterpartyDetailsBreadcrumb(state.counterpartyTitle, { id: state.counterpartyId }),
				useCounterpartyDetailsBankAccountsBreadcrumb({ id: state.counterpartyId }),
				useCounterpartyBankAccountBreadcrumb({ id: state.bankAccountId })
			];
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized() {
			this.counterpartyId = router.currentRoute.params.counterpartyId;
			this.bankAccountId = router.currentRoute.params.accountId;
			
			await this.fetch();
		},
		async fetch() {
			this.loading = true;
			
			try {
				const tasks: Promise<any>[] = [
					counterpartyController.getCounterparty<ApiCounterpartyPersistedBase>(this.counterpartyId, ApiCounterpartyPersistedBase)
				];
				
				if(this.bankAccountId)
					tasks.push(counterpartyController.getCounterpartyAccount(this.counterpartyId, this.bankAccountId));
				
				const [{ counterparty }, account] = await Promise.all(tasks);
				
				if(counterparty instanceof ApiLegalEntityCounterparty) {
					this.counterpartyTitle = counterparty.shortName;
				} else if(counterparty instanceof ApiLegalPersonCounterparty) {
					this.counterpartyTitle = `${i18n.t("common.legalPerson")} "${counterparty.fullName}"`;
				}
				
				if(account) {
					const { bankAccount } = account;
					this.account = CounterpartyBankAccountMapper.map(bankAccount);
				}
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.loading = false;
			}
		}
	}
});
