import { difference } from "@rmp/core/utils/difference";
import { Dictionary } from "vue-router/types/router";
import { plainToInstance } from "class-transformer";
import { validateOrReject } from "class-validator";
import RouteQueryService from "@rmp/core/services/route/routeQueryService";
import MetricsByCostRouteQuery from "@rmp/enterprise/stores/metrics/byCost/types/metricsByCostRouteQuery";
import { MetricsByCostState } from "@rmp/enterprise/stores/metrics/byCost";
import { MetricsPeriodType } from "@rmp/core/types/metrics/metricsPeriodType";
import { parseArrayParameter } from "@rmp/core/utils/query";

export default class MetricsByCostRouteQueryService implements RouteQueryService {
	defaultRouteQuery: MetricsByCostRouteQuery;
	
	constructor(defaultRouteQuery: MetricsByCostRouteQuery) {
		this.defaultRouteQuery = defaultRouteQuery;
	}
	
	private mapStateToQuery(state: MetricsByCostState) {
		return new MetricsByCostRouteQuery(
			state.paging.page,
			state.sorting.type,
			state.sorting.order,
			state.search.query,
			state.filter.period,
			state.filter.periodStart,
			state.filter.periodEnd,
			state.filter.divisionIds
		);
	}
	
	public resolveRouteQueryDictionary(state: MetricsByCostState) {
		let query = this.mapStateToQuery(state);
		
		return difference(this.defaultRouteQuery, query);
	}
	
	public async hasRouteChanges(state: MetricsByCostState, route: Dictionary<any>): Promise<{ [key: string]: any }> {
		let stateQuery = this.mapStateToQuery(state);
		let routeQuery = await this.resolveRouteQuery(route);
		
		return difference(routeQuery, stateQuery);
	}
	
	public async resolveRouteQuery(query: Dictionary<any>) {
		try {
			let result = plainToInstance(MetricsByCostRouteQuery, {
				...this.defaultRouteQuery,
				...query,
				period: query.period || MetricsPeriodType.CURRENT_MONTH,
				divisionIds: parseArrayParameter(query.divisionIds)
			}, { enableImplicitConversion: true });
			
			await validateOrReject(result);
			
			return result;
		} catch (e) {
			console.error(e);
			return this.defaultRouteQuery;
		}
	}
}
