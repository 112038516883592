import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { SortingOrderTypeEnum } from "@rmp/core/types/SortingOrderTypeEnum";
import { RegisterState, useRegisterStore } from "@rmp/core/stores/composables/register/useRegisterStore";
import { ApplicationFilterStatusType } from "@rmp/core/types/ApplicationFilterStatusType";
import { BoatApplicationsFilter } from "@rmp/enterprise/stores/boatApplications/types/boatApplicationsFilter";
import BoatApplicationsRouteQuery from "@rmp/enterprise/stores/boatApplications/types/boatApplicationsRouteQuery";
import { BoatApplicationController } from "@rmp/enterprise/api/boatApplication";
import BoatApplicationsMapperProfile from "@rmp/enterprise/stores/boatApplications/mapper";
import BoatApplicationsRouteQueryService
	from "@rmp/enterprise/stores/boatApplications/services/boatApplicationsRouteQueryService";
import { RouteNames } from "@rmp/enterprise/router/routes";
import router from "@rmp/enterprise/router";
import { BoatApplicationsItem, BoatApplicationsItemMapper } from "@rmp/core/types/boatApplication/boatApplicationsItem";

const abortService = new AbortService();
const boatApplicationController = new BoatApplicationController(abortService);

const mapper = new BoatApplicationsMapperProfile();

export interface BoatApplicationsState extends PageState, RegisterState<BoatApplicationsItem> {
	filter: BoatApplicationsFilter;
}

const defaultRouteQuery = new BoatApplicationsRouteQuery(1,
	"createdAt",
	SortingOrderTypeEnum.DESCENDING,
	"",
	ApplicationFilterStatusType.ALL);

const routeQueryService = new BoatApplicationsRouteQueryService(defaultRouteQuery);

const page = usePageStore(abortService);

const register = useRegisterStore<BoatApplicationsItem, BoatApplicationsFilter>({
	routeName: RouteNames.BOAT_APPLICATIONS,
	routeQueryService,
	routeQuery: defaultRouteQuery,
	getRouter: () => router
});

const getDefaultState = (): BoatApplicationsState => {
	return {
		...page.getDefaultPageState(),
		...register.getDefaultRegisterState(),
		filter: {
			status: ApplicationFilterStatusType.ALL
		}
	};
};

export const useBoatApplicationsStore = defineStore({
	id: "boat-applications",
	state: (): BoatApplicationsState => getDefaultState(),
	getters: {
		...register.getters,
		...page.getters,
		filteredItems(state: BoatApplicationsState): BoatApplicationsItem[] {
			return state.listing.items;
		}
	},
	actions: {
		...page.actions,
		...register.actions,
		async beforeInitialized() {
			await this.initializeRegister();
		},
		patchStateWithRouteQuery(query: BoatApplicationsRouteQuery) {
			this.patchRegisterStateWithRouteQuery(query);
			this.$patch((state) => {
				state.filter.status = query.status;
			});
		},
		async fetchRegisterItems() {
			let { counterpartyBoatApplications, totalCount } = await boatApplicationController.getBoatApplications(
				mapper.mapToApiGetBoatApplicationsParameters(this)
			);
			return {
				totalCount,
				items: counterpartyBoatApplications.map(x => BoatApplicationsItemMapper.map(x))
			};
		}
	}
});
