import { useBreadcrumb } from "@rmp/core/stores/composables/breadcrumb/useBreadcrumb";
import routeToPermissionsMap from "@rmp/enterprise/router/routeToPermissionsMap";
import { RouteNames } from "@rmp/enterprise/router/routes";
import { i18n } from "@rmp/enterprise/plugins";

export function useInformationTariffAssignmentBreadcrumb() {
	return useBreadcrumb({
		route: { name: RouteNames.INFORMATION_SERVICE_TARIFF_ASSIGNMENT },
		text: i18n.tc(`navigation.breadcrumbs.${RouteNames.INFORMATION_SERVICE_TARIFF_ASSIGNMENT}`),
		routeToPermissionsMap
	});
}
