import { ApiMetricsByPropertyDetailedItem } from "@rmp/core/api/types/metrics/apiMetricsByPropertyDetailedItem";
import { parseISODate } from "@rmp/core/utils/dates";
import { ServiceTypeEnum } from "@rmp/core/types/services/ServiceTypeEnum";
import ServiceMapperProfile from "@rmp/core/api/mappers/service";

export interface MetricsByPropertyDetailedItem {
	serviceType: ServiceTypeEnum;
	dateTime: Date | null;
	portId: string;
	portName: string;
	moneyPure: number;
	moneyWithTax: number;
	workflowStateName: string;
	workflowStateAlias: string;
}

export class MetricsByPropertyDetailedItemMapper {
	static map(source: ApiMetricsByPropertyDetailedItem): MetricsByPropertyDetailedItem {
		return {
			...source,
			dateTime: parseISODate(source.dateTime),
			serviceType: ServiceMapperProfile.mapServiceType(source.serviceType)
		}
	}
}
