import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import BranchDivisionGeneralMapperProfile from "@rmp/enterprise/stores/divisions/branch/general/mapper";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { BranchDivision } from "@rmp/enterprise/stores/divisions/branch/general/types/branchDivision";
import { useAppStore } from "@rmp/core/stores/appStore";
import { useBranchDivisionStore } from "@rmp/enterprise/stores/divisions/branch";

const abortService = new AbortService();
const page = usePageStore(abortService);

const mapper = new BranchDivisionGeneralMapperProfile();

export interface BranchDivisionGeneralState extends PageState {
	id: string | null;
	branch: BranchDivision;
}

const getDefaultState = (): BranchDivisionGeneralState => {
	return {
		...page.getDefaultPageState(),
		id: null,
		branch: {} as BranchDivision
	};
};

export const useBranchDivisionGeneralStore = defineStore({
	id: "branch-general",
	state: (): BranchDivisionGeneralState => getDefaultState(),
	getters: {
		...page.getters,
		breadcrumbs() {
			const { breadcrumbs } = useBranchDivisionStore();
			return breadcrumbs;
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			await this.fetch();
		},
		async fetch() {
			try {
				let { branch } = useBranchDivisionStore();
				this.branch = mapper.mapBranchDivision(branch);
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		}
	}
});
