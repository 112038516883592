import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { AgreementController } from "@rmp/enterprise/api/agreement";
import { useAgreementsBreadcrumb } from "@rmp/enterprise/stores/agreements/composables/useAgreementsBreadcrumb";
import { Agreement, AgreementMapper } from "@rmp/core/types/agreement/agreement";
import { useAgreementDetailsBreadcrumb } from "@rmp/enterprise/stores/agreementDetails/composables/useAgreementDetailsBreadcrumb";
import { AgreementDocument, AgreementDocumentMapper } from "@rmp/core/types/agreement/agreementDocument";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import { AgreementWorkflowStateType } from "@rmp/core/types/agreement/AgreementWorkflowStateType";
import InvalidBusinessOperationException from "@rmp/core/exceptions/invalidBusinessOperationException";
import AgreementWorkflowService from "@rmp/core/services/agreement/agreementWorkflowService";
import { ApiAgreementTransitionCodeType } from "@rmp/core/api/types/agreement/ApiAgreementTransitionCodeType";
import { formatAgreementTitle } from "@rmp/core/utils/formatting";
import router from "@rmp/enterprise/router";
import { ArchiveStatusState, useArchiveStatusStore } from "@rmp/core/stores/composables/archiveStatus";

const abortService = new AbortService();
const page = usePageStore(abortService);
const archiveStatusStore = useArchiveStatusStore();

const agreementController = new AgreementController(abortService);

const agreementWorkflowService = new AgreementWorkflowService();

export interface AgreementDetailsState extends PageState, ArchiveStatusState {
	agreement: Agreement;
	id: string;
	title: string;
	declining: boolean;
	isApproving: boolean;
	isDeclining: boolean;
	isStatusUpdating: boolean;
	documents: AgreementDocument[];
	declineReason: string;
}

const getDefaultState = (): AgreementDetailsState => {
	return {
		...page.getDefaultPageState(),
		...archiveStatusStore.getDefaultState(),
		agreement: {} as Agreement,
		id: "",
		title: "",
		declining: false,
		documents: [],
		isApproving: false,
		isDeclining: false,
		isStatusUpdating: false,
		declineReason: ""
	};
};

export const useAgreementDetailsStore = defineStore({
	id: "agreement-details",
	state: (): AgreementDetailsState => getDefaultState(),
	getters: {
		...page.getters,
		...archiveStatusStore.getters,
		breadcrumbs(state: AgreementDetailsState) {
			return [
				useAgreementsBreadcrumb(),
				useAgreementDetailsBreadcrumb(state.title)
			];
		}
	},
	actions: {
		...page.actions,
		...archiveStatusStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			await this.fetch();
		},
		async fetch() {
			try {
				const [agreement, { documents }] = await Promise.all([
					agreementController.getAgreement(this.id!),
					agreementController.getDocuments(this.id!)
				]);
				
				this.agreement = AgreementMapper.map(agreement);
				this.title = formatAgreementTitle(this.agreement.serviceKind, this.agreement.agreementNumber);
				this.documents = documents.map(x => AgreementDocumentMapper.map(x, this.title));
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		},
		async updateStatus(targetStatus: AgreementWorkflowStateType) {
			this.isStatusUpdating = true;
			
			try {
				let res = await agreementController.changeAgreementStatus(this.id!,
					agreementWorkflowService.getTransition(this.agreement.state.code, targetStatus));
				
				if(!res.isSuccess)
					throw new InvalidBusinessOperationException(`Не удалось перевести заявку в статус ${targetStatus}`, res);
				
				// TODO
				this.agreement.state.code = targetStatus;
				
				alertService.addSuccess(AlertKeys.AGREEMENT_STATUS_UPDATED);
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.isStatusUpdating = false;
			}
		},
		async decline() {
			this.isDeclining = true;
			
			try {
				let res = await agreementController.changeAgreementStatus(this.id!, ApiAgreementTransitionCodeType.REJECT, this.declineReason);
				
				if(!res.isSuccess)
					throw new InvalidBusinessOperationException(`Не удалось перевести заявку в статус ${AgreementWorkflowStateType.REJECTED}`,
						res);
				
				this.agreement.state.code = AgreementWorkflowStateType.REJECTED;
				this.agreement.rejectReason = this.declineReason;
				
				alertService.addSuccess(AlertKeys.AGREEMENT_DECLINED);
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.isDeclining = false;
			}
		},
		async updateArchiveStatus() {
			if(!this.agreement.isArchived)
				await agreementController.archive(router.currentRoute.params.id);
			else
				await agreementController.unarchive(router.currentRoute.params.id);
			
			this.agreement.isArchived = !this.agreement.isArchived;
			
			alertService.addSuccess(AlertKeys.STATUS_SUCCESS_UPDATED);
		},
	}
});
