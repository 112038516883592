import { CounterpartyEmployeeTrustStatus } from "@rmp/core/types/CounterpartyEmployeeTrustStatus";
import { useColors } from "@rmp/core/composables/useColors";

export const useStatusColor = () => {
	const { colors } = useColors();
	
	const resolveCounterpartyEmployeeTrustStatusColor = (status: CounterpartyEmployeeTrustStatus) => {
		switch (status) {
			case CounterpartyEmployeeTrustStatus.ABSENT:
			case CounterpartyEmployeeTrustStatus.OUTDATED:
				return colors.error;
			case CounterpartyEmployeeTrustStatus.REJECTED:
				return colors.warning;
			case CounterpartyEmployeeTrustStatus.REVIEW:
				return colors.secondary.lighten1;
			case CounterpartyEmployeeTrustStatus.ACTIVE:
				return colors.success;
		}
	};
	
	return { resolveCounterpartyEmployeeTrustStatusColor };
};

