import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { BranchController } from "@rmp/enterprise/api/divisions/branch";
import ApiBranch from "@rmp/core/api/types/divisions/branch/apiBranch";
import { useBranchesBreadcrumb } from "@rmp/enterprise/stores/divisions/branches/composables/useBranchesBreadcrumb";
import { useBranchBreadcrumb } from "@rmp/enterprise/stores/divisions/branch/composables/useBranchesBreadcrumb";
import { DivisionController } from "@rmp/enterprise/api/divisions";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import { ArchiveStatusState, useArchiveStatusStore } from "@rmp/core/stores/composables/archiveStatus";

const abortService = new AbortService();
const page = usePageStore(abortService);
const archiveStatusStore = useArchiveStatusStore();

const branchController = new BranchController(abortService);
const divisionController = new DivisionController(abortService);

export interface BranchDivisionState extends PageState, ArchiveStatusState {
	branch: ApiBranch;
	id: string;
	statusUpdating: boolean;
}

const getDefaultState = (): BranchDivisionState => {
	return {
		...page.getDefaultPageState(),
		...archiveStatusStore.getDefaultState(),
		branch: {} as ApiBranch,
		id: "",
		statusUpdating: false
	};
};

export const useBranchDivisionStore = defineStore({
	id: "branch",
	state: (): BranchDivisionState => getDefaultState(),
	getters: {
		...page.getters,
		...archiveStatusStore.getters,
		breadcrumbs(state) {
			return [
				useBranchesBreadcrumb(),
				useBranchBreadcrumb(state.branch.shortName)
			];
		}
	},
	actions: {
		...page.actions,
		...archiveStatusStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			await this.fetch();
		},
		async fetch() {
			try {
				let result = await branchController.getBranch(this.id!);
				this.branch = result.division;
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		},
		async updateArchiveStatus() {
			if(this.branch.isArchived)
				await divisionController.unarchive(this.id!);
			else
				await divisionController.archive(this.id!);
			
			this.branch.isArchived = !this.branch.isArchived;
			
			alertService.addSuccess(AlertKeys.BRANCH_STATUS_UPDATED);
		}
	}
});
