import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { RouteNames } from "@rmp/enterprise/router/routes";
import router from "@rmp/enterprise/router";
import { RegisterState, useRegisterStore } from "@rmp/core/stores/composables/register/useRegisterStore";
import { DepartmentController } from "@rmp/enterprise/api/divisions/department";
import DepartmentsMapperProfile from "@rmp/enterprise/stores/divisions/branch/departments/mapper";
import { DepartmentsItem } from "@rmp/enterprise/stores/divisions/branch/departments/types/departmentsItem";
import { useBranchDivisionStore } from "@rmp/enterprise/stores/divisions/branch";
import { useDepartmentsBreadcrumb } from "@rmp/enterprise/stores/divisions/branch/departments/composables/useDepartmentsBreadcrumb";
import { SortingOrderTypeEnum } from "@rmp/core/types/SortingOrderTypeEnum";
import BranchDepartmentsRouteQuery from "@rmp/enterprise/stores/divisions/branch/departments/types/branchDepartmentsRouteQuery";
import BranchDepartmentsRouteQueryService
	from "@rmp/enterprise/stores/divisions/branch/departments/services/branchDepartmentsRouteQueryService";
import {
	BranchDepartmentsFilterStatusEnum
} from "@rmp/enterprise/stores/divisions/branch/departments/types/BranchDepartmentsFilterStatusEnum";
import { DepartmentsFilter } from "@rmp/enterprise/stores/divisions/branch/departments/types/departmentsFilter";

const abortService = new AbortService();
const controller = new DepartmentController(abortService);
const mapper = new DepartmentsMapperProfile();

const defaultRouteQuery = new BranchDepartmentsRouteQuery(
	"shortName",
	SortingOrderTypeEnum.ASCENDING,
	"",
	BranchDepartmentsFilterStatusEnum.ALL
);
const routeQueryService = new BranchDepartmentsRouteQueryService(defaultRouteQuery);

const page = usePageStore(abortService);

export interface DepartmentsState extends PageState, RegisterState<DepartmentsItem, DepartmentsFilter>
{
	branchId: string;
	filter: DepartmentsFilter;
}


const register = useRegisterStore<DepartmentsItem>({
	routeName: RouteNames.BRANCH_DEPARTMENTS,
	routeQueryService,
	routeQuery: defaultRouteQuery,
	getRouter: () => router
});

const getDefaultState = (): DepartmentsState => {
	return {
		...page.getDefaultPageState(),
		...register.getDefaultRegisterState(),
		branchId: "",
		filter: {
			status: BranchDepartmentsFilterStatusEnum.ALL
		}
	};
};

export const useDepartmentsStore = defineStore({
	id: "departments",
	state: (): DepartmentsState => getDefaultState(),
	getters: {
		...register.getters,
		...page.getters,
		breadcrumbs() {
			const { breadcrumbs } = useBranchDivisionStore();
			return [...breadcrumbs, useDepartmentsBreadcrumb()];
		},
		filteredItems(state: DepartmentsState) {
			return state.listing.items;
		}
	},
	actions: {
		...page.actions,
		...register.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.branchId = id;
			await this.initializeRegister();
		},
		patchStateWithRouteQuery(query: BranchDepartmentsRouteQuery) {
			this.patchRegisterStateWithRouteQuery(query);
			this.$patch((state) => {
				state.filter.status = query.status;
			});
		},
		async fetchRegisterItems() {
			const payload = mapper.mapToApiGetDepartmentParameters(this);
			
			let { totalCount, items } = await controller.getBranchDepartments(this.branchId, payload);
			
			return {
				totalCount,
				items: items.map(x => mapper.mapFromApi(x))
			};
		}
	}
});
