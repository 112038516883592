import { parseISO } from "date-fns";
import { ApiCrewboatPersisted } from "@rmp/core/api/types/enterpriseProperty/apiCrewboat";

export interface Crewboat {
	id: string;
	createdAt: Date;
	name: string;
	callSign: string;
	imo: string;
	mmsi: string;
	flag: string;
	type: string;
	grt: string;
	exclusionGRT?: string;
	nrt: string;
	deadWeight: string;
	length: string;
	width: string;
	height: string;
	power: string;
	portOfRegistry: string;
	numberOfRegistry: string;
	yearOfBuilding?: string;
	status: string;
	isArchived: boolean;
	kind: string;
	calculatedGRT?: string;
	precipitationMaxValue?: string;
}

export class CrewboatMapper {
	static map(source: ApiCrewboatPersisted): Crewboat {
		return {
			...source,
			...source.crewboat,
			createdAt: parseISO(source.createdAt),
			grt: source.crewboat?.grt.toString(),
			exclusionGRT: source.crewboat?.exclusionGRT?.toString(),
			nrt: source.crewboat.nrt.toString(),
			deadWeight: source.crewboat.deadWeight.toString(),
			length: source.crewboat.length.toString(),
			width: source.crewboat.width.toString(),
			height: source.crewboat.height.toString(),
			power: source.crewboat.power.toString(),
			yearOfBuilding: source.crewboat?.yearOfBuilding?.toString(),
			calculatedGRT: source.crewboat?.calculatedGRT?.toString(),
			precipitationMaxValue: source.crewboat?.precipitationMaxValue?.toString()
		};
	}
}
