import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import {
	UploadAgreementDocumentsStepTypeEnum
} from "@rmp/enterprise/stores/uploadAgreementDocuments/steps/shared/UploadAgreementDocumentsStepTypeEnum";
import {
	UploadAgreementDocumentsStepState
} from "@rmp/enterprise/stores/uploadAgreementDocuments/steps/shared/uploadAgreementDocumentsStepState";
import { useDocumentStepStore } from "@rmp/enterprise/stores/uploadAgreementDocuments/steps/document";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import router from "@rmp/enterprise/router";
import { RouteNames } from "@rmp/enterprise/router/routes";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { AgreementController } from "@rmp/enterprise/api/agreement";
import { FormState, useFormStore } from "@rmp/core/stores/composables/form/useFormStore";
import { useUploadAgreementDocumentsStore } from "@rmp/enterprise/stores/uploadAgreementDocuments";
import { ApiAgreementDuration } from "@rmp/core/api/types/agreement/apiAgreementDuration";
import { formatISODate } from "@rmp/core/utils/dates";
import ApiAgreementDocumentKindMapper from "@rmp/core/api/types/agreement/ApiAgreementDocumentKindEnum";
import { useDurationStepStore } from "@rmp/enterprise/stores/uploadAgreementDocuments/steps/duration";
import { ApiAgreementPaymentMethodEnum } from "@rmp/core/api/types/agreement/ApiAgreementPaymentMethodEnum";

const abortService = new AbortService();

const agreementController = new AgreementController(abortService);

const page = usePageStore(abortService);
const form = useFormStore();

export interface PaymentMethodStepState extends UploadAgreementDocumentsStepState, PageState, FormState {
	paymentMethod: ApiAgreementPaymentMethodEnum;
}

const getDefaultState = (): PaymentMethodStepState => {
	return {
		...page.getDefaultPageState(),
		...form.getDefaultState(),
		paymentMethod: ApiAgreementPaymentMethodEnum.POST_PAYMENT,
		stepType: UploadAgreementDocumentsStepTypeEnum.PAYMENT_METHOD
	};
};

export const usePaymentMethodStepStore = defineStore({
	id: "upload-agreement-documents-payment-method-step",
	state: (): PaymentMethodStepState => getDefaultState(),
	getters: {
		...page.getters,
		...form.getters,
		completed(state) {
			return state.formValid;
		},
		breadcrumbs() {
			const { breadcrumbs } = useUploadAgreementDocumentsStore();
			return breadcrumbs;
		}
	},
	actions: {
		...page.actions,
		...form.actions,
		async beforeInitialized() {
		},
		async save() {
			const { agreement } = useUploadAgreementDocumentsStore();
			const { documents } = useDocumentStepStore();
			const { endDate, startDate } = useDurationStepStore();
			
			this.formSaving = true;
			
			try {
				await agreementController.saveDocuments(agreement.id,
					documents.filter(x => x.meta).map(x => ({
						documentKind: ApiAgreementDocumentKindMapper.mapApiAgreementDocumentKind(x.kind),
						title: x.meta!.name,
						tempFileId: x.meta!.id
					})));
				
				await Promise.all([
					agreementController.savePaymentMethod(agreement.id, this.paymentMethod),
				])

				alertService.addSuccess(AlertKeys.DOCUMENTS_SAVED);

				await router.push({ name: RouteNames.AGREEMENT_DETAILS, params: router.currentRoute.params });
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.formSaving = false;
			}
		}
	}
});
