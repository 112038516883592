import { MetricsByPropertyState } from "@rmp/enterprise/stores/metrics/byProperty/index";
import { ApiGetMetricsByPropertyParameters } from "@rmp/core/api/types/metrics/apiGetMetricsByPropertyParameters";

export default class MetricsByPropertyMapperProfile {
	constructor() {
	}
	
	mapToApiGetMetricsByPropertyParameters(source: MetricsByPropertyState): ApiGetMetricsByPropertyParameters {
		return {
			from: source.filter.periodStart || source.periods.find(x => source.filter.period === x.value)?.start || "",
			to: source.filter.periodEnd || source.periods.find(x => source.filter.period === x.value)?.end || "",
			propertyType: source.filter.propertyType
		};
	}
}
