import { parseISO } from "date-fns";
import { ApiAgreementsItem } from "@rmp/core/api/types/agreement/apiAgreementsItem";
import { ServiceTypeEnum } from "@rmp/core/types/services/ServiceTypeEnum";
import ServiceMapperProfile from "@rmp/core/api/mappers/service";
import { ApiAgreementWorkflowState } from "@rmp/core/api/types/agreement/apiAgreementWorkflowState";

export interface AgreementsItem {
	id: string;
	agreementNumber: number;
	serviceKind: ServiceTypeEnum;
	target: number;
	amount: number;
	agreementDate?: Date;
	state: ApiAgreementWorkflowState;
	department: string;
	port?: string;
	counterpartyShortName: string;
}

export class AgreementsItemMapper {
	static map(source: ApiAgreementsItem): AgreementsItem {
		return {
			...source,
			agreementDate: source.agreementDate ? parseISO(source.agreementDate) : undefined,
			serviceKind: ServiceMapperProfile.mapServiceType(source.serviceKind),
			department: source.division.title,
			port: source.port?.title,
			counterpartyShortName: source.counterparty.title
		};
	}
}
