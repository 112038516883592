import ApiGetCounterpartyEmployeeActiveTrustsParameters
	from "@rmp/core/api/types/counterparty/trust/apiGetCounterpartyEmployeeActiveTrustsParameters";
import { CounterpartyEmployeeState } from "@rmp/enterprise/stores/counterpartyEmployee/index";

export default class CounterpartyEmployeeMapperProfile {
	constructor() {
	}
	
	static mapToApiGetCounterpartyEmployeeActiveTrustsParameters(source: CounterpartyEmployeeState): ApiGetCounterpartyEmployeeActiveTrustsParameters {
		return {
			counterpartyId: source.counterpartyId,
			trusteeEmployeeId: source.employeeId
		};
	}
}
