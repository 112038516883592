import { BranchDivision } from "@rmp/enterprise/stores/divisions/branch/general/types/branchDivision";
import ApiBranch from "@rmp/core/api/types/divisions/branch/apiBranch";

export default class BranchDivisionGeneralMapperProfile {
	constructor() {
	}
	mapBranchDivision(source: ApiBranch): BranchDivision {
		return {
			...source
		}
	}
}
