import { ArchiveStatusType } from "@rmp/core/types/ArchiveStatusType";
import { PortDestinationsState } from "@rmp/enterprise/stores/divisions/port/destinations/types";
import ApiGetPortDestinationsParameters from "@rmp/core/api/types/divisions/port/destination/apiGetPortDestinationsParameters";
import { ApiPortDestinationPersisted } from "@rmp/core/api/types/divisions/port/destination/apiPortDestinationPersisted";

export default class PortDestinationsMapperProfile {
	constructor() {
	}
	
	mapToApiGetPortDestinationsParameters(source: PortDestinationsState): ApiGetPortDestinationsParameters {
		return {
			skip: source.paging.pageSize * (source.paging.page - 1),
			query: source.search.query,
			sortField: source.sorting.type,
			sortingDirection: source.sorting.order,
			isArchived: ArchiveStatusType.ALL === source.filter.status ? null : source.filter.status === ArchiveStatusType.ARCHIVED
		};
	}
	
	mapFromApi(source: ApiPortDestinationPersisted) {
		return {
			...source.portDestination,
			id: source.id,
			isStatusUpdating: false
		};
	}
}
