import { stringifyQuery } from "@rmp/core/utils/query";
import RmpInternalServerError from "@rmp/core/pages/errors/RmpInternalServerError.vue";
import RmpAccessDeniedError from "@rmp/core/pages/errors/RmpAccessDeniedError.vue";
import RmpPageNotFound from "@rmp/core/pages/errors/RmpPageNotFound.vue";
import RmpSilentRenew from "@rmp/enterprise/views/RmpSilentRenew.vue";
import Vue from "vue";
import VueRouter from "vue-router";
import RmpLayout from "@rmp/enterprise/views/layouts/RmpLayout.vue";
import RmpEmptyLayout from "@rmp/core/components/layouts/RmpEmptyLayout.vue";
import RmpCallback from "@rmp/enterprise/views/RmpCallback.vue";
import RmpTugboats from "@rmp/enterprise/views/tugboats/RmpTugboats.vue";
import RmpTugboat from "@rmp/enterprise/views/tugboat/RmpTugboat.vue";
import RmpTugboatDetails from "@rmp/enterprise/views/tugboatDetails/RmpTugboatDetails.vue";
import RmpCrewboats from "@rmp/enterprise/views/crewboats/RmpCrewboats.vue";
import RmpCrewboat from "@rmp/enterprise/views/crewboat/RmpCrewboat.vue";
import RmpCrewboatDetails from "@rmp/enterprise/views/crewboatDetails/RmpCrewboatDetails.vue";
import RmpFloatingCranes from "@rmp/enterprise/views/floatingCranes/RmpFloatingCranes.vue";
import RmpFloatingCrane from "@rmp/enterprise/views/floatingCrane/RmpFloatingCrane.vue";
import RmpFloatingCraneDetails from "@rmp/enterprise/views/floatingCraneDetails/RmpFloatingCraneDetails.vue";
import RmpPiers from "@rmp/enterprise/views/piers/RmpPiers.vue";
import RmpPier from "@rmp/enterprise/views/pier/RmpPier.vue";
import RmpPierDetails from "@rmp/enterprise/views/pierDetails/RmpPierDetails.vue";
import { RouteNames } from "@rmp/enterprise/router/routes";
import { configureMiddleware } from "@rmp/enterprise/router/middleware";
import RmpRouterView from "@rmp/core/components/common/RmpRouterView.vue";
import RmpBranch from "@rmp/enterprise/views/divisions/branch/RmpBranch.vue";
import RmpBranches from "@rmp/enterprise/views/divisions/branches/RmpBranches.vue";
import RmpBranchGeneral from "@rmp/enterprise/views/divisions/branch/sections/RmpBranchGeneral.vue";
import RmpBranchDepartments from "@rmp/enterprise/views/divisions/branch/sections/RmpBranchDepartments.vue";
import RmpServices from "@rmp/enterprise/views/services/RmpServices.vue";
import RmpPierService from "@rmp/enterprise/views/serviceDetails/pier/RmpPierService.vue";
import RmpPierServiceGeneral from "@rmp/enterprise/views/serviceDetails/pier/sections/RmpPierServiceGeneral.vue";
import RmpPierServiceDocuments from "@rmp/enterprise/views/serviceDetails/pier/sections/RmpPierServiceDocuments.vue";
import RmpPierServiceChat from "@rmp/enterprise/views/serviceDetails/pier/sections/RmpPierServiceChat.vue";
import RmpPierServiceHistory from "@rmp/enterprise/views/serviceDetails/pier/sections/RmpPierServiceHistory.vue";
import RmpUploadPierServiceDocuments from "@rmp/enterprise/views/uploadServiceDocuments/pier/RmpUploadPierServiceDocuments.vue";
import RmpTugboatService from "@rmp/enterprise/views/serviceDetails/tugboat/RmpTugboatService.vue";
import RmpTugboatServiceGeneral from "@rmp/enterprise/views/serviceDetails/tugboat/sections/RmpTugboatServiceGeneral.vue";
import RmpTugboatServiceDocuments from "@rmp/enterprise/views/serviceDetails/tugboat/sections/RmpTugboatServiceDocuments.vue";
import RmpTugboatServiceChat from "@rmp/enterprise/views/serviceDetails/tugboat/sections/RmpTugboatServiceChat.vue";
import RmpTugboatServiceHistory from "@rmp/enterprise/views/serviceDetails/tugboat/sections/RmpTugboatServiceHistory.vue";
import RmpDepartment from "@rmp/enterprise/views/divisions/department/RmpDepartment.vue";
import RmpDepartmentGeneral from "@rmp/enterprise/views/divisions/department/sections/RmpDepartmentGeneral.vue";
import RmpDepartmentPorts from "@rmp/enterprise/views/divisions/department/sections/RmpDepartmentPorts.vue";
import RmpTugboatAssignment from "@rmp/enterprise/views/assignment/tugboat/RmpTugboatAssignment.vue";
import RmpPierAssignment from "@rmp/enterprise/views/assignment/pier/RmpPierAssignment.vue";
import RmpCrewboatAssignment from "@rmp/enterprise/views/assignment/crewboat/RmpCrewboatAssignment.vue";
import RmpUploadTugboatServiceDocuments from "@rmp/enterprise/views/uploadServiceDocuments/tugboat/RmpUploadTugboatServiceDocuments.vue";
import RmpCrewboatService from "@rmp/enterprise/views/serviceDetails/crewboat/RmpCrewboatService.vue";
import RmpCrewboatServiceGeneral from "@rmp/enterprise/views/serviceDetails/crewboat/sections/RmpCrewboatServiceGeneral.vue";
import RmpCrewboatServiceDocuments from "@rmp/enterprise/views/serviceDetails/crewboat/sections/RmpCrewboatServiceDocuments.vue";
import RmpCrewboatServiceChat from "@rmp/enterprise/views/serviceDetails/crewboat/sections/RmpCrewboatServiceChat.vue";
import RmpCrewboatServiceHistory from "@rmp/enterprise/views/serviceDetails/crewboat/sections/RmpCrewboatServiceHistory.vue";
import RmpUploadCrewboatServiceDocuments from "@rmp/enterprise/views/uploadServiceDocuments/crewboat/RmpUploadCrewboatServiceDocuments.vue";
import RmpPort from "@rmp/enterprise/views/divisions/port/RmpPort.vue";
import RmpPortGeneral from "@rmp/enterprise/views/divisions/port/sections/RmpPortGeneral.vue";
import RmpBoatApplications from "@rmp/enterprise/views/boatApplications/RmpBoatApplications.vue";
import RmpBoatApplicationDetails from "@rmp/enterprise/views/boatApplicationDetails/RmpBoatApplicationDetails.vue";
import RmpBoatApplicationDetailsGeneral from "@rmp/enterprise/views/boatApplicationDetails/sections/RmpBoatApplicationDetailsGeneral.vue";
import RmpBoatApplicationDetailsDocuments
	from "@rmp/enterprise/views/boatApplicationDetails/sections/RmpBoatApplicationDetailsDocuments.vue";
import RmpBoatApplicationDetailsHistory from "@rmp/enterprise/views/boatApplicationDetails/sections/RmpBoatApplicationDetailsHistory.vue";
import RmpBoatApplicationDetailsChat from "@rmp/enterprise/views/boatApplicationDetails/sections/RmpBoatApplicationDetailsChat.vue";
import RmpTariffAssignment from "@rmp/enterprise/views/assignment/tariff/RmpTariffAssignment.vue";
import RmpMetrics from "@rmp/enterprise/views/metrics/RmpMetrics.vue";
import RmpMetricsByTime from "@rmp/enterprise/views/metrics/sections/RmpMetricsByTime.vue";
import RmpMetricsByCost from "@rmp/enterprise/views/metrics/sections/RmpMetricsByCost.vue";
import RmpMetricsByProperty from "@rmp/enterprise/views/metrics/sections/RmpMetricsByProperty.vue";
import RmpMetricsByPropertyDetailed from "@rmp/enterprise/views/metrics/sections/RmpMetricsByPropertyDetailed.vue";
import RmpMetricsByAccounts from "@rmp/enterprise/views/metrics/sections/RmpMetricsByAccounts.vue";
import RmpAgreements from "@rmp/enterprise/views/agreements/RmpAgreements.vue";
import RmpAgreementDetails from "@rmp/enterprise/views/agreementDetails/RmpAgreementDetails.vue";
import RmpAgreementDetailsGeneral from "@rmp/enterprise/views/agreementDetails/sections/RmpAgreementDetailsGeneral.vue";
import RmpAgreementDetailsDocuments from "@rmp/enterprise/views/agreementDetails/sections/RmpAgreementDetailsDocuments.vue";
import RmpAgreementDetailsHistory from "@rmp/enterprise/views/agreementDetails/sections/RmpAgreementDetailsHistory.vue";
import RmpAgreementDetailsChat from "@rmp/enterprise/views/agreementDetails/sections/RmpAgreementDetailsChat.vue";
import RmpOuterLayout from "@rmp/core/components/layouts/RmpOuterLayout.vue";
import RmpConfirmEmail from "@rmp/core/pages/confirmation/RmpConfirmEmail.vue";
import RmpConfirmPhone from "@rmp/core/pages/confirmation/RmpConfirmPhone.vue";
import RmpResetPassword from "@rmp/core/pages/resetPassword/RmpResetPassword.vue";
import RmpUploadAgreementDocuments from "@rmp/enterprise/views/uploadAgreementDocuments/RmpUploadAgreementDocuments.vue";
import RmpDredgingServiceHistory from "@rmp/enterprise/views/serviceDetails/dredging/sections/RmpDredgingServiceHistory.vue";
import RmpDredgingService from "@rmp/enterprise/views/serviceDetails/dredging/RmpDredgingService.vue";
import RmpDredgingServiceGeneral from "@rmp/enterprise/views/serviceDetails/dredging/sections/RmpDredgingServiceGeneral.vue";
import RmpDredgingServiceDocuments from "@rmp/enterprise/views/serviceDetails/dredging/sections/RmpDredgingServiceDocuments.vue";
import RmpDredgingServiceChat from "@rmp/enterprise/views/serviceDetails/dredging/sections/RmpDredgingServiceChat.vue";
import RmpUploadDredgingServiceDocuments from "@rmp/enterprise/views/uploadServiceDocuments/dredging/RmpUploadDredgingServiceDocuments.vue";
import RmpGeodeticHydrographicService from "@rmp/enterprise/views/serviceDetails/geodeticHydrographic/RmpGeodeticHydrographicService.vue";
import RmpGeodeticHydrographicServiceGeneral
	from "@rmp/enterprise/views/serviceDetails/geodeticHydrographic/sections/RmpGeodeticHydrographicServiceGeneral.vue";
import RmpGeodeticHydrographicServiceDocuments
	from "@rmp/enterprise/views/serviceDetails/geodeticHydrographic/sections/RmpGeodeticHydrographicServiceDocuments.vue";
import RmpGeodeticHydrographicServiceHistory
	from "@rmp/enterprise/views/serviceDetails/geodeticHydrographic/sections/RmpGeodeticHydrographicServiceHistory.vue";
import RmpGeodeticHydrographicServiceChat
	from "@rmp/enterprise/views/serviceDetails/geodeticHydrographic/sections/RmpGeodeticHydrographicServiceChat.vue";
import RmpUploadGeodeticHydrographicServiceDocuments
	from "@rmp/enterprise/views/uploadServiceDocuments/geodeticHydrographic/RmpUploadGeodeticHydrographicServiceDocuments.vue";
import RmpUploadSnoInstallationServiceDocuments
	from "@rmp/enterprise/views/uploadServiceDocuments/snoInstallation/RmpUploadSnoInstallationServiceDocuments.vue";
import RmpSnoInstallationService from "@rmp/enterprise/views/serviceDetails/snoInstallation/RmpSnoInstallationService.vue";
import RmpSnoInstallationServiceGeneral
	from "@rmp/enterprise/views/serviceDetails/snoInstallation/sections/RmpSnoInstallationServiceGeneral.vue";
import RmpSnoInstallationServiceDocuments
	from "@rmp/enterprise/views/serviceDetails/snoInstallation/sections/RmpSnoInstallationServiceDocuments.vue";
import RmpSnoInstallationServiceHistory
	from "@rmp/enterprise/views/serviceDetails/snoInstallation/sections/RmpSnoInstallationServiceHistory.vue";
import RmpSnoInstallationServiceChat from "@rmp/enterprise/views/serviceDetails/snoInstallation/sections/RmpSnoInstallationServiceChat.vue";
import RmpUploadInformationServiceDocuments
	from "@rmp/enterprise/views/uploadServiceDocuments/information/RmpUploadInformationServiceDocuments.vue";
import RmpInformationService from "@rmp/enterprise/views/serviceDetails/information/RmpInformationService.vue";
import RmpInformationServiceGeneral from "@rmp/enterprise/views/serviceDetails/information/sections/RmpInformationServiceGeneral.vue";
import RmpInformationServiceDocuments from "@rmp/enterprise/views/serviceDetails/information/sections/RmpInformationServiceDocuments.vue";
import RmpInformationServiceHistory from "@rmp/enterprise/views/serviceDetails/information/sections/RmpInformationServiceHistory.vue";
import RmpInformationServiceChat from "@rmp/enterprise/views/serviceDetails/information/sections/RmpInformationServiceChat.vue";
import RmpInformationTariffAssignment from "@rmp/enterprise/views/assignment/informationTariff/RmpInformationTariffAssignment.vue";
import RmpFloatingCraneAssignment from "@rmp/enterprise/views/assignment/floatingCrane/RmpFloatingCraneAssignment.vue";
import RmpFloatingCraneService from "@rmp/enterprise/views/serviceDetails/floatingCrane/RmpFloatingCraneService.vue";
import RmpFloatingCraneServiceGeneral from "@rmp/enterprise/views/serviceDetails/floatingCrane/sections/RmpFloatingCraneServiceGeneral.vue";
import RmpFloatingCraneServiceDocuments
	from "@rmp/enterprise/views/serviceDetails/floatingCrane/sections/RmpFloatingCraneServiceDocuments.vue";
import RmpFloatingCraneServiceHistory from "@rmp/enterprise/views/serviceDetails/floatingCrane/sections/RmpFloatingCraneServiceHistory.vue";
import RmpFloatingCraneServiceChat from "@rmp/enterprise/views/serviceDetails/floatingCrane/sections/RmpFloatingCraneServiceChat.vue";
import RmpUploadFloatingCraneServiceDocuments
	from "@rmp/enterprise/views/uploadServiceDocuments/floatingCrane/RmpUploadFloatingCraneServiceDocuments.vue";
import RmpSignAgreementDocuments from "@rmp/enterprise/views/signAgreementDocuments/RmpSignAgreementDocuments.vue";
import RmpGeodeticHydrographicServiceFeedback
	from "@rmp/enterprise/views/serviceDetails/geodeticHydrographic/sections/RmpGeodeticHydrographicServiceFeedback.vue";
import RmpPierServiceFeedback from "@rmp/enterprise/views/serviceDetails/pier/sections/RmpPierServiceFeedback.vue";
import RmpTugboatServiceFeedback from "@rmp/enterprise/views/serviceDetails/tugboat/sections/RmpTugboatServiceFeedback.vue";
import RmpInformationServiceFeedback from "@rmp/enterprise/views/serviceDetails/information/sections/RmpInformationServiceFeedback.vue";
import RmpCrewboatServiceFeedback from "@rmp/enterprise/views/serviceDetails/crewboat/sections/RmpCrewboatServiceFeedback.vue";
import RmpFloatingCraneServiceFeedback
	from "@rmp/enterprise/views/serviceDetails/floatingCrane/sections/RmpFloatingCraneServiceFeedback.vue";
import RmpDredgingServiceFeedback from "@rmp/enterprise/views/serviceDetails/dredging/sections/RmpDredgingServiceFeedback.vue";
import RmpSnoInstallationServiceFeedback
	from "@rmp/enterprise/views/serviceDetails/snoInstallation/sections/RmpSnoInstallationServiceFeedback.vue";
import RmpCounterparties from "@rmp/enterprise/views/counterparties/RmpCounterparties.vue";
import RmpCounterpartyDetails from "@rmp/enterprise/views/counterpartyDetails/RmpCounterpartyDetails.vue";
import RmpCounterpartyDetailsGeneral from "@rmp/enterprise/views/counterpartyDetails/sections/RmpCounterpartyDetailsGeneral.vue";
import RmpTugboatDetailsGeneral from "@rmp/enterprise/views/tugboatDetails/sections/RmpTugboatDetailsGeneral.vue";
import RmpTugboatDetailsTariffs from "@rmp/enterprise/views/tugboatDetails/sections/RmpTugboatDetailsTariffs.vue";
import RmpCrewboatDetailsGeneral from "@rmp/enterprise/views/crewboatDetails/sections/RmpCrewboatDetailsGeneral.vue";
import RmpCrewboatDetailsTariffs from "@rmp/enterprise/views/crewboatDetails/sections/RmpCrewboatDetailsTariffs.vue";
import RmpCounterpartyBankAccounts from "@rmp/enterprise/views/counterpartyDetails/sections/bankAccounts/RmpCounterpartyBankAccounts.vue";
import RmpCounterpartyBankAccount from "@rmp/enterprise/views/counterpartyBankAccount/RmpCounterpartyBankAccount.vue";
import RmpTariffs from "@rmp/enterprise/views/tariffs/RmpTariffs.vue";
import RmpCounterpartyEmployees from "@rmp/enterprise/views/counterpartyDetails/sections/employees/RmpCounterpartyEmployees.vue";
import RmpCounterpartyEmployee from "@rmp/enterprise/views/counterpartyEmployee/RmpCounterpartyEmployee.vue";
import RmpAgreementDetailsServices from "@rmp/enterprise/views/agreementDetails/sections/RmpAgreementDetailsServices.vue";
import RmpCounterpartyDocuments from "@rmp/enterprise/views/counterpartyDetails/sections/documents/RmpCounterpartyDocuments.vue";
import RmpPortDestinations from "@rmp/enterprise/views/divisions/port/sections/RmpPortDestinations.vue";
import RmpPortDestinationGeneral from "@rmp/enterprise/views/divisions/portDestinationDetails/sections/RmpPortDestinationGeneral.vue";
import RmpPortDestinationDetails from "@rmp/enterprise/views/divisions/portDestinationDetails/RmpPortDestinationDetails.vue";
import RmpPortDestination from "@rmp/enterprise/views/divisions/portDestination/RmpPortDestination.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/callback.html",
		component: RmpCallback,
		meta: { public: true },
		name: RouteNames.CALLBACK
	},
	{
		path: "/silent-renew.html",
		component: RmpSilentRenew,
		meta: { public: true },
		name: RouteNames.SILENT_RENEW
	},
	{
		path: "/",
		component: RmpLayout,
		name: RouteNames.ROOT,
		redirect: { name: RouteNames.SERVICES },
		children: [
			{
				path: "division",
				name: RouteNames.DIVISIONS,
				component: RmpRouterView,
				children: [
					{
						path: "branches",
						name: RouteNames.BRANCHES,
						component: RmpBranches
					},
					{
						path: "branch/:id",
						name: RouteNames.BRANCH,
						redirect: { name: RouteNames.BRANCH_GENERAL },
						component: RmpBranch,
						children: [
							{
								path: "",
								meta: { key: "branch" },
								name: RouteNames.BRANCH_GENERAL,
								component: RmpBranchGeneral
							},
							{
								path: "departments",
								meta: { key: "branch" },
								name: RouteNames.BRANCH_DEPARTMENTS,
								component: RmpBranchDepartments
							}
						]
					},
					{
						path: "branch/:branchId/department/:departmentId",
						name: RouteNames.DEPARTMENT,
						redirect: { name: RouteNames.DEPARTMENT_GENERAL },
						component: RmpDepartment,
						children: [
							{
								path: "",
								meta: { key: "department" },
								name: RouteNames.DEPARTMENT_GENERAL,
								component: RmpDepartmentGeneral
							},
							{
								path: "ports",
								meta: { key: "department" },
								name: RouteNames.DEPARTMENT_PORTS,
								component: RmpDepartmentPorts
							}
						]
					},
					{
						path: "branch/:branchId/department/:departmentId/port/:portId",
						name: RouteNames.PORT,
						redirect: { name: RouteNames.PORT_GENERAL },
						component: RmpPort,
						children: [
							{
								path: "",
								meta: { key: "port" },
								name: RouteNames.PORT_GENERAL,
								component: RmpPortGeneral
							},
							{
								path: "destinations",
								meta: { key: "port" },
								name: RouteNames.PORT_DESTINATIONS,
								component: RmpPortDestinations
							}
						]
					},
					{
						path: "branch/:branchId/department/:departmentId/port/:portId/destination-details/:destinationId",
						name: RouteNames.PORT_DESTINATION_DETAILS,
						redirect: { name: RouteNames.PORT_DESTINATION_GENERAL },
						component: RmpPortDestinationDetails,
						children: [
							{
								path: "",
								meta: { key: "destination-details" },
								name: RouteNames.PORT_DESTINATION_GENERAL,
								component: RmpPortDestinationGeneral
							}
						]
					},
					{
						path: "branch/:branchId/department/:departmentId/port/:portId/destination",
						name: RouteNames.PORT_DESTINATION_CREATE,
						component: RmpPortDestination
					},
					{
						path: "branch/:branchId/department/:departmentId/port/:portId/destination/:destinationId",
						name: RouteNames.PORT_DESTINATION,
						component: RmpPortDestination
					}
				]
			},
			{
				path: "tugboats",
				name: RouteNames.TUGBOATS,
				component: RmpTugboats
			},
			{
				path: "tugboat",
				name: RouteNames.TUGBOAT_CREATE,
				component: RmpTugboat
			},
			{
				path: "tugboat/:id",
				name: RouteNames.TUGBOAT,
				component: RmpTugboat
			},
			{
				path: "tugboat-details/:id",
				component: RmpTugboatDetails,
				name: RouteNames.TUGBOAT_DETAILS,
				redirect: { name: RouteNames.TUGBOAT_DETAILS_GENERAL },
				children: [
					{
						path: "",
						meta: { key: "tugboat-details" },
						name: RouteNames.TUGBOAT_DETAILS_GENERAL,
						component: RmpTugboatDetailsGeneral
					},
					{
						path: "tariffs",
						meta: { key: "tugboat-details-tariffs" },
						name: RouteNames.TUGBOAT_DETAILS_TARIFFS,
						component: RmpTugboatDetailsTariffs
					}
				]
			},
			{
				path: "crewboats",
				name: RouteNames.CREWBOATS,
				component: RmpCrewboats
			},
			{
				path: "crewboat",
				name: RouteNames.CREWBOAT_CREATE,
				component: RmpCrewboat
			},
			{
				path: "crewboat/:id",
				name: RouteNames.CREWBOAT,
				component: RmpCrewboat
			},
			{
				path: "crewboat-details/:id",
				component: RmpCrewboatDetails,
				name: RouteNames.CREWBOAT_DETAILS,
				redirect: { name: RouteNames.CREWBOAT_DETAILS_GENERAL },
				children: [
					{
						path: "",
						meta: { key: "crewboat-details" },
						name: RouteNames.CREWBOAT_DETAILS_GENERAL,
						component: RmpCrewboatDetailsGeneral
					},
					{
						path: "tariffs",
						meta: { key: "crewboat-details-tariffs" },
						name: RouteNames.CREWBOAT_DETAILS_TARIFFS,
						component: RmpCrewboatDetailsTariffs
					}
				]
			},
			{
				path: "floating-cranes",
				name: RouteNames.FLOATING_CRANES,
				component: RmpFloatingCranes
			},
			{
				path: "floating-crane",
				name: RouteNames.FLOATING_CRANE_CREATE,
				component: RmpFloatingCrane
			},
			{
				path: "floating-crane/:id",
				name: RouteNames.FLOATING_CRANE,
				component: RmpFloatingCrane
			},
			{
				path: "floating-crane-details/:id",
				name: RouteNames.FLOATING_CRANE_DETAILS,
				component: RmpFloatingCraneDetails
			},
			{
				path: "piers",
				name: RouteNames.PIERS,
				component: RmpPiers
			},
			{
				path: "pier",
				name: RouteNames.PIER_CREATE,
				component: RmpPier
			},
			{
				path: "pier/:id",
				name: RouteNames.PIER,
				component: RmpPier
			},
			{
				path: "pier-details/:id",
				name: RouteNames.PIER_DETAILS,
				component: RmpPierDetails
			},
			{
				path: "counterparties",
				name: RouteNames.COUNTERPARTIES,
				component: RmpCounterparties
			},
			{
				path: "counterparty",
				component: RmpRouterView,
				children: [
					{
						path: ":id",
						name: RouteNames.COUNTERPARTY_DETAILS,
						redirect: { name: RouteNames.COUNTERPARTY_DETAILS_GENERAL },
						component: RmpCounterpartyDetails,
						children: [
							{
								meta: { key: "counterparty-details" },
								path: "",
								name: RouteNames.COUNTERPARTY_DETAILS_GENERAL,
								component: RmpCounterpartyDetailsGeneral
							},
							{
								meta: { key: "counterparty-details" },
								path: "bank-accounts",
								name: RouteNames.COUNTERPARTY_DETAILS_BANK_ACCOUNTS,
								component: RmpCounterpartyBankAccounts
							},
							{
								meta: { key: "counterparty-details" },
								path: "employees",
								name: RouteNames.COUNTERPARTY_DETAILS_EMPLOYEES,
								component: RmpCounterpartyEmployees
							},
							{
								meta: { key: "counterparty-details" },
								path: "documents",
								name: RouteNames.COUNTERPARTY_DETAILS_DOCUMENTS,
								component: RmpCounterpartyDocuments
							}
						]
					}
				]
			},
			{
				path: "counterparty/:counterpartyId/bank-account/:accountId",
				name: RouteNames.COUNTERPARTY_DETAILS_BANK_ACCOUNT,
				component: RmpCounterpartyBankAccount
			},
			{
				path: "counterparty/:counterpartyId/employee/:employeeId",
				name: RouteNames.COUNTERPARTY_DETAILS_EMPLOYEE,
				component: RmpCounterpartyEmployee
			},
			{
				path: "tariffs",
				name: RouteNames.TARIFFS,
				component: RmpTariffs
			},
			{
				path: "services",
				name: RouteNames.SERVICES,
				component: RmpServices
			},
			{
				path: "services/pier/:id",
				name: RouteNames.PIER_SERVICE,
				redirect: { name: RouteNames.PIER_SERVICE_GENERAL },
				component: RmpPierService,
				children: [
					{
						path: "",
						meta: { key: "pier-service" },
						name: RouteNames.PIER_SERVICE_GENERAL,
						component: RmpPierServiceGeneral
					},
					{
						path: "documents",
						meta: { key: "pier-service" },
						name: RouteNames.PIER_SERVICE_DOCUMENTS,
						component: RmpPierServiceDocuments
					},
					{
						path: "history",
						meta: { key: "pier-service" },
						name: RouteNames.PIER_SERVICE_HISTORY,
						component: RmpPierServiceHistory
					},
					{
						path: "comments",
						meta: { key: "pier-service" },
						name: RouteNames.PIER_SERVICE_COMMENTS,
						component: RmpPierServiceChat
					},
					{
						path: "feedback",
						meta: { key: "pier-service" },
						name: RouteNames.PIER_SERVICE_FEEDBACK,
						component: RmpPierServiceFeedback
					}
				]
			},
			{
				path: "services/pier/:id/pier-assignment",
				name: RouteNames.PIER_SERVICE_PIER_ASSIGNMENT,
				component: RmpPierAssignment
			},
			{
				path: "services/pier/:id/tariff-assignment",
				name: RouteNames.PIER_SERVICE_TARIFF_ASSIGNMENT,
				component: RmpTariffAssignment
			},
			{
				path: "services/pier/:id/upload-documents",
				name: RouteNames.PIER_SERVICE_UPLOAD_DOCUMENTS,
				component: RmpUploadPierServiceDocuments
			},
			{
				path: "services/tugboat/:id",
				name: RouteNames.TUGBOAT_SERVICE,
				redirect: { name: RouteNames.TUGBOAT_SERVICE_GENERAL },
				component: RmpTugboatService,
				children: [
					{
						path: "",
						meta: { key: "tugboat-service" },
						name: RouteNames.TUGBOAT_SERVICE_GENERAL,
						component: RmpTugboatServiceGeneral
					},
					{
						path: "documents",
						meta: { key: "tugboat-service" },
						name: RouteNames.TUGBOAT_SERVICE_DOCUMENTS,
						component: RmpTugboatServiceDocuments
					},
					{
						path: "history",
						meta: { key: "tugboat-service" },
						name: RouteNames.TUGBOAT_SERVICE_HISTORY,
						component: RmpTugboatServiceHistory
					},
					{
						path: "comments",
						meta: { key: "tugboat-service" },
						name: RouteNames.TUGBOAT_SERVICE_COMMENTS,
						component: RmpTugboatServiceChat
					},
					{
						path: "feedback",
						meta: { key: "tugboat-service" },
						name: RouteNames.TUGBOAT_SERVICE_FEEDBACK,
						component: RmpTugboatServiceFeedback
					}
				]
			},
			{
				path: "services/information/:id",
				name: RouteNames.INFORMATION_SERVICE,
				redirect: { name: RouteNames.INFORMATION_SERVICE_GENERAL },
				component: RmpInformationService,
				children: [
					{
						path: "",
						meta: { key: "information-service" },
						name: RouteNames.INFORMATION_SERVICE_GENERAL,
						component: RmpInformationServiceGeneral
					},
					{
						path: "documents",
						meta: { key: "information-service" },
						name: RouteNames.INFORMATION_SERVICE_DOCUMENTS,
						component: RmpInformationServiceDocuments
					},
					{
						path: "history",
						meta: { key: "information-service" },
						name: RouteNames.INFORMATION_SERVICE_HISTORY,
						component: RmpInformationServiceHistory
					},
					{
						path: "comments",
						meta: { key: "information-service" },
						name: RouteNames.INFORMATION_SERVICE_COMMENTS,
						component: RmpInformationServiceChat
					},
					{
						path: "feedback",
						meta: { key: "information-service" },
						name: RouteNames.INFORMATION_SERVICE_FEEDBACK,
						component: RmpInformationServiceFeedback
					}
				]
			},
			{
				path: "services/information/:id/upload-documents",
				name: RouteNames.INFORMATION_SERVICE_UPLOAD_DOCUMENTS,
				component: RmpUploadInformationServiceDocuments
			},
			{
				path: "services/information/:id/tariff-assignment",
				name: RouteNames.INFORMATION_SERVICE_TARIFF_ASSIGNMENT,
				component: RmpInformationTariffAssignment
			},
			{
				path: "services/tugboat/:id/tugboat-assignment",
				name: RouteNames.TUGBOAT_SERVICE_TUGBOAT_ASSIGNMENT,
				component: RmpTugboatAssignment
			},
			{
				path: "services/tugboat/:id/tariff-assignment",
				name: RouteNames.TUGBOAT_SERVICE_TARIFF_ASSIGNMENT,
				component: RmpTariffAssignment
			},
			{
				path: "services/tugboat/:id/upload-documents",
				name: RouteNames.TUGBOAT_SERVICE_UPLOAD_DOCUMENTS,
				component: RmpUploadTugboatServiceDocuments
			},
			{
				path: "services/crewboat/:id",
				name: RouteNames.CREWBOAT_SERVICE,
				redirect: { name: RouteNames.CREWBOAT_SERVICE_GENERAL },
				component: RmpCrewboatService,
				children: [
					{
						path: "",
						meta: { key: "crewboat-service" },
						name: RouteNames.CREWBOAT_SERVICE_GENERAL,
						component: RmpCrewboatServiceGeneral
					},
					{
						path: "documents",
						meta: { key: "crewboat-service" },
						name: RouteNames.CREWBOAT_SERVICE_DOCUMENTS,
						component: RmpCrewboatServiceDocuments
					},
					{
						path: "history",
						meta: { key: "crewboat-service" },
						name: RouteNames.CREWBOAT_SERVICE_HISTORY,
						component: RmpCrewboatServiceHistory
					},
					{
						path: "comments",
						meta: { key: "crewboat-service" },
						name: RouteNames.CREWBOAT_SERVICE_COMMENTS,
						component: RmpCrewboatServiceChat
					},
					{
						path: "feedback",
						meta: { key: "crewboat-service" },
						name: RouteNames.CREWBOAT_SERVICE_FEEDBACK,
						component: RmpCrewboatServiceFeedback
					}
				]
			},
			{
				path: "services/crewboat/:id/crewboat-assignment",
				name: RouteNames.CREWBOAT_SERVICE_CREWBOAT_ASSIGNMENT,
				component: RmpCrewboatAssignment
			},
			{
				path: "services/crewboat/:id/tariff-assignment",
				name: RouteNames.CREWBOAT_SERVICE_TARIFF_ASSIGNMENT,
				component: RmpTariffAssignment
			},
			{
				path: "services/crewboat/:id/upload-documents",
				name: RouteNames.CREWBOAT_SERVICE_UPLOAD_DOCUMENTS,
				component: RmpUploadCrewboatServiceDocuments
			},
			{
				path: "services/floating-crane/:id/upload-documents",
				name: RouteNames.FLOATING_CRANE_SERVICE_UPLOAD_DOCUMENTS,
				component: RmpUploadFloatingCraneServiceDocuments
			},
			{
				path: "services/floating-crane/:id/floating-crane-assignment",
				name: RouteNames.FLOATING_CRANE_SERVICE_FLOATING_CRANE_ASSIGNMENT,
				component: RmpFloatingCraneAssignment
			},
			{
				path: "services/floating-crane/:id",
				name: RouteNames.FLOATING_CRANE_SERVICE,
				redirect: { name: RouteNames.FLOATING_CRANE_SERVICE_GENERAL },
				component: RmpFloatingCraneService,
				children: [
					{
						path: "",
						meta: { key: "floating-crane-service" },
						name: RouteNames.FLOATING_CRANE_SERVICE_GENERAL,
						component: RmpFloatingCraneServiceGeneral
					},
					{
						path: "documents",
						meta: { key: "floating-crane-service" },
						name: RouteNames.FLOATING_CRANE_SERVICE_DOCUMENTS,
						component: RmpFloatingCraneServiceDocuments
					},
					{
						path: "history",
						meta: { key: "floating-crane-service" },
						name: RouteNames.FLOATING_CRANE_SERVICE_HISTORY,
						component: RmpFloatingCraneServiceHistory
					},
					{
						path: "comments",
						meta: { key: "floating-crane-service" },
						name: RouteNames.FLOATING_CRANE_SERVICE_COMMENTS,
						component: RmpFloatingCraneServiceChat
					},
					{
						path: "feedback",
						meta: { key: "floating-crane-service" },
						name: RouteNames.FLOATING_CRANE_SERVICE_FEEDBACK,
						component: RmpFloatingCraneServiceFeedback
					}
				]
			},
			{
				path: "services/dredging/:id/upload-documents",
				name: RouteNames.DREDGING_SERVICE_UPLOAD_DOCUMENTS,
				component: RmpUploadDredgingServiceDocuments
			},
			{
				path: "services/dredging/:id",
				name: RouteNames.DREDGING_SERVICE,
				redirect: { name: RouteNames.DREDGING_SERVICE_GENERAL },
				component: RmpDredgingService,
				children: [
					{
						path: "",
						meta: { key: "dredging-service" },
						name: RouteNames.DREDGING_SERVICE_GENERAL,
						component: RmpDredgingServiceGeneral
					},
					{
						path: "documents",
						meta: { key: "dredging-service" },
						name: RouteNames.DREDGING_SERVICE_DOCUMENTS,
						component: RmpDredgingServiceDocuments
					},
					{
						path: "history",
						meta: { key: "dredging-service" },
						name: RouteNames.DREDGING_SERVICE_HISTORY,
						component: RmpDredgingServiceHistory
					},
					{
						path: "comments",
						meta: { key: "dredging-service" },
						name: RouteNames.DREDGING_SERVICE_COMMENTS,
						component: RmpDredgingServiceChat
					},
					{
						path: "feedback",
						meta: { key: "dredging-service" },
						name: RouteNames.DREDGING_SERVICE_FEEDBACK,
						component: RmpDredgingServiceFeedback
					}
				]
			},
			{
				path: "services/sno-installation/:id",
				name: RouteNames.SNO_INSTALLATION_SERVICE,
				redirect: { name: RouteNames.SNO_INSTALLATION_SERVICE_GENERAL },
				component: RmpSnoInstallationService,
				children: [
					{
						path: "",
						meta: { key: "sno-installation-service" },
						name: RouteNames.SNO_INSTALLATION_SERVICE_GENERAL,
						component: RmpSnoInstallationServiceGeneral
					},
					{
						path: "documents",
						meta: { key: "sno-installation-service" },
						name: RouteNames.SNO_INSTALLATION_SERVICE_DOCUMENTS,
						component: RmpSnoInstallationServiceDocuments
					},
					{
						path: "history",
						meta: { key: "sno-installation-service" },
						name: RouteNames.SNO_INSTALLATION_SERVICE_HISTORY,
						component: RmpSnoInstallationServiceHistory
					},
					{
						path: "comments",
						meta: { key: "sno-installation-service" },
						name: RouteNames.SNO_INSTALLATION_SERVICE_COMMENTS,
						component: RmpSnoInstallationServiceChat
					},
					{
						path: "feedback",
						meta: { key: "sno-installation-service" },
						name: RouteNames.SNO_INSTALLATION_SERVICE_FEEDBACK,
						component: RmpSnoInstallationServiceFeedback
					}
				]
			},
			{
				path: "services/sno-installation/:id/upload-documents",
				name: RouteNames.SNO_INSTALLATION_SERVICE_UPLOAD_DOCUMENTS,
				component: RmpUploadSnoInstallationServiceDocuments
			},
			{
				path: "services/geodetic-hydrographic/:id",
				name: RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE,
				redirect: { name: RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_GENERAL },
				component: RmpGeodeticHydrographicService,
				children: [
					{
						path: "",
						meta: { key: "geodetic-hydrographic-service" },
						name: RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_GENERAL,
						component: RmpGeodeticHydrographicServiceGeneral
					},
					{
						path: "documents",
						meta: { key: "geodetic-hydrographic-service" },
						name: RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_DOCUMENTS,
						component: RmpGeodeticHydrographicServiceDocuments
					},
					{
						path: "history",
						meta: { key: "geodetic-hydrographic-service" },
						name: RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_HISTORY,
						component: RmpGeodeticHydrographicServiceHistory
					},
					{
						path: "comments",
						meta: { key: "geodetic-hydrographic-service" },
						name: RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_COMMENTS,
						component: RmpGeodeticHydrographicServiceChat
					},
					{
						path: "feedback",
						meta: { key: "geodetic-hydrographic-service" },
						name: RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_FEEDBACK,
						component: RmpGeodeticHydrographicServiceFeedback
					}
				]
			},
			{
				path: "services/geodetic-hydrographic/:id/upload-documents",
				name: RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_UPLOAD_DOCUMENTS,
				component: RmpUploadGeodeticHydrographicServiceDocuments
			},
			{
				path: "boat-applications",
				name: RouteNames.BOAT_APPLICATIONS,
				component: RmpBoatApplications
			},
			{
				path: "boat-application-details/:id",
				name: RouteNames.BOAT_APPLICATION_DETAILS,
				redirect: { name: RouteNames.BOAT_APPLICATION_DETAILS_GENERAL },
				component: RmpBoatApplicationDetails,
				children: [
					{
						path: "",
						meta: { key: "boat-application-details" },
						name: RouteNames.BOAT_APPLICATION_DETAILS_GENERAL,
						component: RmpBoatApplicationDetailsGeneral
					},
					{
						path: "documents",
						meta: { key: "boat-application-details" },
						name: RouteNames.BOAT_APPLICATION_DETAILS_DOCUMENTS,
						component: RmpBoatApplicationDetailsDocuments
					},
					{
						path: "history",
						meta: { key: "boat-application-details" },
						name: RouteNames.BOAT_APPLICATION_DETAILS_HISTORY,
						component: RmpBoatApplicationDetailsHistory
					},
					{
						path: "comments",
						meta: { key: "boat-application-details" },
						name: RouteNames.BOAT_APPLICATION_DETAILS_COMMENTS,
						component: RmpBoatApplicationDetailsChat
					}
				]
			},
			{
				path: "metrics",
				name: RouteNames.METRICS,
				component: RmpMetrics,
				redirect: { name: RouteNames.METRICS_BY_TIME },
				children: [
					{
						path: "by-time",
						meta: { key: "metrics" },
						name: RouteNames.METRICS_BY_TIME,
						component: RmpMetricsByTime
					},
					{
						path: "by-cost",
						meta: { key: "metrics" },
						name: RouteNames.METRICS_BY_COST,
						component: RmpMetricsByCost
					},
					{
						path: "by-property",
						meta: { key: "metrics" },
						name: RouteNames.METRICS_BY_PROPERTY,
						component: RmpMetricsByProperty
					},
					{
						path: "by-accounts",
						meta: { key: "metrics" },
						name: RouteNames.METRICS_BY_ACCOUNTS,
						component: RmpMetricsByAccounts
					}
				]
			},
			{
				path: "metrics/by-property/:id",
				name: RouteNames.METRICS_BY_PROPERTY_DETAILED,
				component: RmpMetricsByPropertyDetailed
			},
			{
				path: "agreements",
				name: RouteNames.AGREEMENTS,
				component: RmpAgreements
			},
			{
				path: "agreement-details/:id",
				name: RouteNames.AGREEMENT_DETAILS,
				redirect: { name: RouteNames.AGREEMENT_DETAILS_GENERAL },
				component: RmpAgreementDetails,
				children: [
					{
						path: "",
						meta: { key: "agreement-details" },
						name: RouteNames.AGREEMENT_DETAILS_GENERAL,
						component: RmpAgreementDetailsGeneral
					},
					{
						path: "documents",
						meta: { key: "agreement-details" },
						name: RouteNames.AGREEMENT_DETAILS_DOCUMENTS,
						component: RmpAgreementDetailsDocuments
					},
					{
						path: "history",
						meta: { key: "agreement-details" },
						name: RouteNames.AGREEMENT_DETAILS_HISTORY,
						component: RmpAgreementDetailsHistory
					},
					{
						path: "comments",
						meta: { key: "agreement-details" },
						name: RouteNames.AGREEMENT_DETAILS_COMMENTS,
						component: RmpAgreementDetailsChat
					},
					{
						path: "services",
						meta: { key: "agreement-details" },
						name: RouteNames.AGREEMENT_DETAILS_SERVICES,
						component: RmpAgreementDetailsServices
					}
				]
			},
			{
				path: "agreement-details/:id/upload-documents",
				name: RouteNames.AGREEMENT_UPLOAD_DOCUMENTS,
				component: RmpUploadAgreementDocuments
			},
			{
				path: "agreement-details/:id/sign-documents",
				name: RouteNames.AGREEMENT_SIGN_DOCUMENTS,
				component: RmpSignAgreementDocuments
			}
		]
	},
	{
		path: "/",
		component: RmpEmptyLayout,
		children: [
			{
				path: "",
				component: RmpOuterLayout,
				children: [
					{
						path: "confirm-email",
						component: RmpConfirmEmail,
						name: RouteNames.CONFIRM_EMAIL
					},
					{
						path: "confirm-phone",
						component: RmpConfirmPhone,
						name: RouteNames.CONFIRM_PHONE
					},
					{
						path: "reset-password",
						component: RmpResetPassword,
						name: RouteNames.RESET_PASSWORD
					}
				]
			}
		]
	},
	{
		path: "/",
		component: RmpEmptyLayout,
		meta: { public: true },
		children: [
			{
				path: "internal-server-error",
				component: RmpInternalServerError,
				name: RouteNames.ERROR_INTERNAL_SERVER_ERROR
			},
			{
				path: "access-denied",
				component: RmpAccessDeniedError,
				name: RouteNames.ERROR_ACCESS_DENIED
			},
			{
				path: "*",
				component: RmpPageNotFound,
				name: RouteNames.ERROR_PAGE_NOT_FOUND
			}
		]
	}
];

const router = new VueRouter({
	routes,
	mode: "history",
	stringifyQuery: query => {
		// @ts-ignore
		let result = stringifyQuery(query);
		return result ? ("?" + result) : "";
	}
});

configureMiddleware(router);

export default router;
