import AbortService from "@rmp/core/services/abortService";
import { urls } from "@rmp/enterprise/api/config/urls";
import { ApiDepartmentPersisted } from "@rmp/core/api/types/divisions/department/apiDepartment";
import { ApiPortPersisted } from "@rmp/core/api/types/divisions/port/apiPort";
import { ServiceControllerBase } from "@rmp/enterprise/api/service/serviceControllerBase";
import NotImplementedException from "@rmp/core/exceptions/notImplementedException";
import BaseEnterpriseController from "@rmp/enterprise/api/base/baseEnterpriseController";
import { ApiCreateServiceDocument } from "@rmp/core/api/types/service/document/apiCreateServiceDocument";
import { GeodeticHydrographicServiceWorkflowStateType } from "@rmp/core/types/services/geodeticHydrographic/GeodeticHydrographicServiceWorkflowStateType";
import { ApiGeodeticHydrographicServiceApplication } from "@rmp/core/api/types/service/geodeticHydrographic/apiGeodeticHydrographicServiceApplication";
import { ApiCreateGeodeticHydrographicApplication } from "@rmp/core/api/types/service/geodeticHydrographic/apiCreateGeodeticHydrographicApplication";
import {
	ApiGeodeticHydrographicServiceTransitionCodeEnum
} from "@rmp/core/api/types/service/geodeticHydrographic/ApiGeodeticHydrographicServiceTransitionCodeEnum";

export class GeodeticHydrographicServiceController extends BaseEnterpriseController implements ServiceControllerBase {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getService = async (serviceId: string) => {
		return await this.get<{ serviceApplication: ApiGeodeticHydrographicServiceApplication }>(urls.service.geodetichydrographic.$serviceId, { serviceId });
	};
	
	getDepartments = async () => {
		return await this.get<ApiDepartmentPersisted[]>(urls.service.geodetichydrographic.department);
	};
	
	getDepartmentPorts = async (departmentId: string) => {
		return await this.get<ApiPortPersisted[]>(urls.service.geodetichydrographic.department.$departmentId.port, { departmentId });
	};
	
	createApplication = async (request: ApiCreateGeodeticHydrographicApplication) => {
		return await this.post<{ id: string }>(urls.service.geodetichydrographic, {}, request);
	};
	
	saveDocuments = async (serviceId: string, documents: ApiCreateServiceDocument[]) => {
		return await this.put<void>(urls.service.geodetichydrographic.$serviceId.document, { serviceId }, documents);
	};
	
	updateStatusByTransition = async (serviceId: string, code: ApiGeodeticHydrographicServiceTransitionCodeEnum, reason?: string) => {
		return await this.post<void>(urls.service.geodetichydrographic.$serviceId.$code,
			{ serviceId, code },
			{ rejectionTransition: { reason } });
	};
	
	getTariffs = async () => {
		throw new NotImplementedException("getTariffs");
	};
	
	calculateTariffCost = async () => {
		throw new NotImplementedException("calculateTariffCost");
	};
	
	assignTariffs = async () => {
		throw new NotImplementedException("assignTariffs");
	};
}
