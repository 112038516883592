import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { i18n } from "@rmp/core/plugins";
import { StorageController } from "@rmp/core/api/storage";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import router from "@rmp/enterprise/router";
import { RouteNames } from "@rmp/enterprise/router/routes";
import { AgreementController } from "@rmp/enterprise/api/agreement";
import { Agreement, AgreementMapper } from "@rmp/core/types/agreement/agreement";
import { useAgreementsBreadcrumb } from "@rmp/enterprise/stores/agreements/composables/useAgreementsBreadcrumb";
import { useAgreementDetailsBreadcrumb } from "@rmp/enterprise/stores/agreementDetails/composables/useAgreementDetailsBreadcrumb";
import {
	useSignAgreementDocumentsBreadcrumb
} from "@rmp/enterprise/stores/signAgreementDocuments/composables/useSignAgreementDocumentsBreadcrumb";
import InvalidBusinessOperationException from "@rmp/core/exceptions/invalidBusinessOperationException";
import { AgreementWorkflowStateType } from "@rmp/core/types/agreement/AgreementWorkflowStateType";
import { ApiAgreementTransitionCodeType } from "@rmp/core/api/types/agreement/ApiAgreementTransitionCodeType";
import { AgreementDocument, AgreementDocumentMapper } from "@rmp/core/types/agreement/agreementDocument";
import { formatAgreementTitle } from "@rmp/core/utils/formatting";

const abortService = new AbortService();
const page = usePageStore(abortService);

const agreementController = new AgreementController(abortService);
const storageController = new StorageController(abortService);

export interface SignAgreementDocumentsState extends PageState {
	agreement: Agreement;
	id: string;
	title: string;
	documents: AgreementDocument[],
	approving: boolean;
}

const getDefaultState = (): SignAgreementDocumentsState => {
	return {
		...page.getDefaultPageState(),
		agreement: {} as Agreement,
		id: "",
		title: "",
		documents: [] as AgreementDocument[],
		approving: false
	};
};

export const useSignAgreementDocumentsStore = defineStore({
	id: "sign-agreement-documents",
	state: (): SignAgreementDocumentsState => getDefaultState(),
	getters: {
		...page.getters,
		breadcrumbs(state: SignAgreementDocumentsState) {
			return [
				useAgreementsBreadcrumb(),
				useAgreementDetailsBreadcrumb(state.title),
				useSignAgreementDocumentsBreadcrumb()
			];
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized() {
			this.id = router.currentRoute.params.id;
			await this.fetch();
		},
		async fetch() {
			try {
				const [agreement, { documents }] = await Promise.all([
					agreementController.getAgreement(this.id),
					agreementController.getDocuments(this.id)
				]);
				
				this.agreement = AgreementMapper.map(agreement);
				this.title = formatAgreementTitle(this.agreement.serviceKind, this.agreement.agreementNumber);
				this.documents = documents.map(x => AgreementDocumentMapper.map(x, this.title));
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		},
		async saveSignature(id: string, signature: string) {
			let serviceDocument = this.documents.find(x => x.fileId === id)!;
			serviceDocument.isSigning = true;
			
			try {
				await storageController.signFile(serviceDocument.fileId, signature);
				
				this.documents = [];
				await this.fetch();
			} catch (ex) {
				console.error(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				serviceDocument.isSigning = false;
			}
		},
		async approve() {
			this.approving = true;
			
			try {
				let toSigned = await agreementController.changeAgreementStatus(this.id!, ApiAgreementTransitionCodeType.FINALLY_SIGN);

				if(!toSigned.isSuccess)
					throw new InvalidBusinessOperationException(`Не удалось перевести заявку в статус ${AgreementWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE}`,
						toSigned);

				this.agreement.state.code = AgreementWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE;

				alertService.addSuccess(AlertKeys.AGREEMENT_STATUS_UPDATED);

				await router.push({ name: RouteNames.AGREEMENT_DETAILS, params: { id: this.id } });
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.approving = false;
			}
		}
	}
});
