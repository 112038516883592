import { MetricsPeriodType } from "@rmp/core/types/metrics/metricsPeriodType";
import { ApiPropertyType } from "@rmp/core/api/types/enterpriseProperty/apiPropertyType";

export default class MetricsByPropertyFilter {
	period: MetricsPeriodType;
	periodStart: string;
	periodEnd: string;
	propertyType?: ApiPropertyType;
	
	constructor(
		period: MetricsPeriodType = MetricsPeriodType.CURRENT_MONTH,
		periodStart: string = "",
		periodEnd: string = "" 
	)
	{
		this.period = period;
		this.periodStart = periodStart;
		this.periodEnd = periodEnd;
	}
}
