import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { CounterpartyController } from "@rmp/enterprise/api/counterparty";
import { useCounterpartyDetailsStore } from "@rmp/enterprise/stores/counterpartyDetails";
import { LegalEntityCounterparty, LegalEntityCounterpartyMapper } from "@rmp/core/types/counterparty/legalEntity/legalEntityCounterparty";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import ApiLegalEntityCounterpartyPersisted from "@rmp/core/api/types/counterparty/apiLegalEntityCounterpartyPersisted";

const abortService = new AbortService();
const counterpartyController = new CounterpartyController(abortService);

const page = usePageStore(abortService);

export interface LegalEntityCounterpartyState extends PageState {
	id: string;
	counterparty: LegalEntityCounterparty;
	counterpartyLoading: boolean;
}

const getDefaultState = (): LegalEntityCounterpartyState => {
	return {
		...page.getDefaultPageState(),
		id: "",
		counterparty: {} as LegalEntityCounterparty,
		counterpartyLoading: false
	};
};

export const useLegalEntityCounterpartyDetailsStore = defineStore({
	id: "legal-entity-counterparty-details",
	state: (): LegalEntityCounterpartyState => getDefaultState(),
	getters: {
		...page.getters,
		breadcrumbs(state: LegalEntityCounterpartyState) {
			const { breadcrumbs } = useCounterpartyDetailsStore();
			return breadcrumbs;
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized() {
			const { id } = useCounterpartyDetailsStore();
			
			this.id = id;
			await this.fetch();
		},
		async fetch() {
			this.counterpartyLoading = true;
			
			try {
				const counterpartyPersisted = await counterpartyController.getCounterparty<ApiLegalEntityCounterpartyPersisted>(this.id,
					ApiLegalEntityCounterpartyPersisted);
				
				this.counterparty = LegalEntityCounterpartyMapper.map(counterpartyPersisted);
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.counterpartyLoading = false;
			}
		}
	}
});
