import { ApiPropertyType } from "@rmp/core/api/types/enterpriseProperty/apiPropertyType";
import { ApiMetricsByPropertyItem } from "@rmp/core/api/types/metrics/apiMetricsByPropertyItem";

export interface MetricsByPropertyItem {
	propertyId: string;
	propertyType: ApiPropertyType;
	name: string;
	activeServicesCount: number;
	providedServicesCount: number;
	rejectedServicesCount: number;
	totalServicesCount: number;
	totalMoney: number;
}

export class MetricsByPropertyItemMapper {
	static map(source: ApiMetricsByPropertyItem): MetricsByPropertyItem {
		return {
			...source
		}
	}
}
