import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { RouteNames } from "@rmp/enterprise/router/routes";
import router from "@rmp/enterprise/router";
import { RegisterState, useRegisterStore } from "@rmp/core/stores/composables/register/useRegisterStore";
import { PortController } from "@rmp/enterprise/api/divisions/port";
import { PortsItem } from "@rmp/enterprise/stores/divisions/department/ports/types/portsItem";
import { useDepartmentDivisionStore } from "@rmp/enterprise/stores/divisions/department";
import { usePortsBreadcrumb } from "@rmp/enterprise/stores/divisions/department/ports/composables/usePortsBreadcrumb";
import PortsMapperProfile from "@rmp/enterprise/stores/divisions/department/ports/mapper";
import { SortingOrderTypeEnum } from "@rmp/core/types/SortingOrderTypeEnum";
import { DepartmentPortsFilterStatusEnum } from "@rmp/enterprise/stores/divisions/department/ports/types/DepartmentPortsFilterStatusEnum";
import DepartmentPortsRouteQuery from "@rmp/enterprise/stores/divisions/department/ports/types/departmentPortsRouteQuery";
import DepartmentPortsRouteQueryService from "@rmp/enterprise/stores/divisions/department/ports/services/departmentPortsRouteQueryService";
import { PortsFilter } from "@rmp/enterprise/stores/divisions/department/ports/types/portsFilter";

const abortService = new AbortService();
const controller = new PortController(abortService);
const mapper = new PortsMapperProfile();

const defaultRouteQuery = new DepartmentPortsRouteQuery(
	"fullName",
	SortingOrderTypeEnum.DESCENDING,
	"",
	DepartmentPortsFilterStatusEnum.ALL
);
const routeQueryService = new DepartmentPortsRouteQueryService(defaultRouteQuery);

const page = usePageStore(abortService);

export interface PortsState extends PageState, RegisterState<PortsItem, PortsFilter>
{
	departmentId: string;
	filter: PortsFilter;
}


const register = useRegisterStore<PortsItem>({
	routeName: RouteNames.DEPARTMENT_PORTS,
	routeQueryService,
	routeQuery: defaultRouteQuery,
	getRouter: () => router
});

const getDefaultState = (): PortsState => {
	return {
		...page.getDefaultPageState(),
		...register.getDefaultRegisterState(),
		departmentId: "",
		filter: {
			status: DepartmentPortsFilterStatusEnum.ALL
		}
	};
};

export const usePortsStore = defineStore({
	id: "ports",
	state: (): PortsState => getDefaultState(),
	getters: {
		...register.getters,
		...page.getters,
		breadcrumbs() {
			const { breadcrumbs } = useDepartmentDivisionStore();
			return [...breadcrumbs, usePortsBreadcrumb()];
		},
		filteredItems(state: PortsState) {
			return state.listing.items;
		}
	},
	actions: {
		...page.actions,
		...register.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.departmentId = id;
			await this.initializeRegister();
		},
		patchStateWithRouteQuery(query: DepartmentPortsRouteQuery) {
			this.patchRegisterStateWithRouteQuery(query);
			this.$patch((state) => {
				state.filter.status = query.status;
			});
		},
		async fetchRegisterItems() {
			const payload = mapper.mapToApiGetDepartmentPortsParameters(this);
			
			let { totalCount, items } = await controller.getDepartmentPorts(this.departmentId, payload);
			
			return {
				totalCount,
				items: items.map(x => mapper.mapFromApi(x))
			};
		}
	}
});
