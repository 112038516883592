import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { EnterprisePropertyController } from "@rmp/enterprise/api/enterpriseProperty";
import router from "@rmp/enterprise/router";
import { FloatingCrane, FloatingCraneMapper } from "@rmp/core/types/enterpriseProperty/floatingCrane";
import HttpNotFoundException from "@rmp/core/exceptions/httpNotFoundException";
import AccessForbiddenException from "@rmp/core/exceptions/accessForbiddenException";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import { useFloatingCraneDetailsBreadcrumb } from "@rmp/enterprise/stores/property/floatingCraneDetails/composables/useFloatingCraneDetailsBreadcrumb";
import { useFloatingCranesBreadcrumb } from "@rmp/enterprise/stores/floatingCranes/composables/useFloatingCranesBreadcrumb";
import { BasePropertyGeneralState, useBasePropertyGeneralStore } from "@rmp/enterprise/stores/composables/basePropertyGeneral";
import { ArchiveStatusState, useArchiveStatusStore } from "@rmp/core/stores/composables/archiveStatus";

const abortService = new AbortService();

const enterprisePropertyController = new EnterprisePropertyController(abortService);

const page = usePageStore(abortService);
const baseBasePropertyGeneralStore = useBasePropertyGeneralStore({ abortService });
const archiveStatusStore = useArchiveStatusStore();

export interface FloatingCraneDetailsState extends PageState, BasePropertyGeneralState, ArchiveStatusState {
	details: FloatingCrane;
	
}

const getDefaultState = (): FloatingCraneDetailsState => {
	return {
		...page.getDefaultPageState(),
		...baseBasePropertyGeneralStore.getDefaultState(),
		...archiveStatusStore.getDefaultState(),
		details: {} as FloatingCrane
	};
};

export const useFloatingCraneDetailsPropertyStore = defineStore({
	id: "floating-crane-details",
	state: (): FloatingCraneDetailsState => getDefaultState(),
	getters: {
		...page.getters,
		...baseBasePropertyGeneralStore.getters,
		...archiveStatusStore.getters,
		breadcrumbs(state: FloatingCraneDetailsState) {
			return [
				useFloatingCranesBreadcrumb(),
				useFloatingCraneDetailsBreadcrumb(state.details.name)
			];
		}
	},
	actions: {
		...page.actions,
		...baseBasePropertyGeneralStore.actions,
		...archiveStatusStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			const [{ divisionId }] = await Promise.all([
				enterprisePropertyController.getFloatingCraneDivision(id),
				this.fetchBranches(),
				this.fetch(id)
			]);
			
			await this.initializeBasePropertyGeneralStore(divisionId);
		},
		async fetch(id: string) {
			try {
				const floatingCrane = await enterprisePropertyController.getFloatingCrane(id);
				
				this.details = FloatingCraneMapper.map(floatingCrane);
			} catch (ex) {
				const { setPageModeNotFound, setPageModeAccessForbidden } = useAppStore();
				
				switch (ex.constructor) {
					case HttpNotFoundException:
						setPageModeNotFound();
						break;
					case AccessForbiddenException:
						setPageModeAccessForbidden();
						break;
					default:
						AlertHelper.handleGeneralRequestErrors(ex);
						break;
				}
			}
		},
		async updateArchiveStatus() {
			if(!this.details.isArchived) {
				const floatingCrane = await enterprisePropertyController.archiveFloatingCrane(router.currentRoute.params.id);
				
				this.details = FloatingCraneMapper.map(floatingCrane);
			} else {
				const floatingCrane = await enterprisePropertyController.unarchiveFloatingCrane(router.currentRoute.params.id);
				
				this.details = FloatingCraneMapper.map(floatingCrane);
			}
			
			alertService.addSuccess(AlertKeys.STATUS_SUCCESS_UPDATED);
		}
	}
});
