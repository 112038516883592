import { IsArray, IsEnum, IsIn, IsInt, IsString, Length, Max, Min } from "class-validator";
import { SortingOrderTypeEnum } from "@rmp/core/types/SortingOrderTypeEnum";
import { AVAILABLE_SORTS } from "@rmp/enterprise/stores/tariffs/constants";
import RegisterRouteQuery from "@rmp/core/stores/composables/register/types/registerRouteQuery";
import { ApiServiceTypeEnum } from "@rmp/core/types/services/ApiServiceTypeEnum";
import { ArchiveStatusType } from "@rmp/core/types/ArchiveStatusType";

export default class TariffsRouteQuery implements RegisterRouteQuery {
	@IsInt()
	@Min(0)
	@Max(1000)
	page: number;
	
	@IsString()
	@IsIn(AVAILABLE_SORTS)
	sort: string;
	
	@IsString()
	@IsIn([SortingOrderTypeEnum.DESCENDING, SortingOrderTypeEnum.ASCENDING])
	sortDirection: string;
	
	@IsString()
	@Length(0, 100)
	query: string;
	
	@IsArray()
	serviceTypes: ApiServiceTypeEnum[];
	
	@IsString()
	@IsEnum(ArchiveStatusType)
	status: ArchiveStatusType;
	
	@IsString()
	propertyId: string;
	
	constructor(
		page: number,
		sort: string,
		sortDirection: string,
		query: string,
		serviceTypes: ApiServiceTypeEnum[],
		status: ArchiveStatusType,
		propertyId: string
	)
	{
		this.page = page;
		this.sort = sort;
		this.sortDirection = sortDirection;
		this.query = query;
		this.serviceTypes = serviceTypes;
		this.status = status;
		this.propertyId = propertyId;
	}
}
