<template>
	<rmp-nested-content-layout>
		<template #title>
			<v-row class="d-flex align-center justify-start pb-1">
				<v-col class="loader-title pb-0">
					<rmp-custom-title-loader :width="500" :height="20"/>
				</v-col>
			</v-row>
		</template>
		<template #content>
			<rmp-details-card-title-loader></rmp-details-card-title-loader>
			<rmp-form-card style="margin-top: 14px">
				<rmp-form-card-blocks>
					<rmp-form-card-block>
						<rmp-form-card-block-row>
							<rmp-form-card-block-col cols="12" sm="6">
								<rmp-form-item v-for="i in 9" :key="i">
									<rmp-input-loader/>
								</rmp-form-item>
							</rmp-form-card-block-col>
							<rmp-form-card-block-col cols="12" sm="6" :class="{ 'pt-0': $vuetify.breakpoint.xsOnly }">
								<rmp-form-item v-for="i in 9" :key="i">
									<rmp-input-loader/>
								</rmp-form-item>
							</rmp-form-card-block-col>
						</rmp-form-card-block-row>
					</rmp-form-card-block>
				</rmp-form-card-blocks>
			</rmp-form-card>
		</template>
	</rmp-nested-content-layout>
</template>

<script>
import RmpDetailsSectionColumn from "@rmp/core/components/details/RmpDetailsSectionColumn.vue";
import RmpCustomTitleLoader from "@rmp/core/components/loaders/common/RmpCustomTitleLoader.vue";
import RmpDetailsCardTitleLoader from "@rmp/core/components/loaders/details/RmpDetailsCardTitleLoader.vue";
import RmpFormCard from "@rmp/core/components/markup/RmpFormCard.vue";
import RmpFormCardBlocks from "@rmp/core/components/markup/RmpFormCardBlocks.vue";
import RmpFormCardBlock from "@rmp/core/components/markup/RmpFormCardBlock.vue";
import RmpFormCardBlockRow from "@rmp/core/components/markup/RmpFormCardBlockRow.vue";
import RmpFormCardBlockCol from "@rmp/core/components/markup/RmpFormCardBlockCol.vue";
import RmpFormItem from "@rmp/core/components/markup/RmpFormItem.vue";
import RmpNestedContentLayout from "@rmp/core/components/layouts/RmpNestedContentLayout.vue";
import RmpInputLoader from "@rmp/core/components/loaders/form/RmpInputLoader.vue";
import RmpFormBlockTitleLoader from "@rmp/core/components/loaders/form/RmpFormBlockTitleLoader.vue";

export default {
	components: {
		RmpCustomTitleLoader,
		RmpDetailsCardTitleLoader,
		RmpNestedContentLayout,
		RmpDetailsSectionColumn,
		RmpFormCardBlocks,
		RmpFormCard,
		RmpFormCardBlock,
		RmpFormCardBlockCol,
		RmpFormCardBlockRow,
		RmpFormItem,
		RmpInputLoader,
		RmpFormBlockTitleLoader
	}
};
</script>
