import { defineStore } from "pinia";
import { usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useRegisterStore } from "@rmp/core/stores/composables/register/useRegisterStore";
import { ArchiveStatusType } from "@rmp/core/types/ArchiveStatusType";
import { z } from "zod";
import { useRouteQueryService } from "@rmp/core/composables/useRouteQueryService";
import router from "@rmp/enterprise/router";
import { RouteNames } from "@rmp/enterprise/router/routes";
import { PortDestination, PortDestinationsFilter, PortDestinationsState } from "@rmp/enterprise/stores/divisions/port/destinations/types";
import PortDestinationsMapperProfile from "@rmp/enterprise/stores/divisions/port/destinations/mapper";
import { PortDestinationController } from "@rmp/enterprise/api/divisions/port/destination";
import { ApiPortDestinationSortField } from "@rmp/core/api/types/divisions/port/destination/ApiPortDestinationSortField";
import { usePortDestinationsBreadcrumb } from "@rmp/enterprise/stores/divisions/port/destinations/composables/usePortDestinationsBreadcrumb";
import { usePortDivisionGeneralStore } from "@rmp/enterprise/stores/divisions/port/general";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import NotDefinedException from "@rmp/core/exceptions/notDefinedException";
import { useArchiveStatusStore } from "@rmp/core/stores/composables/archiveStatus";

const abortService = new AbortService();
const controller = new PortDestinationController(abortService);
const mapper = new PortDestinationsMapperProfile();

const schema = z.object({
	sort: z.nativeEnum(ApiPortDestinationSortField).catch(ApiPortDestinationSortField.FullName),
	status: z.nativeEnum(ArchiveStatusType).default(ArchiveStatusType.ALL)
});

const { defaultRouteQuery, ...routeQueryService } = useRouteQueryService(schema.shape);
const page = usePageStore(abortService);
const archiveStatusStore = useArchiveStatusStore();

const register = useRegisterStore<PortDestination, PortDestinationsFilter>({
	routeName: RouteNames.PORT_DESTINATIONS,
	routeQueryService,
	routeQuery: defaultRouteQuery,
	getRouter: () => router
});

const getDefaultState = (): PortDestinationsState => {
	return {
		...page.getDefaultPageState(),
		...register.getDefaultRegisterState(),
		...archiveStatusStore.getDefaultState(),
		branchId: "",
		departmentId: "",
		portId: "",
		filter: {
			status: ArchiveStatusType.ALL
		}
	};
};

export const usePortDestinationsStore = defineStore({
	id: "port-destinations",
	state: (): PortDestinationsState => getDefaultState(),
	getters: {
		...register.getters,
		...page.getters,
		...archiveStatusStore.getters,
		breadcrumbs(state: PortDestinationsState) {
			const { breadcrumbs } = usePortDivisionGeneralStore();
			
			return [
				...breadcrumbs,
				usePortDestinationsBreadcrumb()
			];
		},
		filteredItems(state: PortDestinationsState) {
			return state.listing.items;
		}
	},
	actions: {
		...page.actions,
		...register.actions,
		...archiveStatusStore.actions,
		async beforeInitialized() {
			this.branchId = router.currentRoute.params.branchId;
			this.departmentId = router.currentRoute.params.departmentId;
			this.portId = router.currentRoute.params.portId;
			
			await this.initializeRegister();
		},
		patchStateWithRouteQuery(query: typeof defaultRouteQuery) {
			this.patchRegisterStateWithRouteQuery(query);
			this.$patch((state) => {
				state.filter.status = query.status;
			});
		},
		async fetchRegisterItems() {
			const payload = mapper.mapToApiGetPortDestinationsParameters(this);
			
			let { totalCount, items } = await controller.getPortDestinations(this.portId, payload);
			
			return {
				totalCount,
				items: items.map(mapper.mapFromApi)
			};
		},
		async updateArchiveStatus(id: string) {
			if(!id)
				throw new NotDefinedException("id");
			
			const { isArchived, portId } = this.listing.items.find(x => x.id === id)!;
			
			if(isArchived)
				await controller.unarchive(portId, id);
			else
				await controller.archive(portId, id);
			
			await this.updateListingItems();
			
			alertService.addSuccess(AlertKeys.STATUS_SUCCESS_UPDATED);
		}
	}
});
