import BaseEnterpriseController from "@rmp/enterprise/api/base/baseEnterpriseController";
import AbortService from "@rmp/core/services/abortService";
import ApiGetCounterpartiesParameters from "@rmp/core/api/types/counterparty/apiGetCounterpartiesParameters";
import ApiCounterparties from "@rmp/core/api/types/counterparty/apiCounterparties";
import { plainToInstance } from "class-transformer";
import ApiCounterpartyPersistedBase from "@rmp/core/api/types/counterparty/apiCounterpartyPersistedBase";
import HttpNotFoundException from "@rmp/core/exceptions/httpNotFoundException";
import { urls } from "@rmp/enterprise/api/config/urls";
import { ApiCounterpartyBankAccountPersisted } from "@rmp/core/api/types/counterpartyBankAccount/apiCounterpartyBankAccount";

export class CounterpartyController extends BaseEnterpriseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getCounterparties = async (parameters: ApiGetCounterpartiesParameters): Promise<ApiCounterparties> => {
		let data = await this.get<{ counterparties: object[], totalCount: number }>(urls.counterparty, parameters);
		
		return new ApiCounterparties(plainToInstance(ApiCounterpartyPersistedBase, data.counterparties), data.totalCount);
	};
	
	getCounterparty = async <T extends ApiCounterpartyPersistedBase>(id: string, Type: { new(): T }): Promise<T> => {
		let { counterparty } = await this.get<{ counterparty: object }>(urls.counterparty.$id, { id });
		
		if(counterparty === null)
			throw new HttpNotFoundException("counterparty");
		
		return plainToInstance(Type, counterparty);
	};
	
	getCounterpartyAccount = async (id: string, bankAccountId: string): Promise<ApiCounterpartyBankAccountPersisted> => {
		return await this.get<ApiCounterpartyBankAccountPersisted>(urls.counterparty.$id.bankAccount.$bankAccountId,
			{ id, bankAccountId });
	};
	
	getCounterpartyAccounts = async (id: string): Promise<ApiCounterpartyBankAccountPersisted[]> => {
		let { bankAccounts } = await this.get<{
			bankAccounts: ApiCounterpartyBankAccountPersisted[]
		}>(urls.counterparty.$id.bankAccount, { id });
		
		return bankAccounts;
	};
}
