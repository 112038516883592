<template>
	<rmp-internal-list-layout>
		<template #side-btn>
			<rmp-btn-loader></rmp-btn-loader>
		</template>

		<template #content>

		</template>
	</rmp-internal-list-layout>
</template>

<script>
import RmpDetailsGroup from "@rmp/core/components/details/RmpDetailsGroup.vue";
import RmpDetailsSectionColumn from "@rmp/core/components/details/RmpDetailsSectionColumn.vue";
import RmpInternalListLayout from "@rmp/core/components/layouts/RmpInternalListLayout.vue";
import RmpTitleLoader from "@rmp/core/components/loaders/common/RmpTitleLoader.vue";
import RmpBtnLoader from "@rmp/core/components/loaders/form/RmpBtnLoader.vue";
import RmpFormCard from "@rmp/core/components/markup/RmpFormCard.vue";
import RmpFormCardBlocks from "@rmp/core/components/markup/RmpFormCardBlocks.vue";
import RmpFormCardBlock from "@rmp/core/components/markup/RmpFormCardBlock.vue";
import RmpFormCardBlockRow from "@rmp/core/components/markup/RmpFormCardBlockRow.vue";
import RmpFormCardBlockCol from "@rmp/core/components/markup/RmpFormCardBlockCol.vue";
import RmpFormItem from "@rmp/core/components/markup/RmpFormItem.vue";
import RmpNestedContentLayout from "@rmp/core/components/layouts/RmpNestedContentLayout.vue";
import RmpFormBlockTitleLoader from "@rmp/core/components/loaders/form/RmpFormBlockTitleLoader.vue";

export default {
	components: {
		RmpBtnLoader,
		RmpTitleLoader,
		RmpInternalListLayout,
		RmpNestedContentLayout,
		RmpDetailsGroup,
		RmpDetailsSectionColumn,
		RmpFormCardBlocks,
		RmpFormCard,
		RmpFormCardBlock,
		RmpFormCardBlockCol,
		RmpFormCardBlockRow,
		RmpFormItem,
		RmpFormBlockTitleLoader
	}
};
</script>
