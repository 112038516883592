import { defineStore } from "pinia";
import uniq from "lodash/uniq";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { useServicesBreadcrumb } from "@rmp/enterprise/stores/services/composables/useServicesBreadcrumb";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import { SnapshotKeysEnum, SnapshotState, useSnapshotStore } from "@rmp/core/stores/composables/snapshot";
import { PierServiceController } from "@rmp/enterprise/api/service/pier";
import { PierServiceApplication, PierServiceApplicationMapper } from "@rmp/core/types/services/pier/pierServiceApplication";
import { usePierServiceBreadcrumb } from "@rmp/enterprise/stores/serviceDetails/pier/composables/usePierServiceBreadcrumb";
import { usePierAssignmentBreadcrumb } from "@rmp/enterprise/stores/assignment/pier/composables/usePierAssignmentBreadcrumb";
import { Pier, PierMapper, PierPersisted } from "@rmp/core/types/enterpriseProperty/pier";
import router from "@rmp/enterprise/router";
import { RouteNames } from "@rmp/enterprise/router/routes";
import { formatServiceTitle } from "@rmp/core/utils/formatting";

const abortService = new AbortService();
const page = usePageStore(abortService);
const snapshotStore = useSnapshotStore([
	{
		key: SnapshotKeysEnum.LAST_SAVED, fields: ["selectedPierIds"]
	}
]);

const pierServiceController = new PierServiceController(abortService);

export interface PierAssignmentState extends PageState, SnapshotState {
	selectedPierIds: string[];
	piers: PierPersisted[];
	service: PierServiceApplication;
	id: string;
	isSaving: boolean;
}

const getDefaultState = (): PierAssignmentState => {
	return {
		...page.getDefaultPageState(),
		...snapshotStore.getDefaultState(),
		selectedPierIds: [],
		piers: [],
		service: {} as PierServiceApplication,
		id: "",
		isSaving: false
	};
};

export const usePierAssignmentStore = defineStore({
	id: "pier-assignment",
	state: (): PierAssignmentState => getDefaultState(),
	getters: {
		...page.getters,
		...snapshotStore.getters,
		breadcrumbs(state: PierAssignmentState) {
			return [
				useServicesBreadcrumb(),
				usePierServiceBreadcrumb(formatServiceTitle(state.service.type, state.service.applicationNumber), { id: state.id }),
				usePierAssignmentBreadcrumb()
			];
		}
	},
	actions: {
		...page.actions,
		...snapshotStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			
			await this.fetch();
			
			if(this.service.requestedPiers.length) {
				this.setStateSnapshot();
				
				let requestedPiersIds = this.service.requestedPiers.map(x => x.pierId);
				
				this.selectedPierIds = uniq(requestedPiersIds);
			} else {
				this.selectedPierIds = this.service.assignedPiers.map(x => x.pierId);
				
				this.setStateSnapshot();
			}
		},
		async fetch() {
			try {
				let { serviceApplication } = await pierServiceController.getService(this.id!);
				
				let piers = await pierServiceController.getServicePiers(serviceApplication.divisionId);
				
				this.service = PierServiceApplicationMapper.map(serviceApplication);
				this.piers = piers.map(x => PierMapper.mapPersisted(x));
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		},
		async assign() {
			this.isSaving = true;
			
			try {
				await pierServiceController.assignPiers(this.id!, this.selectedPierIds);
				
				alertService.addSuccess(AlertKeys.PIERS_ASSIGNED);
				
				await router.push({ name: RouteNames.PIER_SERVICE, params: router.currentRoute.params });
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.isSaving = false;
			}
		}
	}
});
