import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { useAppStore } from "@rmp/core/stores/appStore";
import { usePierServiceStore } from "@rmp/enterprise/stores/serviceDetails/pier";
import { cloneDeep, groupBy, uniqBy } from "lodash";
import { ApiAgreementPersisted } from "@rmp/core/api/types/agreement/apiAgreement";
import { ApiPort } from "@rmp/core/api/types/divisions/port/apiPort";
import { CounterpartyController } from "@rmp/core/api/counterparty";
import { AgreementController } from "@rmp/enterprise/api/agreement";
import { ApiDepartment } from "@rmp/core/api/types/divisions/department/apiDepartment";
import { PortController } from "@rmp/enterprise/api/divisions/port";
import { DepartmentController } from "@rmp/enterprise/api/divisions/department";
import ApiLegalEntityCounterparty from "@rmp/core/api/types/counterparty/apiLegalEntityCounterparty";
import ApiLegalEntityCounterpartyPersisted from "@rmp/core/api/types/counterparty/apiLegalEntityCounterpartyPersisted";
import { EnterprisePropertyController } from "@rmp/enterprise/api/enterpriseProperty";
import { ApiTariff } from "@rmp/core/api/types/service/tariffs/apiTariff";
import { BoatController } from "@rmp/enterprise/api/boat";
import { ApiPierPersisted } from "@rmp/core/api/types/enterpriseProperty/apiPier";
import { PierServiceApplication } from "@rmp/core/types/services/pier/pierServiceApplication";
import { ServiceApplicationPropertyTariff } from "@rmp/core/types/services/serviceApplicationPropertyTariff";
import { ServiceApplicationTariff, ServiceApplicationTariffMapper } from "@rmp/core/types/services/serviceApplicationTariff";
import { parseWorkingTime } from "@rmp/core/utils/dates";
import { ServiceController } from "@rmp/enterprise/api/service";
import ServiceMapperProfile from "@rmp/core/api/mappers/service";
import { LegalEntityCounterparty, LegalEntityCounterpartyMapper } from "@rmp/core/types/counterparty/legalEntity/legalEntityCounterparty";
import { LegalPersonCounterparty, LegalPersonCounterpartyMapper } from "@rmp/core/types/counterparty/legalPerson/legalPersonCounterparty";
import ApiLegalPersonCounterparty from "@rmp/core/api/types/counterparty/apiLegalPersonCounterparty";
import { ServiceApplicationPier } from "@rmp/core/types/services/pier/serviceApplicationPier";
import { ApiCounterpartyBoat } from "@rmp/core/api/types/counterpartyBoat/apiCounterpartyBoat";

const abortService = new AbortService();
const page = usePageStore(abortService);

const agreementController = new AgreementController(abortService);
const counterpartyController = new CounterpartyController(abortService);
const portController = new PortController(abortService);
const departmentController = new DepartmentController(abortService);
const enterprisePropertyController = new EnterprisePropertyController(abortService);
const boatController = new BoatController(abortService);
const serviceController = new ServiceController(abortService);

export interface ServiceDetailsGeneralState extends PageState {
	id: string | null;
	service: PierServiceApplication;
	agreement: ApiAgreementPersisted;
	legalEntity: LegalEntityCounterparty | null;
	legalPerson: LegalPersonCounterparty | null;
	port: ApiPort;
	department: ApiDepartment;
	boat: ApiCounterpartyBoat;
	tariffs: ServiceApplicationPropertyTariff[];
	preferredTariffs: ServiceApplicationPropertyTariff[];
}

const getDefaultState = (): ServiceDetailsGeneralState => {
	return {
		...page.getDefaultPageState(),
		id: null,
		service: {} as PierServiceApplication,
		agreement: {} as ApiAgreementPersisted,
		legalEntity: null,
		legalPerson: null,
		department: {} as ApiDepartment,
		port: {} as ApiPort,
		boat: {} as ApiCounterpartyBoat,
		tariffs: [],
		preferredTariffs: []
	};
};

export const usePierServiceGeneralStore = defineStore({
	id: "pier-service-details-general",
	state: (): ServiceDetailsGeneralState => getDefaultState(),
	getters: {
		...page.getters,
		breadcrumbs() {
			const { breadcrumbs } = usePierServiceStore();
			return breadcrumbs;
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			await this.fetch();
		},
		async fetch() {
			try {
				let { service } = usePierServiceStore();
				
				const tasks: Promise<any>[] = [
					agreementController.getAgreement(service.agreementId),
					counterpartyController.getCounterparty<ApiLegalEntityCounterpartyPersisted>(service.initiatorCounterpartyId,
						ApiLegalEntityCounterpartyPersisted),
					departmentController.getDepartment(service.divisionId),
					portController.getPort(service.divisionId, service.portId),
					boatController.getBoat(service.counterpartyBoatId),
					serviceController.getTariffs(ServiceMapperProfile.mapApiServiceType(service.type))
				];
				
				const pierTasks: Promise<ApiPierPersisted>[] = [];
				
				uniqBy([...service.assignedPiers, ...service.requestedPiers], x => x.pierId).forEach(x => {
					pierTasks.push(enterprisePropertyController.getPier(x.pierId));
				});
				
				const [agreement, apiCounterparty, { division: department }, { division: port }, { counterpartyBoatPersisted }, { tariffs }] = await Promise.all(
					[...tasks, pierTasks]);
				
				const piers = await Promise.all(pierTasks);
				
				let propertyGroups = groupBy(service.assignedPiers, x => x.pierId);
				let preferredGroups = groupBy(service.requestedPiers, x => x.pierId);
				
				const prepareTariffs = (groups: { [key: string]: ServiceApplicationPier[] }) => {
					let result: ServiceApplicationPropertyTariff[] = [];
					
					for (const [propertyId, items] of Object.entries(groups)) {
						let internalTariffs: ServiceApplicationTariff[] = [];
						
						for (const item of items) {
							let tariff = (tariffs as ApiTariff[]).find(x => x.id === item.tariffId);
							if(!tariff || !item.totalAmount) continue;
							
							internalTariffs.push({
								...ServiceApplicationTariffMapper.map(tariff),
								value: item.workingTime ? parseWorkingTime(item.workingTime) : 0,
								amount: item.totalAmount.amount,
								vatIncluded: item.totalAmount.vatIncluded
							});
						}
						
						result.push({
							propertyName: piers.find(y => y.id === propertyId)!.pier.name,
							tariffs: internalTariffs
						});
					}
					
					return result;
				};
				
				
				if(apiCounterparty.counterparty instanceof ApiLegalPersonCounterparty)
					this.legalPerson = LegalPersonCounterpartyMapper.mapFromApiLegalPerson(apiCounterparty.counterparty);
				if(apiCounterparty.counterparty instanceof ApiLegalEntityCounterparty)
					this.legalEntity = LegalEntityCounterpartyMapper.map(apiCounterparty as ApiLegalEntityCounterpartyPersisted);
				
				this.agreement = agreement;
				this.department = department;
				this.port = port;
				this.boat = counterpartyBoatPersisted.counterpartyBoat;
				this.tariffs = prepareTariffs(propertyGroups);
				this.preferredTariffs = prepareTariffs(preferredGroups);
				this.service = cloneDeep(service);
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		}
	}
});
