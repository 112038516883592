import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { CounterpartyController } from "@rmp/core/api/counterparty";
import {
	CounterpartyEmployeeGeneral,
	CounterpartyEmployeeGeneralMapper
} from "@rmp/core/types/counterpartyEmployee/counterpartyEmployeeGeneral";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import {
	useCounterpartyEmployeeBreadcrumb
} from "@rmp/enterprise/stores/counterpartyEmployee/composables/useCounterpartyEmployeeBreadcrumb";
import { useCounterpartiesBreadcrumb } from "@rmp/enterprise/stores/counterparties/composables/useCounterpartiesBreadcrumb";
import { useCounterpartyDetailsBreadcrumb } from "@rmp/enterprise/stores/counterpartyDetails/composables/useCounterpartyDetailsBreadcrumb";
import router from "@rmp/enterprise/router";
import ApiCounterpartyPersistedBase from "@rmp/core/api/types/counterparty/apiCounterpartyPersistedBase";
import ApiLegalEntityCounterparty from "@rmp/core/api/types/counterparty/apiLegalEntityCounterparty";
import ApiLegalPersonCounterparty from "@rmp/core/api/types/counterparty/apiLegalPersonCounterparty";
import { i18n } from "@rmp/core/plugins";
import { useCounterpartyDetailsEmployeesBreadcrumb } from "@rmp/enterprise/stores/counterpartyDetails/employees/composables/useCounterpartyDetailsEmployeesBreadcrumb";
import { formatFullName } from "@rmp/core/utils/formatting";
import { TrustController } from "@rmp/core/api/counterparty/trust";
import CounterpartyEmployeeMapperProfile from "@rmp/enterprise/stores/counterpartyEmployee/mapper";
import {
	CounterpartyEmployeeTrust,
	CounterpartyEmployeeTrustMapper
} from "@rmp/core/types/counterpartyEmployee/trust/counterpartyEmployeeTrust";
import ApiCounterpartyEmployeeTrustPersisted from "@rmp/core/api/types/counterparty/trust/apiCounterpartyEmployeeTrustPersisted";
import { formatDate } from "@rmp/core/utils/dates";
import { dateTimeFormat } from "@rmp/core/utils/formats";
import { CertificateInfoMapper } from "@rmp/core/types/certificateInfo";
import { TrustActionerKind } from "@rmp/core/types/counterpartyEmployee/trust/TrustActionerKind";
import { StorageController } from "@rmp/core/api/storage";

const abortService = new AbortService();
const counterpartyController = new CounterpartyController(abortService);
const trustController = new TrustController(abortService);
const storageController = new StorageController(abortService);

const page = usePageStore(abortService);

export interface CounterpartyEmployeeState extends PageState {
	employeeId: string;
	counterpartyId: string;
	employee: Omit<CounterpartyEmployeeGeneral, "isActive">;
	loading: boolean;
	counterpartyTitle: string;
	activeTrusts: CounterpartyEmployeeTrust[];
}

const getDefaultState = (): CounterpartyEmployeeState => {
	return {
		...page.getDefaultPageState(),
		counterpartyId: "",
		employeeId: "",
		loading: false,
		counterpartyTitle: "",
		employee: {
			inn: "",
			email: "",
			phone: "",
			position: "",
			snils: "",
			firstName: "",
			middleName: "",
			lastName: ""
		} as Omit<CounterpartyEmployeeGeneral, "isActive">,
		activeTrusts: []
	};
};

export const useCounterpartyEmployeeStore = defineStore({
	id: "counterparty-employee",
	state: (): CounterpartyEmployeeState => getDefaultState(),
	getters: {
		...page.getters,
		breadcrumbs(state: CounterpartyEmployeeState) {
			return [
				useCounterpartiesBreadcrumb(),
				useCounterpartyDetailsBreadcrumb(state.counterpartyTitle, { id: state.counterpartyId }),
				useCounterpartyDetailsEmployeesBreadcrumb({ id: state.counterpartyId }),
				useCounterpartyEmployeeBreadcrumb(formatFullName(this.employee))
			];
		},
		formattedActiveTrusts(state: CounterpartyEmployeeState) {
			return state.activeTrusts.map(x => ({
				...x,
				file: x.fileId,
				acceptedDate: formatDate(x.acceptedAt, dateTimeFormat),
				expireDate: formatDate(x.expireAt, dateTimeFormat)
			}));
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized() {
			this.counterpartyId = router.currentRoute.params.counterpartyId;
			this.employeeId = router.currentRoute.params.employeeId;
			
			await this.fetch();
		},
		async fetch() {
			this.loading = true;
			
			try {
				const trustTasks = [];
				const tasks: Promise<any>[] = [
					counterpartyController.getCounterparty<ApiCounterpartyPersistedBase>(this.counterpartyId, ApiCounterpartyPersistedBase),
					counterpartyController.getCounterpartyEmployee(this.employeeId, this.counterpartyId),
					trustController.getCounterpartyEmployeeActiveTrusts(CounterpartyEmployeeMapperProfile.mapToApiGetCounterpartyEmployeeActiveTrustsParameters(
						this))
				];
				
				const [{ counterparty }, employeePersisted, trusts] = await Promise.all(tasks);
				
				this.activeTrusts = trusts.map((x: ApiCounterpartyEmployeeTrustPersisted) => CounterpartyEmployeeTrustMapper.map(x));
				
				this.activeTrusts.forEach(x => trustTasks.push([
					this.fetchCertificateInfo(x),
					this.fetchAcceptorInfo(x)
				]));
				
				if(counterparty instanceof ApiLegalEntityCounterparty) {
					this.counterpartyTitle = counterparty.shortName;
				} else if(counterparty instanceof ApiLegalPersonCounterparty) {
					this.counterpartyTitle = `${i18n.t("common.legalPerson")} "${counterparty.fullName}"`;
				}
				
				if(employeePersisted) {
					this.employee = CounterpartyEmployeeGeneralMapper.map(employeePersisted.employee);
				}
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.loading = false;
			}
		},
		async fetchCertificateInfo(trust: CounterpartyEmployeeTrust) {
			if(!trust.isSigned)
				return;
			
			try {
				const { signatures } = await storageController.getFileMeta(trust.fileId);
				
				const [signature] = signatures;
				const [certificate] = signature.certificates;
				
				trust.certificateInfo = CertificateInfoMapper.map(certificate);
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		},
		async fetchAcceptorInfo(trust: CounterpartyEmployeeTrust) {
			try {
				trust.actionerFullName = await trustController.getCounterpartyTrustActioner(
					trust.id,
					this.employeeId,
					this.counterpartyId,
					TrustActionerKind.ACCEPTOR
				);
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		}
	}
});
