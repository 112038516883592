import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { DocumentsState, useDocumentsStore } from "@rmp/enterprise/stores/composables/baseDocuments";
import {
	useFloatingCraneServiceDocumentsBreadcrumb
} from "@rmp/enterprise/stores/serviceDetails/floatingCrane/documents/composables/useFloatingCraneServiceDocumentsBreadcrumb";
import { useFloatingCraneServiceStore } from "@rmp/enterprise/stores/serviceDetails/floatingCrane";
import { ServiceDocument } from "@rmp/core/types/services/serviceDocument";

const abortService = new AbortService();
const page = usePageStore(abortService);

const documentsStore = useDocumentsStore<ServiceDocument>({});

export interface FloatingCraneServiceDocumentsState extends PageState, DocumentsState<ServiceDocument> {
}

const getDefaultState = (): FloatingCraneServiceDocumentsState => {
	return {
		...page.getDefaultPageState(),
		...documentsStore.getDefaultState()
	};
};

export const useFloatingCraneServiceDocumentsStore = defineStore({
	id: "floating-crane-service-documents",
	state: (): FloatingCraneServiceDocumentsState => getDefaultState(),
	getters: {
		...page.getters,
		...documentsStore.getters,
		breadcrumbs() {
			const { breadcrumbs } = useFloatingCraneServiceStore();
			return [...breadcrumbs, useFloatingCraneServiceDocumentsBreadcrumb()];
		}
	},
	actions: {
		...page.actions,
		...documentsStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			const { documents } = useFloatingCraneServiceStore();
			
			this.id = id;
			this.documents = documents;
		}
	}
});
