import { ApiPersistedBase } from "@rmp/core/api/types/base/apiPersistedBase";
import { Pier } from "@rmp/core/types/enterpriseProperty/pier";
import { ApiDepartmentPersisted } from "@rmp/core/api/types/divisions/department/apiDepartment";

export interface ApiPier {
	name: string;
	depth: string;
	length: string;
	status: string;
	isArchived: boolean;
}

export interface ApiPierPersisted extends ApiPersistedBase {
	pier: ApiPier;
}

export interface ApiPiersItem {
	pierPersisted: ApiPierPersisted;
	divisionPersisted: ApiDepartmentPersisted;
}

export class ApiPierMapper {
	static map(source: Pier): ApiPier {
		return {
			...source
		};
	}
}
