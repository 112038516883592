import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { useServicesBreadcrumb } from "@rmp/enterprise/stores/services/composables/useServicesBreadcrumb";
import { useCrewboatServiceBreadcrumb } from "@rmp/enterprise/stores/serviceDetails/crewboat/composables/useCrewboatServiceBreadcrumb";
import { CrewboatServiceController } from "@rmp/enterprise/api/service/crewboat";
import { CrewboatServiceApplication, CrewboatServiceApplicationMapper } from "@rmp/core/types/services/crewboat/crewboatServiceApplication";
import { BaseServiceState, useBaseServiceStore } from "@rmp/enterprise/stores/composables/baseService";
import { formatServiceTitle } from "@rmp/core/utils/formatting";

const abortService = new AbortService();
const page = usePageStore(abortService);
const baseServiceStore = useBaseServiceStore({ abortService });

const crewboatServiceController = new CrewboatServiceController(abortService);

export interface CrewboatServiceState extends PageState, BaseServiceState {
	service: CrewboatServiceApplication;
}

const getDefaultState = (): CrewboatServiceState => {
	return {
		...page.getDefaultPageState(),
		...baseServiceStore.getDefaultState(),
		service: {} as CrewboatServiceApplication
	};
};

export const useCrewboatServiceStore = defineStore({
	id: "crewboat-service",
	state: (): CrewboatServiceState => getDefaultState(),
	getters: {
		...page.getters,
		...baseServiceStore.getters,
		breadcrumbs(state: CrewboatServiceState) {
			return [
				useServicesBreadcrumb(),
				useCrewboatServiceBreadcrumb(state.title)
			];
		}
	},
	actions: {
		...page.actions,
		...baseServiceStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			
			await Promise.all([
				this.fetch(),
				this.fetchDocuments()
			]);
			
			await this.initializeBaseServiceStore(this.service.type);
		},
		async fetch() {
			try {
				const { serviceApplication } = await crewboatServiceController.getService(this.id!);
				
				this.service = CrewboatServiceApplicationMapper.map(serviceApplication);
				this.title = formatServiceTitle(this.service.type, this.service.applicationNumber);
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		}
	}
});
