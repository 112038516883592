import { difference } from "@rmp/core/utils/difference";
import { Dictionary } from "vue-router/types/router";
import { plainToInstance } from "class-transformer";
import { validateOrReject } from "class-validator";
import { ApplicationFilterStatusType } from "@rmp/core/types/ApplicationFilterStatusType";
import RouteQueryService from "@rmp/core/services/route/routeQueryService";
import BranchesRouteQuery from "@rmp/enterprise/stores/divisions/branches/types/branchesRouteQuery";
import { BranchesState } from "@rmp/enterprise/stores/divisions/branches";

export default class BranchesRouteQueryService implements RouteQueryService {
	defaultRouteQuery: BranchesRouteQuery;
	
	constructor(defaultRouteQuery: BranchesRouteQuery) {
		this.defaultRouteQuery = defaultRouteQuery;
	}
	
	private mapStateToQuery(state: BranchesState) {
		return new BranchesRouteQuery(
			state.sorting.type,
			state.sorting.order,
			state.search.query,
			state.filter.status
		);
	}
	
	public resolveRouteQueryDictionary(state: BranchesState) {
		let query = this.mapStateToQuery(state);
		
		return difference(this.defaultRouteQuery, query);
	}
	
	public async hasRouteChanges(state: BranchesState, route: Dictionary<any>): Promise<{ [key: string]: any }> {
		let stateQuery = this.mapStateToQuery(state);
		let routeQuery = await this.resolveRouteQuery(route);
		
		return difference(routeQuery, stateQuery);
	}
	
	public async resolveRouteQuery(query: Dictionary<any>) {
		try {
			let result = plainToInstance(BranchesRouteQuery, {
				...this.defaultRouteQuery,
				...query,
				status: query.status || ApplicationFilterStatusType.ALL
			}, { enableImplicitConversion: true });
			
			await validateOrReject(result);
			
			return result;
		} catch (e) {
			console.error(e);
			return this.defaultRouteQuery;
		}
	}
}
