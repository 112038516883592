import { convertToTimestamp } from "@rmp/core/utils/dates";
import { CrewBoatsItem } from "@rmp/enterprise/stores/crewboats/types/crewBoatsItem";
import { CrewBoatsState } from "@rmp/enterprise/stores/crewboats/index";
import ApiGetCrewBoatsParameters from "@rmp/core/api/types/enterpriseProperty/apiGetCrewBoatsParameters";
import { ApiCrewboatPersisted, ApiCrewboatsItem } from "@rmp/core/api/types/enterpriseProperty/apiCrewboat";
import { ArchiveStatusType } from "@rmp/core/types/ArchiveStatusType";

export default class CrewBoatsMapperProfile {
	constructor() {
	}
	
	mapToApiGetCrewBoatsParameters(source: CrewBoatsState): ApiGetCrewBoatsParameters {
		return {
			take: source.paging.pageSize,
			skip: source.paging.pageSize * (source.paging.page - 1),
			query: source.search.query,
			sortField: source.sorting.type,
			sortingDirection: source.sorting.order,
			isArchived: ArchiveStatusType.ALL === source.filter.status ? null : source.filter.status === ArchiveStatusType.ARCHIVED
		};
	}
	
	mapToCrewBoatsItem(source: ApiCrewboatsItem): CrewBoatsItem {
		return {
			...source,
			...source.crewboatPersisted.crewboat,
			id: source.crewboatPersisted.id,
			createdAt: convertToTimestamp(source.crewboatPersisted.createdAt) as number,
			exclusionGRT: source.crewboatPersisted.crewboat?.exclusionGRT || 0,
			yearOfBuilding: source.crewboatPersisted.crewboat?.yearOfBuilding || 0,
			department: source.divisionPersisted.division.shortName
		};
	}
}
