import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { RouteNames } from "@rmp/enterprise/router/routes";
import router from "@rmp/enterprise/router";
import { CounterpartyController } from "@rmp/core/api/counterparty";
import { convertIsoToNumber, formatDate } from "@rmp/core/utils/dates";
import { dateTimeFormat } from "@rmp/core/utils/formats";
import { formatFullName } from "@rmp/core/utils/formatting";
import { RegisterState, useRegisterStore } from "@rmp/core/stores/composables/register/useRegisterStore";
import PermissionsService from "@rmp/core/services/permissionsService";
import ApiCounterpartyEmployeeWithTrustInfo from "@rmp/core/api/types/counterparty/ApiCounterpartyEmployeeWithTrustInfo";
import { Permissions } from "@rmp/core/constants/permissions";
import { AuthorizationScopeType } from "@rmp/core/types/authorization/authorizationScopeType";
import { useCounterpartyDetailsEmployeesBreadcrumb } from "@rmp/enterprise/stores/counterpartyDetails/employees/composables/useCounterpartyDetailsEmployeesBreadcrumb";
import { SortingOrderTypeEnum } from "@rmp/core/types/SortingOrderTypeEnum";
import CounterpartyDetailsEmployeesRouteQueryService
	from "@rmp/enterprise/stores/counterpartyDetails/employees/services/counterpartyDetailsEmployeesRouteQueryService";
import { useCounterpartyDetailsStore } from "@rmp/enterprise/stores/counterpartyDetails";
import CounterpartyDetailsEmployeesRouteQuery
	from "@rmp/enterprise/stores/counterpartyDetails/employees/types/counterpartyDetailsEmployeesRouteQuery";

const abortService = new AbortService();
const counterpartyController = new CounterpartyController(abortService);
const permissionsService = new PermissionsService();

export interface CounterpartyDetailsEmployeesState extends PageState, RegisterState<ApiCounterpartyEmployeeWithTrustInfo>
{
	id: string;
}

const defaultRouteQuery = new CounterpartyDetailsEmployeesRouteQuery(
	1,
	"number",
	SortingOrderTypeEnum.DESCENDING,
	""
);
const routeQueryService = new CounterpartyDetailsEmployeesRouteQueryService(defaultRouteQuery);

const page = usePageStore(abortService);

const register = useRegisterStore<ApiCounterpartyEmployeeWithTrustInfo>({
	routeName: RouteNames.COUNTERPARTY_DETAILS_EMPLOYEES,
	routeQueryService,
	routeQuery: defaultRouteQuery,
	getRouter: () => router
});

const getDefaultState = (): CounterpartyDetailsEmployeesState => {
	return {
		...page.getDefaultPageState(),
		...register.getDefaultRegisterState(),
		id: ""
	};
};

export const useCounterpartyDetailsEmployeesStore = defineStore({
	id: "counterparty-employees",
	state: (): CounterpartyDetailsEmployeesState => getDefaultState(),
	getters: {
		...register.getters,
		...page.getters,
		breadcrumbs(state: CounterpartyDetailsEmployeesState) {
			const { breadcrumbs } = useCounterpartyDetailsStore();
			
			return [
				...breadcrumbs,
				useCounterpartyDetailsEmployeesBreadcrumb({ id: state.id })
			];
		},
		formattedItems(state: CounterpartyDetailsEmployeesState) {
			return state.listing.items.map(({ employee, trust }) => {
				return {
					id: employee.id,
					fullName: formatFullName({ ...employee.employee }),
					email: employee.employee.email,
					position: employee.employee.position,
					isActive: employee.employee.isActive,
					createdAt: formatDate(convertIsoToNumber(employee.createdAt), dateTimeFormat),
					number: employee.number,
					trust: trust.status
				};
			});
		},
		filteredItems() {
			return this.formattedItems;
		}
	},
	actions: {
		...page.actions,
		...register.actions,
		async beforeInitialized() {
			this.id = router.currentRoute.params.id;
			
			await this.initializeRegister();
		},
		async fetchRegisterItems() {
			const scope = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_READ_LIST])
				? AuthorizationScopeType.GLOBAL
				: AuthorizationScopeType.OWN;
			
			let employees: ApiCounterpartyEmployeeWithTrustInfo[] = 
				await counterpartyController.getCounterpartyEmployees(this.id, scope);
			return { items: employees };
		}
	}
});
