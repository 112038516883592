import { ApiMetricsByCounterpartyItem } from "@rmp/core/api/types/metrics/apiMetricsByCounterpartyItem";

export interface MetricsByCounterpartyItem {
	newCounterpartyCount: number;
	newEmployeesCount: number;
}

export class MetricsByCounterpartyItemMapper {
	static map(source: ApiMetricsByCounterpartyItem): MetricsByCounterpartyItem {
		return {
			...source
		}
	}
}
