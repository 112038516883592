import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { EnterprisePropertyController } from "@rmp/enterprise/api/enterpriseProperty";
import router from "@rmp/enterprise/router";
import { SnapshotKeysEnum, SnapshotState, useSnapshotStore } from "@rmp/core/stores/composables/snapshot";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import { RouteNames } from "@rmp/enterprise/router/routes";
import { FormState, useFormStore } from "@rmp/core/stores/composables/form/useFormStore";
import { Tugboat, TugboatMapper } from "@rmp/core/types/enterpriseProperty/tugboat";
import { ApiTugboatMapper } from "@rmp/core/api/types/enterpriseProperty/apiTugboat";
import { useTugboatBreadcrumb } from "@rmp/enterprise/stores/property/tugboat/composables/useTugboatBreadcrumb";
import { i18n } from "@rmp/core/plugins";
import { useTugboatsBreadcrumb } from "@rmp/enterprise/stores/tugboats/composables/useTugboatsBreadcrumb";
import { BasePropertyGeneralState, useBasePropertyGeneralStore } from "@rmp/enterprise/stores/composables/basePropertyGeneral";
import { AxaptaController } from "@rmp/enterprise/api/axapta";

const abortService = new AbortService();

const enterprisePropertyController = new EnterprisePropertyController(abortService);
const axaptaController = new AxaptaController(abortService);

const page = usePageStore(abortService);
const baseBasePropertyGeneralStore = useBasePropertyGeneralStore({ abortService });
const form = useFormStore();
const snapshotStore = useSnapshotStore([
	{
		key: SnapshotKeysEnum.LAST_SAVED, fields: ["tugboat", "calculationObjectCode"]
	}
]);

export interface TugboatState extends PageState, SnapshotState, FormState, BasePropertyGeneralState {
	boatName: string;
	tugboat: Tugboat;
}

const getDefaultState = (): TugboatState => {
	return {
		...page.getDefaultPageState(),
		...baseBasePropertyGeneralStore.getDefaultState(),
		...snapshotStore.getDefaultState(),
		...form.getDefaultState(),
		boatName: "",
		tugboat: {} as Tugboat,
	};
};

export const useTugboatPropertyStore = defineStore({
	id: "tugboat",
	state: (): TugboatState => getDefaultState(),
	getters: {
		...page.getters,
		...baseBasePropertyGeneralStore.getters,
		...snapshotStore.getters,
		...form.getters,
		breadcrumbs(state: TugboatState) {
			const routeName = state.id ? RouteNames.TUGBOAT : RouteNames.TUGBOAT_CREATE;
			const text = state.id ? state.tugboat.name : String(i18n.t("titles.tugboatCreate"));
			
			return [
				useTugboatsBreadcrumb(),
				useTugboatBreadcrumb(routeName, text, { id: state.id })
			];
		}
	},
	actions: {
		...page.actions,
		...baseBasePropertyGeneralStore.actions,
		...snapshotStore.actions,
		...form.actions,
		async beforeInitialized({ id }: { id: string | undefined }) {
			this.setStateSnapshot();
			
			await this.fetchBranches();
			
			if(!id) return;
			
			this.id = id;
			
			const [{ divisionId }] = await Promise.all([
				enterprisePropertyController.getTugboatDivision(id),
				this.fetch()
			]);
			
			await this.initializeBasePropertyGeneralStore(divisionId);
			
			this.setStateSnapshot();
		},
		async fetch() {
			try {
				let tugboat = await enterprisePropertyController.getTugboat(this.id);
				
				this.tugboat = TugboatMapper.map(tugboat);
				this.boatName = this.tugboat.name;
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		},
		async save() {
			this.formSaving = true;
			
			const payload = ApiTugboatMapper.map(this.tugboat);
			
			try {
				if(this.id) {
					const tugboat = await enterprisePropertyController.updateTugboat(this.id, payload);
					const { calculationObjectCode } = await axaptaController.updateCalculationObjectCode(this.id,
						this.calculationObjectCode);
					
					this.tugboat = TugboatMapper.map(tugboat);
					this.tugboat.id = this.id;
					this.boatName = this.tugboat.name;
					this.calculationObjectCode = calculationObjectCode;
					
					this.setStateSnapshot();
					
					alertService.addSuccess(AlertKeys.SUCCESS_UPDATED_INFO);
					
					await router.push({ name: RouteNames.TUGBOAT_DETAILS, params: { id: this.id } });
				} else {
					let { id } = await enterprisePropertyController.createTugboat(payload);
					
					await Promise.all([
						enterprisePropertyController.assignTugboatToDivision(id, this.divisionId),
						axaptaController.updateCalculationObjectCode(id, this.calculationObjectCode)
					]);
					
					this.setStateSnapshot();
					
					alertService.addSuccess(AlertKeys.SUCCESS_CREATED_INFO);
					
					await router.push({ name: RouteNames.TUGBOAT_DETAILS, params: { id } });
				}
				
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.formSaving = false;
			}
		}
	}
});
