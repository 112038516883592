import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { SortingOrderTypeEnum } from "@rmp/core/types/SortingOrderTypeEnum";
import { RouteNames } from "@rmp/enterprise/router/routes";
import router from "@rmp/enterprise/router";
import ServicesRouteQuery from "@rmp/enterprise/stores/services/types/servicesRouteQuery";
import ServicesRouteQueryService from "@rmp/enterprise/stores/services/services/servicesRouteQueryService";
import { RegisterState, useRegisterStore } from "@rmp/core/stores/composables/register/useRegisterStore";
import { ServicesItem } from "@rmp/enterprise/stores/services/types/servicesItem";
import ServicesMapperProfile from "@rmp/enterprise/stores/services/mapper";
import { ServicesFilter } from "@rmp/enterprise/stores/services/types/servicesFilter";
import { ServiceController } from "@rmp/enterprise/api/service";
import { ServicesFilterStatusEnum } from "@rmp/core/types/enums/ServicesFilterStatusEnum";

const abortService = new AbortService();
const serviceController = new ServiceController(abortService);

const mapper = new ServicesMapperProfile();

const defaultRouteQuery = new ServicesRouteQuery(
	1,
	"number",
	SortingOrderTypeEnum.DESCENDING,
	"",
	[],
	[],
	ServicesFilterStatusEnum.ACTIVE);

const routeQueryService = new ServicesRouteQueryService(defaultRouteQuery);

const page = usePageStore(abortService);

export interface ServicesState extends PageState, RegisterState<ServicesItem, ServicesFilter>
{
	filter: ServicesFilter;
}


const register = useRegisterStore<ServicesItem, ServicesFilter>({
	routeName: RouteNames.SERVICES,
	routeQueryService,
	routeQuery: defaultRouteQuery,
	getRouter: () => router
});

const getDefaultState = (): ServicesState => {
	return {
		...page.getDefaultPageState(),
		...register.getDefaultRegisterState(),
		filter: {
			statuses: [],
			serviceTypes: [],
			status: ServicesFilterStatusEnum.ACTIVE
		}
	};
};

export const useServicesStore = defineStore({
	id: "services",
	state: (): ServicesState => getDefaultState(),
	getters: {
		...register.getters,
		...page.getters,
		filteredItems(state: ServicesState) {
			return state.listing.items;
		}
	},
	actions: {
		...page.actions,
		...register.actions,
		async beforeInitialized() {
			await this.initializeRegister();
		},
		patchStateWithRouteQuery(query: ServicesRouteQuery) {
			this.patchRegisterStateWithRouteQuery(query);
			this.$patch((state) => {
				state.filter.statuses = query.statuses;
				state.filter.serviceTypes = query.serviceTypes;
				state.filter.status = query.status;
			});
		},
		async fetchRegisterItems() {
			let { items, totalCount } = await serviceController.getServices(mapper.mapToApiGetServicesParameters(this));
			return {
				items: items.map(x => mapper.mapToServicesItem(x)),
				totalCount
			};
		}
	}
});
