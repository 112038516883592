import AbortService from "@rmp/core/services/abortService";
import { urls } from "@rmp/enterprise/api/config/urls";
import { ApiChatMessage } from "@rmp/core/api/types/chat/apiChatMessage";
import BaseEnterpriseController from "@rmp/enterprise/api/base/baseEnterpriseController";
import { ApiServicesItem } from "@rmp/core/api/types/service/apiServicesItem";
import { ApiServiceHistoryEntryPersisted } from "@rmp/core/api/types/service/apiServiceHistoryEntry";
import { ApiServiceDocument } from "@rmp/core/api/types/service/document/apiServiceDocument";
import { ApiGetServicesParameters } from "@rmp/core/api/types/service/apiGetServicesParameters";
import { ApiTariff } from "@rmp/core/api/types/service/tariffs/apiTariff";
import { ApiGetServiceFeedbackResponsePersisted } from "@rmp/core/api/types/service/apiGetServiceFeedbackResponse";

export class ServiceController extends BaseEnterpriseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getServices = async (parameters: ApiGetServicesParameters) => {
		return await this.get<{ items: ApiServicesItem[], totalCount: number }>(urls.service, parameters);
	};
	
	getHistory = async (serviceApplicationId: string) => {
		return await this.get<{ history: ApiServiceHistoryEntryPersisted[] }>(urls.service.$serviceApplicationId.history,
			{ serviceApplicationId });
	};
	
	getChatMessages = async (serviceApplicationId: string) => {
		return await this.get<{ messages: ApiChatMessage[] }>(urls.service.$serviceApplicationId.chat.message, { serviceApplicationId });
	};
	
	createChatMessage = async (serviceApplicationId: string, message: string): Promise<ApiChatMessage> => {
		return await this.post(urls.service.$serviceApplicationId.chat.message, { serviceApplicationId }, { message });
	};
	
	getDocuments = async (serviceApplicationId: string) => {
		return await this.get<{ documents: ApiServiceDocument[] }>(urls.service.$serviceApplicationId.document, { serviceApplicationId });
	};
	
	getTariffs = async (serviceType: string, archived?: boolean) => {
		return await this.get<{ tariffs: ApiTariff[] }>(urls.service.$serviceType.tariff, { serviceType, isArchived: archived });
	};
	
	archive = async (serviceApplicationId: string) => {
		return await this.post(urls.service.$serviceApplicationId.archive, { serviceApplicationId }, {});
	};
	
	unarchive = async (serviceApplicationId: string) => {
		return await this.post(urls.service.$serviceApplicationId.unarchive, { serviceApplicationId }, {});
	};
	
	getFeedback = async (id: string) => {
		return await this.get<ApiGetServiceFeedbackResponsePersisted | null>(urls.service.$id.feedback, { id });
	};
	
	/**
	 * Метод получения шаблона договора для услуг без объекта договора (Дноуглубление, Геодезические и тд)
	 */
	getAgreementTemplate = async (serviceId: string) => {
		return await this.get<Blob>(urls.service.$id.agreement.template, { id: serviceId }, { responseType: "blob" });
	};
	
	deleteDocumentSignature = async (serviceId: string, documentId: string) => {
		return await this.delete(urls.service.$id.document.$documentId.signature, { id: serviceId, documentId });
	};
}
