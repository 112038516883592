import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import {
	UploadAgreementDocumentsStepTypeEnum
} from "@rmp/enterprise/stores/uploadAgreementDocuments/steps/shared/UploadAgreementDocumentsStepTypeEnum";
import {
	UploadAgreementDocumentsStepState
} from "@rmp/enterprise/stores/uploadAgreementDocuments/steps/shared/uploadAgreementDocumentsStepState";
import { FormState, useFormStore } from "@rmp/core/stores/composables/form/useFormStore";
import { useUploadAgreementDocumentsStore } from "@rmp/enterprise/stores/uploadAgreementDocuments";
import { convertToZonedIso, formatDate, formatISODate, parseISODate } from "@rmp/core/utils/dates";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { AgreementController } from "@rmp/enterprise/api/agreement";
import { isoDate } from "@rmp/core/utils/formats";

const abortService = new AbortService();
const agreementController = new AgreementController(abortService);

const page = usePageStore(abortService);
const form = useFormStore();

export interface DurationStepState extends UploadAgreementDocumentsStepState, PageState, FormState {
	startDate: Date | null;
	endDate: Date | null;
	agreementDate: Date | null;
}

const getDefaultState = (): DurationStepState => {
	return {
		...page.getDefaultPageState(),
		...form.getDefaultState(),
		startDate: null,
		endDate: null,
		agreementDate: null,
		stepType: UploadAgreementDocumentsStepTypeEnum.DURATION
	};
};

export const useDurationStepStore = defineStore({
	id: "upload-agreement-documents-duration-step",
	state: (): DurationStepState => getDefaultState(),
	getters: {
		...page.getters,
		...form.getters,
		completed(state) {
			return state.formValid;
		},
		breadcrumbs() {
			const { breadcrumbs } = useUploadAgreementDocumentsStore();
			return breadcrumbs;
		}
	},
	actions: {
		...page.actions,
		...form.actions,
		async beforeInitialized() {
		},
		async save() {
			const { agreement } = useUploadAgreementDocumentsStore();
			const { endDate, startDate, agreementDate } = useDurationStepStore();
			
			this.formSaving = true;

			try {
				await Promise.all([
					agreementController.saveAgreementDate(agreement.id,
						JSON.stringify(convertToZonedIso(agreementDate!))),
					agreementController.saveDuration(agreement.id, {
						start: convertToZonedIso(startDate!),
						end: endDate ? convertToZonedIso(endDate) : null
					})
				]);
			} catch (ex) {
				console.error(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.formSaving = false;
			}
		}
	}
});
