import { defineStore } from "pinia";
import NotImplementedException from "@rmp/core/exceptions/notImplementedException";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { TariffAssignmentStepTypeEnum } from "@rmp/enterprise/stores/assignment/tariff/steps/shared/TariffAssignmentStepTypeEnum";
import { useTariffStepStore } from "@rmp/enterprise/stores/assignment/tariff/steps/tariff";
import { useRouteStepStore } from "@rmp/enterprise/stores/assignment/tariff/steps/route";
import { ServiceTypeEnum } from "@rmp/core/types/services/ServiceTypeEnum";
import { Property } from "@rmp/enterprise/stores/assignment/tariff/steps/types/property";
import router from "@rmp/enterprise/router";
import { RouteNames } from "@rmp/enterprise/router/routes";
import { TugboatServiceController } from "@rmp/enterprise/api/service/tugboat";
import { CrewboatServiceController } from "@rmp/enterprise/api/service/crewboat";
import { PierServiceController } from "@rmp/enterprise/api/service/pier";
import { TugboatServiceApplication, TugboatServiceApplicationMapper } from "@rmp/core/types/services/tugboat/tugboatServiceApplication";
import { CrewboatServiceApplication, CrewboatServiceApplicationMapper } from "@rmp/core/types/services/crewboat/crewboatServiceApplication";
import { PierServiceApplication, PierServiceApplicationMapper } from "@rmp/core/types/services/pier/pierServiceApplication";
import { useServicesBreadcrumb } from "@rmp/enterprise/stores/services/composables/useServicesBreadcrumb";
import { useTugboatServiceBreadcrumb } from "@rmp/enterprise/stores/serviceDetails/tugboat/composables/useTugboatServiceBreadcrumb";
import { i18n } from "@rmp/enterprise/plugins";
import { useTariffAssignmentBreadcrumb } from "@rmp/enterprise/stores/assignment/tariff/composables/useTariffAssignmentBreadcrumb";
import { useCrewboatServiceBreadcrumb } from "@rmp/enterprise/stores/serviceDetails/crewboat/composables/useCrewboatServiceBreadcrumb";
import { usePierServiceBreadcrumb } from "@rmp/enterprise/stores/serviceDetails/pier/composables/usePierServiceBreadcrumb";
import { SelectedProperty } from "@rmp/enterprise/stores/assignment/tariff/steps/types/selectedProperty";
import { ServiceWorkflowStateType } from "@rmp/core/types/services/ServiceWorkflowStateType";
import * as uuid from "uuid";
import { formatServiceTitle } from "@rmp/core/utils/formatting";

const abortService = new AbortService();
const tugboatServiceController = new TugboatServiceController(abortService);
const crewboatServiceController = new CrewboatServiceController(abortService);
const pierServiceController = new PierServiceController(abortService);

const page = usePageStore(abortService);

export interface TariffAssignmentState extends PageState {
	id: string;
	title: string;
	currentStep: TariffAssignmentStepTypeEnum;
	serviceType: ServiceTypeEnum;
	service: TugboatServiceApplication | PierServiceApplication | CrewboatServiceApplication;
	properties: Property[];
	selectedProperties: SelectedProperty[];
}

const getDefaultState = (): TariffAssignmentState => {
	return {
		...page.getDefaultPageState(),
		id: "",
		title: "",
		currentStep: TariffAssignmentStepTypeEnum.TARIFF,
		serviceType: ServiceTypeEnum.TUGBOAT_SERVICE,
		service: {} as TugboatServiceApplication,
		properties: [],
		selectedProperties: []
	};
};

export const useTariffAssignmentStore = defineStore({
	id: "tariff-assignment",
	state: (): TariffAssignmentState => getDefaultState(),
	getters: {
		...page.getters,
		steps(): TariffAssignmentStepTypeEnum[] {
			return [
				TariffAssignmentStepTypeEnum.TARIFF,
				TariffAssignmentStepTypeEnum.ROUTE
			];
		},
		nextStepAvailable(state): boolean {
			switch (state.currentStep) {
				case TariffAssignmentStepTypeEnum.TARIFF:
				{
					const { completed } = useTariffStepStore();
					return completed;
				}
				case TariffAssignmentStepTypeEnum.ROUTE:
				{
					const { completed } = useRouteStepStore();
					return completed;
				}
				default:
					throw new NotImplementedException("nextStepAvailable");
			}
		},
		prevStepAvailable(): boolean {
			return this.currentStepIndex !== 0;
		},
		currentStepIndex(): number {
			return this.steps.indexOf(this.currentStep);
		},
		breadcrumbs(state: TariffAssignmentState) {
			let usePropertyBreadcrumb;
			let route;
			switch (state.serviceType) {
				case ServiceTypeEnum.TUGBOAT_SERVICE:
					usePropertyBreadcrumb = useTugboatServiceBreadcrumb;
					route = RouteNames.TUGBOAT_SERVICE_TARIFF_ASSIGNMENT;
					break;
				case ServiceTypeEnum.CREWBOAT_SERVICE:
					usePropertyBreadcrumb = useCrewboatServiceBreadcrumb;
					route = RouteNames.CREWBOAT_SERVICE_TARIFF_ASSIGNMENT;
					break;
				case ServiceTypeEnum.PIER_SERVICE:
					usePropertyBreadcrumb = usePierServiceBreadcrumb;
					route = RouteNames.PIER_SERVICE_TARIFF_ASSIGNMENT;
					break;
				default:
					throw new Error();
			}
			
			return [
				useServicesBreadcrumb(),
				usePropertyBreadcrumb(state.title, { id: state.id }),
				useTariffAssignmentBreadcrumb({ name: route }, i18n.t(`navigation.breadcrumbs.${route}`).toString())
			];
		},
		documentsUploadEnabled(state) {
			if(!state.service.workflowState) return;
			return state.service.workflowState.alias === ServiceWorkflowStateType.PROVIDING &&
				(state.serviceType === ServiceTypeEnum.TUGBOAT_SERVICE || state.serviceType === ServiceTypeEnum.CREWBOAT_SERVICE);
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			
			await this.fetch();
			
			this.title = formatServiceTitle(this.service.type, this.service.applicationNumber);
		},
		async fetch() {
			switch (router.currentRoute.name) {
				case RouteNames.TUGBOAT_SERVICE_TARIFF_ASSIGNMENT:
				{
					let { serviceApplication } = await tugboatServiceController.getService(this.id!);
					let allTugboatsOfDepartment = await tugboatServiceController.getServiceTugboats(serviceApplication.divisionId);
					
					let assignedTugboatIds = serviceApplication.assignedTugBoats.map(x => x.tugBoatId);
					
					let assignedTugboats = allTugboatsOfDepartment.filter(x => assignedTugboatIds.includes(x.id));
					
					this.service = TugboatServiceApplicationMapper.map(serviceApplication);
					this.properties = assignedTugboats.map(x => ({ id: x.id, name: x.tugboat.name }));
					this.serviceType = ServiceTypeEnum.TUGBOAT_SERVICE;
					
					const selected = this.service.assignedTugBoats.length && this.service.assignedTugBoats.every(x => x.tariffId) ?
						this.service.assignedTugBoats :
						this.service.requestedTugBoats;
					this.selectedProperties = selected.map(x => ({ ...x, propertyId: x.tugboatId }));
					break;
				}
				case RouteNames.CREWBOAT_SERVICE_TARIFF_ASSIGNMENT:
				{
					let { serviceApplication } = await crewboatServiceController.getService(this.id!);
					let allCrewboatsOfDepartment = await crewboatServiceController.getServiceCrewboats(serviceApplication.divisionId);
					
					let assignedCrewboatIds = serviceApplication.assignedCrewBoats.map(x => x.crewBoatId);
					
					let assignedCrewboats = allCrewboatsOfDepartment.filter(x => assignedCrewboatIds.includes(x.id));
					
					this.service = CrewboatServiceApplicationMapper.map(serviceApplication);
					this.properties = assignedCrewboats.map(x => ({ id: x.id, name: x.crewboat.name }));
					this.serviceType = ServiceTypeEnum.CREWBOAT_SERVICE;
					
					const selected = this.service.assignedCrewBoats.length && this.service.assignedCrewBoats.every(x => x.tariffId && x.tariffId !== uuid.NIL) ?
						this.service.assignedCrewBoats :
						this.service.requestedCrewBoats;
					this.selectedProperties = selected.map(x => ({ ...x, propertyId: x.crewboatId }));
					
					break;
				}
				case RouteNames.PIER_SERVICE_TARIFF_ASSIGNMENT:
				{
					let { serviceApplication } = await pierServiceController.getService(this.id!);
					let allPiersOfDepartment = await pierServiceController.getServicePiers(serviceApplication.divisionId);
					
					let assignedPierIds = serviceApplication.assignedPiers.map(x => x.pierId);
					
					let assignedPiers = allPiersOfDepartment.filter(x => assignedPierIds.includes(x.id));
					
					this.service = PierServiceApplicationMapper.map(serviceApplication);
					this.properties = assignedPiers.map(x => ({ id: x.id, name: x.pier.name }));
					this.serviceType = ServiceTypeEnum.PIER_SERVICE;
					
					const selected = this.service.assignedPiers.length && this.service.assignedPiers.every(x => x.tariffId) ?
						this.service.assignedPiers :
						this.service.requestedPiers;
					this.selectedProperties = selected.map(x => ({ ...x, propertyId: x.pierId }));
					
					break;
				}
				default:
					throw new Error();
			}
		},
		moveToNextStep() {
			this.currentStep = this.steps[this.currentStepIndex + 1];
		},
		moveToPrevStep() {
			this.currentStep = this.steps[this.currentStepIndex - 1];
		}
	}
});
