import { ApiCounterpartyBankAccount } from "@rmp/core/api/types/counterpartyBankAccount/apiCounterpartyBankAccount";

export interface CounterpartyBankAccount {
	bankAccountNumber: string;
	bankIdentificationCode: string;
	bankName: string;
	correspondentAccount: string;
}


export class CounterpartyBankAccountMapper {
	static map(source: ApiCounterpartyBankAccount): CounterpartyBankAccount {
		return {
			...source
		};
	}
	
	static getEmpty(): CounterpartyBankAccount {
		return {
			bankAccountNumber: "",
			bankIdentificationCode: "",
			bankName: "",
			correspondentAccount: ""
		};
	}
}
