import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAgreementDetailsStore } from "@rmp/enterprise/stores/agreementDetails";
import { CounterpartyController } from "@rmp/core/api/counterparty";
import ApiLegalEntityCounterparty from "@rmp/core/api/types/counterparty/apiLegalEntityCounterparty";
import ApiLegalEntityCounterpartyPersisted from "@rmp/core/api/types/counterparty/apiLegalEntityCounterpartyPersisted";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { LegalEntityCounterparty, LegalEntityCounterpartyMapper } from "@rmp/core/types/counterparty/legalEntity/legalEntityCounterparty";
import { LegalPersonCounterparty, LegalPersonCounterpartyMapper } from "@rmp/core/types/counterparty/legalPerson/legalPersonCounterparty";
import ApiLegalPersonCounterparty from "@rmp/core/api/types/counterparty/apiLegalPersonCounterparty";
import ApiCounterpartyPersistedBase from "@rmp/core/api/types/counterparty/apiCounterpartyPersistedBase";
import { AgreementController } from "@rmp/enterprise/api/agreement";
import { AuthorizationScopeType } from "@rmp/core/types/authorization/authorizationScopeType";

const abortService = new AbortService();
const page = usePageStore(abortService);

const counterpartyController = new CounterpartyController(abortService);
const agreementController = new AgreementController(abortService);

export interface AgreementDetailsGeneralState extends PageState {
	id: string | null;
	remainingLimit: number;
	legalEntity: LegalEntityCounterparty | null;
	legalPerson: LegalPersonCounterparty | null;
}

const getDefaultState = (): AgreementDetailsGeneralState => {
	return {
		...page.getDefaultPageState(),
		id: null,
		remainingLimit: 0,
		legalEntity: null,
		legalPerson: null
	};
};

export const useAgreementDetailsGeneralStore = defineStore({
	id: "agreement-details-general",
	state: (): AgreementDetailsGeneralState => getDefaultState(),
	getters: {
		...page.getters,
		breadcrumbs() {
			const { breadcrumbs } = useAgreementDetailsStore();
			return breadcrumbs;
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			
			await this.fetch();
		},
		async fetch() {
			try {
				const { agreement } = useAgreementDetailsStore();
				
				const [{ remainingLimit }, apiCounterparty] = await Promise.all([
					agreementController.getRemainingLimit(agreement.id),
					counterpartyController.getCounterparty<ApiCounterpartyPersistedBase>(agreement.counterpartyId,
						ApiCounterpartyPersistedBase)
				]);
				
				this.remainingLimit = remainingLimit;
				
				if(apiCounterparty.counterparty instanceof ApiLegalPersonCounterparty)
					this.legalPerson = LegalPersonCounterpartyMapper.mapFromApiLegalPerson(apiCounterparty.counterparty);
				if(apiCounterparty.counterparty instanceof ApiLegalEntityCounterparty)
					this.legalEntity = LegalEntityCounterpartyMapper.map(apiCounterparty as ApiLegalEntityCounterpartyPersisted);
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		}
	}
});
