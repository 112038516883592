import { Dictionary } from "vue-router/types/router";
import { useBreadcrumb } from "@rmp/core/stores/composables/breadcrumb/useBreadcrumb";
import { RouteNames } from "@rmp/enterprise/router/routes";
import routeToPermissionsMap from "@rmp/enterprise/router/routeToPermissionsMap";
import { i18n } from "@rmp/enterprise/plugins";

export function useCounterpartyDetailsDocumentsBreadcrumb(params: Dictionary<string>) {
	return useBreadcrumb({
		route: {
			name: RouteNames.COUNTERPARTY_DETAILS_DOCUMENTS, params
		},
		text: i18n.t(`navigation.breadcrumbs.${RouteNames.COUNTERPARTY_DETAILS_DOCUMENTS}`).toString(),
		routeToPermissionsMap
	});
}
