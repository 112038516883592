import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";

const abortService = new AbortService();
const page = usePageStore(abortService);

export interface MetricsState extends PageState {
	reportDownloading: boolean;
}

const getDefaultState = (): MetricsState => {
	return {
		...page.getDefaultPageState(),
		reportDownloading: false
	};
};

export const useMetricsStore = defineStore({
	id: "metrics",
	state: (): MetricsState => getDefaultState(),
	getters: {
		...page.getters
	},
	actions: {
		...page.actions,
		async beforeInitialized() {
		}
	}
});
