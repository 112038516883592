import { difference } from "@rmp/core/utils/difference";
import { Dictionary } from "vue-router/types/router";
import { plainToInstance } from "class-transformer";
import { validateOrReject } from "class-validator";
import RouteQueryService from "@rmp/core/services/route/routeQueryService";
import DepartmentPortsRouteQuery from "@rmp/enterprise/stores/divisions/department/ports/types/departmentPortsRouteQuery";
import { DepartmentPortsFilterStatusEnum } from "@rmp/enterprise/stores/divisions/department/ports/types/DepartmentPortsFilterStatusEnum";
import { PortsState } from "@rmp/enterprise/stores/divisions/department/ports";

export default class DepartmentPortsRouteQueryService implements RouteQueryService {
	defaultRouteQuery: DepartmentPortsRouteQuery;
	
	constructor(defaultRouteQuery: DepartmentPortsRouteQuery) {
		this.defaultRouteQuery = defaultRouteQuery;
	}
	
	private mapStateToQuery(state: PortsState) {
		return new DepartmentPortsRouteQuery(
			state.sorting.type,
			state.sorting.order,
			state.search.query,
			state.filter.status
		);
	}
	
	public resolveRouteQueryDictionary(state: PortsState) {
		let query = this.mapStateToQuery(state);
		
		return difference(this.defaultRouteQuery, query);
	}
	
	public async hasRouteChanges(state: PortsState, route: Dictionary<any>): Promise<{ [key: string]: any }> {
		let stateQuery = this.mapStateToQuery(state);
		let routeQuery = await this.resolveRouteQuery(route);
		
		return difference(routeQuery, stateQuery);
	}
	
	public async resolveRouteQuery(query: Dictionary<any>) {
		try {
			let result = plainToInstance(DepartmentPortsRouteQuery, {
				...this.defaultRouteQuery,
				...query,
				status: query.status || DepartmentPortsFilterStatusEnum.ALL
			}, { enableImplicitConversion: true });
			
			await validateOrReject(result);
			
			return result;
		} catch (e) {
			console.error(e);
			return this.defaultRouteQuery;
		}
	}
}