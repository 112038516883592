import { IsString, IsInt, Min, IsIn, Max, Length, IsArray } from "class-validator";
import { SortingOrderTypeEnum } from "@rmp/core/types/SortingOrderTypeEnum";
import RegisterRouteQuery from "@rmp/core/stores/composables/register/types/registerRouteQuery";
import { AVAILABLE_SORTS } from "@rmp/enterprise/stores/counterpartyDetails/employees/constants";

export default class CounterpartyDetailsEmployeesRouteQuery implements RegisterRouteQuery {
	@IsInt()
	@Min(0)
	@Max(1000)
	page: number;
	
	@IsString()
	@IsIn(AVAILABLE_SORTS)
	sort: string;
	
	@IsString()
	@IsIn([SortingOrderTypeEnum.DESCENDING, SortingOrderTypeEnum.ASCENDING])
	sortDirection: string;
	
	@IsString()
	@Length(0, 100)
	query: string;
	
	constructor(
		page: number,
		sort: string,
		sortDirection: string,
		query: string,
	)
	{
		this.page = page;
		this.sort = sort;
		this.sortDirection = sortDirection;
		this.query = query;
	}
}
