import { AgreementsState } from "@rmp/enterprise/stores/agreements/index";
import ApiGetAgreementsParameters from "@rmp/core/api/types/agreement/apiGetAgreementsParameters";
import { ServicesFilterStatusEnum } from "@rmp/core/types/enums/ServicesFilterStatusEnum";

export default class AgreementsMapperProfile {
	constructor() {
	}
	
	mapToApiGetAgreementsParameters(source: AgreementsState): ApiGetAgreementsParameters {
		return {
			take: source.paging.pageSize,
			skip: source.paging.pageSize * (source.paging.page - 1),
			query: source.search.query,
			sortField: source.sorting.type,
			sortingDirection: source.sorting.order,
			states: source.filter.states,
			serviceTypes: source.filter.serviceTypes,
			isArchived: ServicesFilterStatusEnum.ALL === source.filter.status ? null : source.filter.status === ServicesFilterStatusEnum.ARCHIVED
		};
	}
}
