import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { ServiceController } from "@rmp/enterprise/api/service";
import { FeedbackState, useFeedbackStore } from "@rmp/core/stores/composables/feedback";
import {
	useSnoInstallationServiceFeedbackBreadcrumb
} from "@rmp/enterprise/stores/serviceDetails/snoInstallation/feedback/composables/useSnoInstallationServiceFeedbackBreadcrumb";
import { useSnoInstallationServiceStore } from "@rmp/enterprise/stores/serviceDetails/snoInstallation";

const abortService = new AbortService();
const page = usePageStore(abortService);

const serviceController = new ServiceController(abortService);
const feedbackStore = useFeedbackStore({ controller: serviceController });

export interface SnoInstallationServiceFeedbackState extends PageState, FeedbackState {
}

const getDefaultState = (): SnoInstallationServiceFeedbackState => {
	return {
		...page.getDefaultPageState(),
		...feedbackStore.getDefaultState()
	};
};

export const useSnoInstallationServiceFeedbackStore = defineStore({
	id: "sno-installation-service-feedback",
	state: (): SnoInstallationServiceFeedbackState => getDefaultState(),
	getters: {
		...page.getters,
		...feedbackStore.getters,
		breadcrumbs() {
			const { breadcrumbs } = useSnoInstallationServiceStore();
			return [...breadcrumbs, useSnoInstallationServiceFeedbackBreadcrumb()];
		}
	},
	actions: {
		...page.actions,
		...feedbackStore.actions,
		async beforeInitialized(this: any, { id }: { id: string }) {
			this.id = id;
			await this.initializeFeedbackStore();
		}
	}
});
