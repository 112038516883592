import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { useBranchesBreadcrumb } from "@rmp/enterprise/stores/divisions/branches/composables/useBranchesBreadcrumb";
import { useBranchBreadcrumb } from "@rmp/enterprise/stores/divisions/branch/composables/useBranchesBreadcrumb";
import { usePortBreadcrumb } from "@rmp/enterprise/stores/divisions/port/composables/usePortBreadcrumb";
import { PortController } from "@rmp/enterprise/api/divisions/port";
import { DepartmentController } from "@rmp/enterprise/api/divisions/department";
import { BranchController } from "@rmp/enterprise/api/divisions/branch";
import ApiBranch from "@rmp/core/api/types/divisions/branch/apiBranch";
import { useDepartmentsBreadcrumb } from "@rmp/enterprise/stores/divisions/branch/departments/composables/useDepartmentsBreadcrumb";
import { ApiDepartment } from "@rmp/core/api/types/divisions/department/apiDepartment";
import { useDepartmentBreadcrumb } from "@rmp/enterprise/stores/divisions/department/composables/useDepartmentBreadcrumb";
import { usePortsBreadcrumb } from "@rmp/enterprise/stores/divisions/department/ports/composables/usePortsBreadcrumb";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import { ApiPort } from "@rmp/core/api/types/divisions/port/apiPort";
import router from "@rmp/enterprise/router";
import { PortDestinationController } from "@rmp/enterprise/api/divisions/port/destination";
import { ApiPortDestination } from "@rmp/core/api/types/divisions/port/destination/apiPortDestinationPersisted";
import { usePortDestinationsBreadcrumb } from "@rmp/enterprise/stores/divisions/port/destinations/composables/usePortDestinationsBreadcrumb";
import { usePortDestinationDetailsBreadcrumb } from "@rmp/enterprise/stores/divisions/portDestinationDetails/composables/usePortDestinationDetailsBreadcrumb";
import { useOwnEmployeeStore } from "@rmp/enterprise/stores/ownEmployee";
import { ArchiveStatusState, useArchiveStatusStore } from "@rmp/core/stores/composables/archiveStatus";

const abortService = new AbortService();
const page = usePageStore(abortService);
const archiveStatusStore = useArchiveStatusStore();

const departmentController = new DepartmentController(abortService);
const portController = new PortController(abortService);
const branchController = new BranchController(abortService);
const portDestinationController = new PortDestinationController(abortService);

export interface PortDestinationState extends PageState, ArchiveStatusState {
	branch: ApiBranch;
	department: ApiDepartment,
	port: ApiPort;
	portDestination: ApiPortDestination;
	branchId: string;
	departmentId: string;
	portId: string;
	id: string;
	statusUpdating: boolean;
}

const getDefaultState = (): PortDestinationState => {
	return {
		...page.getDefaultPageState(),
		...archiveStatusStore.getDefaultState(),
		branch: {} as ApiBranch,
		department: {} as ApiDepartment,
		port: {} as ApiPort,
		portDestination: {} as ApiPortDestination,
		branchId: "",
		departmentId: "",
		portId: "",
		id: "",
		statusUpdating: false
	};
};

export const usePortDestinationDetailsStore = defineStore({
	id: "port-destination-details",
	state: (): PortDestinationState => getDefaultState(),
	getters: {
		...page.getters,
		...archiveStatusStore.getters,
		breadcrumbs(state: PortDestinationState) {
			let result = [];
			
			const { belongsToDepartment } = useOwnEmployeeStore();
			
			if(!belongsToDepartment) {
				result.push(useBranchesBreadcrumb(),
					useBranchBreadcrumb(state.branch.shortName, { id: state.branchId }),
					useDepartmentsBreadcrumb({ id: state.branchId }));
			}
			
			result.push(useDepartmentBreadcrumb(state.department.shortName, { id: state.departmentId }),
				usePortsBreadcrumb({ id: state.portId }),
				usePortBreadcrumb(state.port.fullName, { id: state.portId }),
				usePortDestinationsBreadcrumb(),
				usePortDestinationDetailsBreadcrumb(state.portDestination.fullName, { id: state.id }));
			
			return result;
		}
	},
	actions: {
		...page.actions,
		...archiveStatusStore.actions,
		async beforeInitialized() {
			this.branchId = router.currentRoute.params.branchId;
			this.departmentId = router.currentRoute.params.departmentId;
			this.portId = router.currentRoute.params.portId;
			this.id = router.currentRoute.params.destinationId;
			
			await this.fetch();
		},
		async fetch() {
			try {
				const [department, branch, port, portDestination] = await Promise.all([
					departmentController.getDepartment(this.departmentId),
					branchController.getBranch(this.branchId),
					portController.getPort(this.departmentId, this.portId),
					portDestinationController.getPortDestination(this.portId, this.id)
				]);
				
				this.department = department.division;
				this.branch = branch.division;
				this.port = port.division;
				this.portDestination = portDestination.portDestination;
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		},
		async updateArchiveStatus() {
			if(this.portDestination.isArchived)
				await portDestinationController.unarchive(this.portId, this.id);
			else
				await portDestinationController.archive(this.portId, this.id);
			
			this.portDestination.isArchived = !this.portDestination.isArchived;
			
			alertService.addSuccess(AlertKeys.PORT_STATUS_UPDATED);
		}
	}
});
