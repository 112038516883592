import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { ServiceController } from "@rmp/enterprise/api/service";
import { useHistoryStore } from "@rmp/core/stores/composables/history";
import ServiceHistoryMapperProfile, { ServiceHistoryEntry } from "@rmp/core/types/services/serviceHistoryEntry";
import { useDredgingServiceHistoryBreadcrumb } from "@rmp/enterprise/stores/serviceDetails/dredging/history/composables/useDredgingServiceHistoryBreadcrumb";
import { useDredgingServiceStore } from "@rmp/enterprise/stores/serviceDetails/dredging";

const abortService = new AbortService();
const page = usePageStore(abortService);

const serviceController = new ServiceController(abortService);

const mapper = new ServiceHistoryMapperProfile();
const historyStore = useHistoryStore({ controller: serviceController, mapper });

export interface DredgingServiceHistoryState extends PageState {
	id: string | null;
	history: ServiceHistoryEntry[];
}

const getDefaultState = (): DredgingServiceHistoryState => {
	return {
		...page.getDefaultPageState(),
		...historyStore.getDefaultState(),
		id: null,
		history: []
	};
};

export const useDredgingServiceHistoryStore = defineStore({
	id: "dredging-service-history",
	state: (): DredgingServiceHistoryState => getDefaultState(),
	getters: {
		...page.getters,
		...historyStore.getters,
		breadcrumbs() {
			const { breadcrumbs } = useDredgingServiceStore();
			return [...breadcrumbs, useDredgingServiceHistoryBreadcrumb()];
		}
	},
	actions: {
		...page.actions,
		...historyStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			await this.initializeHistoryStore();
		}
	}
});
