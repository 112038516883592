import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { useAppStore } from "@rmp/core/stores/appStore";
import { useDepartmentDivisionStore } from "@rmp/enterprise/stores/divisions/department";
import { ApiDepartment } from "@rmp/core/api/types/divisions/department/apiDepartment";

const abortService = new AbortService();
const page = usePageStore(abortService);

export interface DepartmentDivisionGeneralState extends PageState {
	branchId: string | null;
	id: string | null;
	department: ApiDepartment;
}

const getDefaultState = (): DepartmentDivisionGeneralState => {
	return {
		...page.getDefaultPageState(),
		branchId: null,
		id: null,
		department: {} as ApiDepartment
	};
};

export const useDepartmentDivisionGeneralStore = defineStore({
	id: "department-general",
	state: (): DepartmentDivisionGeneralState => getDefaultState(),
	getters: {
		...page.getters,
		breadcrumbs() {
			const { breadcrumbs } = useDepartmentDivisionStore();
			return breadcrumbs;
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized({ branchId, id }: { branchId: string, id: string }) {
			this.branchId = branchId;
			this.id = id;
			
			await this.fetch();
		},
		async fetch() {
			try {
				let { department } = useDepartmentDivisionStore();
				this.department = department;
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		}
	}
});
