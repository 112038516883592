<template>
	<rmp-nested-content-layout :back-route="{ name: RouteNames.CREWBOATS }">
		<template #title>
			<rmp-details-title-loader></rmp-details-title-loader>
		</template>
		<template #title-append-outer>
			<rmp-btn-loader></rmp-btn-loader>
		</template>
		<template #content>
			<rmp-tabs-loader class="mb-7"></rmp-tabs-loader>
			<rmp-details-card-title-loader></rmp-details-card-title-loader>
			<v-card class="pa-8 pb-1 d-flex align-start flex-wrap"
					:style="{ 'column-gap': $vuetify.breakpoint.lgAndDown ? '56px' : '128px' }" elevation="0">
				<v-row class="d-flex flex-wrap">
					<rmp-details-section-column md="6">
						<rmp-details-group>
							<rmp-details-item-loader v-for="i in 9" :key="i"></rmp-details-item-loader>
						</rmp-details-group>
					</rmp-details-section-column>
					<rmp-details-section-column md="6">
						<rmp-details-group>
							<rmp-details-item-loader v-for="i in 9" :key="i"></rmp-details-item-loader>
						</rmp-details-group>
					</rmp-details-section-column>
				</v-row>
			</v-card>
		</template>
	</rmp-nested-content-layout>
</template>

<script>
import RmpTabsLoader from "@rmp/core/components/loaders/common/RmpTabsLoader.vue";
import RmpDetailsCardTitleLoader from "@rmp/core/components/loaders/details/RmpDetailsCardTitleLoader.vue";
import RmpDetailsItemLoader from "@rmp/core/components/loaders/details/RmpDetailsItemLoader.vue";
import RmpDetailsGroup from "@rmp/core/components/details/RmpDetailsGroup.vue";
import RmpFileLoader from "@rmp/core/components/loaders/common/RmpFileLoader.vue";
import RmpDetailsSectionColumn from "@rmp/core/components/details/RmpDetailsSectionColumn.vue";
import RmpDetailsTitleLoader from "@rmp/core/components/loaders/details/RmpDetailsTitleLoader.vue";
import RmpNestedContentLayout from "@rmp/core/components/layouts/RmpNestedContentLayout.vue";
import RmpBtnLoader from "@rmp/core/components/loaders/form/RmpBtnLoader.vue";
import { RouteNames } from "@rmp/enterprise/router/routes";

export default {
	data() {
		return {
			RouteNames
		};
	},
	components: {
		RmpTabsLoader,
		RmpBtnLoader,
		RmpDetailsTitleLoader,
		RmpNestedContentLayout,
		RmpDetailsItemLoader,
		RmpDetailsGroup,
		RmpDetailsCardTitleLoader,
		RmpFileLoader,
		RmpDetailsSectionColumn
	}
};
</script>
