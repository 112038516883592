import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { SortingOrderTypeEnum } from "@rmp/core/types/SortingOrderTypeEnum";
import { RegisterState, useRegisterStore } from "@rmp/core/stores/composables/register/useRegisterStore";
import { EnterprisePropertyController } from "@rmp/enterprise/api/enterpriseProperty";
import { ArchiveStatusType } from "@rmp/core/types/ArchiveStatusType";
import { RouteNames } from "@rmp/enterprise/router/routes";
import router from "@rmp/enterprise/router";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import FloatingCranesMapperProfile from "@rmp/enterprise/stores/floatingCranes/mapper";
import FloatingCranesFilter from "@rmp/enterprise/stores/floatingCranes/types/floatingCranesFilter";
import FloatingCranesRouteQuery from "@rmp/enterprise/stores/floatingCranes/types/floatingCranesRouteQuery";
import { FloatingCranesItem } from "@rmp/enterprise/stores/floatingCranes/types/floatingCranesItem";
import FloatingCranesRouteQueryService from "@rmp/enterprise/stores/floatingCranes/services/floatingCranesRouteQueryService";
import { ArchiveStatusState, useArchiveStatusStore } from "@rmp/core/stores/composables/archiveStatus";
import NotDefinedException from "@rmp/core/exceptions/notDefinedException";

const abortService = new AbortService();
const enterprisePropertyController = new EnterprisePropertyController(abortService);

const mapper = new FloatingCranesMapperProfile();

export interface FloatingCranesState extends PageState, RegisterState<FloatingCranesItem, FloatingCranesFilter>, ArchiveStatusState
{
	filter: FloatingCranesFilter;
}

const defaultRouteQuery = new FloatingCranesRouteQuery(1,
	"name",
	SortingOrderTypeEnum.ASCENDING,
	"",
	ArchiveStatusType.ALL);

const routeQueryService = new FloatingCranesRouteQueryService(defaultRouteQuery);

const page = usePageStore(abortService);
const archiveStatusStore = useArchiveStatusStore();

const register = useRegisterStore<FloatingCranesItem, FloatingCranesFilter>({
	routeName: RouteNames.FLOATING_CRANES,
	routeQueryService,
	routeQuery: defaultRouteQuery,
	getRouter: () => router
});

const getDefaultState = (): FloatingCranesState => {
	return {
		...page.getDefaultPageState(),
		...register.getDefaultRegisterState(),
		...archiveStatusStore.getDefaultState(),
		filter: new FloatingCranesFilter()
	};
};

export const useFloatingCranesStore = defineStore({
	id: "floating-cranes",
	state: (): FloatingCranesState => getDefaultState(),
	getters: {
		...page.getters,
		...register.getters,
		...archiveStatusStore.getters,
		filteredItems(state: FloatingCranesState): FloatingCranesItem[] {
			return state.listing.items;
		}
	},
	actions: {
		...page.actions,
		...register.actions,
		...archiveStatusStore.actions,
		async beforeInitialized() {
			await this.initializeRegister();
		},
		patchStateWithRouteQuery(query: FloatingCranesRouteQuery) {
			this.patchRegisterStateWithRouteQuery(query);
			this.$patch((state) => {
				state.filter.status = query.status;
			});
		},
		async fetchRegisterItems() {
			const { floatingCranes, totalCount } = await enterprisePropertyController.getFloatingCranes(mapper.mapToApiGetFloatingCranesParameters(
				this));
			return { items: floatingCranes.map(x => mapper.mapToFloatingCranesItem(x)), totalCount };
		},
		async updateArchiveStatus(id: string) {
			if(!id)
				throw new NotDefinedException("id");
			
			const isArchived = this.listing.items.find(x => x.id === id)!.isArchived;
			
			if(isArchived)
				await enterprisePropertyController.unarchiveFloatingCrane(id);
			else
				await enterprisePropertyController.archiveFloatingCrane(id);
			
			await this.updateListingItems();
			
			alertService.addSuccess(AlertKeys.STATUS_SUCCESS_UPDATED);
		}
	}
});
