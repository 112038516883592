import { defineStore } from "pinia";
import AbortService from "@rmp/core/services/abortService";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import {
	InformationTariffAssignmentStepState
} from "@rmp/enterprise/stores/assignment/informationTariff/steps/shared/informationTariffAssignmentStepState";
import {
	TariffAssignmentInformationTariff
} from "@rmp/enterprise/stores/assignment/informationTariff/steps/tariff/types/tariffAssignmentInformationTariff";
import {
	InformationTariffAssignmentStepTypeEnum
} from "@rmp/enterprise/stores/assignment/informationTariff/steps/shared/InformationTariffAssignmentStepTypeEnum";
import { useInformationTariffAssignmentStore } from "@rmp/enterprise/stores/assignment/informationTariff";
import { InformationServiceController } from "@rmp/enterprise/api/service/information";
import { TariffAssignmentTariffMapper } from "@rmp/enterprise/stores/assignment/informationTariff/steps/tariff/types/tariffAssignmentTariff";

const abortService = new AbortService();
const page = usePageStore(abortService);

const informationServiceController = new InformationServiceController(abortService);

export interface InformationTariffStepState extends InformationTariffAssignmentStepState, PageState {
	formValid: boolean;
	tariffs: TariffAssignmentInformationTariff[];
}

const getDefaultState = (): InformationTariffStepState => {
	return {
		...page.getDefaultPageState(),
		formValid: true,
		stepType: InformationTariffAssignmentStepTypeEnum.TARIFF,
		tariffs: []
	};
};

export const useInformationTariffStepStore = defineStore({
	id: "information-tariff-assignment-tariff-step",
	state: (): InformationTariffStepState => getDefaultState(),
	getters: {
		...page.getters,
		completed(state) {
			return state.tariffs.every(x => x.selectedTariffId);
		},
		breadcrumbs() {
			const { breadcrumbs } = useInformationTariffAssignmentStore();
			return breadcrumbs;
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized() {
			await this.fetch();
			this.setDefaultTariffs();
		},
		async fetch() {
			try {
				const { service } = useInformationTariffAssignmentStore();
				
				let apiTariffs = await informationServiceController.getInformationTariffs(service.divisionId);
				
				let tariffs = apiTariffs.map(x => TariffAssignmentTariffMapper.map(x));
				
				this.tariffs.push({
					selectedTariffId: "",
					tariffs
				});
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		},
		setDefaultTariffs() {
			const { service } = useInformationTariffAssignmentStore();
			const defaultTariffId = service.tariff?.tariffId || service.requestedTariff?.tariffId;
			const [tariff] = this.tariffs;
			
			if(defaultTariffId)
				tariff.selectedTariffId = defaultTariffId;
		}
	}
});
