import { convertToTimestamp } from "@rmp/core/utils/dates";
import { FloatingCranesItem } from "@rmp/enterprise/stores/floatingCranes/types/floatingCranesItem";
import ApiGetFloatingCranesParameters from "@rmp/core/api/types/enterpriseProperty/apiGetFloatingCranesParameters";
import { FloatingCranesState } from "@rmp/enterprise/stores/floatingCranes/index";
import { ApiFloatingCranePersisted, ApiFloatingCranesItem } from "@rmp/core/api/types/enterpriseProperty/apiFloatingCrane";
import { ArchiveStatusType } from "@rmp/core/types/ArchiveStatusType";

export default class FloatingCranesMapperProfile {
	constructor() {
	}
	
	mapToApiGetFloatingCranesParameters(source: FloatingCranesState): ApiGetFloatingCranesParameters {
		return {
			take: source.paging.pageSize,
			skip: source.paging.pageSize * (source.paging.page - 1),
			query: source.search.query,
			sortField: source.sorting.type,
			sortingDirection: source.sorting.order,
			isArchived: ArchiveStatusType.ALL === source.filter.status ? null : source.filter.status === ArchiveStatusType.ARCHIVED
		};
	}
	
	mapToFloatingCranesItem(source: ApiFloatingCranesItem): FloatingCranesItem {
		return {
			...source.floatingCranePersisted.floatingCrane,
			id: source.floatingCranePersisted.id,
			createdAt: convertToTimestamp(source.floatingCranePersisted.createdAt) as number,
			exclusionGRT: source.floatingCranePersisted.floatingCrane?.exclusionGRT || 0,
			yearOfBuilding: source.floatingCranePersisted.floatingCrane?.yearOfBuilding || 0,
			department: source.divisionPersisted.division.shortName
		};
	}
}
