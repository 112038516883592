import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { ServiceController } from "@rmp/enterprise/api/service";
import { FeedbackState, useFeedbackStore } from "@rmp/core/stores/composables/feedback";
import { useDredgingServiceFeedbackBreadcrumb } from "@rmp/enterprise/stores/serviceDetails/dredging/feedback/composables/useDredgingServiceFeedbackBreadcrumb";
import { useDredgingServiceStore } from "@rmp/enterprise/stores/serviceDetails/dredging";

const abortService = new AbortService();
const page = usePageStore(abortService);

const serviceController = new ServiceController(abortService);
const feedbackStore = useFeedbackStore({ controller: serviceController });

export interface DredgingServiceFeedbackState extends PageState, FeedbackState {
}

const getDefaultState = (): DredgingServiceFeedbackState => {
	return {
		...page.getDefaultPageState(),
		...feedbackStore.getDefaultState()
	};
};

export const useDredgingServiceFeedbackStore = defineStore({
	id: "dredging-service-feedback",
	state: (): DredgingServiceFeedbackState => getDefaultState(),
	getters: {
		...page.getters,
		...feedbackStore.getters,
		breadcrumbs() {
			const { breadcrumbs } = useDredgingServiceStore();
			return [...breadcrumbs, useDredgingServiceFeedbackBreadcrumb()];
		}
	},
	actions: {
		...page.actions,
		...feedbackStore.actions,
		async beforeInitialized(this: any, { id }: { id: string }) {
			this.id = id;
			await this.initializeFeedbackStore();
		}
	}
});
