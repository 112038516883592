import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { SortingOrderTypeEnum } from "@rmp/core/types/SortingOrderTypeEnum";
import { RegisterState, useRegisterStore } from "@rmp/core/stores/composables/register/useRegisterStore";
import { EnterprisePropertyController } from "@rmp/enterprise/api/enterpriseProperty";
import { ArchiveStatusType } from "@rmp/core/types/ArchiveStatusType";
import { RouteNames } from "@rmp/enterprise/router/routes";
import router from "@rmp/enterprise/router";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import PiersMapperProfile from "@rmp/enterprise/stores/piers/mapper";
import PiersFilter from "@rmp/enterprise/stores/piers/types/piersFilter";
import { PiersItem } from "@rmp/enterprise/stores/piers/types/piersItem";
import PiersRouteQuery from "@rmp/enterprise/stores/piers/types/piersRouteQuery";
import PiersRouteQueryService from "@rmp/enterprise/stores/piers/services/piersRouteQueryService";
import { ArchiveStatusState, useArchiveStatusStore } from "@rmp/core/stores/composables/archiveStatus";
import NotDefinedException from "@rmp/core/exceptions/notDefinedException";

const abortService = new AbortService();
const enterprisePropertyController = new EnterprisePropertyController(abortService);

const mapper = new PiersMapperProfile();

export interface PiersState extends PageState, RegisterState<PiersItem, PiersFilter>, ArchiveStatusState
{
	filter: PiersFilter;
}

const defaultRouteQuery = new PiersRouteQuery(1,
	"name",
	SortingOrderTypeEnum.ASCENDING,
	"",
	ArchiveStatusType.ALL);

const routeQueryService = new PiersRouteQueryService(defaultRouteQuery);

const page = usePageStore(abortService);
const archiveStatusStore = useArchiveStatusStore();

const register = useRegisterStore<PiersItem, PiersFilter>({
	routeName: RouteNames.PIERS,
	routeQueryService,
	routeQuery: defaultRouteQuery,
	getRouter: () => router
});

const getDefaultState = (): PiersState => {
	return {
		...page.getDefaultPageState(),
		...register.getDefaultRegisterState(),
		...archiveStatusStore.getDefaultState(),
		filter: new PiersFilter()
	};
};

export const usePiersStore = defineStore({
	id: "piers",
	state: (): PiersState => getDefaultState(),
	getters: {
		...page.getters,
		...register.getters,
		...archiveStatusStore.getters,
		filteredItems(state: PiersState): PiersItem[] {
			return state.listing.items;
		}
	},
	actions: {
		...page.actions,
		...register.actions,
		...archiveStatusStore.actions,
		async beforeInitialized() {
			await this.initializeRegister();
		},
		patchStateWithRouteQuery(query: PiersRouteQuery) {
			this.patchRegisterStateWithRouteQuery(query);
			this.$patch((state) => {
				state.filter.status = query.status;
			});
		},
		async fetchRegisterItems() {
			const { piers, totalCount } = await enterprisePropertyController.getPiers(mapper.mapToApiGetPiersParameters(this));
			return { items: piers.map(x => mapper.mapToPiersItem(x)), totalCount };
		},
		async updateArchiveStatus(id: string) {
			if(!id)
				throw new NotDefinedException("id");
			
			const isArchived = this.listing.items.find(x => x.id === id)!.isArchived;
			
			if(isArchived)
				await enterprisePropertyController.unarchivePier(id);
			else
				await enterprisePropertyController.archivePier(id);
			
			await this.updateListingItems();
			
			alertService.addSuccess(AlertKeys.STATUS_SUCCESS_UPDATED);
		}
	}
});
