import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { DocumentsState, useDocumentsStore } from "@rmp/enterprise/stores/composables/baseDocuments";
import {
	useInformationServiceDocumentsBreadcrumb
} from "@rmp/enterprise/stores/serviceDetails/information/documents/composables/useInformationServiceDocumentsBreadcrumb";
import { useInformationServiceStore } from "@rmp/enterprise/stores/serviceDetails/information";
import { ServiceDocument } from "@rmp/core/types/services/serviceDocument";

const abortService = new AbortService();
const page = usePageStore(abortService);

const documentsStore = useDocumentsStore<ServiceDocument>({});

export interface InformationServiceDocumentsState extends PageState, DocumentsState<ServiceDocument> {
}

const getDefaultState = (): InformationServiceDocumentsState => {
	return {
		...page.getDefaultPageState(),
		...documentsStore.getDefaultState()
	};
};

export const useInformationServiceDocumentsStore = defineStore({
	id: "information-service-documents",
	state: (): InformationServiceDocumentsState => getDefaultState(),
	getters: {
		...page.getters,
		...documentsStore.getters,
		breadcrumbs() {
			const { breadcrumbs } = useInformationServiceStore();
			return [...breadcrumbs, useInformationServiceDocumentsBreadcrumb()];
		}
	},
	actions: {
		...page.actions,
		...documentsStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			const { documents } = useInformationServiceStore();
			
			this.id = id;
			this.documents = documents;
		}
	}
});
