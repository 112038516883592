import { ApiPortPersisted } from "@rmp/core/api/types/divisions/port/apiPort";
import { PortsItem } from "@rmp/enterprise/stores/divisions/department/ports/types/portsItem";
import { DivisionStatusEnum } from "@rmp/core/api/types/divisions/DivisionStatusEnum";
import { PortsState } from "@rmp/enterprise/stores/divisions/department/ports/index";
import { DepartmentPortsFilterStatusEnum } from "@rmp/enterprise/stores/divisions/department/ports/types/DepartmentPortsFilterStatusEnum";
import ApiGetDepartmentPortsParameters from "@rmp/core/api/types/divisions/port/apiGetDepartmentPortsParameters";

export default class PortsMapperProfile {
	constructor() {
	}
	
	mapToApiGetDepartmentPortsParameters(source: PortsState): ApiGetDepartmentPortsParameters {
		return {
			take: source.paging.pageSize,
			skip: source.paging.pageSize * (source.paging.page - 1),
			query: source.search.query,
			sortField: source.sorting.type,
			sortingDirection: source.sorting.order,
			isArchived: DepartmentPortsFilterStatusEnum.ALL === source.filter.status ?
				null :
				source.filter.status === DepartmentPortsFilterStatusEnum.ARCHIVED
		};
	}
	
	mapFromApi(source: ApiPortPersisted): PortsItem {
		return {
			...source.division,
			id: source.id,
			// TODO: убрать, когда на бэке string заменят на enum
			status: DivisionStatusEnum.ACTIVE
		};
	}
}
