import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import router from "@rmp/enterprise/router";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import { Store } from "pinia";
import ServiceApplicationApiFacade from "@rmp/enterprise/api/service/facade";
import AbortService from "@rmp/core/services/abortService";
import { ServiceController } from "@rmp/enterprise/api/service";
import { ServiceTypeEnum } from "@rmp/core/types/services/ServiceTypeEnum";
import { ApiServiceDocumentKindEnum } from "@rmp/core/api/types/service/document/ApiServiceDocumentKindEnum";
import { ServiceDocument } from "@rmp/core/types/services/serviceDocument";
import { ServiceApplicationBase } from "@rmp/core/types/services/base/serviceApplicationBase";
import { StorageController } from "@rmp/core/api/storage";
import { AgreementController } from "@rmp/enterprise/api/agreement";
import ApiFileMeta from "@rmp/core/api/types/storage/apiFileMeta";
import { FileMetaHelper } from "@rmp/core/types/storage/fileMeta";
import { saveAs } from "file-saver";
import { i18n } from "@rmp/core/plugins";
import { NewServiceDocument } from "@rmp/enterprise/stores/uploadServiceDocuments/types/newServiceDocument";
import { ApiVatEnum } from "@rmp/core/api/types/vat/ApiVatEnum";
import { getServiceRoute } from "@rmp/enterprise/utils/route";

export interface BaseUploadServiceDocumentsState {
	service: ServiceApplicationBase;
	id: string;
	title: string;
	documents: ServiceDocument[],
	newDocuments: NewServiceDocument[],
	saving: boolean;
	approving: boolean;
	templateDownloading: boolean;
}

interface BaseUploadServiceDocumentsOptions {
	abortService: AbortService;
}

export const useBaseUploadServiceDocumentsStore = (options: BaseUploadServiceDocumentsOptions) => {
	type BaseUploadServiceDocumentsStore = Store<string, BaseUploadServiceDocumentsState, {}, {
		[key: string]: any,
		updateArchiveStatus(): void
	}>;
	
	const serviceController = new ServiceController(options.abortService);
	const storageController = new StorageController(options.abortService);
	const agreementController = new AgreementController(options.abortService);
	const serviceApplicationApiFacade = new ServiceApplicationApiFacade(options.abortService);
	
	const getDefaultState = (): BaseUploadServiceDocumentsState => {
		return {
			service: {} as ServiceApplicationBase,
			id: "",
			title: "",
			documents: [] as ServiceDocument[],
			newDocuments: [] as NewServiceDocument[],
			saving: false,
			approving: false,
			templateDownloading: false
		};
	};
	
	const getters = {};
	
	const actions = {
		async initializeBaseUploadServiceDocumentsStore(this: BaseUploadServiceDocumentsState, serviceType: ServiceTypeEnum) {
			if(this.service.totalAmounts.length && !this.service.totalAmounts.every(x => x.vat === ApiVatEnum.NoVat))
				// @ts-ignore
				this.newDocuments.push(this.createDocument(ApiServiceDocumentKindEnum.FACTURE_INVOICE));
			
			serviceApplicationApiFacade.configure(serviceType);
		},
		createDocument(this: BaseUploadServiceDocumentsState, kind: ApiServiceDocumentKindEnum, meta: ApiFileMeta | undefined = undefined) {
			return {
				meta: undefined,
				kind,
				isSigned: false,
				isSigning: false,
				fileName: `${this.title} - ${i18n.t(`service.document.${kind}`)}`
			} as NewServiceDocument;
		},
		addFile(this: BaseUploadServiceDocumentsStore, meta: ApiFileMeta, kind: ApiServiceDocumentKindEnum) {
			let serviceDocument = this.newDocuments.find(x => x.kind === kind)!;
			serviceDocument.meta = FileMetaHelper.map(meta);
		},
		deleteFile(this: BaseUploadServiceDocumentsStore, kind: ApiServiceDocumentKindEnum) {
			let serviceDocument = this.newDocuments.find(x => x.kind === kind)!;
			serviceDocument.meta = undefined;
			serviceDocument.isSigned = false;
		},
		async saveNewDocumentSignature(this: BaseUploadServiceDocumentsStore, kind: string, signature: string) {
			let serviceDocument = this.newDocuments.find(x => x.kind === kind)!;
			serviceDocument.isSigning = true;
			
			try {
				await storageController.signTemper(serviceDocument.meta!.id, signature);
				const meta = await storageController.getTempFileMeta(serviceDocument.meta!.id);
				
				serviceDocument.meta = FileMetaHelper.map(meta);
				
				serviceDocument.isSigned = true;
				await this.fetch(this.id);
			} catch (ex) {
				console.error(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				serviceDocument.isSigning = false;
			}
		},
		async downloadTemplate(this: BaseUploadServiceDocumentsStore) {
			this.templateDownloading = true;
			
			try {
				const file = await serviceController.getAgreementTemplate(this.service.id);
				
				saveAs(file, i18n.tc(`service.agreement.title.${this.service.type}`).concat(` №${this.service.applicationNumber}`));
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.templateDownloading = false;
			}
		},
		async save(this: BaseUploadServiceDocumentsStore) {
			this.saving = true;
			
			try {
				await serviceApplicationApiFacade.saveDocuments(this.service.id,
					this.newDocuments.filter(x => x.meta).map(x => ({
						documentKind: x.kind,
						title: x.meta!.name,
						tempFileId: x.meta!.id
					})));
				
				alertService.addSuccess(AlertKeys.DOCUMENTS_SAVED);
				
				await router.push({ name: getServiceRoute(this.service.type), params: router.currentRoute.params });
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.saving = false;
			}
		},
		async saveSignature(this: BaseUploadServiceDocumentsStore, id: string, signature: string) {
			let serviceDocument = this.documents.find(x => x.fileId === id)!;
			serviceDocument.isSigning = true;
			
			try {
				await storageController.signFile(serviceDocument.fileId, signature);
				
				this.documents = [];
				await this.fetch(this.id);
			} catch (ex) {
				console.error(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				serviceDocument.isSigning = false;
			}
		}
	};
	
	return { getters, actions, getDefaultState };
};
