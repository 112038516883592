import { IsString, IsInt, Min, IsIn, Max, Length, IsEnum } from "class-validator";
import { SortingOrderTypeEnum } from "@rmp/core/types/SortingOrderTypeEnum";
import { AVAILABLE_SORTS } from "@rmp/enterprise/stores/piers/constants";
import RegisterRouteQuery from "@rmp/core/stores/composables/register/types/registerRouteQuery";
import { ArchiveStatusType } from "@rmp/core/types/ArchiveStatusType";

export default class PiersRouteQuery implements RegisterRouteQuery {
	@IsInt()
	@Min(0)
	@Max(1000)
	page: number;
	
	@IsString()
	@IsIn(AVAILABLE_SORTS)
	sort: string;
	
	@IsString()
	@IsIn([SortingOrderTypeEnum.DESCENDING, SortingOrderTypeEnum.ASCENDING])
	sortDirection: string;
	
	@IsString()
	@Length(0, 100)
	query: string;
	
	@IsString()
	@IsEnum(ArchiveStatusType)
	status: ArchiveStatusType;
	
	constructor(page: number, sort: string, sortDirection: string, query: string, status: ArchiveStatusType) {
		this.page = page;
		this.sort = sort;
		this.sortDirection = sortDirection;
		this.query = query;
		this.status = status;
	}
}
