import AbortService from "@rmp/core/services/abortService";
import BaseEnterpriseController from "@rmp/enterprise/api/base/baseEnterpriseController";
import { urls } from "@rmp/enterprise/api/config/urls";
import { ApiTariff } from "@rmp/core/api/types/tariff/apiTariff";
import { ApiGetTariffsParameters } from "@rmp/core/api/types/tariff/apiGetTariffsParameters";

export class TariffController extends BaseEnterpriseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getTariffs = async (parameters: ApiGetTariffsParameters) => {
		return await this.get<{ tariffs: ApiTariff[], totalCount: number }>(urls.service.tariff, parameters);
	};
}
