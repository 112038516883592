import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { EnterprisePropertyController } from "@rmp/enterprise/api/enterpriseProperty";
import router from "@rmp/enterprise/router";
import { SnapshotKeysEnum, SnapshotState, useSnapshotStore } from "@rmp/core/stores/composables/snapshot";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import { RouteNames } from "@rmp/enterprise/router/routes";
import { FormState, useFormStore } from "@rmp/core/stores/composables/form/useFormStore";
import { Pier, PierMapper } from "@rmp/core/types/enterpriseProperty/pier";
import { ApiPierMapper } from "@rmp/core/api/types/enterpriseProperty/apiPier";
import { i18n } from "@rmp/core/plugins";
import { usePiersBreadcrumb } from "@rmp/enterprise/stores/piers/composables/usePiersBreadcrumb";
import { usePierBreadcrumb } from "@rmp/enterprise/stores/property/pier/composables/usePierBreadcrumb";
import { BasePropertyGeneralState, useBasePropertyGeneralStore } from "@rmp/enterprise/stores/composables/basePropertyGeneral";
import { AxaptaController } from "@rmp/enterprise/api/axapta";

const abortService = new AbortService();

const enterprisePropertyController = new EnterprisePropertyController(abortService);
const axaptaController = new AxaptaController(abortService);

const page = usePageStore(abortService);
const baseBasePropertyGeneralStore = useBasePropertyGeneralStore({ abortService });
const form = useFormStore();
const snapshotStore = useSnapshotStore([
	{
		key: SnapshotKeysEnum.LAST_SAVED, fields: ["pier", "calculationObjectCode"]
	}
]);

export interface PierState extends PageState, SnapshotState, FormState, BasePropertyGeneralState {
	pier: Pier;
	title: string;
}

const getDefaultState = (): PierState => {
	return {
		...page.getDefaultPageState(),
		...baseBasePropertyGeneralStore.getDefaultState(),
		...snapshotStore.getDefaultState(),
		...form.getDefaultState(),
		pier: {} as Pier,
		title: ""
	};
};

export const usePierPropertyStore = defineStore({
	id: "pier",
	state: (): PierState => getDefaultState(),
	getters: {
		...page.getters,
		...baseBasePropertyGeneralStore.getters,
		...snapshotStore.getters,
		...form.getters,
		breadcrumbs(state: PierState) {
			const routeName = state.id ? RouteNames.PIER : RouteNames.PIER_CREATE;
			const text = state.id ? state.pier.name : String(i18n.t("titles.pierCreate"));
			
			return [
				usePiersBreadcrumb(),
				usePierBreadcrumb(routeName, text, { id: state.id })
			];
		}
	},
	actions: {
		...page.actions,
		...baseBasePropertyGeneralStore.actions,
		...snapshotStore.actions,
		...form.actions,
		async beforeInitialized({ id }: { id: string | undefined }) {
			this.setStateSnapshot();
			
			await this.fetchBranches();
			if(!id) return;
			
			this.id = id;
			
			const [{ divisionId }] = await Promise.all([
				enterprisePropertyController.getPierDivision(id),
				this.fetch()
			]);
			
			await this.initializeBasePropertyGeneralStore(divisionId);
			
			this.setStateSnapshot();
		},
		async fetch() {
			try {
				let { pier } = await enterprisePropertyController.getPier(this.id);
				
				this.pier = PierMapper.map(pier);
				this.title = this.pier.name;
			} catch (ex) {
				console.error(ex);
				this.formDisabled = true;
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		},
		async save() {
			this.formSaving = true;
			
			const payload = ApiPierMapper.map(this.pier);
			
			try {
				if(this.id) {
					const { pier } = await enterprisePropertyController.updatePier(this.id, payload);
					const { calculationObjectCode } = await axaptaController.updateCalculationObjectCode(this.id,
						this.calculationObjectCode);
					
					this.pier = PierMapper.map(pier);
					this.title = this.pier.name;
					this.calculationObjectCode = calculationObjectCode;
					
					this.setStateSnapshot();
					
					alertService.addSuccess(AlertKeys.SUCCESS_UPDATED_INFO);
					
					await router.push({ name: RouteNames.PIER_DETAILS, params: { id: this.id } });
				} else {
					let { id } = await enterprisePropertyController.createPier(payload);
					
					await Promise.all([
						enterprisePropertyController.assignPierToDivision(id, this.divisionId),
						axaptaController.updateCalculationObjectCode(id, this.calculationObjectCode)
					]);

					this.setStateSnapshot();
					
					alertService.addSuccess(AlertKeys.SUCCESS_CREATED_INFO);
					await router.push({ name: RouteNames.PIER_DETAILS, params: { id } });
				}
				
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.formSaving = false;
			}
		}
	}
});
