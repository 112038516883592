import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { SortingOrderTypeEnum } from "@rmp/core/types/SortingOrderTypeEnum";
import { RouteNames } from "@rmp/enterprise/router/routes";
import router from "@rmp/enterprise/router";
import { RegisterState, useRegisterStore } from "@rmp/core/stores/composables/register/useRegisterStore";
import { AgreementsItem, AgreementsItemMapper } from "@rmp/enterprise/stores/agreements/types/agreementsItem";
import { AgreementsFilter } from "@rmp/enterprise/stores/agreements/types/agreementsFilter";
import AgreementsRouteQueryService from "@rmp/enterprise/stores/agreements/services/agreementsRouteQueryService";
import AgreementsRouteQuery from "@rmp/enterprise/stores/agreements/types/agreementsRouteQuery";
import { AgreementController } from "@rmp/enterprise/api/agreement";
import AgreementsMapperProfile from "@rmp/enterprise/stores/agreements/mapper";
import { ServicesFilterStatusEnum } from "@rmp/core/types/enums/ServicesFilterStatusEnum";

const abortService = new AbortService();
const agreementController = new AgreementController(abortService);

const defaultRouteQuery = new AgreementsRouteQuery(
	1,
	"agreementNumber",
	SortingOrderTypeEnum.DESCENDING,
	"",
	[],
	[],
	ServicesFilterStatusEnum.ACTIVE
);

const routeQueryService = new AgreementsRouteQueryService(defaultRouteQuery);
const mapper = new AgreementsMapperProfile();

const page = usePageStore(abortService);

export interface AgreementsState extends PageState, RegisterState<AgreementsItem, AgreementsFilter>
{
	filter: AgreementsFilter;
}


const register = useRegisterStore<AgreementsItem, AgreementsFilter>({
	routeName: RouteNames.AGREEMENTS,
	routeQueryService,
	routeQuery: defaultRouteQuery,
	getRouter: () => router
});

const getDefaultState = (): AgreementsState => {
	return {
		...page.getDefaultPageState(),
		...register.getDefaultRegisterState(),
		filter: {
			states: [],
			serviceTypes: [],
			status: ServicesFilterStatusEnum.ACTIVE
		}
	};
};

export const useAgreementsStore = defineStore({
	id: "agreements",
	state: (): AgreementsState => getDefaultState(),
	getters: {
		...register.getters,
		...page.getters,
		filteredItems(state: AgreementsState) {
			return state.listing.items;
		}
	},
	actions: {
		...page.actions,
		...register.actions,
		async beforeInitialized() {
			await this.initializeRegister();
		},
		patchStateWithRouteQuery(query: AgreementsRouteQuery) {
			this.patchRegisterStateWithRouteQuery(query);
			this.$patch((state) => {
				state.filter.states = query.states;
				state.filter.serviceTypes = query.serviceTypes;
				state.filter.status = query.status;
			});
		},
		async fetchRegisterItems() {
			let { items, totalCount } = await agreementController.getAgreements(mapper.mapToApiGetAgreementsParameters(this));
			return {
				items: items.map(x => AgreementsItemMapper.map(x)),
				totalCount
			};
		}
	}
});
