import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { useBranchesBreadcrumb } from "@rmp/enterprise/stores/divisions/branches/composables/useBranchesBreadcrumb";
import { useBranchBreadcrumb } from "@rmp/enterprise/stores/divisions/branch/composables/useBranchesBreadcrumb";
import { usePortBreadcrumb } from "@rmp/enterprise/stores/divisions/port/composables/usePortBreadcrumb";
import { PortController } from "@rmp/enterprise/api/divisions/port";
import { DepartmentController } from "@rmp/enterprise/api/divisions/department";
import { BranchController } from "@rmp/enterprise/api/divisions/branch";
import ApiBranch from "@rmp/core/api/types/divisions/branch/apiBranch";
import { useDepartmentsBreadcrumb } from "@rmp/enterprise/stores/divisions/branch/departments/composables/useDepartmentsBreadcrumb";
import { ApiDepartment } from "@rmp/core/api/types/divisions/department/apiDepartment";
import { useDepartmentBreadcrumb } from "@rmp/enterprise/stores/divisions/department/composables/useDepartmentBreadcrumb";
import { usePortsBreadcrumb } from "@rmp/enterprise/stores/divisions/department/ports/composables/usePortsBreadcrumb";
import { DivisionController } from "@rmp/enterprise/api/divisions";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import { ApiPort } from "@rmp/core/api/types/divisions/port/apiPort";
import { useOwnEmployeeStore } from "@rmp/enterprise/stores/ownEmployee";
import { ArchiveStatusState, useArchiveStatusStore } from "@rmp/core/stores/composables/archiveStatus";

const abortService = new AbortService();
const page = usePageStore(abortService);
const archiveStatusStore = useArchiveStatusStore();

const departmentController = new DepartmentController(abortService);
const portController = new PortController(abortService);
const branchController = new BranchController(abortService);
const divisionController = new DivisionController(abortService);

export interface PortDivisionState extends PageState, ArchiveStatusState {
	branch: ApiBranch;
	department: ApiDepartment,
	port: ApiPort;
	branchId: string;
	departmentId: string;
	id: string;
}

const getDefaultState = (): PortDivisionState => {
	return {
		...page.getDefaultPageState(),
		...archiveStatusStore.getDefaultState(),
		branch: {} as ApiBranch,
		department: {} as ApiDepartment,
		port: {} as ApiPort,
		branchId: "",
		departmentId: "",
		id: ""
	};
};

export const usePortDivisionStore = defineStore({
	id: "port",
	state: (): PortDivisionState => getDefaultState(),
	getters: {
		...page.getters,
		...archiveStatusStore.getters,
		breadcrumbs(state) {
			let result = [];
			
			const { belongsToDepartment } = useOwnEmployeeStore();
			
			if(!belongsToDepartment) {
				result.push(useBranchesBreadcrumb(),
					useBranchBreadcrumb(state.branch.shortName, { id: state.branchId }),
					useDepartmentsBreadcrumb({ id: state.branchId }));
			}
			
			result.push(useDepartmentBreadcrumb(state.department.shortName, { id: state.departmentId }),
				usePortsBreadcrumb({ id: state.id }),
				usePortBreadcrumb(state.port.fullName, { id: state.id }));
			
			return result;
		}
	},
	actions: {
		...page.actions,
		...archiveStatusStore.actions,
		async beforeInitialized({ branchId, departmentId, id }: { branchId: string, departmentId: string, id: string }) {
			
			this.branchId = branchId;
			this.departmentId = departmentId;
			this.id = id;
			
			await this.fetch();
		},
		async fetch() {
			try {
				const [department, branch, port] = await Promise.all([
					
					departmentController.getDepartment(this.departmentId),
					branchController.getBranch(this.branchId),
					portController.getPort(this.departmentId, this.id)
				]);
				
				this.department = department.division;
				this.branch = branch.division;
				this.port = port.division;
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		},
		async updateArchiveStatus() {
			if(this.port.isArchived)
				await divisionController.unarchive(this.id!);
			else
				await divisionController.archive(this.id!);
			
			this.port.isArchived = !this.port.isArchived;
			
			alertService.addSuccess(AlertKeys.PORT_STATUS_UPDATED);
		}
	}
});
