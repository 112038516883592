import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { EnterprisePropertyController } from "@rmp/enterprise/api/enterpriseProperty";
import router from "@rmp/enterprise/router";
import { SnapshotKeysEnum, SnapshotState, useSnapshotStore } from "@rmp/core/stores/composables/snapshot";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import { RouteNames } from "@rmp/enterprise/router/routes";
import { FormState, useFormStore } from "@rmp/core/stores/composables/form/useFormStore";
import { Crewboat, CrewboatMapper } from "@rmp/core/types/enterpriseProperty/crewboat";
import { ApiCrewboatMapper } from "@rmp/core/api/types/enterpriseProperty/apiCrewboat";
import { useCrewboatBreadcrumb } from "@rmp/enterprise/stores/property/crewboat/composables/useCrewboatBreadcrumb";
import { i18n } from "@rmp/core/plugins";
import { useCrewboatsBreadcrumb } from "@rmp/enterprise/stores/crewboats/composables/useCrewboatsBreadcrumb";
import { AxaptaController } from "@rmp/enterprise/api/axapta";
import { BasePropertyGeneralState, useBasePropertyGeneralStore } from "@rmp/enterprise/stores/composables/basePropertyGeneral";

const abortService = new AbortService();

const enterprisePropertyController = new EnterprisePropertyController(abortService);
const axaptaController = new AxaptaController(abortService);

const page = usePageStore(abortService);
const baseBasePropertyGeneralStore = useBasePropertyGeneralStore({ abortService });
const form = useFormStore();
const snapshotStore = useSnapshotStore([
	{
		key: SnapshotKeysEnum.LAST_SAVED, fields: ["crewboat", "calculationObjectCode"]
	}
]);

export interface CrewboatPropertyState extends PageState, SnapshotState, FormState, BasePropertyGeneralState {
	boatName: string;
	crewboat: Crewboat;
}

const getDefaultState = (): CrewboatPropertyState => {
	return {
		...page.getDefaultPageState(),
		...baseBasePropertyGeneralStore.getDefaultState(),
		...snapshotStore.getDefaultState(),
		...form.getDefaultState(),
		boatName: "",
		crewboat: {} as Crewboat
	};
};

export const useCrewboatPropertyStore = defineStore({
	id: "crewboat",
	state: (): CrewboatPropertyState => getDefaultState(),
	getters: {
		...page.getters,
		...baseBasePropertyGeneralStore.getters,
		...snapshotStore.getters,
		...form.getters,
		breadcrumbs(state: CrewboatPropertyState) {
			const routeName = state.id ? RouteNames.CREWBOAT : RouteNames.CREWBOAT_CREATE;
			const text = state.id ? state.crewboat.name : String(i18n.t("titles.tugboatCreate"));
			
			return [
				useCrewboatsBreadcrumb(),
				useCrewboatBreadcrumb(routeName, text, { id: state.id })
			];
		}
	},
	actions: {
		...page.actions,
		...baseBasePropertyGeneralStore.actions,
		...snapshotStore.actions,
		...form.actions,
		async beforeInitialized({ id }: { id: string | undefined }) {
			this.setStateSnapshot();
			await this.fetchBranches();
			
			if(!id) return;
			
			this.id = id;
			
			const [{ divisionId }] = await Promise.all([
				enterprisePropertyController.getCrewboatDivision(id),
				this.fetch()
			]);
			
			await this.initializeBasePropertyGeneralStore(divisionId);
			
			this.setStateSnapshot();
		},
		async fetch() {
			try {
				let crewboat = await enterprisePropertyController.getCrewboat(this.id);
				
				this.crewboat = CrewboatMapper.map(crewboat);
				this.boatName = this.crewboat.name;
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		},
		async save() {
			this.formSaving = true;
			
			const payload = ApiCrewboatMapper.map(this.crewboat);
			
			try {
				if(this.id) {
					const crewboat = await enterprisePropertyController.updateCrewboat(this.id, payload);
					const { calculationObjectCode } = await axaptaController.updateCalculationObjectCode(this.id,
						this.calculationObjectCode);
					
					this.crewboat = CrewboatMapper.map(crewboat);
					this.crewboat.id = this.id;
					this.boatName = this.crewboat.name;
					this.calculationObjectCode = calculationObjectCode;
					
					this.setStateSnapshot();
					
					alertService.addSuccess(AlertKeys.SUCCESS_UPDATED_INFO);
					
					await router.push({ name: RouteNames.CREWBOAT_DETAILS, params: { id: this.id } });
				} else {
					let { id } = await enterprisePropertyController.createCrewboat(payload);
					
					await Promise.all([
						enterprisePropertyController.assignCrewboatToDivision(id, this.divisionId),
						axaptaController.updateCalculationObjectCode(id, this.calculationObjectCode)
					]);

					this.setStateSnapshot();
					
					alertService.addSuccess(AlertKeys.SUCCESS_CREATED_INFO);
					
					await router.push({ name: RouteNames.CREWBOAT_DETAILS, params: { id } });
				}
				
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.formSaving = false;
			}
		}
	}
});
