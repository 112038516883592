import { RouteNames } from "@rmp/enterprise/router/routes";
import { useBreadcrumb } from "@rmp/core/stores/composables/breadcrumb/useBreadcrumb";
import { i18n } from "@rmp/enterprise/plugins";
import routeToPermissionsMap from "@rmp/enterprise/router/routeToPermissionsMap";
export function useCounterpartiesBreadcrumb() {
	return useBreadcrumb({
		route: {
			name: RouteNames.COUNTERPARTIES
		},
		text: i18n.t(`navigation.breadcrumbs.${RouteNames.COUNTERPARTIES}`).toString(),
		routeToPermissionsMap
	});
}
