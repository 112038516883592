import { ServiceTypeEnum } from "@rmp/core/types/services/ServiceTypeEnum";
import ServiceMapperProfile from "@rmp/core/api/mappers/service";
import { ApiMetricsByTimeItem } from "@rmp/core/api/types/metrics/apiMetricsByTimeItem";

export interface MetricsByTimeItem {
	serviceType: ServiceTypeEnum;
	avgProcessingTime: number;
	avgDelayTime: number;
	countProvided: number;
}

export class MetricsByTimeItemMapper {
	static map(source: ApiMetricsByTimeItem): MetricsByTimeItem {
		return {
			...source,
			serviceType: ServiceMapperProfile.mapServiceType(source.serviceType)
		}
	}
}
