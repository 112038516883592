import { ApiGetCounterpartyDocumentsParameters } from "@rmp/core/api/types/documents/apiGetCounterpartyDocumentsParameters";
import { ArchiveStatusType } from "@rmp/core/types/ArchiveStatusType";
import { CounterpartyDetailsDocumentsState } from "@rmp/enterprise/stores/counterpartyDetails/documents/index";

export default class CounterpartyDetailsDocumentsMapperProfile {
	constructor() {
	}
	
	static mapToApiGetCounterpartyDocumentsParameters(source: CounterpartyDetailsDocumentsState): ApiGetCounterpartyDocumentsParameters {
		return {
			take: source.paging.pageSize,
			skip: source.paging.pageSize * (source.paging.page - 1),
			query: source.search.query,
			sortField: source.sorting.type,
			sortingDirection: source.sorting.order,
			isArchived: ArchiveStatusType.ALL === source.filter.status ? null : source.filter.status === ArchiveStatusType.ARCHIVED
		};
	}
}
