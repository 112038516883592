import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { ServiceController } from "@rmp/enterprise/api/service";
import {
	useCrewboatServiceHistoryBreadcrumb
} from "@rmp/enterprise/stores/serviceDetails/crewboat/history/composables/useCrewboatServiceHistoryBreadcrumb";
import { useCrewboatServiceStore } from "@rmp/enterprise/stores/serviceDetails/crewboat";
import ServiceHistoryMapperProfile, { ServiceHistoryEntry } from "@rmp/core/types/services/serviceHistoryEntry";
import { useHistoryStore } from "@rmp/core/stores/composables/history";

const abortService = new AbortService();
const page = usePageStore(abortService);

const serviceController = new ServiceController(abortService);

const mapper = new ServiceHistoryMapperProfile();
const historyStore = useHistoryStore({ controller: serviceController, mapper });

export interface CrewboatServiceHistoryState extends PageState {
	id: string | null;
	history: ServiceHistoryEntry[];
}

const getDefaultState = (): CrewboatServiceHistoryState => {
	return {
		...page.getDefaultPageState(),
		...historyStore.getDefaultState(),
		id: null,
		history: []
	};
};

export const useCrewboatServiceHistoryStore = defineStore({
	id: "crewboat-service-history",
	state: (): CrewboatServiceHistoryState => getDefaultState(),
	getters: {
		...page.getters,
		...historyStore.getters,
		breadcrumbs() {
			const { breadcrumbs } = useCrewboatServiceStore();
			return [...breadcrumbs, useCrewboatServiceHistoryBreadcrumb()];
		}
	},
	actions: {
		...page.actions,
		...historyStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			await this.initializeHistoryStore();
		}
	}
});
