import { IsString, IsInt, Min, IsIn, Max, Length, IsArray, IsEnum } from "class-validator";
import { SortingOrderTypeEnum } from "@rmp/core/types/SortingOrderTypeEnum";
import { AVAILABLE_SORTS } from "@rmp/enterprise/stores/services/constants";
import RegisterRouteQuery from "@rmp/core/stores/composables/register/types/registerRouteQuery";
import { ServiceWorkflowStateType } from "@rmp/core/types/services/ServiceWorkflowStateType";
import { ApiServiceTypeEnum } from "@rmp/core/types/services/ApiServiceTypeEnum";
import { ServicesFilterStatusEnum } from "@rmp/core/types/enums/ServicesFilterStatusEnum";

export default class AgreementServicesRouteQuery implements RegisterRouteQuery {
	@IsInt()
	@Min(0)
	@Max(1000)
	page: number;
	
	@IsString()
	@IsIn(AVAILABLE_SORTS)
	sort: string;
	
	@IsString()
	@IsIn([SortingOrderTypeEnum.DESCENDING, SortingOrderTypeEnum.ASCENDING])
	sortDirection: string;
	
	@IsString()
	@Length(0, 100)
	query: string;
	
	@IsArray()
	statuses: ServiceWorkflowStateType[];
	
	@IsArray()
	serviceTypes: ApiServiceTypeEnum[];
	
	@IsString()
	@IsEnum(ServicesFilterStatusEnum)
	status: ServicesFilterStatusEnum;
	
	constructor(
		page: number,
		sort: string,
		sortDirection: string,
		query: string,
		statuses: ServiceWorkflowStateType[],
		serviceTypes: ApiServiceTypeEnum[],
		status: ServicesFilterStatusEnum
	)
	{
		this.page = page;
		this.sort = sort;
		this.sortDirection = sortDirection;
		this.query = query;
		this.statuses = statuses;
		this.serviceTypes = serviceTypes;
		this.status = status;
	}
}
