import Middleware from "@rmp/core/router/middleware/middleware";
import { NavigationGuard, NavigationGuardNext, Route, VueRouter } from "vue-router/types/router";
import AuthenticationMiddleware from "@rmp/core/router/middleware/items/authenticationMiddleware";
import RedirectMiddleware from "@rmp/enterprise/router/middleware/items/redirectMiddleware";
import PermissionsMiddleware from "@rmp/enterprise/router/middleware/items/permissionsMiddleware";
import { useAppStore } from "@rmp/core/stores/appStore";
import CheckEmailConfirmationMiddleware from "@rmp/core/router/middleware/items/checkEmailConfirmationMiddleware";
import CheckPhoneConfirmationMiddleware from "@rmp/core/router/middleware/items/checkPhoneConfirmationMiddleware";
import CheckPasswordMiddleware from "@rmp/core/router/middleware/items/checkPasswordMiddleware";

export const configureMiddleware = (router: VueRouter) => {
	router.beforeEach(async (to: Route, from: Route, next: NavigationGuardNext) => {
		const middleware = new Middleware(to, from, next);

		const publicRoute = to.matched.some(record => record.meta.public);

		if(!publicRoute) {
			middleware.useMiddleware(AuthenticationMiddleware);
			middleware.useMiddleware(CheckPasswordMiddleware);
			middleware.useMiddleware(CheckEmailConfirmationMiddleware);
			middleware.useMiddleware(CheckPhoneConfirmationMiddleware);
			middleware.useMiddleware(RedirectMiddleware);
			middleware.useMiddleware(PermissionsMiddleware);
		}

		await middleware.run();
		
		const appStore = useAppStore();
		appStore.setMiddlewareExecuted(false);
	});
};
