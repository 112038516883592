import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { SortingOrderTypeEnum } from "@rmp/core/types/SortingOrderTypeEnum";
import { RegisterState, useRegisterStore } from "@rmp/core/stores/composables/register/useRegisterStore";
import { RouteNames } from "@rmp/enterprise/router/routes";
import router from "@rmp/enterprise/router";
import MetricsByTimeFilter from "@rmp/enterprise/stores/metrics/byTime/types/metricsByTimeFilter";
import MetricsByTimeMapperProfile from "@rmp/enterprise/stores/metrics/byTime/mapper";
import MetricsByTimeRouteQuery from "@rmp/enterprise/stores/metrics/byTime/types/metricsByTimeRouteQuery";
import MetricsByTimeRouteQueryService from "@rmp/enterprise/stores/metrics/byTime/services/metricsByTimeRouteQueryService";
import { MetricsController } from "@rmp/enterprise/api/metrics";
import { MetricsByTimeItem, MetricsByTimeItemMapper } from "@rmp/core/types/metrics/metricsByTimeItem";
import { MetricsPeriod } from "@rmp/enterprise/stores/metrics/types/metricsPeriod";
import { getMetricsPeriods } from "@rmp/enterprise/stores/metrics/helpers/getMetricsPeriods";
import { MetricsPeriodType } from "@rmp/core/types/metrics/metricsPeriodType";
import { Department, DepartmentMapper } from "@rmp/core/types/divisions/department";
import { BranchController } from "@rmp/enterprise/api/divisions/branch";
import { DepartmentController } from "@rmp/enterprise/api/divisions/department";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { saveAs } from "file-saver";

const abortService = new AbortService();
const metricsController = new MetricsController(abortService);
const branchController = new BranchController(abortService);
const departmentController = new DepartmentController(abortService);

const mapper = new MetricsByTimeMapperProfile();

export interface MetricsByTimeState extends PageState, RegisterState<MetricsByTimeItem, MetricsByTimeFilter>
{
	filter: MetricsByTimeFilter;
	periods: MetricsPeriod[];
	divisions: Department[];
	reportDownloading: boolean;
}

const defaultRouteQuery = new MetricsByTimeRouteQuery(1,
	"createdAt",
	SortingOrderTypeEnum.DESCENDING,
	"",
	MetricsPeriodType.CURRENT_MONTH,
	"",
	"",
	[]);

const routeQueryService = new MetricsByTimeRouteQueryService(defaultRouteQuery);

const page = usePageStore(abortService);

const register = useRegisterStore<MetricsByTimeItem, MetricsByTimeFilter>({
	routeName: RouteNames.METRICS_BY_TIME,
	routeQueryService,
	routeQuery: defaultRouteQuery,
	getRouter: () => router
});

const getDefaultState = (): MetricsByTimeState => {
	return {
		...page.getDefaultPageState(),
		...register.getDefaultRegisterState(),
		filter: new MetricsByTimeFilter(),
		periods: [],
		divisions: [],
		reportDownloading: false
	};
};

export const useMetricsByTimeStore = defineStore({
	id: "metrics-by-time",
	state: (): MetricsByTimeState => getDefaultState(),
	getters: {
		...page.getters,
		...register.getters,
		filteredItems(state: MetricsByTimeState): MetricsByTimeItem[] {
			return state.listing.items;
		}
	},
	actions: {
		...page.actions,
		...register.actions,
		async beforeInitialized() {
			await this.fetch();
			await this.initializeRegister();
		},
		async fetch() {
			try {
				const { items: branches } = await branchController.getBranches();	
				const departmentsPromises = branches.map(async (branch) => {
					const { items: departments } = await departmentController.getDepartments(branch.id);
					return departments.map(x => DepartmentMapper.map(x));
				});
				const departmentsArrays = await Promise.all(departmentsPromises);
				
				this.divisions = departmentsArrays.flat();
				this.periods = getMetricsPeriods();
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		},
		patchStateWithRouteQuery(query: MetricsByTimeRouteQuery) {
			this.patchRegisterStateWithRouteQuery(query);
			this.$patch((state) => {
				state.filter.period = query.period;
				state.filter.periodStart = query.periodStart;
				state.filter.periodEnd = query.periodEnd;
				state.filter.divisionIds = query.divisionIds;
			});
		},
		async fetchRegisterItems() {
			const { items } = await metricsController.getMetricsByTime(mapper.mapToApiGetMetricsByTimeParameters(this));

			return { items: items.map(x => MetricsByTimeItemMapper.map(x)), totalCount: items.length };
		},
		async downloadReport() {
			this.reportDownloading = true;
			
			try {
				const file = await metricsController.downloadReportByTime(mapper.mapToApiGetMetricsByTimeParameters(this));
				
				saveAs(file, "Статистика по времени");
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.reportDownloading = false;
			}
		}
	}
});
