import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { useServicesBreadcrumb } from "@rmp/enterprise/stores/services/composables/useServicesBreadcrumb";
import { DredgingServiceController } from "@rmp/enterprise/api/service/dredging";
import { DredgingServiceApplication, DredgingServiceApplicationMapper } from "@rmp/core/types/services/dredging/dredgingServiceApplication";
import { useDredgingServiceBreadcrumb } from "@rmp/enterprise/stores/serviceDetails/dredging/composables/useDredgingServiceBreadcrumb";
import { BaseServiceState, useBaseServiceStore } from "@rmp/enterprise/stores/composables/baseService";
import { formatServiceTitle } from "@rmp/core/utils/formatting";

const abortService = new AbortService();
const page = usePageStore(abortService);
const baseServiceStore = useBaseServiceStore({ abortService });

const dredgingServiceController = new DredgingServiceController(abortService);

export interface DredgingServiceState extends PageState, BaseServiceState {
	service: DredgingServiceApplication;
}

const getDefaultState = (): DredgingServiceState => {
	return {
		...page.getDefaultPageState(),
		...baseServiceStore.getDefaultState(),
		service: {} as DredgingServiceApplication
	};
};

export const useDredgingServiceStore = defineStore({
	id: "dredging-service",
	state: (): DredgingServiceState => getDefaultState(),
	getters: {
		...page.getters,
		...baseServiceStore.getters,
		breadcrumbs(state: DredgingServiceState) {
			return [
				useServicesBreadcrumb(),
				useDredgingServiceBreadcrumb(state.title)
			];
		}
	},
	actions: {
		...page.actions,
		...baseServiceStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			
			await Promise.all([
				this.fetch(),
				this.fetchDocuments()
			]);
			
			await this.initializeBaseServiceStore(this.service.type);
		},
		async fetch() {
			try {
				const { serviceApplication } = await dredgingServiceController.getService(this.id!);
				
				this.service = DredgingServiceApplicationMapper.map(serviceApplication);
				this.title = formatServiceTitle(this.service.type, this.service.applicationNumber);
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		}
	}
});
