import { convertToTimestamp } from "@rmp/core/utils/dates";
import { HistoryBaseMapper } from "@rmp/core/stores/composables/history";
import ApiBoatHistoryEntryPersisted from "@rmp/core/api/types/counterparty/boatApplication/apiBoatHistoryEntryPersisted";
import { BoatApplicationHistoryEntry } from "@rmp/enterprise/stores/boatApplicationDetails/history/types/boatApplicationHistoryEntry";
import { formatFullNameWithPosition } from "@rmp/core/utils/formatting";

export default class BoatApplicationHistoryMapperProfile implements HistoryBaseMapper<BoatApplicationHistoryEntry> {
	constructor() {
	}
	
	mapToHistoryEntry(source: ApiBoatHistoryEntryPersisted): BoatApplicationHistoryEntry {
		return {
			...source.counterpartyBoatApplicationHistoryEntry,
			employeeName: formatFullNameWithPosition(source.counterpartyBoatApplicationHistoryEntry),
			actionDateTime: convertToTimestamp(source.counterpartyBoatApplicationHistoryEntry.actionDateTime) as number
		};
	}
}
