import { defineStore } from "pinia";
import uniq from "lodash/uniq";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { useServicesBreadcrumb } from "@rmp/enterprise/stores/services/composables/useServicesBreadcrumb";
import { useCrewboatServiceBreadcrumb } from "@rmp/enterprise/stores/serviceDetails/crewboat/composables/useCrewboatServiceBreadcrumb";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import { useCrewboatAssignmentBreadcrumb } from "@rmp/enterprise/stores/assignment/crewboat/composables/useCrewboatAssignmentBreadcrumb";
import { SnapshotKeysEnum, SnapshotState, useSnapshotStore } from "@rmp/core/stores/composables/snapshot";
import { CrewboatServiceController } from "@rmp/enterprise/api/service/crewboat";
import { CrewboatServiceApplication, CrewboatServiceApplicationMapper } from "@rmp/core/types/services/crewboat/crewboatServiceApplication";
import { Crewboat, CrewboatMapper } from "@rmp/core/types/enterpriseProperty/crewboat";
import router from "@rmp/enterprise/router";
import { RouteNames } from "@rmp/enterprise/router/routes";
import { formatServiceTitle } from "@rmp/core/utils/formatting";

const abortService = new AbortService();
const page = usePageStore(abortService);
const snapshotStore = useSnapshotStore([
	{
		key: SnapshotKeysEnum.LAST_SAVED, fields: ["selectedCrewboatIds"]
	}
]);

const crewboatServiceController = new CrewboatServiceController(abortService);

export interface CrewboatAssignmentState extends PageState, SnapshotState {
	selectedCrewboatIds: string[];
	crewboats: Crewboat[];
	service: CrewboatServiceApplication;
	id: string;
	isSaving: boolean;
}

const getDefaultState = (): CrewboatAssignmentState => {
	return {
		...page.getDefaultPageState(),
		...snapshotStore.getDefaultState(),
		selectedCrewboatIds: [],
		crewboats: [],
		service: {} as CrewboatServiceApplication,
		id: "",
		isSaving: false
	};
};

export const useCrewboatAssignmentStore = defineStore({
	id: "crewboat-assignment",
	state: (): CrewboatAssignmentState => getDefaultState(),
	getters: {
		...page.getters,
		...snapshotStore.getters,
		breadcrumbs(state: CrewboatAssignmentState) {
			return [
				useServicesBreadcrumb(),
				useCrewboatServiceBreadcrumb(formatServiceTitle(state.service.type, state.service.applicationNumber), { id: state.id }),
				useCrewboatAssignmentBreadcrumb()
			];
		}
	},
	actions: {
		...page.actions,
		...snapshotStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			
			await this.fetch();
			
			if(this.service.requestedCrewBoats.length) {
				this.setStateSnapshot();
				
				let requestedCrewboatsIds = this.service.requestedCrewBoats.map(x => x.crewboatId);
				
				this.selectedCrewboatIds = uniq(requestedCrewboatsIds);
			} else {
				this.selectedCrewboatIds = this.service.assignedCrewBoats.map(x => x.crewboatId);
				
				this.setStateSnapshot();
			}
		},
		async fetch() {
			try {
				let { serviceApplication } = await crewboatServiceController.getService(this.id!);
				
				let crewBoats = await crewboatServiceController.getServiceCrewboats(serviceApplication.divisionId);
				
				this.service = CrewboatServiceApplicationMapper.map(serviceApplication);
				this.crewboats = crewBoats.map(x => CrewboatMapper.map(x));
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		},
		async assign() {
			this.isSaving = true;
			
			try {
				await crewboatServiceController.assignCrewboat(this.id!, this.selectedCrewboatIds);
				
				alertService.addSuccess(AlertKeys.CREWBOATS_ASSIGNED);
				
				await router.push({ name: RouteNames.CREWBOAT_SERVICE, params: router.currentRoute.params });
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.isSaving = false;
			}
		}
	}
});
