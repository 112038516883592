import { urls } from "@rmp/enterprise/api/config/urls";
import AbortService from "@rmp/core/services/abortService";
import ApiGetTugBoatsParameters from "@rmp/core/api/types/enterpriseProperty/apiGetTugBoatsParameters";
import ApiGetCrewBoatsParameters from "@rmp/core/api/types/enterpriseProperty/apiGetCrewBoatsParameters";
import { ApiPierPersisted, ApiPier, ApiPiersItem } from "@rmp/core/api/types/enterpriseProperty/apiPier";
import { ApiTugboat, ApiTugboatPersisted, ApiTugboatsItem } from "@rmp/core/api/types/enterpriseProperty/apiTugboat";
import BaseEnterpriseController from "@rmp/enterprise/api/base/baseEnterpriseController";
import ApiGetFloatingCranesParameters from "@rmp/core/api/types/enterpriseProperty/apiGetFloatingCranesParameters";
import { ApiCrewboat, ApiCrewboatPersisted, ApiCrewboatsItem } from "@rmp/core/api/types/enterpriseProperty/apiCrewboat";
import {
	ApiFloatingCrane,
	ApiFloatingCranePersisted,
	ApiFloatingCranesItem
} from "@rmp/core/api/types/enterpriseProperty/apiFloatingCrane";
import ApiGetPiersParameters from "@rmp/core/api/types/enterpriseProperty/apiGetPiersParameters";
import { ApiCrewboatTariffs } from "@rmp/core/api/types/enterpriseProperty/apiCrewboatTariffs";
import { ApiTugboatTariffs } from "@rmp/core/api/types/enterpriseProperty/apiTugboatTariffs";

export class EnterprisePropertyController extends BaseEnterpriseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getTugboats = async (parameters: ApiGetTugBoatsParameters) => {
		return await this.get<{ tugboats: ApiTugboatsItem[], totalCount: number }>(urls.enterprise.property.tugboat, parameters);
	};
	
	getTugboat = async (id: string): Promise<ApiTugboatPersisted> => {
		return await this.get<ApiTugboatPersisted>(urls.enterprise.property.tugboat.$id, { id });
	};
	
	getTugboatDivision = async (id: string): Promise<{ divisionId: string }> => {
		return await this.get(urls.enterprise.property.tugboat.$id.division, { id });
	};
	
	getTugboatTariffs = async (id: string): Promise<ApiTugboatTariffs> => {
		return await this.get<ApiTugboatTariffs>(urls.enterprise.property.tugboat.$id.tariff, { id });
	};
	
	createTugboat = async (tugboat: ApiTugboat): Promise<ApiTugboatPersisted> => {
		return await this.post(urls.enterprise.property.tugboat, {}, tugboat);
	};
	
	updateTugboat = async (id: string, tugboat: ApiTugboat): Promise<ApiTugboatPersisted> => {
		return await this.put(urls.enterprise.property.tugboat.$id, { id }, tugboat);
	};
	
	archiveTugboat = async (id: string): Promise<ApiTugboatPersisted> => {
		return await this.post(urls.enterprise.property.tugboat.$id.archive, { id }, {});
	};
	
	unarchiveTugboat = async (id: string): Promise<ApiTugboatPersisted> => {
		return await this.post(urls.enterprise.property.tugboat.$id.unarchive, { id }, {});
	};
	
	assignTugboatToDivision = async (id: string, divisionId: string): Promise<void> => {
		return await this.post(urls.enterprise.property.tugboat.$id.assignToDivision, { id }, { divisionId });
	};
	
	getCrewboats = async (parameters: ApiGetCrewBoatsParameters) => {
		return await this.get<{ crewboats: ApiCrewboatsItem[], totalCount: number }>(urls.enterprise.property.crewboat, parameters);
	};
	
	getCrewboat = async (id: string): Promise<ApiCrewboatPersisted> => {
		return await this.get<ApiCrewboatPersisted>(urls.enterprise.property.crewboat.$id, { id });
	};
	
	getCrewboatDivision = async (id: string): Promise<{ divisionId: string }> => {
		return await this.get(urls.enterprise.property.crewboat.$id.division, { id });
	};
	
	getCrewboatTariffs = async (id: string): Promise<ApiCrewboatTariffs> => {
		return await this.get<ApiCrewboatTariffs>(urls.enterprise.property.crewboat.$id.tariff, { id });
	};
	
	createCrewboat = async (crewBoat: ApiCrewboat): Promise<ApiCrewboatPersisted> => {
		return await this.post(urls.enterprise.property.crewboat, {}, crewBoat);
	};
	
	updateCrewboat = async (id: string, crewBoat: ApiCrewboat): Promise<ApiCrewboatPersisted> => {
		return await this.put(urls.enterprise.property.crewboat.$id, { id }, crewBoat);
	};
	
	archiveCrewboat = async (id: string): Promise<ApiCrewboatPersisted> => {
		return await this.post(urls.enterprise.property.crewboat.$id.archive, { id }, {});
	};
	
	unarchiveCrewboat = async (id: string): Promise<ApiCrewboatPersisted> => {
		return await this.post(urls.enterprise.property.crewboat.$id.unarchive, { id }, {});
	};
	
	assignCrewboatToDivision = async (id: string, divisionId: string): Promise<void> => {
		return await this.post(urls.enterprise.property.crewboat.$id.assignToDivision, { id }, { divisionId });
	};
	
	getFloatingCranes = async (parameters: ApiGetFloatingCranesParameters) => {
		return await this.get<{ floatingCranes: ApiFloatingCranesItem[], totalCount: number }>(urls.enterprise.property.floatingCrane,
			parameters);
	};
	
	getFloatingCrane = async (id: string): Promise<ApiFloatingCranePersisted> => {
		return await this.get<ApiFloatingCranePersisted>(urls.enterprise.property.floatingCrane.$id, { id });
	};
	
	getFloatingCraneDivision = async (id: string): Promise<{ divisionId: string }> => {
		return await this.get(urls.enterprise.property.floatingCrane.$id.division, { id });
	};
	
	createFloatingCrane = async (floatingCrane: ApiFloatingCrane): Promise<ApiFloatingCranePersisted> => {
		return await this.post(urls.enterprise.property.floatingCrane, {}, floatingCrane);
	};
	
	updateFloatingCrane = async (id: string, floatingCrane: ApiFloatingCrane): Promise<ApiFloatingCranePersisted> => {
		return await this.put(urls.enterprise.property.floatingCrane.$id, { id }, floatingCrane);
	};
	
	archiveFloatingCrane = async (id: string): Promise<ApiFloatingCranePersisted> => {
		return await this.post(urls.enterprise.property.floatingCrane.$id.archive, { id }, {});
	};
	
	unarchiveFloatingCrane = async (id: string): Promise<ApiFloatingCranePersisted> => {
		return await this.post(urls.enterprise.property.floatingCrane.$id.unarchive, { id }, {});
	};
	
	assignFloatingCraneToDivision = async (id: string, divisionId: string): Promise<void> => {
		return await this.post(urls.enterprise.property.floatingCrane.$id.assignToDivision, { id }, { divisionId });
	};
	
	getPiers = async (parameters: ApiGetPiersParameters) => {
		return await this.get<{ piers: ApiPiersItem[], totalCount: number }>(urls.enterprise.property.pier, parameters);
	};
	
	getPier = async (id: string): Promise<ApiPierPersisted> => {
		return await this.get<ApiPierPersisted>(urls.enterprise.property.pier.$id, { id });
	};
	
	getPierDivision = async (id: string): Promise<{ divisionId: string }> => {
		return await this.get(urls.enterprise.property.pier.$id.division, { id });
	};
	
	createPier = async (pier: ApiPier): Promise<ApiPierPersisted> => {
		return await this.post(urls.enterprise.property.pier, {}, pier);
	};
	
	updatePier = async (id: string, pier: ApiPier): Promise<ApiPierPersisted> => {
		return await this.put(urls.enterprise.property.pier.$id, { id }, pier);
	};
	
	archivePier = async (id: string): Promise<ApiPierPersisted> => {
		return await this.post(urls.enterprise.property.pier.$id.archive, { id }, {});
	};
	
	unarchivePier = async (id: string): Promise<ApiPierPersisted> => {
		return await this.post(urls.enterprise.property.pier.$id.unarchive, { id }, {});
	};
	
	assignPierToDivision = async (id: string, divisionId: string): Promise<void> => {
		return await this.post(urls.enterprise.property.pier.$id.assignToDivision, { id }, { divisionId });
	};
}
