import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { SortingOrderTypeEnum } from "@rmp/core/types/SortingOrderTypeEnum";
import { RouteNames } from "@rmp/enterprise/router/routes";
import router from "@rmp/enterprise/router";
import { RegisterState, useRegisterStore } from "@rmp/core/stores/composables/register/useRegisterStore";
import { TariffsFilter } from "@rmp/enterprise/stores/tariffs/types/tariffsFilter";
import { TariffsItem } from "@rmp/enterprise/stores/tariffs/types/tariffsItem";
import { TariffController } from "@rmp/enterprise/api/tariff";
import TariffsRouteQuery from "@rmp/enterprise/stores/tariffs/types/tariffsRouteQuery";
import TariffsRouteQueryService from "@rmp/enterprise/stores/tariffs/services/tariffsRouteQueryService";
import TariffsMapperProfile from "@rmp/enterprise/stores/tariffs/mapper";
import { ArchiveStatusType } from "@rmp/core/types/ArchiveStatusType";

const abortService = new AbortService();
const tariffController = new TariffController(abortService);

const mapper = new TariffsMapperProfile();

const defaultRouteQuery = new TariffsRouteQuery(
	1,
	"title",
	SortingOrderTypeEnum.DESCENDING,
	"",
	[],
	ArchiveStatusType.ALL,
	"");

const routeQueryService = new TariffsRouteQueryService(defaultRouteQuery);
const page = usePageStore(abortService);

export interface TariffsState extends PageState, RegisterState<TariffsItem, TariffsFilter> {
	filter: TariffsFilter;
	propertyId: string;
}

const register = useRegisterStore<TariffsItem, TariffsFilter>({
	routeName: RouteNames.TARIFFS,
	routeQueryService,
	routeQuery: defaultRouteQuery,
	getRouter: () => router
});

const getDefaultState = (): TariffsState => {
	return {
		...page.getDefaultPageState(),
		...register.getDefaultRegisterState(),
		filter: {
			serviceTypes: [],
			status: ArchiveStatusType.ALL
		},
		propertyId: ""
	};
};

export const useTariffsStore = defineStore({
	id: "tariffs",
	state: (): TariffsState => getDefaultState(),
	getters: {
		...register.getters,
		...page.getters,
		filteredItems(state: TariffsState) {
			return state.listing.items;
		}
	},
	actions: {
		...page.actions,
		...register.actions,
		async beforeInitialized() {
			await this.initializeRegister();
		},
		patchStateWithRouteQuery(query: TariffsRouteQuery) {
			this.patchRegisterStateWithRouteQuery(query);
			
			this.$patch((state) => {
				state.filter.serviceTypes = query.serviceTypes;
				state.filter.status = query.status;
			});
		},
		async fetchRegisterItems() {
			let { tariffs, totalCount } = await tariffController.getTariffs(mapper.mapToApiGetTariffsParameters(this));
			
			return {
				items: tariffs,
				totalCount
			};
		}
	}
});
