import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { SortingOrderTypeEnum } from "@rmp/core/types/SortingOrderTypeEnum";
import { RegisterState, useRegisterStore } from "@rmp/core/stores/composables/register/useRegisterStore";
import { RouteNames } from "@rmp/enterprise/router/routes";
import router from "@rmp/enterprise/router";
import MetricsByPropertyDetailedFilter from "@rmp/enterprise/stores/metrics/byPropertyDetailed/types/metricsByPropertyDetailedFilter";
import MetricsByPropertyDetailedRouteQuery
	from "@rmp/enterprise/stores/metrics/byPropertyDetailed/types/metricsByPropertyDetailedRouteQuery";
import MetricsByPropertyDetailedRouteQueryService
	from "@rmp/enterprise/stores/metrics/byPropertyDetailed/services/metricsByTimeRouteQueryService";
import MetricsByPropertyDetailedMapperProfile from "@rmp/enterprise/stores/metrics/byPropertyDetailed/mapper";
import {
	useMetricsByPropertyDetailedBreadcrumb
} from "@rmp/enterprise/stores/metrics/byPropertyDetailed/composables/useMetricsByPropertyDetailedBreadcrumb";
import { useMetricsBreadcrumb } from "@rmp/enterprise/stores/metrics/composables/useMetricsBreadcrumb";
import { MetricsPeriod } from "@rmp/enterprise/stores/metrics/types/metricsPeriod";
import { MetricsPeriodType } from "@rmp/core/types/metrics/metricsPeriodType";
import { MetricsByPropertyDetailedItem, MetricsByPropertyDetailedItemMapper } from "@rmp/core/types/metrics/metricsByPropertyDetailedItem";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { MetricsController } from "@rmp/enterprise/api/metrics";
import { EnterprisePropertyController } from "@rmp/enterprise/api/enterpriseProperty";
import { getMetricsPeriods } from "@rmp/enterprise/stores/metrics/helpers/getMetricsPeriods";
import { ServiceTypeEnum } from "@rmp/core/types/services/ServiceTypeEnum";
import { saveAs } from "file-saver";

const abortService = new AbortService();

const mapper = new MetricsByPropertyDetailedMapperProfile();
const metricsController = new MetricsController(abortService);
const propertyController = new EnterprisePropertyController(abortService);

export interface MetricsByPropertyDetailedState extends PageState,
	RegisterState<MetricsByPropertyDetailedItem, MetricsByPropertyDetailedFilter>
{
	filter: MetricsByPropertyDetailedFilter;
	id: string;
	periods: MetricsPeriod[];
	propertyName: string;
	reportDownloading: boolean;
}

const defaultRouteQuery = new MetricsByPropertyDetailedRouteQuery(1,
	"createdAt",
	SortingOrderTypeEnum.DESCENDING,
	"",
	MetricsPeriodType.CURRENT_MONTH,
	"",
	"");

const routeQueryService = new MetricsByPropertyDetailedRouteQueryService(defaultRouteQuery);

const page = usePageStore(abortService);

const register = useRegisterStore<MetricsByPropertyDetailedItem, MetricsByPropertyDetailedFilter>({
	routeName: RouteNames.METRICS_BY_PROPERTY_DETAILED,
	routeQueryService,
	routeQuery: defaultRouteQuery,
	getRouter: () => router
});

const getDefaultState = (): MetricsByPropertyDetailedState => {
	return {
		...page.getDefaultPageState(),
		...register.getDefaultRegisterState(),
		filter: new MetricsByPropertyDetailedFilter(),
		id: "",
		periods: [],
		propertyName: "",
		reportDownloading: false
	};
};

export const useMetricsByPropertyDetailedStore = defineStore({
	id: "metrics-by-property-detailed",
	state: (): MetricsByPropertyDetailedState => getDefaultState(),
	getters: {
		...page.getters,
		...register.getters,
		filteredItems(state: MetricsByPropertyDetailedState): MetricsByPropertyDetailedItem[] {
			return state.listing.items;
		},
		breadcrumbs(state: MetricsByPropertyDetailedState) {
			return [
				useMetricsBreadcrumb(),
				useMetricsByPropertyDetailedBreadcrumb(state.propertyName, { id: state.id })
			];
		}
	},
	actions: {
		...page.actions,
		...register.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.periods = getMetricsPeriods();
			
			this.id = id;
			await this.initializeRegister();
			await this.fetchProperty();
		},
		patchStateWithRouteQuery(query: MetricsByPropertyDetailedRouteQuery) {
			this.patchRegisterStateWithRouteQuery(query);
			this.$patch((state) => {
				state.filter.period = query.period;
				state.filter.periodStart = query.periodStart;
				state.filter.periodEnd = query.periodEnd;
			});
		},
		async fetchRegisterItems() {
			const { items } = await metricsController.getMetricsByPropertyDetailed(mapper.mapToApiGetMetricsByPropertyDetailedParameters(
				this));
			
			return { items: items.map(x => MetricsByPropertyDetailedItemMapper.map(x)), totalCount: items.length };
		},
		async fetchProperty() {
			try {
				if(!this.filteredItems.length)
					return;
				
				const [{ serviceType }] = this.filteredItems;
				
				switch (serviceType) {
					case ServiceTypeEnum.TUGBOAT_SERVICE:
						this.propertyName = (await propertyController.getTugboat(this.id)).tugboat.name;
						break;
					case ServiceTypeEnum.CREWBOAT_SERVICE:
						this.propertyName = (await propertyController.getCrewboat(this.id)).crewboat.name;
						break;
					case ServiceTypeEnum.PIER_SERVICE:
						this.propertyName = (await propertyController.getPier(this.id)).pier.name;
						break;
					case ServiceTypeEnum.FLOATING_CRANE_SERVICE:
						this.propertyName = (await propertyController.getFloatingCrane(this.id)).floatingCrane.name;
						break;
					default:
						throw new Error();
				}
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		},
		async downloadReport() {
			this.reportDownloading = true;
			
			try {
				const file = await metricsController.downloadReportPropertyDetailed(mapper.mapToApiGetMetricsByPropertyDetailedParameters(
					this));
				
				saveAs(file, "Статистика по объекту имущества");
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.reportDownloading = false;
			}
		}
	}
});
