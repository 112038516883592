import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { cloneDeep, uniqBy } from "lodash";
import { useBoatApplicationDetailsStore } from "@rmp/enterprise/stores/boatApplicationDetails";
import { BoatApplicationDetails } from "@rmp/core/types/boatApplication/boatApplicationDetails";
import { CounterpartyController } from "@rmp/core/api/counterparty";
import ApiLegalEntityCounterparty from "@rmp/core/api/types/counterparty/apiLegalEntityCounterparty";
import ApiLegalEntityCounterpartyPersisted from "@rmp/core/api/types/counterparty/apiLegalEntityCounterpartyPersisted";
import { LegalEntityCounterparty, LegalEntityCounterpartyMapper } from "@rmp/core/types/counterparty/legalEntity/legalEntityCounterparty";
import { LegalPersonCounterparty, LegalPersonCounterpartyMapper } from "@rmp/core/types/counterparty/legalPerson/legalPersonCounterparty";
import ApiLegalPersonCounterparty from "@rmp/core/api/types/counterparty/apiLegalPersonCounterparty";
import ApiCounterpartyPersistedBase from "@rmp/core/api/types/counterparty/apiCounterpartyPersistedBase";

const abortService = new AbortService();
const page = usePageStore(abortService);

const counterpartyController = new CounterpartyController(abortService);

export interface BoatApplicationDetailsGeneralState extends PageState {
	id: string | null;
	application: BoatApplicationDetails;
	legalEntity: LegalEntityCounterparty| null;
	legalPerson: LegalPersonCounterparty | null;
}

const getDefaultState = (): BoatApplicationDetailsGeneralState => {
	return {
		...page.getDefaultPageState(),
		id: null,
		application: {} as BoatApplicationDetails,
		legalEntity: null,
		legalPerson: null
	};
};

export const useBoatApplicationDetailsGeneralStore = defineStore({
	id: "boat-application-details-general",
	state: (): BoatApplicationDetailsGeneralState => getDefaultState(),
	getters: {
		...page.getters,
		breadcrumbs() {
			// @ts-ignore
			const { breadcrumbs } = useBoatApplicationDetailsStore();
			return breadcrumbs;
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			await this.fetch();
		},
		async fetch() {
			let { application } = useBoatApplicationDetailsStore();
			
			const apiCounterparty = await counterpartyController.getCounterparty<ApiCounterpartyPersistedBase>(application.counterpartyId,
				ApiCounterpartyPersistedBase);
			
			this.application = cloneDeep(application);
			
			if(apiCounterparty.counterparty instanceof ApiLegalPersonCounterparty)
				this.legalPerson = LegalPersonCounterpartyMapper.mapFromApiLegalPerson(apiCounterparty.counterparty);
			if(apiCounterparty.counterparty instanceof ApiLegalEntityCounterparty)
				this.legalEntity = LegalEntityCounterpartyMapper.map(apiCounterparty as ApiLegalEntityCounterpartyPersisted);
		}
	}
});
