import { MetricsPeriodType } from "@rmp/core/types/metrics/metricsPeriodType";

export default class MetricsByCostFilter {
	period: MetricsPeriodType;
	periodStart: string;
	periodEnd: string;
	divisionIds: string[];
	
	constructor(
		period: MetricsPeriodType = MetricsPeriodType.CURRENT_MONTH,
		periodStart: string = "",
		periodEnd: string = "",
		divisionIds: string[] = []
	)
	{
		this.period = period;
		this.periodStart = periodStart;
		this.periodEnd = periodEnd;
		this.divisionIds = divisionIds;
	}
}
