import { RouteNames } from "@rmp/enterprise/router/routes";
import { useBreadcrumb } from "@rmp/core/stores/composables/breadcrumb/useBreadcrumb";
import routeToPermissionsMap from "@rmp/enterprise/router/routeToPermissionsMap";

export function useBoatApplicationDetailsBreadcrumb(text: string) {
	return useBreadcrumb({
		route: {
			name: RouteNames.BOAT_APPLICATION_DETAILS
		},
		text,
		routeToPermissionsMap
	});
}
