export const urls = {
	axapta: {
		path: "axapta",
		property: {
			path: "property",
			$id: {
				path: ":id"
			}
		}
	},
	harborDues: {
		path: "harbor-dues",
		destination: {
			path: "destination",
			$id: {
				path: ":id"
			}
		}
	},
	agreement: {
		path: "agreement",
		all: {
			path: "all"
		},
		$id: {
			path: ":id",
			remainingLimit: {
				path: "remaining-limit"
			},
			document: {
				path: "document"
			},
			duration: {
				path: "duration"
			},
			agreementDate: {
				path: "agreement-date"
			},
			paymentMethod: {
				path: "payment-method"
			},
			chat: {
				path: "chat",
				message: {
					path: "message"
				}
			},
			history: {
				path: "history"
			},
			services: {
				path: "service"
			},
			transition: {
				path: "transition",
				$code: {
					path: ":code"
				}
			},
			template: {
				path: "template"
			},
			archive: {
				path: "archive"
			},
			unarchive: {
				path: "unarchive"
			}
		}
	},
	boat: {
		path: "boat",
		$id: {
			path: ":id"
		}
	},
	service: {
		path: "service",
		$id: {
			path: ":id",
			feedback: {
				path: "feedback"
			},
			agreement: {
				path: "agreement",
				template: {
					path: "template"
				}
			},
			document: {
				path: "document",
				$documentId: {
					path: ":documentId",
					signature: {
						path: "signature"
					}
				}
			}
		},
		tariff: {
			path: "tariff",
		},
		pier: {
			path: "pier",
			$pierServiceId: {
				path: ":pierServiceId",
				provisionData: {
					path: "provision-data"
				},
				document: {
					path: "document"
				},
				$code: {
					path: ":code"
				},
				tariff: {
					path: "tariff"
				},
				pier: {
					path: "pier"
				},
				piers: {
					path: "piers"
				}
			},
			department: {
				path: "department",
				$departmentId: {
					path: ":departmentId",
					pier: {
						path: "pier",
						$pierId: {
							path: ":pierId",
							tariff: {
								path: "tariff",
								$tariffId: {
									path: ":tariffId",
									calculate: {
										path: "calculate"
									}
								}
							}
						}
					}
				}
			}
		},
		information: {
			path: "information",
			$serviceId: {
				path: ":serviceId",
				$code: {
					path: ":code"
				},
				tariff: {
					path: "tariff"
				},
				document: {
					path: "document"
				}
			},
			department: {
				path: "department",
				$departmentId: {
					path: ":departmentId",
					tariff: {
						path: "tariff",
						$tariffId: {
							path: ":tariffId",
							calculate: {
								path: "calculate"
							}
						}
					}
				}
			}
		},
		tugboat: {
			path: "tugboat",
			$tugboatServiceId: {
				path: ":tugboatServiceId",
				provisionData: {
					path: "provision-data"
				},
				copy: {
					path: "copy"
				},
				$code: {
					path: ":code"
				},
				tariff: {
					path: "tariff"
				},
				tugboats: {
					path: "tugboats"
				},
				document: {
					path: "document"
				}
			},
			department: {
				path: "department",
				$departmentId: {
					path: ":departmentId",
					tugboat: {
						path: "tugboat",
						$tugboat: {
							path: ":tugboatId",
							tariff: {
								path: "tariff",
								$tariffId: {
									path: ":tariffId",
									calculate: {
										path: "calculate"
									}
								}
							}
						}
					}
				}
			}
		},
		crewboat: {
			path: "crewboat",
			$crewboatServiceId: {
				path: ":crewboatServiceId",
				provisionData: {
					path: "provision-data"
				},
				copy: {
					path: "copy"
				},
				$code: {
					path: ":code"
				},
				document: {
					path: "document"
				},
				tariff: {
					path: "tariff"
				},
				crewboat: {
					path: "crewboat"
				},
				crewboats: {
					path: "crewboats"
				}
			},
			department: {
				path: "department",
				$departmentId: {
					path: ":departmentId",
					crewboat: {
						path: "crewboat",
						$crewboatId: {
							path: ":crewboatId",
							tariff: {
								path: "tariff",
								$tariffId: {
									path: ":tariffId",
									calculate: {
										path: "calculate"
									}
								}
							}
						}
					}
				}
			}
		},
		floatingcrane: {
			path: "floatingcrane",
			$serviceId: {
				path: ":serviceId",
				document: {
					path: "document"
				},
				$code: {
					path: ":code"
				},
				floatingcranes: {
					path: "floatingcranes"
				}
			},
			department: {
				path: "department",
				$departmentId: {
					path: ":departmentId",
					port: {
						path: "port"
					},
					floatingcrane: {
						path: "floatingcrane"
					}
				}
			}
		},
		dredging: {
			path: "dredging",
			$serviceId: {
				path: ":serviceId",
				document: {
					path: "document"
				},
				$code: {
					path: ":code"
				}
			},
			department: {
				path: "department",
				$departmentId: {
					path: ":departmentId",
					port: {
						path: "port"
					}
				}
			}
		},
		snoInstallation: {
			path: "sno-installation",
			$serviceId: {
				path: ":serviceId",
				document: {
					path: "document"
				},
				$code: {
					path: ":code"
				}
			},
			department: {
				path: "department",
				$departmentId: {
					path: ":departmentId",
					port: {
						path: "port"
					}
				}
			}
		},
		geodetichydrographic: {
			path: "geodetichydrographic",
			$serviceId: {
				path: ":serviceId",
				document: {
					path: "document"
				},
				$code: {
					path: ":code"
				}
			},
			department: {
				path: "department",
				$departmentId: {
					path: ":departmentId",
					port: {
						path: "port"
					}
				}
			}
		},
		$serviceApplicationId: {
			path: ":serviceApplicationId",
			document: {
				path: "document"
			},
			chat: {
				path: "chat",
				message: {
					path: "message"
				}
			},
			history: {
				path: "history"
			},
			archive: {
				path: "archive"
			},
			unarchive: {
				path: "unarchive"
			}
		},
		$serviceType: {
			path: ":serviceType",
			tariff: {
				path: "tariff"
			}
		}
	},
	counterparty: {
		path: "counterparty",
		$id: {
			path: ":id",
			bankAccount: {
				path: "bank-account",
				$bankAccountId: {
					path: ":bankAccountId"
				}
			}
		}
	},
	enterprise: {
		path: "enterprise",
		division: {
			path: "division",
			$id: {
				path: ":id",
				archive: {
					path: "archive"
				},
				unarchive: {
					path: "unarchive"
				}
			},
			department: {
				path: "department",
				$departmentId: {
					path: ":departmentId",
					port: {
						path: "port",
						$portId: {
							path: ":portId"
						}
					}
				},
				$id: {
					path: ":id",
					port: {
						path: "port"
					}
				}
			},
			branch: {
				path: "branch",
				$id: {
					path: ":id",
					port: {
						path: "port"
					}
				},
				$branchId: {
					path: ":branchId",
					port: {
						path: "port",
						$portId: {
							path: ":portId"
						}
					}
				}
			},
			branchBasin: {
				path: "branch-basin",
				$id: {
					path: ":id",
					department: {
						path: "department"
					}
				}
			},
			head: {
				path: "head"
			},
			port: {
				path: "port",
				$portId: {
					path: ":portId",
					destination: {
						path: "destination",
						$destinationId: {
							path: ":destinationId",
							archive: {
								path: "archive"
							},
							unarchive: {
								path: "unarchive"
							}
						}
					}
				}
			}
		},
		property: {
			path: "property",
			tugboat: {
				path: "tugboat",
				$id: {
					path: ":id",
					archive: {
						path: "archive"
					},
					unarchive: {
						path: "unarchive"
					},
					division: {
						path: "division"
					},
					assignToDivision: {
						path: "assign-to-division"
					},
					tariff: {
						path: "tariff"
					}
				}
			},
			pier: {
				path: "pier",
				$id: {
					path: ":id",
					archive: {
						path: "archive"
					},
					unarchive: {
						path: "unarchive"
					},
					division: {
						path: "division"
					},
					assignToDivision: {
						path: "assign-to-division"
					}
				}
			},
			floatingCrane: {
				path: "floating-crane",
				$id: {
					path: ":id",
					archive: {
						path: "archive"
					},
					unarchive: {
						path: "unarchive"
					},
					division: {
						path: "division"
					},
					assignToDivision: {
						path: "assign-to-division"
					}
				}
			},
			crewboat: {
				path: "crewboat",
				$id: {
					path: ":id",
					archive: {
						path: "archive"
					},
					unarchive: {
						path: "unarchive"
					},
					division: {
						path: "division"
					},
					assignToDivision: {
						path: "assign-to-division"
					},
					tariff: {
						path: "tariff"
					}
				}
			}
		}
	},
	boatApplication: {
		path: "boat-application",
		$boatApplicationId: {
			path: ":boatApplicationId",
			chat: {
				path: "chat",
				message: {
					path: "message"
				}
			},
			history: {
				path: "history"
			},
			approve: {
				path: "approve"
			},
			decline: {
				path: "decline"
			}
		}
	},
	metrics: {
		path: "metrics",
		statsTime: {
			path: "stats-time",
			report: {
				path: "report"
			}
		},
		statsMoney: {
			path: "stats-money",
			report: {
				path: "report"
			}
		},
		statsProperty: {
			path: "stats-property",
			report: {
				path: "report"
			}
		},
		propertyStats: {
			path: "property-stats",
			report: {
				path: "report"
			}
		},
		counterpartyStats: {
			path: "counterparty-stats",
			report: {
				path: "report"
			}
		}
	},
	own: {
		path: "own",
		enterprise: {
			path: "enterprise",
			division: {
				path: "division",
				department: {
					path: "department"
				},
				kind: {
					path: "kind"
				}
			}
		}
	}
};
