import urls, { urlTemplateParts } from "@rmp/core/api/config/urls";
import { plainToInstance } from "class-transformer";
import ApiCounterpartyEmployeeTrustCreateRequest from "@rmp/core/api/types/counterparty/trust/apiCounterpartyEmployeeTrustCreateRequest";
import ApiCounterpartyEmployeeTrustPersisted from "@rmp/core/api/types/counterparty/trust/apiCounterpartyEmployeeTrustPersisted";
import BaseController from "@rmp/core/api/shared/baseController";
import AbortService from "@rmp/core/services/abortService";
import HttpNotFoundException from "@rmp/core/exceptions/httpNotFoundException";
import { AuthorizationScopeType } from "@rmp/core/types/authorization/authorizationScopeType";
import { prepareUrl } from "@rmp/core/utils/prepareUrlQuery";
import ApiGetCounterpartyEmployeeActiveTrustsParameters
	from "@rmp/core/api/types/counterparty/trust/apiGetCounterpartyEmployeeActiveTrustsParameters";

export class TrustController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getCounterpartyEmployeeTrust =
		async (id: string,
			employeeId: string,
			counterpartyId: string,
			scope = AuthorizationScopeType.GLOBAL): Promise<ApiCounterpartyEmployeeTrustPersisted> => {
			const url = scope === AuthorizationScopeType.GLOBAL
				? urls.counterparty.employee.trust.get
					  .replace(urlTemplateParts.id, counterpartyId)
					  .replace(urlTemplateParts.subId, employeeId)
					  .replace(urlTemplateParts.thirdId, id)
				: urls.counterparty.employee.trust.getOwn
					  .replace(urlTemplateParts.subId, employeeId)
					  .replace(urlTemplateParts.thirdId, id);
			
			let { trust } = await this.client.get<{ trust: object }>(url);
			
			if(trust === null)
				throw new HttpNotFoundException(url);
			
			return plainToInstance(ApiCounterpartyEmployeeTrustPersisted, trust);
		};
	
	getCounterpartyEmployeeTrusts = async (counterpartyId: string,
		employeeId: string,
		scope = AuthorizationScopeType.GLOBAL): Promise<ApiCounterpartyEmployeeTrustPersisted[]> =>
	{
		const url = scope === AuthorizationScopeType.GLOBAL
			? urls.counterparty.employee.trust.getItems
				  .replace(urlTemplateParts.id, counterpartyId)
				  .replace(urlTemplateParts.subId, employeeId)
			: urls.counterparty.employee.trust.getOwnItems
				  .replace(urlTemplateParts.id, counterpartyId)
				  .replace(urlTemplateParts.subId, employeeId);
		
		let { trusts } = await this.client.get<{ trusts: object[] }>(url);
		
		return plainToInstance(ApiCounterpartyEmployeeTrustPersisted, trusts);
	};
	
	createCounterpartyEmployeeTrust = async (counterpartyId: string, employeeId: string,
		payload: ApiCounterpartyEmployeeTrustCreateRequest, scope = AuthorizationScopeType.GLOBAL): Promise<string> =>
	{
		const url = scope === AuthorizationScopeType.GLOBAL
			? urls.counterparty.employee.trust.getItems
				  .replace(urlTemplateParts.id, counterpartyId)
				  .replace(urlTemplateParts.subId, employeeId)
			: urls.counterparty.employee.trust.getOwnItems
				  .replace(urlTemplateParts.subId, employeeId);
		
		
		return await this.client.post<string>(url, payload);
	};
	
	acceptCounterpartyEmployeeTrust = async (counterpartyId: string,
		employeeId: string,
		trustId: string): Promise<ApiCounterpartyEmployeeTrustPersisted> =>
	{
		const url = urls.counterparty.employee.trust.accept
						.replace(urlTemplateParts.id, counterpartyId)
						.replace(urlTemplateParts.subId, employeeId)
						.replace(urlTemplateParts.thirdId, trustId);
		
		
		let { trust } = await this.client.post<{ trust: object }>(url, {}, {});
		
		return plainToInstance(ApiCounterpartyEmployeeTrustPersisted, trust);
	};
	
	declineCounterpartyEmployeeTrust = async (
		counterpartyId: string,
		employeeId: string,
		trustId: string,
		declineReason: string
	): Promise<ApiCounterpartyEmployeeTrustPersisted> => {
		const url = urls.counterparty.employee.trust.decline
						.replace(urlTemplateParts.id, counterpartyId)
						.replace(urlTemplateParts.subId, employeeId)
						.replace(urlTemplateParts.thirdId, trustId);
		
		
		let { trust } = await this.client.post<{ trust: object }>(url, { declineReason }, {});
		
		return plainToInstance(ApiCounterpartyEmployeeTrustPersisted, trust);
	};
	
	cancelCounterpartyEmployeeTrust = async (counterpartyId: string,
		employeeId: string,
		trustId: string): Promise<ApiCounterpartyEmployeeTrustPersisted> => {
		const url = urls.counterparty.employee.trust.cancel
						.replace(urlTemplateParts.id, counterpartyId)
						.replace(urlTemplateParts.subId, employeeId)
						.replace(urlTemplateParts.thirdId, trustId);
		
		
		let { trust } = await this.client.post<{ trust: object }>(url, {}, {});
		
		return plainToInstance(ApiCounterpartyEmployeeTrustPersisted, trust);
	};
	
	getCounterpartyTrustActioner = async (
		trustId: string,
		employeeId: string,
		counterpartyId: string,
		actionerKind: string,
		scope = AuthorizationScopeType.GLOBAL
	): Promise<string> => {
		const url = scope === AuthorizationScopeType.GLOBAL
			? prepareUrl(urls.counterparty.employee.trust.getActioner
							 .replace(urlTemplateParts.id, trustId), { actionerKind })
			: prepareUrl(urls.counterparty.employee.trust.getOwnActioner
							 .replace(urlTemplateParts.id, employeeId).replace(urlTemplateParts.subId, trustId), { actionerKind });
		
		let { fullname } = await this.client.get<{ fullname: string }>(url);
		
		return fullname;
	};
	
	getCounterpartyEmployeeActiveTrusts = async (parameters: ApiGetCounterpartyEmployeeActiveTrustsParameters) => {
		const url = prepareUrl(urls.counterparty.employee.trust.getActiveItems, parameters);
		
		let { trusts } = await this.client.get<{ trusts: ApiCounterpartyEmployeeTrustPersisted[] }>(url);
		
		return plainToInstance(ApiCounterpartyEmployeeTrustPersisted, trusts);
	};
}
