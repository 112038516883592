import { BranchesItem } from "@rmp/enterprise/stores/divisions/branches/types/branchesItem";
import { ApiBranchPersisted } from "@rmp/core/api/types/divisions/branch/apiBranch";
import { BranchesState } from "@rmp/enterprise/stores/divisions/branches/index";
import ApiGetBranchesParameters from "@rmp/core/api/types/divisions/branch/apiGetBranchesParameters";
import { BranchesFilterStatusEnum } from "@rmp/enterprise/stores/divisions/branches/types/BranchesFilterStatusEnum";

export default class BranchesMapperProfile {
	constructor() {
	}
	
	static mapToBranchesItem(source: ApiBranchPersisted): BranchesItem {
		return {
			...source.division,
			id: source.id
		};
	}
	
	static mapToApiGetBranchesParameters(source: BranchesState): ApiGetBranchesParameters {
		return {
			take: source.paging.pageSize,
			skip: source.paging.pageSize * (source.paging.page - 1),
			query: source.search.query,
			sortField: source.sorting.type,
			sortingDirection: source.sorting.order,
			isArchived: BranchesFilterStatusEnum.ALL === source.filter.status ? null : source.filter.status === BranchesFilterStatusEnum.ARCHIVED
		};
	}
}
