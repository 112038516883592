import * as ls from "local-storage";
import SearchSuggestion from "@rmp/core/services/searchSuggestion/searchSuggestion";


export default class SearchSuggestionsService {
	suggestionSize: number;

	constructor(suggestionSize = 5) {
		this.suggestionSize = suggestionSize;
	}

	add(key: string, searchSuggestion: SearchSuggestion) {
		if(!ls.get(key)) {
			const suggestions = [];
			suggestions.push(searchSuggestion);
			ls.set(key, suggestions);
		} else {
			const suggestions: SearchSuggestion[] = ls.get(key);

			const filteredSuggestions = suggestions.filter(item => item.id !== searchSuggestion.id);
			filteredSuggestions.unshift(searchSuggestion);

			if(filteredSuggestions.length > this.suggestionSize) {
				filteredSuggestions.pop();
			}

			ls.set(key, filteredSuggestions);
		}
	}
}
