import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { useAppStore } from "@rmp/core/stores/appStore";
import router from "@rmp/enterprise/router";
import { PortDestinationController } from "@rmp/enterprise/api/divisions/port/destination";
import { ApiPortDestination } from "@rmp/core/api/types/divisions/port/destination/apiPortDestinationPersisted";
import { usePortDestinationDetailsStore } from "enterprise/src/stores/divisions/portDestinationDetails";
import { HarborDuesController } from "@rmp/enterprise/api/harborDues";

const abortService = new AbortService();
const controller = new PortDestinationController(abortService);
const harborDuesController = new HarborDuesController(abortService);

const page = usePageStore(abortService);

export interface PortDestinationGeneralState extends PageState {
	branchId: string;
	departmentId: string;
	portId: string;
	id: string;
	portDestination: ApiPortDestination;
	harborDuesIdentifier: number;
}

const getDefaultState = (): PortDestinationGeneralState => {
	return {
		...page.getDefaultPageState(),
		branchId: "",
		departmentId: "",
		portId: "",
		id: "",
		portDestination: {} as ApiPortDestination,
		harborDuesIdentifier: -1
	};
};

export const usePortDestinationDetailsGeneralStore = defineStore({
	id: "port-destination-details-general",
	state: (): PortDestinationGeneralState => getDefaultState(),
	getters: {
		...page.getters,
		breadcrumbs() {
			const { breadcrumbs } = usePortDestinationDetailsStore();
			return breadcrumbs;
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized() {
			this.branchId = router.currentRoute.params.branchId;
			this.departmentId = router.currentRoute.params.departmentId;
			this.portId = router.currentRoute.params.portId;
			this.id = router.currentRoute.params.destinationId;
			
			await this.fetch();
			await this.getPortDestinationForHarborDues();
		},
		async fetch() {
			try {
				const { portDestination } = await controller.getPortDestination(this.portId, this.id);
				
				this.portDestination = portDestination;
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		},
		async getPortDestinationForHarborDues() {
			try {
				this.harborDuesIdentifier = (await harborDuesController.getPortDestination(this.id)).id;
			} catch (error) {
				console.error(error);
			}
		}
	}
});
