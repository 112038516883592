import { MetricsPeriodType } from "@rmp/core/types/metrics/metricsPeriodType";

export default class MetricsByPropertyDetailedFilter {
	period: MetricsPeriodType;
	periodStart: string;
	periodEnd: string;
	
	constructor(
		period: MetricsPeriodType = MetricsPeriodType.CURRENT_MONTH,
		periodStart: string = "",
		periodEnd: string = ""
	)
	{
		this.period = period;
		this.periodStart = periodStart;
		this.periodEnd = periodEnd;
	}
}
