import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { useBranchesBreadcrumb } from "@rmp/enterprise/stores/divisions/branches/composables/useBranchesBreadcrumb";
import { useBranchBreadcrumb } from "@rmp/enterprise/stores/divisions/branch/composables/useBranchesBreadcrumb";
import { useDepartmentBreadcrumb } from "@rmp/enterprise/stores/divisions/department/composables/useDepartmentBreadcrumb";
import { DepartmentController } from "@rmp/enterprise/api/divisions/department";
import { BranchController } from "@rmp/enterprise/api/divisions/branch";
import ApiBranch from "@rmp/core/api/types/divisions/branch/apiBranch";
import { useDepartmentsBreadcrumb } from "@rmp/enterprise/stores/divisions/branch/departments/composables/useDepartmentsBreadcrumb";
import { ApiDepartment } from "@rmp/core/api/types/divisions/department/apiDepartment";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import { DivisionController } from "@rmp/enterprise/api/divisions";
import { useOwnEmployeeStore } from "@rmp/enterprise/stores/ownEmployee";
import { ArchiveStatusState, useArchiveStatusStore } from "@rmp/core/stores/composables/archiveStatus";

const abortService = new AbortService();
const page = usePageStore(abortService);
const archiveStatusStore = useArchiveStatusStore();

const departmentController = new DepartmentController(abortService);
const branchController = new BranchController(abortService);
const divisionController = new DivisionController(abortService);

export interface DepartmentDivisionState extends PageState, ArchiveStatusState {
	branch: ApiBranch;
	department: ApiDepartment;
	branchId: string;
	id: string;
	statusUpdating: boolean;
}

const getDefaultState = (): DepartmentDivisionState => {
	return {
		...page.getDefaultPageState(),
		...archiveStatusStore.getDefaultState(),
		branch: {} as ApiBranch,
		department: {} as ApiDepartment,
		branchId: "",
		id: "",
		statusUpdating: false
	};
};

export const useDepartmentDivisionStore = defineStore({
	id: "department",
	state: (): DepartmentDivisionState => getDefaultState(),
	getters: {
		...page.getters,
		...archiveStatusStore.getters,
		breadcrumbs(state) {
			let result = [];
			
			const { belongsToDepartment } = useOwnEmployeeStore();
			
			if(!belongsToDepartment) {
				result.push(useBranchesBreadcrumb(),
					useBranchBreadcrumb(state.branch.shortName, { id: state.branchId }),
					useDepartmentsBreadcrumb({ id: state.branchId }));
			}
			
			result.push(useDepartmentBreadcrumb(state.department.shortName));
			
			return result;
		}
	},
	actions: {
		...page.actions,
		...archiveStatusStore.actions,
		async beforeInitialized({ branchId, id }: { branchId: string, id: string }) {
			this.branchId = branchId;
			this.id = id;
			await this.fetch();
		},
		async fetch() {
			try {
				const [department, branch] = await Promise.all([
					departmentController.getDepartment(this.id),
					branchController.getBranch(this.branchId)
				]);
				this.department = department.division;
				this.branch = branch.division;
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		},
		async updateArchiveStatus() {
			if(this.department.isArchived)
				await divisionController.unarchive(this.id!);
			else
				await divisionController.archive(this.id!);
			
			this.department.isArchived = !this.department.isArchived;
			
			alertService.addSuccess(AlertKeys.DEPARTMENT_STATUS_UPDATED);
		}
	}
});
