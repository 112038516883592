import AbortService from "@rmp/core/services/abortService";
import { urls } from "@rmp/enterprise/api/config/urls";
import BaseEnterpriseController from "@rmp/enterprise/api/base/baseEnterpriseController";
import { ApiAgreementPersisted } from "@rmp/core/api/types/agreement/apiAgreement";
import { ApiAgreementDocument } from "@rmp/core/api/types/agreement/apiAgreementDocument";
import ApiGetAgreementsParameters from "@rmp/core/api/types/agreement/apiGetAgreementsParameters";
import { ApiAgreementsItem } from "@rmp/core/api/types/agreement/apiAgreementsItem";
import { ApiChatMessage } from "@rmp/core/api/types/chat/apiChatMessage";
import { ApiAgreementHistoryEntryPersisted } from "@rmp/core/api/types/agreement/apiAgreementHistoryEntryPersisted";
import { ApiCreateAgreementDocument } from "@rmp/core/api/types/agreement/apiCreateAgreementDocument";
import { ApiAgreementTransitionCodeType } from "@rmp/core/api/types/agreement/ApiAgreementTransitionCodeType";
import { ApiAgreementDuration } from "@rmp/core/api/types/agreement/apiAgreementDuration";
import { ApiAgreementPaymentMethodEnum } from "@rmp/core/api/types/agreement/ApiAgreementPaymentMethodEnum";
import { ApiGetServicesParameters } from "@rmp/core/api/types/service/apiGetServicesParameters";
import { ApiServicesItem } from "@rmp/core/api/types/service/apiServicesItem";

export class AgreementController extends BaseEnterpriseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getAgreement = async (id: string) => {
		return await this.get<ApiAgreementPersisted>(urls.agreement.$id, { id });
	};
	
	getDocuments = async (id: string) => {
		return await this.get<{ documents: ApiAgreementDocument[] }>(urls.agreement.$id.document, { id });
	};
	
	getAgreements = async (parameters: ApiGetAgreementsParameters) => {
		// TODO
		// return await this.get<{ items: ApiAgreementsItem[], totalCount: number }>(urls.agreement, parameters);
		return await this.get<{ items: ApiAgreementsItem[], totalCount: number }>(urls.agreement.all, parameters);
	};
	
	getChatMessages = async (id: string) => {
		return await this.get<{ messages: ApiChatMessage[] }>(urls.agreement.$id.chat.message, { id });
	};
	
	createChatMessage = async (id: string, message: string): Promise<ApiChatMessage> => {
		return await this.post(urls.agreement.$id.chat.message, { id }, { message });
	};
	
	getHistory = async (id: string) => {
		return await this.get<{ history: ApiAgreementHistoryEntryPersisted[] }>(urls.agreement.$id.history, { id });
	};
	
	getServices = async (id: string, parameters: ApiGetServicesParameters) => {
		return await this.get<{ items: ApiServicesItem[], totalCount: number }>(urls.agreement.$id.services,{id, ...parameters});
	};
	
	saveDocuments = async (id: string, documents: ApiCreateAgreementDocument[]) => {
		return await this.put(urls.agreement.$id.document, { id }, { documents });
	};
	
	saveDuration = async (id: string, duration: ApiAgreementDuration) => {
		return await this.put<ApiAgreementDuration>(urls.agreement.$id.duration, { id }, duration);
	};
	
	saveAgreementDate = async (id: string, date: string) => {
		return await this.put<string>(
			urls.agreement.$id.agreementDate,
			{ id },
			date,
			{ headers: { "Content-Type": "application/json" } }
		);
	};
	
	savePaymentMethod = async (id: string, paymentMethod: ApiAgreementPaymentMethodEnum) => {
		return await this.put<ApiAgreementPaymentMethodEnum>(urls.agreement.$id.paymentMethod,
			{ id },
			paymentMethod,
			{ headers: { "Content-Type": "application/json" } });
	};
	
	changeAgreementStatus = async (id: string, code: ApiAgreementTransitionCodeType, reason?: string) => {
		return await this.post<{ isSuccess: boolean }>(urls.agreement.$id.transition.$code,
			{ id, code },
			{ rejectionTransition: { reason } });
	};
	
	getTemplate = async (agreementId: string) => {
		return await this.get<Blob>(urls.agreement.$id.template, { id: agreementId }, { responseType: "blob" });
	};
	
	getRemainingLimit = async (id: string) => {
		return await this.get<{ remainingLimit: number }>(urls.agreement.$id.remainingLimit, { id });
	};
	
	archive = async (id: string) => {
		return await this.post(urls.agreement.$id.archive, { id }, {});
	};
	
	unarchive = async (id: string) => {
		return await this.post(urls.agreement.$id.unarchive, { id }, {});
	};
}
