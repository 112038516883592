import AbortService from "@rmp/core/services/abortService";
import { ApiBranchPersisted } from "@rmp/core/api/types/divisions/branch/apiBranch";
import { urls } from "@rmp/enterprise/api/config/urls";
import BaseEnterpriseController from "@rmp/enterprise/api/base/baseEnterpriseController";
import ApiGetBranchesParameters from "@rmp/core/api/types/divisions/branch/apiGetBranchesParameters";

export class BranchController extends BaseEnterpriseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getBranches = async (parameters?: ApiGetBranchesParameters) => {
		let items = await this.get<ApiBranchPersisted[]>(urls.enterprise.division.branchBasin, parameters);
		
		return {
			totalCount: items.length,
			items: items as ApiBranchPersisted[]
		};
	};
	
	getBranch = async (id: string): Promise<ApiBranchPersisted> => {
		return await this.get(urls.enterprise.division.branchBasin.$id, { id });
	};
}
