import { useBreadcrumb } from "@rmp/core/stores/composables/breadcrumb/useBreadcrumb";
import routeToPermissionsMap from "@rmp/enterprise/router/routeToPermissionsMap";
import { Location } from "vue-router/types/router";

export function useTariffAssignmentBreadcrumb(route: Location, text: string) {
	return useBreadcrumb({
		route,
		text,
		routeToPermissionsMap
	});
}
