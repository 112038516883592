import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { ChatState, useChatStore } from "@rmp/core/stores/composables/chat";
import { ServiceController } from "@rmp/enterprise/api/service";
import {
	useInformationServiceChatBreadcrumb
} from "@rmp/enterprise/stores/serviceDetails/information/chat/composables/useInformationServiceChatBreadcrumb";
import { useInformationServiceStore } from "@rmp/enterprise/stores/serviceDetails/information";

const abortService = new AbortService();
const page = usePageStore(abortService);

const serviceController = new ServiceController(abortService);
const chatStore = useChatStore({ controller: serviceController });

export interface InformationServiceChatState extends PageState, ChatState {
	id: string | null;
}

const getDefaultState = (): InformationServiceChatState => {
	return {
		...page.getDefaultPageState(),
		...chatStore.getDefaultState(),
		id: null
	};
};

export const useInformationServiceChatStore = defineStore({
	id: "information-service-chat",
	state: (): InformationServiceChatState => getDefaultState(),
	getters: {
		...page.getters,
		...chatStore.getters,
		breadcrumbs() {
			const { breadcrumbs } = useInformationServiceStore();
			return [...breadcrumbs, useInformationServiceChatBreadcrumb()];
		}
	},
	actions: {
		...page.actions,
		...chatStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			await this.initializeChatStore(id);
		}
	}
});
