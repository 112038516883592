import { convertToTimestamp } from "@rmp/core/utils/dates";
import { PiersItem } from "@rmp/enterprise/stores/piers/types/piersItem";
import { ApiPiersItem } from "@rmp/core/api/types/enterpriseProperty/apiPier";
import { ArchiveStatusType } from "@rmp/core/types/ArchiveStatusType";
import ApiGetPiersParameters from "@rmp/core/api/types/enterpriseProperty/apiGetPiersParameters";
import { PiersState } from "@rmp/enterprise/stores/piers/index";

export default class PiersMapperProfile {
	constructor() {
	}
	
	mapToApiGetPiersParameters(source: PiersState): ApiGetPiersParameters {
		return {
			take: source.paging.pageSize,
			skip: source.paging.pageSize * (source.paging.page - 1),
			query: source.search.query,
			sortField: source.sorting.type,
			sortingDirection: source.sorting.order,
			isArchived: ArchiveStatusType.ALL === source.filter.status ? null : source.filter.status === ArchiveStatusType.ARCHIVED
		};
	}
	
	mapToPiersItem(source: ApiPiersItem): PiersItem {
		return {
			...source.pierPersisted.pier,
			id: source.pierPersisted.id,
			createdAt: convertToTimestamp(source.pierPersisted.createdAt) as number,
			department: source.divisionPersisted.division.shortName
		};
	}
}
