import { ApiPersistedBase } from "@rmp/core/api/types/base/apiPersistedBase";
import { Crewboat } from "@rmp/core/types/enterpriseProperty/crewboat";
import { ApiDepartmentPersisted } from "@rmp/core/api/types/divisions/department/apiDepartment";
import { parseFloatNumber } from "@rmp/core/utils/number";

export interface ApiCrewboat {
	name: string;
	callSign: string;
	imo: string;
	mmsi: string;
	flag: string;
	type: string;
	grt: number;
	exclusionGRT?: number;
	nrt: number;
	deadWeight: number;
	length: number;
	width: number;
	height: number;
	power: number;
	portOfRegistry: string;
	numberOfRegistry: string;
	yearOfBuilding?: number;
	status: string;
	isArchived: boolean;
	kind: string;
	calculatedGRT?: number;
	precipitationMaxValue?: number;
}

export interface ApiCrewboatPersisted extends ApiPersistedBase {
	crewboat: ApiCrewboat;
}

export interface ApiCrewboatsItem {
	crewboatPersisted: ApiCrewboatPersisted;
	divisionPersisted: ApiDepartmentPersisted;
}

export class ApiCrewboatMapper {
	static map(source: Crewboat): ApiCrewboat {
		return {
			...source,
			grt: parseFloatNumber(source.grt),
			exclusionGRT: source.exclusionGRT ? parseFloatNumber(source.exclusionGRT) : undefined,
			nrt: parseFloatNumber(source.nrt),
			deadWeight: parseFloatNumber(source.deadWeight),
			length: parseFloatNumber(source.length),
			width: parseFloatNumber(source.width),
			height: parseFloatNumber(source.height),
			power: parseFloatNumber(source.power),
			yearOfBuilding: source.yearOfBuilding ? +source.yearOfBuilding : undefined,
			calculatedGRT: source.calculatedGRT ? parseFloatNumber(source.calculatedGRT) : undefined,
			precipitationMaxValue: source.precipitationMaxValue ? parseFloatNumber(source.precipitationMaxValue) : undefined
		};
	}
}
