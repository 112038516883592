import { difference } from "@rmp/core/utils/difference";
import { Dictionary } from "vue-router/types/router";
import { plainToInstance } from "class-transformer";
import { validateOrReject } from "class-validator";
import RouteQueryService from "@rmp/core/services/route/routeQueryService";
import { CounterpartyDetailsEmployeesState } from "@rmp/enterprise/stores/counterpartyDetails/employees";
import CounterpartyDetailsEmployeesRouteQuery
	from "@rmp/enterprise/stores/counterpartyDetails/employees/types/counterpartyDetailsEmployeesRouteQuery";

export default class CounterpartyDetailsEmployeesRouteQueryService implements RouteQueryService {
	defaultRouteQuery: CounterpartyDetailsEmployeesRouteQuery;
	
	constructor(defaultRouteQuery: CounterpartyDetailsEmployeesRouteQuery) {
		this.defaultRouteQuery = defaultRouteQuery;
	}
	
	private mapStateToQuery(state: CounterpartyDetailsEmployeesState) {
		return new CounterpartyDetailsEmployeesRouteQuery(
			state.paging.page,
			state.sorting.type,
			state.sorting.order,
			state.search.query,
		);
	}
	
	public resolveRouteQueryDictionary(state: CounterpartyDetailsEmployeesState) {
		let query = this.mapStateToQuery(state);
		
		return difference(this.defaultRouteQuery, query);
	}
	
	public async hasRouteChanges(state: CounterpartyDetailsEmployeesState, route: Dictionary<any>): Promise<{ [key: string]: any }> {
		let stateQuery = this.mapStateToQuery(state);
		let routeQuery = await this.resolveRouteQuery(route);
		
		return difference(routeQuery, stateQuery);
	}
	
	public async resolveRouteQuery(query: Dictionary<any>) {
		try {
			let result = plainToInstance(CounterpartyDetailsEmployeesRouteQuery, {
				...this.defaultRouteQuery,
				...query,
			}, { enableImplicitConversion: true });
			
			await validateOrReject(result);
			
			return result;
		} catch (e) {
			console.error(e);
			return this.defaultRouteQuery;
		}
	}
}
