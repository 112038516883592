import { Dictionary } from "vue-router/types/router";
import { useBreadcrumb } from "@rmp/core/stores/composables/breadcrumb/useBreadcrumb";
import { RouteNames } from "@rmp/enterprise/router/routes";
import routeToPermissionsMap from "@rmp/enterprise/router/routeToPermissionsMap";

export function useCounterpartyDetailsBreadcrumb(text: string, params?: Dictionary<string>) {
	return useBreadcrumb({
		route: {
			name: RouteNames.COUNTERPARTY_DETAILS_GENERAL, params
		},
		text,
		routeToPermissionsMap
	});
}
