import { ApiGetMetricsByPropertyDetailedParameters } from "@rmp/core/api/types/metrics/apiGetMetricsByPropertyDetailedParameters";
import { MetricsByPropertyDetailedState } from "@rmp/enterprise/stores/metrics/byPropertyDetailed/index";

export default class MetricsByPropertyDetailedMapperProfile {
	constructor() {
	}
	
	mapToApiGetMetricsByPropertyDetailedParameters(source: MetricsByPropertyDetailedState): ApiGetMetricsByPropertyDetailedParameters {
		return {
			from: source.filter.periodStart || source.periods.find(x => source.filter.period === x.value)?.start || "",
			to: source.filter.periodEnd || source.periods.find(x => source.filter.period === x.value)?.end || "",
			propertyId: source.id
		};
	}
}
