export interface DocumentsState<DocumentType> {
	id: string | null;
	documents: DocumentType[];
}

interface DocumentsOptions {
}

export const useDocumentsStore = <DocumentType>(options: DocumentsOptions) => {
	const getDefaultState = (): DocumentsState<DocumentType> => {
		return {
			id: null,
			documents: []
		};
	};
	
	const getters = {};
	
	const actions = {
		async initializeDocumentsStore(this: DocumentsState<DocumentType>) {
			
		}
	};
	
	return { getters, actions, getDefaultState };
};
